import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ContextService } from '@bmng/services/context/context.service';
import { Context } from '@bmng/services/context/interfaces/context.interface';
import { LoginService } from '@bmng/services/login.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const contextResolver: ResolveFn<Context> =
    (route: ActivatedRouteSnapshot) => {
        const contextService = inject(ContextService);
        const loginService = inject(LoginService);

        const errCtx: Context = {
            channel: { type: null, id: null, role: null, name: null, billTo: null, supportPartner: null },
            unit: { type: null, id: null, role: null, name: null, billTo: null, supportPartner: null },
            hotel: { type: null, id: null, role: null, name: null, billTo: null, supportPartner: null },
        };

        return contextService.getContext(route)
            .pipe(
                map(cor => cor.context),
                catchError(err => {
                    loginService.catchUnauthenticatedUser(err);
                    return of(errCtx);
                }),
            );
    };
