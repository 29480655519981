import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { EndpointService } from '@bmng/services/endpoint.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    Voucher,
    VoucherAutocompleteField,
    VoucherAutocompleteItem,
    VoucherListResult,
} from './interfaces/vouchers.interface';
import { VoucherListParameters, VouchersServiceInterface } from './interfaces/vouchers.service.interface';

@Injectable()
export class VouchersService extends EndpointService implements VouchersServiceInterface {
    constructor(http: HttpClient) {
        super(http);
    }

    getVoucherList(hotelId: string, params?: VoucherListParameters): Observable<VoucherListResult> {
        let baseUrl: string = `${EndpointService.getBmBackendUrl()}/api/voucher/hotel/${hotelId}`;

        if (!params)  {
            baseUrl += '?includeAutoGenerated=true';
        }

        return this.httpGet<VoucherListResult>(UrlHelpers.buildUrl(baseUrl, params || {}), EndpointService.HTTP_HEADERS_ACCEPT)
            .pipe(
                map(voucherList => {
                    voucherList.result = voucherList.result.map(this.preprocessVoucherFromEndpoint);
                    return voucherList;
                }),
            );
    }

    getVoucher(hotelId: string, voucherId: number): Observable<Voucher> {
        const url: string = `${EndpointService.getBmBackendUrl()}/api/voucher/hotel/${hotelId}/${voucherId}`;

        return this.httpGet<Voucher>(url, EndpointService.HTTP_HEADERS_ACCEPT)
            .pipe(
                map(voucher => this.preprocessVoucherFromEndpoint(voucher)),
            );
    }

    updateVoucher(hotelId: string, voucher: Voucher): Observable<boolean> {
        const url: string = `${EndpointService.getBmBackendUrl()}/api/voucher/hotel/${hotelId}`;

        if (voucher.id) {
            // Edit
            return this.httpPut(url, this.preprocessVoucherForEndpoint(voucher), EndpointService.HTTP_HEADERS);
        } else {
            // Create
            return this.httpPost(url, this.preprocessVoucherForEndpoint(voucher), EndpointService.HTTP_HEADERS);
        }
    }

    private preprocessVoucherForEndpoint(voucher: Voucher): Voucher {
        const copiedVoucher: Voucher = { ...voucher };

        if (!copiedVoucher.currencyCode) {
            copiedVoucher.currencyCode = null;
        }

        return copiedVoucher;
    }

    public preprocessVoucherFromEndpoint(voucher: Voucher): Voucher {
        if (!voucher.discount || voucher.discount.type === 'PERCENT') {
            voucher.currencyCode = null;
        }

        return voucher;
    }

    deleteVoucher(hotelId: string, voucherId: number): Observable<boolean> {
        const url: string = `${EndpointService.getBmBackendUrl()}/api/voucher/hotel/${hotelId}/${voucherId}`;

        return this.httpDelete(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    autocompleteVoucherFilter(hotelId: string, type: VoucherAutocompleteField, searchTerm: string): Observable<VoucherAutocompleteItem[]> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/hotel/switch/api/autocomplete/${hotelId}/voucher/${type}`;
        const url = UrlHelpers.buildUrl(baseUrl, { value: searchTerm });

        return this.httpGet(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    downloadVouchers(hotelId: string, isMultiProperty: boolean, languageCode: string): void {
        const url: string = `${EndpointService.getBmBackendUrl()}/api/voucher/hotel/${hotelId}/search.xlsx` +
                           `?languageCode=${languageCode}&multiPropertyContext=${isMultiProperty}`;
        window.open(url, '_blank');
    }
}
