import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sentenceCasePipe',
})
export class SentenceCasePipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return '';
        }
        return value.trim()[0].toUpperCase() + value.trim().substring(1);
    }
}
