// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
    production: false,
    baseUrls: {
        bm: '/~',
        kube: 'dev.officialbookings.com',
        bfBackend: 'https://widget-bf-dev.seekda.com',
        history: 'https://cm-history-dev.seekda.com',
        historyNew: 'https://cm-history-ng-dev.seekda.com',
        images: 'https://images-dev.seekda.com',
        switch: 'https://switch-dev.seekda.com',
        cpc: 'https://cpc-dev.seekda.com',
        search: 'https://s-dev.seekda.com',
        cloudinary: 'https://res.cloudinary.com/seekda-dev/image',
        quality: 'https://quality-dev.seekda.com',
        ibe: 'https://ibe-dev.seekda.com',
        login: 'https://login-dev.seekda.com',
        oldBm: 'https://cm-dev.seekda.com',
        my: 'https://my-dev.seekda.com',
        atlassian: 'https://valsofthospitality.atlassian.net',
        hbe: 'https://hbe-api-dev.seekda.com',
        widgetsPreview: 'https://widgets-configuration.preview.kognitiv.com',
        seekdaMain: 'https://www.seekda.com',
        bmBackendUrls: [
            {
                frontend: 'https://hotels-dev.seekda.com',
                backend: 'https://bm-backend-dev.seekda.com',
            },
            {
                frontend: 'https://hotels-dev.kognitiv.com',
                backend: 'https://bm-backend-dev.kognitiv.com',
            },
        ],
        paymentLib: 'https://payment-dev.seekda.com/lib/kognitiv-payment.js',
        frontdesk: 'https://frontdesk-qa.seekda.com',
    },
    apiKeys: {
        googleMaps: 'AIzaSyDydZJ7RZF1SAXwg4pZqnFFskYL8CW5RqA',
    },
};
