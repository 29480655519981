<div class="app-container" [ngClass]="{ 'paddingAndMarginReduced' : pageService.isRemovingPaddingAndMargin() }">
    <menu class="main-menu" [class.open]="pageService.isMenuShown()" bm-menu [hidden]="pageService.isOnlyPageContentsShown()"></menu>
    <div class="content-container" [class.content-container-max-width]="pageService.isMaxWidth">
        <header class="main-header" bm-header [hidden]="pageService.isOnlyPageContentsShown()" [class.compact]="pageService.isMenuCompact()"></header>
        <action-bar-desktop class="content-top" [ngClass]="{'full-width': !pageService.isMenuShown() }"></action-bar-desktop>

        <div class="content">
            <bm-notifications *ngIf="navSrv.getStackHeight() === 0" position="top-center" [offset]="50" [notifications]="pageService.notifications"></bm-notifications>
            <router-outlet></router-outlet>
        </div>
        <div class="show-xsmall show-small show-medium">
            <bm-fab-menu [buttonConfigs]="pageService.buttonConfigs" [breadcrumbConfigs]="pageService.breadcrumbConfigs"></bm-fab-menu>
        </div>
    </div>
</div>
<bm-modal type="alert" [(opened)]="pageService.errorShown" modalStyle="error" [bgClose]="false">
    <div bmModalHeader>{{ pageService.errorTitle }}</div>
    <div bmModalBody>{{ pageService.errorMessage }}</div>
</bm-modal>