import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'imgToBanner',
})
export class ImgToBannerPipe implements PipeTransform {
    // Extract the sources matched with the ids passed by param
    transform(img: string, width: number, aspectRatio: number): string {
        if (!img) {
            return '';
        }

        if (width === -1 && aspectRatio === -1) {
            return img;
        }

        if (!aspectRatio) {
            aspectRatio = 4.5;
        }

        if (!width) {
            width = 200;
        }

        return img.replace('upload', `upload/w_${width},c_fill,ar_${aspectRatio}`);
    }
}
