import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CodeTitle } from '@bmng/services/metadata/interfaces/metadata.interface';

import { UiPolicyTax, UiTaxPaymentPolicy, UiTaxPolicyPayment as UiTaxPolicyPayment } from '../../../../pages/master/policies/interface/policies-ui.interface';
import { PolicyFormHelpersService } from '../policy-form-helpers.service';
import { PolicyFormValidators } from '../policy-form-validators.service';

import { PolicyFormTaxValidators } from './policy-form-tax-validators.service';

@Injectable()
export class PolicyFormTaxService {
    constructor(
        private formHelpersService: PolicyFormHelpersService,
        private taxValidators: PolicyFormTaxValidators,
        private validators: PolicyFormValidators,
    ) {}

    getTaxPolicyForm(
        policyConfig: UiPolicyTax,
        isNewPolicy: boolean,
        languages: CodeTitle[],
        usedCodes?: string[],
    ): UntypedFormGroup {
        if (!policyConfig) {
            return;
        }

        return new UntypedFormGroup({
            code: new UntypedFormControl(policyConfig.code, [
                Validators.required,
                this.validators.uniqueCodeValidation(isNewPolicy, usedCodes),
            ]),
            currencyCode: new UntypedFormControl(policyConfig.currencyCode),
            uiPaymentPolicies: new UntypedFormArray((policyConfig.uiPaymentPolicies || [])
                .map(policy => this.getTaxPaymentPolicyControls(policy, languages))),
        });
    }

    getTaxPaymentPolicyControls(policy: UiTaxPaymentPolicy, languages: CodeTitle[]): UntypedFormGroup {
        return new UntypedFormGroup({
            taxType: new UntypedFormControl(policy.taxType),
            priceCalculation: new UntypedFormControl(policy.priceCalculation),
            vatCalculation: new UntypedFormControl(policy.vatCalculation),
            taxBase: new UntypedFormControl(policy.taxBase),
            showDateRanges: new UntypedFormControl(policy.showDateRanges),
            showAdvancedOptions: new UntypedFormControl(policy.showAdvancedOptions),
            showMaximum: new UntypedFormControl(policy.showMaximum),
            showAgeRanges: new UntypedFormControl(policy.showAgeRanges),
            uiPayments: new UntypedFormArray((policy.uiPayments || []).map(uiPayment => this.getTaxPaymentControls(uiPayment))),
            descriptions: new UntypedFormArray(
                this.formHelpersService.getDescriptionControlsByLang(languages, policy.descriptions, true),
            ),
            descriptionTabIndex: new UntypedFormControl(0),
            showDescriptions: new UntypedFormControl(policy.showDescriptions),
        }, [
            this.taxValidators.taxPaymentPolicyValidation(),
        ]);
    }

    getTaxPaymentControls(calculation: UiTaxPolicyPayment): UntypedFormGroup {
        return new UntypedFormGroup({
            dateFrom: new UntypedFormControl(calculation.dateFrom || null),
            dateTo: new UntypedFormControl(calculation.dateTo || null),
            amounts: new UntypedFormArray((calculation.amounts || []).map(amountGroup => this.getTaxAmountsControls(amountGroup))),
        });
    }

    getTaxAmountsControls(amountGroup: UiTaxPolicyPayment['amounts'][0]): UntypedFormGroup {
        return new UntypedFormGroup({
            amount: new UntypedFormControl(amountGroup.amount),
            percent: new UntypedFormControl(amountGroup.percent),
            maxAmount: new UntypedFormControl(amountGroup.maxAmount),
            ageFrom: new UntypedFormControl(amountGroup.ageFrom),
            ageTo: new UntypedFormControl(amountGroup.ageTo),
            summary: new UntypedFormControl(amountGroup.summary),
        });
    }

    updateTaxPolicySummary(policyConfig: UiPolicyTax, form: UntypedFormGroup): void {
        const uiPaymentPolicyCtrls = form.get('uiPaymentPolicies') as UntypedFormArray;
        if (uiPaymentPolicyCtrls && uiPaymentPolicyCtrls.controls) {
            uiPaymentPolicyCtrls.controls.forEach((uiPaymentPolicyForm: UntypedFormGroup, paymentPolicyIndex) => {
                this.updateTaxPaymentPolicySummary(policyConfig, uiPaymentPolicyForm, paymentPolicyIndex);
            });
        }
    }

    updateTaxPaymentPolicySummary(
        policyConfig: UiPolicyTax,
        uiPaymentPolicyCtrls: UntypedFormGroup,
        paymentPolicyIndex: number,
    ): void {
        const paymentPolicy = policyConfig.uiPaymentPolicies[paymentPolicyIndex];
        const uiPaymentsCtrls = uiPaymentPolicyCtrls.get('uiPayments') as UntypedFormArray;
        if (paymentPolicy && uiPaymentsCtrls) {
            uiPaymentsCtrls.controls.forEach((paymentCtrl: UntypedFormGroup, paymentIndex) => {
                const payment = paymentPolicy.uiPayments[paymentIndex];
                const amountsCtrls = paymentCtrl.get('amounts') as UntypedFormArray;
                if (payment && amountsCtrls) {
                    amountsCtrls.controls.forEach((amountCtrl: UntypedFormGroup, amountIndex: number) => {
                        const amounts = payment.amounts[amountIndex];
                        const summaryCtrl = amountCtrl.get('summary');

                        if (amounts && summaryCtrl) {
                            summaryCtrl.setValue(amounts.summary);
                        }
                    });
                }
            });
        }
    }
}
