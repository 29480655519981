import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormHelpers } from '@bmng/helpers/form-helpers';
import { LangService } from '@kognitiv/angular-i18n';

import { LeadTimeType, UiCancellationPenalty } from '../../../../pages/master/policies/interface/policies-ui.interface';

@Injectable()
export class PolicyFormCancellationValidators {
    constructor(
        private lang: LangService,
    ) {}

    paymentPolicyLeadTimeAlwaysValidator(): ValidatorFn {
        return (paymentPolicyGroup: UntypedFormGroup): ValidationErrors => {
            const leadTimeAlwaysError: ValidationErrors = {
                leadTimeAlways: this.lang.get('bmng.policies.error.penalty.always.unique'),
            };
            const uiPenalties = paymentPolicyGroup.get('uiPenalties') as UntypedFormArray;
            if (!uiPenalties || !uiPenalties.controls) {
                return null;
            }
            const penaltiesValue = uiPenalties.value as UiCancellationPenalty[];
            const leadTimeValues = penaltiesValue.map(penalty => penalty.leadTimeType);
            const leadTimeAlwaysValues = leadTimeValues.filter(value => value === 'Always');
            const hasLeadTimeAlwaysError = leadTimeAlwaysValues.length > 1;

            uiPenalties.controls.forEach(uiPenalty => {
                const leadTimeTypeControl = uiPenalty.get('leadTimeType');
                const leadTimeType = leadTimeTypeControl.value as LeadTimeType;
                if (hasLeadTimeAlwaysError && leadTimeType === 'Always') {
                    FormHelpers.addErrors(leadTimeTypeControl, leadTimeAlwaysError);
                } else {
                    FormHelpers.clearErrors(leadTimeTypeControl, 'leadTimeAlways');
                }
            });

            return hasLeadTimeAlwaysError ? leadTimeAlwaysError : null;
        };
    }

    paymentPolicyLeadTimeDaysValidator(): ValidatorFn {
        return (paymentPolicyGroup: UntypedFormGroup): ValidationErrors => {
            const leadTimeDaysError: ValidationErrors = {
                leadTime: this.lang.get('bmng.policies.error.penalty.days.unique'),
            };
            const uiPenalties = paymentPolicyGroup.get('uiPenalties') as UntypedFormArray;
            if (!uiPenalties || !uiPenalties.controls) {
                return null;
            }

            const leadTimeValues: string[] = [];
            uiPenalties.controls.forEach(uiPenalty => {
                const leadTimeDaysControl = uiPenalty.get('leadTimeDays');
                const leadTimeOfDayControl = uiPenalty.get('leadTimeOfDay');
                if (leadTimeDaysControl.enabled) {
                    const days = leadTimeDaysControl.value as number | string;
                    const hours = leadTimeOfDayControl.value as string;
                    const leadTime = this.getLeadTime(days, hours);
                    if (leadTime) {
                        leadTimeValues.push(leadTime);
                    }
                }
            });

            const duplicateTimeValues: string[] = [];
            for (const timeValue of leadTimeValues) {
                if (leadTimeValues.length > 1 && leadTimeValues.indexOf(timeValue) !== leadTimeValues.lastIndexOf(timeValue)) {
                    duplicateTimeValues.push(timeValue);
                }
            }

            const hasLeadTimeDaysError = duplicateTimeValues.length > 0;

            uiPenalties.controls.forEach(uiPenalty => {
                const leadTimeDaysControl = uiPenalty.get('leadTimeDays');
                const leadTimeOfDayControl = uiPenalty.get('leadTimeOfDay');
                const days = leadTimeDaysControl.value as number | string;
                const hours = leadTimeOfDayControl.value as string;
                const leadTime = this.getLeadTime(days, hours);

                if (hasLeadTimeDaysError && duplicateTimeValues.includes(leadTime)) {
                    FormHelpers.addErrors(leadTimeDaysControl, leadTimeDaysError);
                    leadTimeOfDayControl.setErrors(leadTimeDaysError);
                } else {
                    FormHelpers.clearErrors(leadTimeDaysControl, 'leadTime');
                    leadTimeOfDayControl.setErrors(null);
                }
            });

            return hasLeadTimeDaysError ? leadTimeDaysError : null;
        };
    }

    private getLeadTime(days: number | string, hours: string): string {
        if (typeof days === 'number' || typeof days === 'string') {
            if (hours) {
                return `${days}:${hours}`;
            }
            return `${days}`;
        }

        return null;
    }
}
