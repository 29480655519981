
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { EndpointService } from '@bmng/services/endpoint.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MailInheritance, MailInheritanceParameters, MailStyle } from './interfaces/guest-notification-style.interface';
import { GuestNotificationStylesServiceInterface } from './interfaces/guest-notification-styles.service.interface';

@Injectable()
export class GuestNotificationStyleService extends EndpointService implements GuestNotificationStylesServiceInterface {
    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    getStyle(hotelId: string): Observable<MailStyle> {
        const url: string = `${EndpointService.getBmBackendUrl()}/api/notifications/${hotelId}/templateBranding?withInheritance=true`;

        return this.httpGet(url, EndpointService.HTTP_HEADERS_ACCEPT).pipe(
            map(this.fixEmptyResults),
        );
    }
    updateStyle(hotelId: string, style: MailStyle): Observable<boolean> {
        const url: string = `${EndpointService.getBmBackendUrl()}/api/notifications/${hotelId}/templateBranding?withInheritance=true`;

        return this.httpPost(url, style, EndpointService.HTTP_HEADERS);
    }

    getInheritanceInfo(chainId: string, params: MailInheritanceParameters): Observable<MailInheritance> {
        const url: string = `${EndpointService.getBmBackendUrl()}/api/notifications/${chainId}/templateInheritance`;

        const fullUrl = UrlHelpers.buildUrl(url, params);
        return this.httpGet(fullUrl, EndpointService.HTTP_HEADERS);
    }

    fixEmptyResults(style: MailStyle): MailStyle {
        if (style.templates == null) {
            style.templates = [];
        }

        return style;
    }
}
