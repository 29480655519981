import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointService } from '@bmng/services/endpoint.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { MailTemplatePlaceholder } from '../guest-notifications/interfaces/guest-notifications.interface';

import {
    GuestNotificationCustom,
    GuestNotificationCustomOverview,
} from './interfaces/guest-notification-custom.interface';
import { GuestNotificationCustomServiceInterface } from './interfaces/guest-notification-custom.service.interface.ts';

@Injectable()
export class GuestNotificationCustomService extends EndpointService implements GuestNotificationCustomServiceInterface {
    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    getTemplatesList(hotelId: string): Observable<GuestNotificationCustomOverview[]> {
        const url: string = `${EndpointService.getBmBackendUrl()}/api/notifications/${hotelId}/customNotifications`;

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }
    getTemplate(hotelId: string, templateId: number): Observable<GuestNotificationCustom> {
        const url: string = `${EndpointService.getBmBackendUrl()}/api/notifications/${hotelId}/customNotifications/${templateId}`;

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }

    updateTemplate(hotelId: string, template: GuestNotificationCustom): Observable<never> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/notifications/${hotelId}/customNotifications`;

        if (template.id) {
            return this.httpPut(`${baseUrl}/${template.id}`, template, EndpointService.HTTP_HEADERS);
        } else {
            return this.httpPost(baseUrl, template, EndpointService.HTTP_HEADERS);
        }
    }
    deleteTemplate(hotelId: string, templateId: number): Observable<boolean> {
        const url = `${EndpointService.getBmBackendUrl()}/api/notifications/${hotelId}/customNotifications/${templateId}`;

        return this.httpDelete(url, EndpointService.HTTP_HEADERS);
    }

    validateTemplate(hotelId: string, template: GuestNotificationCustom): Observable<never> {
        const url = `${environment.baseUrls.switch}/switch/api/notifications/${hotelId}/customNotifications/validate`;

        return this.httpPost(url, template, EndpointService.HTTP_HEADERS);
    }

    getPlaceholders(templateType: 'prepost'): Observable<MailTemplatePlaceholder[]> {
        const url = `${environment.baseUrls.switch}/switch/api/notifications/customNotifications/placeholders`;

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }
}
