import { Injectable } from '@angular/core';
import { LangService } from '@kognitiv/angular-i18n';
import { PageService } from './page.service';

@Injectable()
export class ClipboardService {
    constructor(
        private lang: LangService,
        private pageService: PageService,
    ) {}

    copyToClipboard(text: string): void {
        if (!navigator || !text) {
            return;
        }

        if (!navigator.clipboard) {
            this.pageService.pushNotification({
                content: this.lang.get('bm.copyToClipboard.browserSupport'),
                type: 'error',
            });
            return;
        }

        navigator.clipboard.writeText(text).then(() => {
            this.pageService.pushNotification({
                content: this.lang.get('bm.copyToClipboard.success'),
                type: 'success',
            });
        }, () => {
            this.pageService.pushNotification({
                content: this.lang.get('bm.copyToClipboard.error'),
                type: 'error',
            });
        });
    }

}
