import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class RouteTransformerService {
    constructor(
        private route: ActivatedRoute,
    ) {}

    getActiveNavigation(pathPattern: string): string {
        if (!pathPattern) {
            return;
        }

        const filterPathStrings: string[] = [ 'global', 'portal', 'circle', 'master', 'pms', 'unit', 'hotel', 'iframe', 'embedded' ];

        const paths = pathPattern.split('/').filter(path =>
            !!path && !filterPathStrings.includes(path) && path !== '*',
        );

        return paths[0];
    }

    getRoute(outlet: 'primary' | 'level1' | 'level2'): string {
        const pathParts = [];
        let snapshot = null;

        if (!!this.route && !!this.route.root && !!this.route.root.children) {
            snapshot = this.route.root.children.find(c => c.outlet === outlet);
        }

        if (!snapshot) {
            return '';
        }

        do {
            if (snapshot.routeConfig && snapshot.routeConfig.path) {
                pathParts.push(snapshot.routeConfig.path);
            }
            snapshot = snapshot.firstChild;
        } while (snapshot);

        return pathParts.join('/');
    }

    getPathPattern(): { pathPattern: string; modalPathPattern?: string } {

        const transformRoute = function(route: string): string {
            return '/' + route.replace(/:\w+/g, '*').replace(/modal\//, '');
        };

        const baseRoute = this.getRoute('primary');
        const level1Route = this.getRoute('level1');
        const level2Route = this.getRoute('level2');

        const pathPattern = transformRoute(baseRoute);
        if (level2Route) {
            return {
                pathPattern,
                modalPathPattern: transformRoute(level2Route),
            };
        } else if (level1Route) {
            return {
                pathPattern,
                modalPathPattern: transformRoute(level1Route),
            };
        }

        return {
            pathPattern,
        };
    }
}
