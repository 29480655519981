import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LangService } from '@kognitiv/angular-i18n';

@Injectable()
export class PolicyFormValidators {
    constructor(private lang: LangService) {}

    setConditionalRequiredValidator(control: UntypedFormControl, condition: boolean): void {
        if (!control) {
            return;
        }
        if (condition) {
            control.setValidators([ Validators.required ]);
        } else {
            control.clearValidators();
            control.setErrors(null);
        }
    }

    setConditionalRequiredAndMaxValidators(control: UntypedFormControl, condition: boolean, max: number = 999999.99): void {
        if (!control) {
            return;
        }
        if (condition) {
            control.setValidators([ Validators.required, Validators.max(max) ]);
        } else {
            control.clearValidators();
            control.setErrors(null);
        }
    }

    setConditionalMaxValidator(control: UntypedFormControl, condition: boolean, max: number = 999999.99): void {
        if (!control) {
            return;
        } if (condition) {
            control.setValidators([ Validators.max(max) ]);
        } else {
            control.setErrors(null);
            control.clearValidators();
        }
    }

    uniqueCodeValidation(isNewPolicy: boolean, usedCodes: string[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if (!isNewPolicy) {
                return null;
            }
            const code: string = control.value;
            if (code) {
                const pattern = new RegExp('^[A-Za-z0-9-_]+$');
                if (!pattern.test(code)) {
                    return {
                        code: this.lang.get('cc.policy.policycode.errormessage'),
                    } as ValidationErrors;
                }
                if (Array.isArray(usedCodes) && usedCodes.includes(code)) {
                    return {
                        code: this.lang.get('bmng.policies.validation.code.unique.error'),
                    } as ValidationErrors;
                }
            }
            return null;
        };
    }
}
