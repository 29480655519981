import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { LangService } from '@kognitiv/angular-i18n';
import { Observable } from 'rxjs';

import { EndpointService } from '../endpoint.service';

import { BookingEngine, KubeWidget, KubeWidgetConfiguration, WidgetsUsage, WidgetsUsageDetail } from './interfaces/kube-widgets.interface';
import { KubeWidgetsServiceInterface } from './interfaces/kube-widgets.service.interface';
import { BookingEngineContextType, KubeWidgetsContextType } from './../context/interfaces/context-type.type';

@Injectable()
export class KubeWidgetsService extends EndpointService implements KubeWidgetsServiceInterface {
    private static readonly TRAFFIC_URL = `${EndpointService.getBmBackendUrl()}/api/traffic`;

    constructor(
        private readonly langService: LangService,
        protected readonly httpClient: HttpClient,
    ) {
        super(httpClient);
    }

    getWidgetsUsageOverview(type: KubeWidgetsContextType, assetId: string): Observable<WidgetsUsage[]> {
        const languageCode = this.langService.getCurrentLanguage();
        const url = UrlHelpers.buildUrl(`${KubeWidgetsService.TRAFFIC_URL}/${type}/${assetId}/bookingEngine/summary`, {
            languageCode,
        });

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }

    getWidgetsUsage(type: KubeWidgetsContextType, assetId: string, bookingEngine: BookingEngine): Observable<WidgetsUsageDetail[]> {
        const languageCode = this.langService.getCurrentLanguage();
        const url = `${KubeWidgetsService.TRAFFIC_URL}/${type}/${assetId}/bookingEngine/${bookingEngine.toLowerCase()}`;
        const fullUrl = UrlHelpers.buildUrl(url, {
            languageCode,
        });

        return this.httpGet(fullUrl, EndpointService.HTTP_HEADERS);
    }

    getWidgetsList(context: BookingEngineContextType): Observable<KubeWidget[]> {
        if (context === 'direct'){
            context = 'hotel';
        }

        const url = `${EndpointService.getBmBackendUrl()}/api/${context}/kubeDocs`;

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }

    getWidgetConfiguration(context: BookingEngineContextType, widgetId: string): Observable<KubeWidgetConfiguration> {
        if (context === 'direct'){
            context = 'hotel';
        }

        const url = `${EndpointService.getBmBackendUrl()}/api/${context}/kubeDocs/widgets/${widgetId}`;

        return this.httpGet<KubeWidgetConfiguration>(url, EndpointService.HTTP_HEADERS);
    }
}
