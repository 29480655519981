import { GuestNotification } from '../interfaces/guest-notifications.interface';

const mockData: GuestNotification[] = [
    {
        type: 'bookingConfirmation',
        enabled: true,
        settings: {
            senderName: '',
            replyMail: 'hotel@example.com',
            contents: {
                en: { subject: 'Foo EN', message: 'Bar EN' },
                de: { subject: 'Foo DE', message: 'Bar DE' },
                es: { subject: 'Foo ES', message: 'Bar ES' },
            },
            adviceTexts: {
                en: { subject: 'Foo Advice EN', message: 'Bar Advice EN' },
                de: { subject: 'Foo Advice DE', message: 'Bar Advice DE' },
                ch: { subject: 'Foo Advice CH', message: 'Bar Advice CH' },
            },
            placeholders: [
                {
                    key: 'guest.firstName',
                    description: 'Guest\'s first name',
                },
                {
                    key: 'guest.lastName',
                    description: 'Guest\'s last name',
                },
            ],
        },
    },
    {
        type: 'bookingCancellation',
        enabled: true,
        settings: {
            senderName: '',
            replyMail: 'hotel@example.com',
            contents: {
                en: { subject: 'Foo EN', message: 'Bar EN' },
                de: { subject: 'Foo DE', message: 'Bar DE' },
            },
            placeholders: [
                {
                    key: 'guest.firstName',
                    description: 'Guest\'s first name',
                },
                {
                    key: 'guest.lastName',
                    description: 'Guest\'s last name',
                },
            ],
        },
    },
    {
        type: 'oversellingInitial',
        enabled: true,
        settings: {
            senderName: 'Example Hotel',
            replyMail: 'hotel@example.com',
            contents: {
                en: { subject: 'Foo EN', message: 'Bar EN' },
                de: { subject: 'Foo DE', message: 'Bar DE' },
            },
            placeholders: [
                {
                    key: 'guest.firstName',
                    description: 'Guest\'s first name',
                },
                {
                    key: 'guest.lastName',
                    description: 'Guest\'s last name',
                },
            ],
        },
    },
    {
        type: 'oversellingRejected',
        enabled: true,
        settings: {
            senderName: 'Example Hotel',
            replyMail: 'hotel@example.com',
            contents: {
                en: { subject: 'Foo EN', message: 'Bar EN' },
                de: { subject: 'Foo DE', message: 'Bar DE' },
            },
            placeholders: [
                {
                    key: 'guest.firstName',
                    description: 'Guest\'s first name',
                },
                {
                    key: 'guest.lastName',
                    description: 'Guest\'s last name',
                },
            ],
        },
    },
    {
        type: 'smsNotification',
        enabled: false,
        settings: {
            number: {
                countryCode: '43',
                areaCode: '1',
                lineNumber: '2365084',
            },
        },
    },
];

export default {
    data: mockData,
};
