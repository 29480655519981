import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LangService } from '@kognitiv/angular-i18n';
import { Observable } from 'rxjs';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { EndpointService } from '../endpoint.service';
import { ReservationMisconductOptions, ReservationMisconductReasons } from './interfaces/reservations-misconduct.interface';
import { ReservationsMisconductServiceInterface } from './interfaces/reservations-misconduct.service.interface';

@Injectable()
export class ReservationsMisconductService extends EndpointService implements ReservationsMisconductServiceInterface {
    constructor(
        http: HttpClient,
        private lang: LangService,
    ) {
        super(http);
    }

    /**
     *
     * @param channelId NOT THE CONTEXTID! This is the actual channelId from the source
     */
    getMisconductOptions(
        channelId: string,
    ): Observable<ReservationMisconductOptions> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/channels/${channelId}/misconductcategory`;

        const url = UrlHelpers.buildUrl(baseUrl, {
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }

    reportMisconduct(
        hotelId: string,
        reservationId: string,
        channelId: string,
        data: ReservationMisconductReasons,
    ): Observable<boolean> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/hotels/${hotelId}/reservationId/${reservationId}/misconductcategory`;

        const url = UrlHelpers.buildUrl(baseUrl, {
            channelId: channelId ?? undefined,
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpPost(url, data, EndpointService.HTTP_HEADERS);
    }
}
