import { Pipe, PipeTransform } from '@angular/core';
import { DateRangeSingleDatesTranslator } from '@bmng/helpers/date-range-single-dates-translator';
import { UiBookingPaymentPolicy } from '@bmng/pages/master/policies/interface/policies-ui.interface';
import { PolicyBookingType } from '@bmng/services/policies/interfaces/policies.interface';
import { UsersService } from '@bmng/services/users/users.service';
import { PoliciesTextHelpers } from '@bmng/shared/services/policies-text-helpers';
import { LangService } from '@kognitiv/angular-i18n';
import { BmCurrencyPipe } from '@kognitiv/bm-components';

import { ExpiredDateRangeHelper } from '../services/expired-date-range.helper';

@Pipe({
    name: 'policyBookingPaymentSummary',
})
export class PolicyBookingPaymentSummaryPipe implements PipeTransform {
    private activeLanguage: string = 'EN';

    constructor(
        private lang: LangService,
        private currencyPipe: BmCurrencyPipe,
        private textHelper: PoliciesTextHelpers,
        private userService: UsersService,
        private expiredDaterangeHelper: ExpiredDateRangeHelper,
    ) { }

    transform(paymentPolicy: UiBookingPaymentPolicy,
        currencyCode: string,
        textOnly: boolean,
        bookingType: PolicyBookingType,
        showErrors: boolean,
        onHoldHours?: number): string {
        const summary = this.getPolicySummaryItems(paymentPolicy, currencyCode, textOnly, bookingType, showErrors, onHoldHours);
        const summaryString = summary.join('<br>');
        return summaryString;
    }

    public getPolicySummaryItems(paymentPolicy: UiBookingPaymentPolicy,
        currencyCode: string,
        textOnly: boolean,
        bookingType: PolicyBookingType,
        showErrors: boolean,
        onHoldHours?: number): string[] {
        this.activeLanguage = this.userService.currentUserSettings.value.language.toUpperCase();

        let dateRangeSummary: string[] = [];
        const paymentSummary: string[] = [];
        if (paymentPolicy.paymentPolicyType === 'override') {
            const dateRanges = DateRangeSingleDatesTranslator.translateToRangesStartEnd(paymentPolicy.uiDateRanges);
            dateRangeSummary = this.expiredDaterangeHelper.getExpiredDateRangeStrings(dateRanges);
        }

        paymentSummary.push(this.textHelper.getBookingTypeLabel(bookingType));

        const depositSummary: string[] = [];

        if (paymentPolicy.guaranteeType !== 'None' && paymentPolicy.guaranteeType !== 'CcStore') {
            if (!textOnly) {
                if (paymentPolicy.hasPercent) {
                    if (paymentPolicy.numberOfNights) {
                        depositSummary.push(this.lang.get('bmng.booking.deposit.percent.nights',
                            paymentPolicy.percent, paymentPolicy.numberOfNights));
                    } else {
                        depositSummary.push(this.lang.get('bmng.booking.deposit.percent.fullstay', paymentPolicy.percent));
                    }
                }

                if (paymentPolicy.hasAmount) {
                    if (typeof paymentPolicy.amount === 'string') {
                        const amount = `${paymentPolicy.amount}`.replace(',', '.');
                        paymentPolicy.amount = parseFloat(amount);
                    }
                    depositSummary.push(this.currencyPipe.transform(paymentPolicy.amount, currencyCode));
                }
            }
        }

        let guaranteeType = this.textHelper.getBookingGuaranteeTypeLabel(paymentPolicy.guaranteeType);
        if (depositSummary.length) {
            guaranteeType = `${guaranteeType}: ${depositSummary.join(', ')}`;
        }
        paymentSummary.push(guaranteeType);

        if (onHoldHours) {
            paymentSummary.push(`${onHoldHours}H ${this.lang.get('bf.statusOnHold')}`);
        }

        let description = this.getPaymentDescription(paymentPolicy);

        if (textOnly) {
            const hasNoDefaultDescription = !paymentPolicy.descriptions[0] || !paymentPolicy.descriptions[0].description;
            if (hasNoDefaultDescription && showErrors) {
                const errorMessage = this.lang.get('policies.textonly.description.required');
                description = `<div class="policy-no-description">${errorMessage}</div>`;
            }
        }

        if (description) {
            paymentSummary.push(`<div>${description}</div>`);
        }

        const summary = [ ...dateRangeSummary, ...paymentSummary ];

        return summary;
    }

    private getPaymentDescription(paymentPolicy: UiBookingPaymentPolicy): string {
        const descriptions = paymentPolicy.descriptions;
        if (descriptions && descriptions.length) {
            const descriptionInActiveLang = descriptions.find(d => d.languageCode === this.activeLanguage);
            const ifActiveLangDescriptionExists: boolean = descriptionInActiveLang &&
                descriptionInActiveLang.description &&
                !!descriptionInActiveLang.description.length;

            const descriptionWithFallback = ifActiveLangDescriptionExists ? descriptionInActiveLang.description
                : descriptions[0].description;
            return descriptionWithFallback || '';
        }

        return '';
    }
}
