import { Pipe, PipeTransform } from '@angular/core';
import { RemoteInventoryPmsChannel } from '@bmng/services/remote-inventory/interfaces/remote-inventory.interface';

@Pipe({
    name: 'updateablePmsChannels',
})
export class UpdateablePmsChannelsPipe implements PipeTransform {
    transform(pmsChannels: RemoteInventoryPmsChannel[]): RemoteInventoryPmsChannel[] {
        return (pmsChannels || [])
            .filter(channel => channel.canUpdateAvailability || channel.canUpdateRates);
    }
}
