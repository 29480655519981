import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BreadcrumbConfig } from '@kognitiv/bm-components';

@Component({
    selector: 'action-bar-desktop-breadcrumb',
    templateUrl: './action-bar-desktop-breadcrumb.component.html',
    styleUrls: [ './action-bar-desktop-breadcrumb.component.scss' ],
    encapsulation: ViewEncapsulation.None,
})
export class ActionBarDesktopBreadcrumbComponent {
    @Input() breadcrumbConfig: BreadcrumbConfig[];
    @Input() showIcon: boolean;
}
