import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { EndpointService } from '@bmng/services/endpoint.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ChainPropertiesEnabledServiceInterface } from './interfaces/chain-properties-enabled.service.interface';

@Injectable()
export class ChainPropertiesEnabledService extends EndpointService implements ChainPropertiesEnabledServiceInterface {
    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    getEnabledProperties(chainId: string, includeTestProperties: boolean = false): Observable<string[]> {
        // TODO Change back to bm-backend when SKD-5791 is added and proxy is adapted accordingly
        // const url: string = `${environment.baseUrls.cpc}/api/switch/channels/${chainId}/properties_enabled.json`;
        const url: string = `${environment.baseUrls.switch}/channels/${chainId}/properties_enabled.json`;
        const fullUrl = UrlHelpers.buildUrl(url, { includeTestProperties });

        return this.httpGet(fullUrl, EndpointService.HTTP_HEADERS, false);
    }
}
