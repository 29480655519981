import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { LangService } from '@kognitiv/angular-i18n';
import { EndpointData } from '../endpoint-data.interface';
import { EndpointService } from '../endpoint.service';
import { ChannelSetting } from '../channels/interfaces/channel';
import { MappingServiceInterface } from './interfaces/mapping-service.interface';
import { MappingHistory, MappingHistoryKPIs, MappingHistoryType, MappingTransaction } from './interfaces/mapping-history.interface';
import { MappingCreateRateData, MappingCreateRoomData, MappingData, MappingDataChannelRate, MappingDataChannelRoom, MappingPostData, RoomCreationOptions } from './interfaces/mapping.interface';

type PossibleEndpointPaths = 'mapping' | 'createOptions' | 'mappingOptions' | 'mappingHistory';

@Injectable()
export class MappingService extends EndpointService implements MappingServiceInterface {

    constructor(
        http: HttpClient,

        private lang: LangService,
    ) {
        super(http);
    }

    getMapping(hotelId: string, channelId: string): Observable<EndpointData<MappingData>> {
        const baseUrl = this.getBaseUrl(hotelId, channelId, 'mapping');
        const url = UrlHelpers.buildUrl(baseUrl, {
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpGetWithFullData<MappingData>(url, EndpointService.HTTP_HEADERS);
    }

    getRoomCreationOptions(hotelId: string, channelId: string, roomCode: string): Observable<RoomCreationOptions> {
        const baseUrl = this.getBaseUrl(hotelId, channelId, 'createOptions', roomCode);
        const url = UrlHelpers.buildUrl(baseUrl, {
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpGet(url);
    }

    getRoomMappingOptions(hotelId: string, channelId: string, roomCode: string, currentMapping: MappingPostData):
    Observable<MappingDataChannelRoom[]> {
        const baseUrl = this.getBaseUrl(hotelId, channelId, 'mappingOptions', roomCode);
        const url = UrlHelpers.buildUrl(baseUrl, {
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpPost(url, currentMapping, EndpointService.HTTP_HEADERS);
    }

    getRateMappingOptions(hotelId: string, channelId: string, roomCode: string, rateCode: string, currentMapping: MappingPostData):
    Observable<MappingDataChannelRate[]> {
        const baseUrl = this.getBaseUrl(hotelId, channelId, 'mappingOptions', `${roomCode}/${rateCode}`);
        const url = UrlHelpers.buildUrl(baseUrl, {
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpPost<MappingDataChannelRoom[] | MappingDataChannelRate[]>(url, currentMapping, EndpointService.HTTP_HEADERS).pipe(
            map(data => {
                if (this.isChannelRoom(data)) {
                    return data[0].rates;
                }

                return data;
            }),
        );
    }

    saveMapping(hotelId: string, channelId: string, dryRun: boolean, data: MappingPostData): Observable<EndpointData<MappingData>> {
        const baseUrl = this.getBaseUrl(hotelId, channelId, 'mapping');
        const url = UrlHelpers.buildUrl(baseUrl, {
            dryRun,
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpPostWithFullData(url, data, EndpointService.HTTP_HEADERS);
    }

    createRemoteRoom(hotelId: string, channelId: string, data: MappingCreateRoomData): Observable<MappingDataChannelRoom> {
        const baseUrl = this.getBaseUrl(hotelId, channelId, 'mapping', 'rooms');
        const url = UrlHelpers.buildUrl(baseUrl, {
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpPost(url, data, EndpointService.HTTP_HEADERS);
    }

    removeRemoteRooms(hotelId: string, channelId: string, channelRoomCodes: string[]): Observable<boolean> {
        const baseUrl = this.getBaseUrl(hotelId, channelId, 'mapping', 'rooms/batch-remove');
        const url = UrlHelpers.buildUrl(baseUrl, {
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpPost(url, { channelRoomCodes }, EndpointService.HTTP_HEADERS);
    }

    createRemoteRate(hotelId: string, channelId: string, roomCode: string, data: MappingCreateRateData):
        Observable<MappingDataChannelRate> {
        const baseUrl = this.getBaseUrl(hotelId, channelId, 'mapping', `rooms/${roomCode}/rates`);
        const url = UrlHelpers.buildUrl(baseUrl, {
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpPost(url, data, EndpointService.HTTP_HEADERS);
    }

    removeRemoteRates(hotelId: string, channelId: string, channelRates: { roomCode: string; rateCode: string }[]): Observable<boolean> {
        const baseUrl = this.getBaseUrl(hotelId, channelId, 'mapping', 'rates/batch-remove');
        const url = UrlHelpers.buildUrl(baseUrl, {
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpPost(url, { channelRates }, EndpointService.HTTP_HEADERS);
    }

    validateChannelSetup(hotelId: string, channelId: string, channelSetup: ChannelSetting[]): Observable<boolean> {
        // TODO: Call the actual endpoint
        console.log('validating mapping for', hotelId, channelId, channelSetup);
        const channelPropertyId = channelSetup.find(property => property.code === 'channelPropertyID');
        return of(!!channelPropertyId?.value);
    }

    getTransactions(hotelId: string, channelId: string, start: number = 0, size: number = 1): Observable<MappingTransaction> {
        const baseUrl = this.getBaseUrl(hotelId, channelId, 'mappingHistory');
        const url = UrlHelpers.buildUrl(baseUrl, {
            start,
            size,
        });

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }

    getHistory(
        hotelId: string,
        channelId: string,
        transactionId: string,
        start: number = 0,
        size: number = 1,
        type?: MappingHistoryType,
    ): Observable<MappingHistory> {
        const baseUrl = this.getBaseUrl(hotelId, channelId, 'mappingHistory', transactionId);
        const url = UrlHelpers.buildUrl(baseUrl, {
            start,
            size,
            type,
        });

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }

    getHistoryKpi(hotelId: string, channelId: string, transactionId: string): Observable<MappingHistoryKPIs> {
        const url = this.getBaseUrl(hotelId, channelId, 'mappingHistory', `${transactionId}/kpi`);

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }

    private getBaseUrl(hotelId: string, channelId: string, path: PossibleEndpointPaths, slug: string = ''):
        string {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/hotels/${hotelId}/channels/${channelId}/${path}`;

        if (slug) {
            return `${baseUrl}/${slug}`;
        }

        return baseUrl;
    }

    private isChannelRoom(data: MappingDataChannelRoom[] | MappingDataChannelRate[]): data is MappingDataChannelRoom[] {
        return data.length === 1 && 'rates' in data[0];
    }
}
