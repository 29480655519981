
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { ChannelIdVerificationResult } from './interfaces/channel-id-verification-result.interface';
import { ChannelIdVerificationServiceInterface } from './interfaces/channel-id-verification.service.interface';

@Injectable()
export class ChannelIdVerificationServiceMock implements ChannelIdVerificationServiceInterface {
    private shouldSucceed: boolean = true;
    private failureReason: string = '';

    configureToSucceed(): void {
        this.shouldSucceed = true;
    }
    configureToFail(reason: string): void {
        this.shouldSucceed = false;
        this.failureReason = reason;
    }

    verifyChannel(channelId: string, foreignId: string): Observable<ChannelIdVerificationResult> {
        const result: ChannelIdVerificationResult = {
            foreignId,
            verified: this.shouldSucceed,
            reason: this.failureReason,
        };

        return of(result).pipe(
            delay(0));
    }
}
