import { UiPolicy } from '@bmng/pages/master/policies/interface/policies-ui.interface';
import { Component, OnInit } from '@angular/core';
import { RenderComponent } from '@kognitiv/bm-components';
import { PortalPolicyApplicabilityItem } from '@bmng/services/policies/interfaces/policies.interface';
import { LangService } from '@kognitiv/angular-i18n';
import { environment } from 'src/environments/environment';

@Component({
    templateUrl: './policy-applicability-renderer.component.html',
})
export class PortalPolicyApplicabilityRendererComponent<T extends UiPolicy> extends RenderComponent<T> implements OnInit {
    data: T;
    hotels: string;
    suppliers: string;

    isDev: boolean = !environment.production;

    hasNoHotels: boolean = true;
    hasNoSuppliers: boolean = true;

    constructor(
        private lang: LangService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.hasNoHotels = this.data.applicability?.hotels.length < 1;
        this.hasNoSuppliers = this.data.applicability?.suppliers.length < 1;

        this.hotels = this.renderHotelItemString(this.data.applicability?.hotels);
        this.suppliers = this.renderSupplierItemString(this.data.applicability?.suppliers);
    }

    renderHotelItemString(hotels: PortalPolicyApplicabilityItem[]): string {
        if (this.hasNoHotels) {
            if (this.hasNoSuppliers) {
                return this.lang.get('bm.policies.portal.applicability.all');
            } else {
                return this.lang.get('cc.rateManager.rateType.none');
            }
        }

        return this.renderApplicabilityItemString(hotels);
    }

    renderSupplierItemString(suppliers: PortalPolicyApplicabilityItem[]): string {
        if (this.hasNoSuppliers) {
            if (this.hasNoHotels) {
                return this.lang.get('bm.policies.portal.applicability.all');
            } else {
                return this.lang.get('cc.rateManager.rateType.none');
            }
        }

        return this.renderApplicabilityItemString(suppliers, true);
    }

    private renderApplicabilityItemString(items: PortalPolicyApplicabilityItem[], showCodeOnly?: boolean): string {
        let names: string[] = items.map(item => showCodeOnly ? item.code : (item.name ?? item.code));
        const length = names.length;
        if (length > 2) {
            names = names.slice(0, 2);
            return names.join(', ') + ' ' + this.lang.get('bm.policies.portal.applicability.more', length - 2);
        }

        return names.join(', ');
    }
}
