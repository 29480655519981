import { RateService } from '../interfaces/rate-services.interface';

const NEW_SERVICE_DATA: RateService = {
    serviceCode: null,
    categories: [],
    enabled: true,
    netService: false,
    euPackage: false,
    packageConditionCode: null,
    contents: [],
    images: [],
    currencyCode: null,
    prepaymentType: 'NONE',
    cancellationRefundType: 'NONE',
    taxPolicyCode: null,
    priceType: null,
    limitType: null,
    limit: null,
    rates: [
        {
            unitPrice: null,
            dateFrom: null,
            dateTo: null,
            refPrice: null,
            indicatedDiscount: null,
            minLos: null,
            maxLos: null,
        },
    ],
    onRequest: null,
    autoAdd: null,
    accessRestriction: null,
    restriction: {
        rates: [],
        rooms: [],
        promotions: {
            type: 'ALL',
            promotions: [],
        },
    },
    sort: 0,
};

export default NEW_SERVICE_DATA;
