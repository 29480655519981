export type PossibleImageAlternativeSizeCategory = 'Other' | 'Banner';
export type PossibleImageAlternativeSizeSize = 'XSmall' | 'Small' | 'Medium' | 'Large' | 'XLarge';

export enum CategoryType {
    'EXTERIOR_VIEW' = '1',
    'LOBBY' = '2',
    'POOL' = '3',
    'RESTAURANT' = '4',
    'GUEST_ROOM' = '6',
    'SUITE' = '7',
    'GOLF_COURSE' = '10',
    'BEACH' = '11',
    'SPA' = '12',
    'BAR_LOUNGE' = '13',
    'LOGO' = '15',
    'SMALL_LOGO' = '15.SML',
    'MAP' = '17',
    'MISCELLANEOUS' = '20',
    'GROUND_PLAN' = '21',
    'BANNER' = '22',
    'TERMINAL' = '23',
}

export interface ImagesResult {
    images: ImageResultData[];
}

export interface ImageResultData {
    contentLength: number;
    resourceURI: string;
    name: string;
    resourceURL: string;
    titles: { [ lang: string ]: string };
    lastModifiedDate: string;
    sequenceNumber: number;
    format: string;
    width: number;
    height: number;
    isArchived: boolean;
    category?: CategoryType;

    url: string;
    alternativeSizes: ImageResultAlternativeSize[];
}

export interface ImageResultAlternativeSize {
    url: string;
    width: number;
    height: number;
    category: PossibleImageAlternativeSizeCategory;
    size: PossibleImageAlternativeSizeSize;
}

export interface RenderedImage {
    renderUrl: string;
    url: string;
    category?: PossibleImageAlternativeSizeCategory;
}
