import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EndpointService } from './../endpoint.service';
import { UserSettings } from './interfaces/user-settings.interface';
import { UsersServiceInterface } from './interfaces/users.service.interface';

@Injectable()
export class UsersService extends EndpointService implements UsersServiceInterface {
    public currentUserSettings: BehaviorSubject<UserSettings | undefined> = new BehaviorSubject<UserSettings | undefined>(undefined);

    constructor(http: HttpClient) {
        super(http);
    }

    getUserSettings(userId: string): Observable<UserSettings> {
        const url = `${EndpointService.getBmBackendUrl()}/api/users/settings/${userId}`;

        return this.httpGet<UserSettings>(url, EndpointService.HTTP_HEADERS_ACCEPT).pipe(
            tap(this.updateUserSettings.bind(this)));
    }

    getUserSettingsFromOldBm(languageCode?: string): Observable<UserSettings> {
        let url = `${EndpointService.getBmBackendUrl()}/api/cm/user/info`;

        if (languageCode) {
            url += `?lang=${languageCode}`;
        }

        return this.httpGet<UserSettings>(url, EndpointService.HTTP_HEADERS_ACCEPT).pipe(
            tap(this.updateUserSettings.bind(this)));
    }

    updateUserLanguage(languageCode: string): Observable<boolean> {
        const url = `${EndpointService.getBmBackendUrl()}/api/cm/user/language`;
        return this.httpPost(url, { languageCode }, EndpointService.HTTP_HEADERS);
    }

    updateUserSettings(userSettings: UserSettings): void {
        this.currentUserSettings.next(userSettings);
    }

    getAdminUserSettings(hotelId: string): Observable<UserSettings[]> {
        const url = `${EndpointService.getBmBackendUrl()}/api/cm/api/hotel/${hotelId}/users?type=apiAdmin`;

        return this.httpGet<UserSettings[]>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }
}
