
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EndpointService } from './../endpoint.service';
// Interfaces
import { Property } from './interfaces/property.interface';

const PROPERTY_ENDPOINT: string = `${EndpointService.getBmBackendUrl()}/api/hotel`;

@Injectable()
export class PropertyService extends EndpointService {

    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    getById(hotelId: string): Observable<Property> {
        const url: string = `${PROPERTY_ENDPOINT}/${hotelId}/content`;

        return this.httpGet<Property>(url, EndpointService.HTTP_HEADERS);
    }
}
