import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { EndpointService } from '@bmng/services/endpoint.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { KubeStylingListParameters } from './../../../pages/generic/kube-styling2/kube-styling.constants';
import { KubeWidgetsContextType } from './../context/interfaces/context-type.type';
import { KubeStyleConstants } from './kube-style.constants';
import { BookingStyle, KubeStyling, KubeStylingUrlWithBannerData } from './kube-style.interface';

const ENDPOINT = `${EndpointService.getBmBackendUrl()}/api`;

@Injectable()
export class BookingStylesService extends EndpointService {

    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    /*******************************/
    /* NEW BOOKING STYLE ENDPOINTS */
    /*******************************/

    getKubeStylingsList(contextType: KubeWidgetsContextType, assetId: string,
        params: KubeStylingListParameters = {}, circleId: string = null): Observable<KubeStyling[]> {

        let baseUrl: string = `${ENDPOINT}/${contextType}/${assetId}/bookingEngine/styles`;

        if (circleId) {
            baseUrl = baseUrl.concat(`?channels=${circleId}`);
        }

        const url: string = UrlHelpers.buildUrl(baseUrl, params);
        return this.httpGet<KubeStyling[]>(url, EndpointService.HTTP_HEADERS).pipe(
            map(styles => {
                const hasDefault = styles?.length && styles.some(style => style.default);

                if (hasDefault) {
                    return styles;
                }

                const FE_GENERATED_DEFAULT_STYLE: KubeStyling = {
                    ...KubeStyleConstants.KUBE_STYLINGS_DEFAULT,
                    default: true,
                };

                return [
                    FE_GENERATED_DEFAULT_STYLE,
                    ...styles,
                ];
            }),
        );
    }

    getKubeStylingsListByCircleId(contextType: KubeWidgetsContextType, assetId: string,
        params: KubeStylingListParameters = {}, circleId: string): Observable<KubeStyling[]> {
        return this.getKubeStylingsList(contextType, assetId, params, circleId);
    }

    getNewKubeStyling(isDefault: boolean = false): Observable<KubeStyling> {
        return of(KubeStyleConstants.KUBE_STYLINGS_DEFAULT).pipe(
            map((kubeStyling: KubeStyling) => {
                kubeStyling.default = isDefault;
                kubeStyling.lastUpdate = new Date().toISOString();
                return kubeStyling;
            }),
        );
    }

    getKubeStyling(contextType: KubeWidgetsContextType, assetId: string, id?: number): Observable<KubeStyling> {
        const url: string = !id
            ? `${ENDPOINT}/${contextType}/${assetId}/bookingEngine/style`
            : `${ENDPOINT}/${contextType}/${assetId}/bookingEngine/style/${id}`;
        return this.httpGet<KubeStyling>(url, EndpointService.HTTP_HEADERS);
    }

    getKubeStylingUrlAndData(contextType: KubeWidgetsContextType, assetId: string, id?: number): Observable<KubeStylingUrlWithBannerData> {
        const url: string = `${environment.baseUrls.switch}/switch/api/${contextType}/${assetId}/bookingEngine/styling`;
        const payload = {
            channelId: 'ibe',
            propertyCode: assetId,
        };
        // TODO: extend with Access Restriction properties?
        return this.httpPost<KubeStylingUrlWithBannerData>(url, payload, EndpointService.HTTP_HEADERS);
    }

    saveKubeStyling(contextType: KubeWidgetsContextType, assetId: string, kubeStyling: KubeStyling): Observable<boolean> {
        const url: string = `${ENDPOINT}/${contextType}/${assetId}/bookingEngine/style`;
        return this.httpPost(url, kubeStyling, EndpointService.HTTP_HEADERS);
    }

    saveKubeStylingListOrder(contextType: KubeWidgetsContextType,
        assetId: string, styleIdsOrdered: number[]): Observable<boolean> {
        const url: string = `${ENDPOINT}/${contextType}/${assetId}/bookingEngine/style/order`;
        return this.httpPost(url, styleIdsOrdered, EndpointService.HTTP_HEADERS);
    }

    deleteKubeStyling(contextType: KubeWidgetsContextType, assetId: string, id: number): Observable<boolean> {
        const url: string = `${ENDPOINT}/${contextType}/${assetId}/bookingEngine/style/${id ?? ''}`;
        return this.httpDelete(url, EndpointService.HTTP_HEADERS);
    }


    /*******************************/
    /* OLD BOOKING STYLE ENDPOINTS */
    /*******************************/
    getBookingStyle(contextType: KubeWidgetsContextType, assetId: string, stylingId: string = null): Observable<BookingStyle> {
        return this.get(this.getUrl(contextType, assetId, stylingId));
    }
    saveBookingStyle(assetType: KubeWidgetsContextType, assetId: string, style: BookingStyle): Observable<BookingStyle> {
        return this.save(this.getUrl(assetType, assetId), style);
    }

    private get(endpoint: string): Observable<BookingStyle> {
        return this.httpGet<BookingStyle>(endpoint, EndpointService.HTTP_HEADERS);
    }
    private save(endpoint: string, bookingStyle: BookingStyle): Observable<BookingStyle> {
        return this.httpPost(endpoint, bookingStyle, EndpointService.HTTP_HEADERS);
    }
    private getUrl(contextType: KubeWidgetsContextType, assetId: string, stylingId: string = null): string {
        return !!stylingId
            ? `${ENDPOINT}/${contextType}/${assetId}/bookingEngine/style/{stylingId}`
            : `${ENDPOINT}/${contextType}/${assetId}/bookingEngine/style`;
    }
}
