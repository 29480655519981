import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CodeTitle } from '@bmng/services/metadata/interfaces/metadata.interface';
import { PortalPolicyApplicability, PortalPolicyApplicabilityItem } from '@bmng/services/policies/interfaces/policies.interface';

import { UiBookingOnHold, UiBookingPaymentPolicy, UiPolicyBooking } from '../../../../pages/master/policies/interface/policies-ui.interface';
import { PolicyFormHelpersService } from '../policy-form-helpers.service';
import { PolicyFormValidators } from '../policy-form-validators.service';

import { PolicyFormBookingValidators } from './policy-form-booking-validators.service';

@Injectable()
export class PolicyFormBookingService {
    constructor(
        private formHelpersService: PolicyFormHelpersService,
        private validators: PolicyFormValidators,
        private bookingValidators: PolicyFormBookingValidators,
    ) {}

    getBookingPolicyForm(
        policyConfig: UiPolicyBooking,
        isNewPolicy: boolean,
        languages: CodeTitle[],
        usedCodes?: string[],
        hasPaymentProviders?: boolean,
    ): UntypedFormGroup {
        if (!policyConfig) {
            return;
        }

        const isOnRequest = policyConfig.bookingType === 'OnRequest';
        const isPortal = policyConfig.context === 'portal';
        policyConfig.hasOnHoldEnabled = isNewPolicy || isOnRequest || isPortal ? false : policyConfig.hasOnHoldEnabled;

        const isTextOnlyDisabled = policyConfig.uiPaymentPolicies
            .find(policy => policy.paymentPolicyType === 'main').guaranteeType === 'CcOnline';

        return new UntypedFormGroup({
            code: new UntypedFormControl(policyConfig.code, [
                Validators.required,
                this.validators.uniqueCodeValidation(isNewPolicy, usedCodes),
            ]),
            portalId: new UntypedFormControl(policyConfig.portalId),
            enabled: new UntypedFormControl(policyConfig.enabled),
            currencyCode: new UntypedFormControl(policyConfig.currencyCode),
            hasOnRequest: new UntypedFormControl(policyConfig.hasOnRequest),
            hasAvailabilityIndependent: new UntypedFormControl(policyConfig.hasAvailabilityIndependent),
            bookingType: new UntypedFormControl(policyConfig.bookingType),
            textOnly: new UntypedFormControl( { value: policyConfig.textOnly, disabled: isTextOnlyDisabled }),
            hasOnHoldEnabled: new UntypedFormControl({ value: policyConfig.hasOnHoldEnabled, disabled: isOnRequest || isPortal }),
            uiOnHold: this.getOnHoldFormGroup(policyConfig.uiOnHold),
            uiPaymentPolicies: this.getPolicyPaymentsFormArray(policyConfig.uiPaymentPolicies, languages,
                                !!policyConfig.portalId, hasPaymentProviders),
            uiApplicability: this.getApplicabilityForm(policyConfig.applicability),
            context: new UntypedFormControl(policyConfig.context),
            sort: new UntypedFormControl(policyConfig.sort),
        });
    }

    public getOnHoldFormGroup(onHoldUI: UiBookingOnHold): UntypedFormGroup {
        return new UntypedFormGroup({
            durationHours: new UntypedFormControl(onHoldUI.durationHours),
            stayDatesType: new UntypedFormControl(onHoldUI.stayDatesType),
            uiStayDatesIncluded: new UntypedFormControl(onHoldUI.uiStayDatesIncluded),
            uiStayDatesExcluded: new UntypedFormControl(onHoldUI.uiStayDatesExcluded),
            hasLeadTimeEnabled: new UntypedFormControl(Number(onHoldUI.hasLeadTimeEnabled)),
            reminderLeadTimeHours: new UntypedFormControl(onHoldUI.reminderLeadTimeHours || 12),
            minimumLeadTimeDays: new UntypedFormControl(onHoldUI.minimumLeadTimeDays || onHoldUI.durationHours / 24),
            availabilityThreshold: new UntypedFormControl(onHoldUI.availabilityThreshold || 12),
            hasPromotionEnabled: new UntypedFormControl(Number(onHoldUI.hasPromotionEnabled)),
            promotionType: new UntypedFormControl(onHoldUI.promotionType),
            promotion: new UntypedFormGroup({
                amount: new UntypedFormControl(onHoldUI.promotion?.amount),
                percent: new UntypedFormControl(onHoldUI.promotion?.percent),
            }),
        }, this.bookingValidators.bookingOnHoldValidation());
    }

    public getPolicyPaymentFormGroup(
        uiPolicyPayment: UiBookingPaymentPolicy,
        languages: CodeTitle[],
        isPortal?: boolean,
        textOnly?: boolean,
        hasPaymentProviders?: boolean,
    ): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({
            priority: new UntypedFormControl(uiPolicyPayment.priority),
            index: new UntypedFormControl(uiPolicyPayment.index),
            uiDateRanges: new UntypedFormControl(uiPolicyPayment.uiDateRanges),
            paymentPolicyType: new UntypedFormControl(uiPolicyPayment.paymentPolicyType),
            guaranteeType: new UntypedFormControl(
                uiPolicyPayment.guaranteeType,
                [ this.bookingValidators.bookingGuaranteeTypeValidation(hasPaymentProviders) ],
            ),
            stayType: new UntypedFormControl({ value: uiPolicyPayment.stayType, disabled: isPortal ? false : !uiPolicyPayment.hasPercent }),
            numberOfNights: new UntypedFormControl(
                { value: uiPolicyPayment.numberOfNights, disabled: !uiPolicyPayment.hasPercent || uiPolicyPayment.stayType === 'FullStay' },
                [ Validators.required ],
            ),
            hasPercent: new UntypedFormControl(uiPolicyPayment.hasPercent || isPortal),
            percent: new UntypedFormControl(
                { value: uiPolicyPayment.percent, disabled: isPortal ? false : !uiPolicyPayment.hasPercent },
                [ Validators.required ],
            ),
            hasAmount: new UntypedFormControl(uiPolicyPayment.hasAmount),
            amount: new UntypedFormControl(
                { value: uiPolicyPayment.amount, disabled: !uiPolicyPayment.hasAmount },
                [ Validators.required ],
            ),
            descriptions: new UntypedFormArray(
                this.formHelpersService.getDescriptionControlsByLang(languages, uiPolicyPayment.descriptions, false),
            ),
            descriptionTabIndex: new UntypedFormControl(uiPolicyPayment.descriptionTabIndex),
            showDescriptions: new UntypedFormControl(uiPolicyPayment.showDescriptions),
        }, this.bookingValidators.bookingPaymentValidation(isPortal, textOnly));

        return formGroup;
    }

    public getPolicyPaymentsFormArray(
        uiPolicyPayments: UiBookingPaymentPolicy[],
        languages: CodeTitle[],
        isPortal?: boolean,
        hasPaymentProviders?: boolean,
    ): UntypedFormArray {
        const rangeGroup: UntypedFormGroup[] = [];
        uiPolicyPayments.forEach(payment => {
            rangeGroup.push(this.getPolicyPaymentFormGroup(payment, languages, isPortal, undefined, hasPaymentProviders));
        });
        return new UntypedFormArray(rangeGroup);
    }

    private getApplicabilityForm(applicability: PortalPolicyApplicability): UntypedFormGroup {
        return new UntypedFormGroup({
            hotels: new UntypedFormControl(this.getCodesFromPortalHotelsOrSuppliers(applicability?.hotels)),
            suppliers: new UntypedFormControl(this.getCodesFromPortalHotelsOrSuppliers(applicability?.suppliers)),
        });
    }

    private getCodesFromPortalHotelsOrSuppliers(items: PortalPolicyApplicabilityItem[]): string[] {
        return !items ? null : items.map(item => item.code);
    }
}
