import { Injectable } from '@angular/core';
import { LocalStorageService } from '@kognitiv/bm-components';
import { ContextParams } from '../context/interfaces/context-params.interface';
import { ContextType } from '../context/interfaces/context-type.type';

import { UsersService } from '../users/users.service';

import { RecentSelection } from './interfaces/recent-selection.interface';
import { RecentSelectionServiceInterface } from './interfaces/recent-selection.service.interface';

@Injectable()
export class RecentSelectionService implements RecentSelectionServiceInterface {
    constructor(
        private localStorageService: LocalStorageService,
        private usersService: UsersService,
    ) { }

    public getRecentSelections(): RecentSelection[] {
        this.migrateGlobalRecentSelections();

        const recentSelections: RecentSelection[] = this.localStorageService.getItem(this.getRecentSelectionsKey());
        if (recentSelections === null) {
            return [];
        } else {
            return recentSelections;
        }
    }

    public addToRecentSelections(recentSelection: RecentSelection): void {
        const recentSelections = this.getRecentSelections();
        const idxToDelete = recentSelections.findIndex(row => row.id === recentSelection.id);
        if (idxToDelete !== -1) {
            recentSelections.splice(idxToDelete, 1);
        }
        recentSelections.unshift(recentSelection);
        this.localStorageService.setItem(this.getRecentSelectionsKey(), recentSelections.slice(0, 10));
    }

    public getLastContext(type: ContextType): ContextParams | undefined {
        const key = this.getLastContextKey(type);
        const data: ContextParams = this.localStorageService.getItem(key);

        return data;
    }

    public setLastContext(params: ContextParams): void {
        const key = this.getLastContextKey(params.type);
        this.localStorageService.setItem(key, params);
    }

    private migrateGlobalRecentSelections(): void {
        const recentSelections: RecentSelection[] = this.localStorageService.getItem('recentSelections');
        if (recentSelections) {
            this.localStorageService.setItem(this.getRecentSelectionsKey(), recentSelections);
            this.localStorageService.removeItem('recentSelections');
        }
    }

    private getRecentSelectionsKey(): string {
        return `recentSelections-${this.usersService.currentUserSettings.value.id}`;
    }
    private getLastContextKey(type: ContextType): string {
        return `lastContext-${this.usersService.currentUserSettings.value.id}-${type}`;
    }
}
