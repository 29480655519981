import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EndpointService } from '../endpoint.service';
import { Metadata } from '../metadata/interfaces/metadata.interface';

import { RatePlanSetupServiceInterface } from './interfaces/rate-plan-setup-service.interface';
import { RatePlanSetup } from './interfaces/rate-plan-setup.interface';
import { RoomNode } from './interfaces/rates-tree.interface';

@Injectable()
export class RatePlanSetupService extends EndpointService implements RatePlanSetupServiceInterface {
    static readonly MAX_NUM_OF_BASE_RATES = 20;

    constructor(
        protected http: HttpClient,
    ) {
        super(http);
    }

    getRatePlanSetups(hotelCode: string): Observable<RatePlanSetup[]> {
        const url = this.getRatePlanSetupUrl(hotelCode);

        return this.httpGet<RatePlanSetup[]>(url, EndpointService.HTTP_HEADERS)
            .pipe(
                map(rates => rates.map(enrichRateSetupDependencies)),
            );
    }

    getRatePlanSetup(hotelCode: string, code: string): Observable<RatePlanSetup> {
        const url = this.getRatePlanSetupUrl(hotelCode, code);

        return this.httpGet<RatePlanSetup>(url, EndpointService.HTTP_HEADERS)
            .pipe(
                map(rate => enrichRateSetupDependencies(rate)),
            );
    }

    private getRatePlanSetupUrl(hotelCode: string, code?: string): string {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/hotels/${hotelCode}/rateplans`;

        if (!code) {
            return baseUrl;
        }

        return `${baseUrl}/${code}`;
    }

    getRatesTree(hotelId: string, languageCode?: string): Observable<RoomNode[]> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/hotels/${hotelId}/room-rateplans`;
        let url = baseUrl;

        if (!!languageCode) {
            url = baseUrl + `?languageCode=${languageCode.toUpperCase()}`;
        }

        return this.httpGet<RoomNode[]>(url, EndpointService.HTTP_HEADERS);
    }

    updateRatePlanSetup(hotelCode: string, setup: RatePlanSetup, isUpdate?: boolean): Observable<boolean> {
        const url = isUpdate ?
            this.getRatePlanSetupUrl(hotelCode, setup.code) :
            this.getRatePlanSetupUrl(hotelCode);
        const headers = EndpointService.HTTP_HEADERS;
        const result: Observable<RatePlanSetup> = isUpdate ?
            this.httpPut(url, setup, headers) :
            this.httpPost(url, setup, headers);

        return result.pipe(
            map(rate => !!rate),
        );
    }

    preprocessRatePlanForUpdate(ratePlan: RatePlanSetup, metadata: Metadata): RatePlanSetup {
        const processed = {
            ...ratePlan,
        };

        const defaultCurrencyCode = metadata.currencyCode === ratePlan.currencyCode;

        if (defaultCurrencyCode) {
            processed.currencyCode = null;
        }

        return processed;
    }

    deleteRatePlanSetup(hotelCode: string, code: string): Observable<boolean> {
        const url = this.getRatePlanSetupUrl(hotelCode, code);

        return this.httpDelete<RatePlanSetup>(url, EndpointService.HTTP_HEADERS).pipe(
            map(result => !!result),
        );
    }
}

export function enrichRateSetupDependencies(rate: RatePlanSetup): RatePlanSetup {
    if (rate.type === 'BASE' && !rate.roomRateDependencies?.length) {
        rate.roomRateDependencies = [
            {
                resultRatePlanCode: rate.code,
                roomCode: rate.roomCode,
            },
        ];
    }

    rate.roomRateDependencies = (rate.roomRateDependencies || []).map(dependency => {
        dependency.derivePathParts = (dependency.derivePath || '').split('.');
        return dependency;
    });

    return rate;
}
