import { AvailabilityType } from '@bmng/pages/master/availability-bulk-update/interfaces/availability-bulk-update.interface';
import { MomentService } from '@kognitiv/bm-components';

import { ArrayHelpers } from '@bmng/helpers/array-helpers';
import { Availability, IsoDate, RateMetadataFieldType, Room, RoomMetadata, RoomMetadataFieldType, RoomRateMetadataField } from '../interfaces/room-rates.interface';
import { AvailabilityValue, RoomRatesMetadata, RoomDynamicAllocationMetadata, RoomDynamicAllocationValueMetadata } from './../interfaces/room-rates.interface';

const moment = MomentService.get();

const EMPTY_DAY: AvailabilityValue = {
    day: null,
    type: null,
    closed: null,
    quantity: null,
    free: 0,
    total: 0,
    sold: 0,
    empty: true,
    source: 'DYNAMIC',
};

const EXISTING_ALLOTMENT_TYPES: (RoomMetadataFieldType | RateMetadataFieldType | '')[] =
    [ 'hardAllotment', 'lastHardAllotment', 'allotment', 'lastAllotment', 'limit' ];

const EXISTING_ALLOTMENT_AVAILABILITY_TYPES: AvailabilityType[] = [
    'AVAILABILITY', 'ALLOTMENT', 'LAST_ALLOTMENT', 'HARD_ALLOTMENT', 'LAST_HARD_ALLOTMENT', 'LIMIT',
];

type EmptyAllotmentValueTemplate = AvailabilityValue[];

interface DatesToIdxMap { [key: string]: number }

export function padGlobalAvailabilities(room: Room, startDate: IsoDate, expectedNumber: number): void {
    const hasDefaultAvailability = !!room?.availabilities?.find(av => !av.channelId);

    if (!hasDefaultAvailability) {
        room.availabilities.push({
            values: [],
        });
    }

    room.availabilities
        .filter(availability => !availability.channelId && !availability.allocationCode)
        .filter(availability => availability.values.length < expectedNumber)
        .forEach(availability => {
            const availabilityValues: AvailabilityValue[] = [];
            const curType: AvailabilityType = getAvailabilityType(availability.channelId, availability.values);

            for (let i = 0; i < expectedNumber; i++) {
                const currentDay = moment(startDate, 'YYYY-MM-DD').add(i, 'day').format('YYYY-MM-DD');
                const dayValue = availability.values.find(v => v.day === currentDay);

                if (!!dayValue) {
                    availabilityValues.push(dayValue);
                } else {
                    availabilityValues.push({
                        ...EMPTY_DAY,
                        day: currentDay,
                        type: curType,
                    });
                }
            }
            availability.values = availabilityValues;
    });
}

export function padAllDynamicAllocations(room: Room, startDate: IsoDate, expectedNumber: number, metadata?: RoomRatesMetadata): void {

    const paddedAvailabilitiesList: Availability[] = [];

    const dynamicAllocationsInMetadata: RoomDynamicAllocationMetadata = metadata.allocations?.find(a => a.roomCode === room.code);
    const dynamicAllocationsInAvailabilities: Availability[] = room.availabilities.filter(av => av.allocationCode);
    let dynamicAllocationCodesInMetadata: string[];

    if (!!dynamicAllocationsInMetadata) {

        dynamicAllocationCodesInMetadata = dynamicAllocationsInMetadata.values.map(v => v.code);

        // 1. pad availabilites that exist in metadata
        dynamicAllocationCodesInMetadata.forEach(allocationCodeInMetadata => {
            const allocationInAvailabilities: Availability =
                dynamicAllocationsInAvailabilities.find(da => da.allocationCode === allocationCodeInMetadata);
            const allocationInMetadata: RoomDynamicAllocationValueMetadata =
                dynamicAllocationsInMetadata.values?.find(a => a.code === allocationCodeInMetadata);

            let tempAllocation: Availability;
            if (!allocationInAvailabilities) {
                // there is allocation in metadata, but not in availabilities,
                // means allocation travel windows are outside of the visible range
                // => add empty availability in its stead
                tempAllocation = getPaddedEmptyAllocation(allocationInMetadata, startDate, expectedNumber);
            } else {
                // allocation is both in metadata and availabilities
                // means allocation travel windows are within the visible range
                // => pad existing availability
                tempAllocation = getPaddedAvailabilityAllocation(allocationInAvailabilities,
                    startDate, expectedNumber);
            }
            paddedAvailabilitiesList.push(tempAllocation);
        });
    }

    // 2. pad availabilites that do not exist in the metadata => created by the preview
    let dynamicAllocationCodesInAvailabilitiesOnly: string[] =
        dynamicAllocationsInAvailabilities.map(da => da.allocationCode);
    if (!!dynamicAllocationCodesInMetadata){
        dynamicAllocationCodesInAvailabilitiesOnly =
        dynamicAllocationCodesInAvailabilitiesOnly.filter(code => !dynamicAllocationCodesInMetadata.includes(code));
    }
    dynamicAllocationCodesInAvailabilitiesOnly.forEach(allocationCodeInAvailabilitiesOnly => {
        // this list contains the codes of newly created availabilites
        // not yet in metadata
        // => pad them and add at the end of the list
        const allocationInAvailabilitiesOnly: Availability =
            dynamicAllocationsInAvailabilities.find(da => da.allocationCode === allocationCodeInAvailabilitiesOnly);
        const paddedAllocation: Availability =
            getPaddedAvailabilityAllocation(allocationInAvailabilitiesOnly, startDate, expectedNumber);
        paddedAvailabilitiesList.push(paddedAllocation);
    });

    // remove all dynamic allocations at random position from availabilities
    // and add the new consistently ordered set at the end of the array
    room.availabilities = room.availabilities.filter(r => !r.allocationCode);
    room.availabilities.push(...paddedAvailabilitiesList);
}
function getPaddedEmptyAllocation(allocationInMetadata: RoomDynamicAllocationValueMetadata,
    startDate: IsoDate, expectedNumber: number): Availability {
    const [ valuesTemplate ] = getDateBasedAllotmentTemplates(startDate, expectedNumber);
    valuesTemplate.forEach(v => {
        v.type = allocationInMetadata.type;
        v.readOnly = true;
    });
    return {
        allocationCode: allocationInMetadata.code,
        allocationName: allocationInMetadata.name,
        values: valuesTemplate,
    };
}

function getPaddedAvailabilityAllocation(allocationInAvailabilities: Availability,
    startDate: IsoDate, expectedNumber: number): Availability {
    // there is an allocation in availabilities, pad it and add to the list
    const [ valuesTemplate ] = getDateBasedAllotmentTemplates(startDate, expectedNumber);
    valuesTemplate.forEach((template, tIdx) => {
        const allocationMatchingByDay: AvailabilityValue =
            allocationInAvailabilities.values.find(value => value.day === template.day);
        if (!!allocationMatchingByDay){
            valuesTemplate[tIdx] = allocationMatchingByDay;
        } else {
            valuesTemplate[tIdx].readOnly = true;
        }
    });
    return {
        ...allocationInAvailabilities,
        values: valuesTemplate,
    };
}


export function padAllAllocations(room: Room, startDate: IsoDate, expectedNumber: number, metaData?: RoomRatesMetadata): void {
    const roomMetadata: RoomMetadata = (metaData.availabilities || [])
        .find(availability => availability.roomCode === room.code);
    const channelIds = [
        ...getChannelIdsFromMetadata(room.code, roomMetadata),
        ...getChannelIdsFromRoom(room),
    ].filter(ArrayHelpers.Filter.noDuplicates);
    const [ valuesTemplate, datesIdxMap ] = getDateBasedAllotmentTemplates(startDate, expectedNumber);

    channelIds.forEach(channelId => {
        const fields: RoomRateMetadataField[] = getAllotmentTypesByExistingChannelValues(channelId, roomMetadata);
        const typeValuesMap = getAllotmentTypeValuesMap(fields, valuesTemplate);

        const availability: Availability = room.availabilities.find(av => av.channelId === channelId
            && av.allocationCode === undefined);
            // TODO Elena: filter out dynamic allocations. Remove the last comparison eventually,
            // as all allocations will be expected to have an allocation code

        if (!!availability) {
            availability.values.forEach(value => {
                const idx = datesIdxMap[value.day];
                const valueMissingInMetadata = !typeValuesMap[value.type];


                if (valueMissingInMetadata) {
                    typeValuesMap[value.type] = valuesTemplate.map(val => ({
                        ...val,
                        type: value.type,
                    }));
                }

                if (idx >= 0) {
                    typeValuesMap[value.type][idx] = value;
                } else {
                    console.warn(`Couldn't find index for day ${value.day} - room ${room.code} channel ${channelId}`);
                }
            });
            swapValuesWithPaddedValues(availability, typeValuesMap);
        }
    });
}

function getAllotmentTypesByExistingChannelValues(channelId: string, roomMetadata: RoomMetadata): RoomRateMetadataField[] {
    if (!roomMetadata) {
        return [];
    }
    return roomMetadata.values
        .filter(rmd => rmd.channelId === channelId)
        .filter(rmd => !!rmd.firstDate)
        .filter(rmd => EXISTING_ALLOTMENT_TYPES.includes(rmd.field));
}

function getChannelIdsFromRoom(room: Room): string[] {
    return (room.availabilities || [])
        .filter(av => !!av.channelId)
        .map(av => av.channelId);
}

function getChannelIdsFromMetadata(roomCode: string, roomMetadata: RoomMetadata): string[] {
    const channelIds = new Set((roomMetadata?.values || [])
        .filter(value => value.channelId)
        .map(value => value.channelId));

    return [ ...channelIds ];
}

function getDateBasedAllotmentTemplates(startDate: IsoDate, expectedNumber: number): [ EmptyAllotmentValueTemplate, DatesToIdxMap ] {
    const map: DatesToIdxMap = {};
    const template: EmptyAllotmentValueTemplate = [];

    for (let i = 0; i < expectedNumber; i++) {
        const day = moment(startDate, 'YYYY-MM-DD').add(i, 'days').format('YYYY-MM-DD');
        map[day] = i;

        template.push({
            ...EMPTY_DAY,
            type: null,
            day,
        });
    }

    return [ template, map ];
}

export function getAllotmentTypeValuesMap(
        fields: RoomRateMetadataField[],
        valueTemplate: EmptyAllotmentValueTemplate ): { [key: string]: AvailabilityValue[] } {
    const valueMap: { [key: string]: AvailabilityValue[] } = {};

    fields.map(field => field.field)
        .forEach(fieldName => {
            const avTypeName = getMappedAllocationType(fieldName);

            valueMap[avTypeName] = valueTemplate.map(value => ({
                ...value,
                type: avTypeName,
            }));
        });

    return valueMap;
}

export function getAllotmentCodeValuesMap(
    allocationMetadataItems: RoomDynamicAllocationValueMetadata[],
    valueTemplate: EmptyAllotmentValueTemplate ): { [key: string]: AvailabilityValue[] } {
    const valueMap: { [key: string]: AvailabilityValue[] } = {};

    allocationMetadataItems.forEach((allocationMetadata: RoomDynamicAllocationValueMetadata) => {
        valueMap[allocationMetadata.code] = valueTemplate.map(value => ({
            ...value,
            type: allocationMetadata.type,
        }));
    });

    return valueMap;
}

function swapValuesWithPaddedValues(availability: Availability, typeValuesMap: { [key: string]: AvailabilityValue[] }): void {
    let paddedAvailabilities: AvailabilityValue[] = [];
    EXISTING_ALLOTMENT_AVAILABILITY_TYPES.forEach(avType => {
        const values = typeValuesMap[avType];

        if (!!values) {
            paddedAvailabilities = paddedAvailabilities.concat(values);
        }
    });

    availability.values = paddedAvailabilities;
}

function getMappedAllocationType(field: string): AvailabilityType {
    switch (field) {
        case 'allotment':
            return 'ALLOTMENT';
        case 'lastAllotment':
            return 'LAST_ALLOTMENT';
        case 'hardAllotment':
            return 'HARD_ALLOTMENT';
        case 'lastHardAllotment':
            return 'LAST_HARD_ALLOTMENT';
        case 'limit':
            return 'LIMIT';
        default:
            return null;
    }
}

function getAvailabilityType(channelId: string, values: AvailabilityValue[]): AvailabilityType {
    if (!channelId) {
        return 'AVAILABILITY';
    }

    if (!values || values.length === 0) {
        return 'ALLOTMENT';
    }

    const idx = values.length - 1;

    return values[idx].type;
}
