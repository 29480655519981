import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ContextService } from '@bmng/services/context/context.service';
import { RecentSelectionService } from '@bmng/services/recent-selection/recent-selection.service';


export const wildcardRouteGuardCanActivate: ResolveFn<boolean | UrlTree> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const WILDCARD_TOKENS = [ '$', '*' ];

        const contextService = inject(ContextService);
        const recentSelectionService = inject(RecentSelectionService);
        const router = inject(Router);

        const context = contextService.getContextParams(route);

        if (
            WILDCARD_TOKENS.includes(context.hotelId) ||
            WILDCARD_TOKENS.includes(context.unitId) ||
            WILDCARD_TOKENS.includes(context.id)
        ) {
            const lastContext = recentSelectionService.getLastContext(context.type);

            if (!lastContext) {
                console.warn('no last context found');

                // Returning user to root page so they can select an asset and try again
                return router.createUrlTree([ '/' ]);
            }

            let routeLeaf = route;
            while (routeLeaf.firstChild) {
                routeLeaf = routeLeaf.firstChild;
            }

            return contextService.generateUrlFromContextParams(lastContext, routeLeaf);
        }

        recentSelectionService.setLastContext(context);

        return true;
    };
