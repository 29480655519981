import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { PageService } from '@bmng/services/page.service';
import { StackableRoutingNavigatorService } from '@bmng/services/navigation/stackable-routing-navigator.service';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: './page-root.component.html',
    styleUrls: [ './page-root.component.scss' ],
})
export class PageRootComponent implements OnDestroy {
    pageDataChangedSubscription: Subscription;

    constructor(
        public pageService: PageService,
        private changeDetectorRef: ChangeDetectorRef,
        public navSrv: StackableRoutingNavigatorService,
    ) {
        this.pageDataChangedSubscription = this.pageService.pageDataChanged.subscribe(() => {
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.pageDataChangedSubscription.unsubscribe();
    }
}
