import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EndpointService } from '../endpoint.service';
import { AwardType } from './interfaces/awards.interface';
import { AwardsServiceInterface } from './interfaces/awards.service.interface';

@Injectable()
export class AwardsService extends EndpointService implements AwardsServiceInterface {
    getPortalAwards(portalId: string): Observable<AwardType[]> {
        throw new Error('Method not implemented.');
    }
}
