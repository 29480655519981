<bm-button-group class="action-bar-desktop-edit-preview-toggle pr-4">
    <bm-button
        type="small"
        color="secondary"
        [active]="config.initialActiveButtonIdx === 0"
        (onClick)="onClickAction(config.buttons[0].onClick, 0)">
        {{ config.buttons[0].label }}
    </bm-button>
    <bm-button
        type="small"
        color="secondary"
        [active]="config.initialActiveButtonIdx === 1"
        (onClick)="onClickAction(config.buttons[1].onClick, 1)">
        {{ config.buttons[1].label }}
    </bm-button>
</bm-button-group>