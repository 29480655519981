import { AvailabilityBulkUpdate } from '@bmng/services/power-grid/interfaces/availability-bulk-update.interface';
import { Injectable } from '@angular/core';
import { IsoDate, Rate, RatePlan, Room, RoomRates, RoomRatesMetadata, RoomUpdates } from '@bmng/pages/master/power-grid/interfaces/room-rates.interface';
import { padAllAllocations, padAllDynamicAllocations, padGlobalAvailabilities } from '@bmng/pages/master/power-grid/services/pad-availabilities-helper';
import { DateRangeStartEnd, MomentHelpers, MomentService } from '@kognitiv/bm-components';
import { Moment } from 'moment';

import { AvailabilityValueUpdate,
    RoomAvailabilityUpdate } from '../../../pages/master/power-grid/interfaces/room-rates.interface';
import { TimeLogger } from '../../utils/time-logger.class';
import { BulkRate, RateUpdate, RateUpdates } from './interfaces/availability-bulk-update.interface';
import { RoomRateDateParams } from './interfaces/room-rate-updates.interface';

const moment = MomentService.get();

@Injectable()
export class RoomRateAugmentationService {
    constructor() {}

    augmentRoomRates(roomRates: RoomRates, roomRateParams: RoomRateDateParams, skipRates?: boolean): void {
        const expectedNumberOfValues = this.calcExpectedNumberOfValues(roomRateParams);
        const dateRange = this.getTimeRange(roomRateParams.start, expectedNumberOfValues);

        if (!roomRates.rooms) {
            roomRates.rooms = [];
        }

        roomRates.rooms.forEach(room => {
            this.setRootBaseRateReference(room);
            this.cleanAvailabilitiesByType(room);
            this.padAvailabilitiesOfRoom(room, roomRateParams.start, expectedNumberOfValues, roomRates.metaData);
            this.addMissingOccupancyRatesToMetadata(room, roomRates.metaData);

            if (!skipRates) {
                room.ratePlans
                    .filter(ratePlan => ratePlan.rates.length !== expectedNumberOfValues)
                    .forEach(ratePlan => this.padRates(ratePlan, dateRange));
            }
        });
    }

    cleanAvailabilitiesByType(room: Room): void {
        room.availabilities = room.availabilities.map(availability => {
                availability.values = availability.values
                    .filter(value => !value.quantityType || value.quantityType === 'EFFECTIVE');

            return availability;
        });
    }

    adjustBulkUpdatesForPreview(rateUpdate: RateUpdates): void {
        const updateStart: Moment = MomentHelpers.getMoment(rateUpdate.start);
        const updateEnd: Moment = MomentHelpers.getMoment(rateUpdate.end);

        if (!!rateUpdate.availabilities) {
            rateUpdate.availabilities = this.adjustBulkAvailabilityUpdatesForPreview(
                updateStart, updateEnd, rateUpdate.availabilities);
        }
        if (!!rateUpdate.rates?.length) {
            rateUpdate.rates = this.adjustRateUpdatesForPreview(
                updateStart, updateEnd, rateUpdate.rates);
        }
    }

    private adjustBulkAvailabilityUpdatesForPreview(updateStart: Moment, updateEnd: Moment,
        bulkAvailabilityUpdates: AvailabilityBulkUpdate[]): AvailabilityBulkUpdate[] {
        return (bulkAvailabilityUpdates || [])
            .map(reference => {
                const update = { ...reference };
                const dateRange = this.reduceDaterangeToVisibleDates(update.dateRange, updateStart, updateEnd);

                return {
                    ...update,
                    dateRange,
                };
            })
            .filter(update => update.dateRange.length > 0);
    }

    private reduceDaterangeToVisibleDates(dateRanges: DateRangeStartEnd[], updateStart: Moment, updateEnd: Moment): DateRangeStartEnd[] {
        return dateRanges
            .map(range => [
                MomentHelpers.getMoment(range.start),
                MomentHelpers.getMoment(range.end),
                range,
            ])
            .filter(([ rangeStart, rangeEnd, _ ]: [ Moment, Moment, DateRangeStartEnd ]) =>
                MomentHelpers.isBetweenDate(rangeStart, updateStart, updateEnd) ||
                    MomentHelpers.isBetweenDate(rangeEnd, updateStart, updateEnd) ||
                        rangeStart.isBefore(updateStart) && rangeEnd.isAfter(updateEnd),
            )
            .map(([ rangeStart, rangeEnd, range ]: [ Moment, Moment, DateRangeStartEnd ]) => {
                const start = MomentHelpers.isAfterDate(updateStart, rangeStart) ?
                    updateStart.format('YYYY-MM-DD') : range.start;
                const end = MomentHelpers.isBeforeDate(updateEnd, rangeEnd) ?
                    updateEnd.format('YYYY-MM-DD') : range.end;

                return { start, end };
            });
    }

    private adjustRateUpdatesForPreview(start: Moment, end: Moment, rateUpdates: RateUpdate[]): RateUpdate[] {
        return rateUpdates
            .map(reference => {
                const update = { ...reference };

                update.rates = update.rates
                    .map(rate => [
                        MomentHelpers.getMoment(rate.start),
                        MomentHelpers.getMoment(rate.end),
                        { ...rate },
                    ])
                    .filter(([ rateStart, rateEnd, _ ]: [ Moment, Moment, BulkRate ]) =>
                        MomentHelpers.isBetweenDate(rateStart, start, end) ||
                            MomentHelpers.isBetweenDate(rateEnd, start, end) ||
                                rateStart.isBefore(start) && rateEnd.isAfter(end),
                    )
                    .map(([ rateStart, rateEnd, rate ]: [ Moment, Moment, BulkRate ]) => {
                        const previewStart = MomentHelpers.isBeforeDate(rateStart, start) ? start : rateStart;
                        const previewEnd = MomentHelpers.isAfterDate(rateEnd, end) ? end : rateEnd;

                        rate.start = previewStart.format('YYYY-MM-DD');
                        rate.end = previewEnd.format('YYYY-MM-DD');

                        return rate;
                    });
                return update;
            })
            .filter(update => update.rates.length > 0);
    }

    cleanRoomUpdates(roomUpdates: RoomUpdates, rooms: Room[], filterValue?: (value: AvailabilityValueUpdate) => boolean): void {
        if (!roomUpdates) {
            return;
        }

        const virtualRoomCodes = rooms
            .filter(room => !!room.virtual)
            .map(room => room.code);

        roomUpdates.availabilities = roomUpdates.availabilities.map(
            (availability: RoomAvailabilityUpdate) => {
            const isQuantityUpdatable = !!availability.channelId || !virtualRoomCodes.includes(availability.roomCode);

            availability.values = availability.values
                .map(value => {
                    if (!isQuantityUpdatable && 'quantity' in value) {
                        delete value.quantity;

                        const containsClosed = 'closed' in value;

                        if (!containsClosed) {
                            return null;
                        }
                    }

                    return value;
                })
                .filter(value => !!value)
                .filter(value => !filterValue || filterValue(value));

            if (availability.values.length === 0) {
                return null;
            }

            return availability;
        }).filter(availability => !!availability);
    }

    addMissingOccupancyRatesToMetadata(room: Room, metadata: RoomRatesMetadata): void {
        const start = room.minOccupancy;
        const end = room.stdOccupancy;

        room.ratePlans.forEach(ratePlan => {
            const container = metadata.ratePlans.find(r => r.rateCode === ratePlan.code);

            // this might be true in case that the indexer did not provide us with any data
            // should not happen, but still might
            if (!!container) {
                for (let i = start; i <= end; i++) {
                    const rateMetadataVal = container.values.find(val => val.field === 'prices' && val.numberOfGuests === i);

                    if (!rateMetadataVal) {
                        container.values.push({
                            field: 'prices',
                            numberOfDays: 0,
                            numberOfGuests: i,
                        });
                    }
                }
            }
        });
    }

    getTimeRange(start: IsoDate, expectedNumberOfValues: number): Moment[] {
        const currentMomentObj = moment(start, 'YYYY-MM-DD');
        const result: Moment[] = [];

        for (let i = 0; i < expectedNumberOfValues; i++) {
            result.push(currentMomentObj
                .clone()
                .add(i, 'days'));
        }

        return result;
    }

    padRates(ratePlan: RatePlan, dateRange: Moment[]): void {
        function createEmptyRate(day: IsoDate): Rate {
            const emptyRate: Rate = {
                day,
                closedForArrival: null,
                closedForDeparture: null,
                closed: null,
                prices: [],
                childPrices: [],
                isFrontendGenerated: true,
            };

            if (ratePlan.derivedRateCode) {
                emptyRate.inheritClosed = true;
            }

            return emptyRate;
        }

        const expectedRates = [];

        dateRange.forEach((dateObj: Moment, idx: number, _: Moment[]) => {
            const dateToken = dateObj.format('YYYY-MM-DD');
            const rate = ratePlan.rates.find(r => r.day === dateToken);

            if (!!rate) {
                expectedRates.push(rate);
            } else {
                expectedRates.push(createEmptyRate(dateToken));
            }
        });

        ratePlan.rates = expectedRates;
    }

    setRootBaseRateReference(room: Room): void {
        room.ratePlans = room.ratePlans || [];
        room.ratePlans.forEach(ratePlan => {
            if (ratePlan.type !== 'BASE') {
                const derivePathParts = (ratePlan.derivePath || '').split('.');
                ratePlan.rootBaseRateCode = derivePathParts[0];
            }
        });
    }

    calcExpectedNumberOfValues(params: RoomRateDateParams): number {
        if (!params || !params.start || !params.end) {
            return -1;
        }

        return moment(params.end, 'YYYY-MM-DD').diff(moment(params.start, 'YYYY-MM-DD'), 'days') + 1;
    }

    padAvailabilitiesOfRoom(room: Room, startDate: IsoDate, expectedNumber: number, metaData?: RoomRatesMetadata): Room {
        padGlobalAvailabilities(room, startDate, expectedNumber);
        TimeLogger.start('padAllAllocations');
        padAllDynamicAllocations(room, startDate, expectedNumber, metaData);
        padAllAllocations(room, startDate, expectedNumber, metaData);
        TimeLogger.end('padAllAllocations', 'Finished padding');
        return room;
    }
}
