import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { RouteHelpers } from '@bmng/helpers/route-helpers';
import { ContextType } from '@bmng/services/context/interfaces/context-type.type';
import { Observable } from 'rxjs';

export const subContextGuardServiceCanActivateChild:
    ResolveFn<Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree> =
    (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const ALLOWED_SUBCONTEXT_PAGES: { [T in ContextType]?: string[] } = {
            portal: [ 'reservations' ],
            master: [ 'reservations' ],
        };

        const router = inject(Router);

        const contextType: ContextType = RouteHelpers.getDataFromRoute(childRoute, 'contextType');
        const contextId: string = RouteHelpers.getParamFromRoute(childRoute, 'contextId');
        const contextUrl = getBasicContextUrl(childRoute);
        const targetPage = state.url.replace(`${contextUrl}/`, '');

        if (!ALLOWED_SUBCONTEXT_PAGES[contextType]) {
            return true;
        }

        if (ALLOWED_SUBCONTEXT_PAGES[contextType].some(page => targetPage.startsWith(page))) {
            return true;
        }

        // eslint-disable-next-line no-console
        console.info('route not enabled in sub context; redirecting to ', `/${contextType}/${contextId}/${targetPage}`);

        return router.parseUrl(`/${contextType}/${contextId}/${targetPage}`);
    };

function getBasicContextUrl(childRoute: ActivatedRouteSnapshot): string {
    const contextType: ContextType = RouteHelpers.getDataFromRoute(childRoute, 'contextType');
    const contextId: string = RouteHelpers.getParamFromRoute(childRoute, 'contextId');
    const unitId: string = RouteHelpers.getParamFromRoute(childRoute, 'unitId');
    const hotelId: string = RouteHelpers.getParamFromRoute(childRoute, 'hotelId');

    let contextUrl = `/${contextType}/${contextId}`;

    if (unitId) {
        contextUrl += `/unit/${unitId}`;
    }

    if (hotelId) {
        contextUrl += `/hotel/${hotelId}`;
    }

    return contextUrl;
}
