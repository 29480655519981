import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EndpointService } from '../endpoint.service';

import { CpcChainUnitInfo } from './interfaces/cpcchain-unitinfo.interface';
import { CpcChainUnitInfoServiceInterface } from './interfaces/cpcchain-unitinfo.service.interface';

@Injectable()
export class CpcChainUnitInfoService extends EndpointService implements CpcChainUnitInfoServiceInterface {
    getUnitInfo(cpcChainId: string): Observable<CpcChainUnitInfo> {
        const url = `${EndpointService.getBmBackendUrl()}/api/cm/cpcchain/${cpcChainId}/unitinfo`;

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }
}
