import { ContextType } from '@bmng/services/context/interfaces/context-type.type';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ContextService } from '@bmng/services/context/context.service';

export type DocumentationIdMap = {
    [contextType in ContextType]?: string;
};
export class UrlHelpers {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static buildUrl(url: string, params: {[key: string]: any }): string {
        const allParams: string[] = [];
        Object.keys(params).forEach(key => {
            const val = params[key];
            if (Array.isArray(val)) {
                val.filter(singleVal => singleVal !== undefined && singleVal !== null)
                    .map(singleVal => `${singleVal}`)
                    .forEach(singleVal => {
                        allParams.push(`${key}=${encodeURIComponent(singleVal)}`);
                    });
            } else if (val !== undefined && val !== null) {
                const valAsString = `${val}`;
                allParams.push(`${key}=${encodeURIComponent(valAsString)}`);
            }
        });

        let paramsStr = '';
        if (allParams.length) {
            let delimiter = '?';
            if (url.indexOf('?') !== -1) {
                delimiter = '&';
            }

            paramsStr = `${delimiter}${allParams.join('&')}`;
        }

        return `${url}${paramsStr}`;
    }

    public static getDocumentationUrl(contextService: ContextService,
        route: ActivatedRouteSnapshot, documentationId: string): string {
        return `${contextService.getBaseContextUrl(route)}/documentation/${documentationId}`;
    }
    public static getDocumentationId(type: ContextType, documentationsIds: DocumentationIdMap): string {
        return documentationsIds[type] ?? null;
    }
}
