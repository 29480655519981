import { Component, Input } from '@angular/core';
import { TopToggleButtonConfig } from '@bmng/services/page-config.interface';

@Component({
    selector: 'action-bar-desktop-edit-preview-toggle',
    templateUrl: './action-bar-desktop-edit-preview-toggle.component.html',
    styleUrls: [ './action-bar-desktop-edit-preview-toggle.component.scss' ],
})
export class ActionBarDesktopEditPreviewToggleComponent {
    @Input() config: TopToggleButtonConfig = null;

    onClickAction(toggle: () => void, index: number): void {
        this.config.initialActiveButtonIdx = index;
        toggle();
    }
}
