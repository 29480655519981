<img [attr.src]="email | userAvatar" (click)="openDropdown($event)">
<bm-button-dropdown type="icon" icon="angle-down" (click)="openDropdown($event)">
</bm-button-dropdown>

<em [class.open]="dropdownOpened">
    <ul class="dropdown-menu">
        <li class="item">
            <div>
                <span class="item-title">{{ 'common.LANGUAGE' | lang }}</span>
                <span class="bm-icon-angle-left"></span>
            </div>
            <ul>
                <li class="item language-item" *ngFor="let language of languages | keyvalue" [class.active]="language.key.toLowerCase() === activeLanguage.toLowerCase()"
                    (click)="changeLanguage(language.key)">
                    <span class="bm-icon-check"></span>
                    {{language.value}}
                </li>
            </ul>
        </li>
        <li class="item" *ngIf="superAdmin && isDev">
            <div>
                <span class="item-title role-switch-btn">{{ 'bmng.header.userinfo.option.roleswitch' | lang }}</span>
                <span class="bm-icon-angle-left"></span>
            </div>
            <ul class="role-switch-options">
                <li class="item item-warning">!!Frontend Only!!</li>
                <li *ngFor="let role of rolesForDowngrading" (click)="fakeRole(role.role)" class="item role-item" [class.active]="currentRole === role.role" [attr.data-role]="role.role">
                    <span class="bm-icon-check"></span>
                    {{ role.label }}
                </li>
                <li class="item-separator"><hr /></li>
                <li class="item" [attr.data-role]="'reset'" (click)="fakeRole(null)">
                    {{ 'seekda.chainmetacpc.reset' | lang }}
                </li>
            </ul>
        </li>
        <li class="item-separator">
            <hr />
        </li>
        <li class="item">
            <a href="{{baseUrl}}/user/settings" class="item-title">{{ 'common.MY_ACCOUNT' | lang }}</a>
        </li>
        <li class="item">
            <a href="{{logoutUrl}}" class="item-title">{{ 'logout.title' | lang }}</a>
        </li>
    </ul>
</em>
