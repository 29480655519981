import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { assertNever } from '@kognitiv/bm-components';
import { Observable } from 'rxjs';

import { EndpointService } from '../endpoint.service';

import { WaitingListConfig } from './interfaces/waiting-list-config.interface';
import { WaitingListPrice, WaitingListReservationDetails } from './interfaces/waiting-list-reservation.interface';
import { RoomDetails, WaitingListChannel, WaitingListContextType, WaitingListParams, WaitingListResult } from './interfaces/waiting-list.interface';
import { WaitingListServiceInterface } from './interfaces/waiting-list.service.interface';

@Injectable()
export class WaitingListService extends EndpointService implements WaitingListServiceInterface {

    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    getWaitingListConfig(
        contextType: WaitingListContextType,
        entityId: string,
    ): Observable<WaitingListConfig> {
        const url = this.getBaseUrl(contextType, entityId) + `config`;
        return this.httpGet<WaitingListConfig>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    saveWaitingListConfig(
        contextType: WaitingListContextType,
        entityId: string,
        config: WaitingListConfig,
    ): Observable<boolean> {
        const url = this.getBaseUrl(contextType, entityId) + `config`;
        return this.httpPost<boolean>(url, config, EndpointService.HTTP_HEADERS);
    }

    getWaitingListHotels(
        contextType: WaitingListContextType,
        entityId: string,
        languageCode: string,
    ): Observable<WaitingListChannel[]> {
        const url = this.getBaseUrl(contextType, entityId) + `summary?languageCode=${languageCode}`;
        return this.httpGet<WaitingListChannel[]>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    getWaitingListRooms(
        contextType: WaitingListContextType,
        entityId: string,
        languageCode: string,
    ): Observable<RoomDetails[]> {
        const url = this.getBaseUrl(contextType, entityId) + `summary?languageCode=${languageCode}`;
        return this.httpGet<RoomDetails[]>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    getWaitingList(
        contextType: WaitingListContextType,
        entityId: string,
        params: WaitingListParams,
    ): Observable<WaitingListResult> {
        const url = this.getBaseUrl(contextType, entityId) + `search.json`;
        return this.httpGet<WaitingListResult>(UrlHelpers.buildUrl(url, params), EndpointService.HTTP_HEADERS_ACCEPT);
    }

    getWaitingListReservationDetails(
        contextType: WaitingListContextType,
        entityId: string,
        applicantId: number,
        languageCode: string,
    ): Observable<WaitingListReservationDetails> {
        const url = this.getBaseUrl(contextType, entityId) + `detail/${applicantId}?languageCode=${languageCode}`;
        return this.httpGet<WaitingListReservationDetails>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    getWaitingListPrice(
        contextType: WaitingListContextType,
        entityId: string,
        applicantId: number,
    ): Observable<WaitingListPrice> {
        const url = this.getBaseUrl(contextType, entityId) + `price/${applicantId}`;
        return this.httpGet<WaitingListPrice>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    reprioritiseRequest(
        contextType: WaitingListContextType,
        entityId: string,
        applicantId: number,
        newPosition: number,
    ): Observable<boolean> {
        const payLoad = { newPosition };
        const url = this.getBaseUrl(contextType, entityId) + `reprioritise/${applicantId}`;
        return this.httpPost<boolean>(url, payLoad, EndpointService.HTTP_HEADERS);
    }

    rejectRequest(
        contextType: WaitingListContextType,
        entityId: string,
        applicantId: number,
    ): Observable<boolean> {
        const url = this.getBaseUrl(contextType, entityId) + `reject/${applicantId}`;
        return this.httpPost<boolean>(url, EndpointService.HTTP_HEADERS);
    }

    createOnHoldReservation(
        contextType: WaitingListContextType,
        entityId: string,
        applicantId: number,
        acceptedPrice: number,
    ): Observable<boolean> {
        const url = this.getBaseUrl(contextType, entityId) + `confirmonhold/${applicantId}`;
        const payLoad = { acceptedPrice };
        return this.httpPost<boolean>(url, payLoad, EndpointService.HTTP_HEADERS);
    }

    getBaseUrl(
        contextType: WaitingListContextType,
        entityId: string,
    ): string {
        if (contextType === 'portal') {
            return `${EndpointService.getBmBackendUrl()}/api/waitinglist/channel/${entityId}/`;
        } else if (contextType === 'hotel') {
            return `${EndpointService.getBmBackendUrl()}/api/waitinglist/${contextType}/${entityId}/`;
        } else {
            assertNever(contextType);
        }
    }

}
