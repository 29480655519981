import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class FormHelpers {
    public static validateFormGroup(form: UntypedFormGroup, update: boolean = false): boolean {
        form.markAsDirty();
        form.markAsTouched();

        FormHelpers.validateFormControls(form.controls, update);

        if (update) {
            form.updateValueAndValidity();
        }

        return (form.valid || (!form.invalid && form.disabled));
    }

    public static validateFormControls(formControls: ({ [key: string]: AbstractControl }) | AbstractControl[],
        update: boolean = false): void {
        Object.keys(formControls)
            .map(key => formControls[key])
            .filter(control => control.enabled)
            .forEach(control => {
                control.markAsTouched();
                control.markAsDirty();

                if (control.controls) {
                    FormHelpers.validateFormControls(control.controls, update);
                }

                if (update) {
                    control.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                }
            });
    }

    public static validateForm(form: { [name: string]: AbstractControl }): boolean {
        const allControls = Object.keys(form)
            .map(key => form[key])
            .filter(control => control.enabled);

        allControls
            .forEach(control => {
                control.markAsTouched();
                control.markAsDirty();
            });

        return allControls.every(control => control.valid);
    }

    public static addErrors(control: AbstractControl, errors: ValidationErrors | null): void {
        if (!errors) {
            return;
        }

        let existingErrors = control.errors;
        if (existingErrors === null || existingErrors === undefined) {
            existingErrors = {};
        }

        existingErrors = Object.assign(existingErrors, errors);

        control.setErrors(existingErrors);
    }

    public static clearErrors(control: AbstractControl, ...errorCodesToRemove: string[]): void {
        if (!errorCodesToRemove || !errorCodesToRemove.length) {
            return;
        }

        const existingErrors = control.errors;
        if (!existingErrors) {
            return;
        }

        errorCodesToRemove.forEach(errorCodeToRemove => {
            if (existingErrors.hasOwnProperty(errorCodeToRemove)) {
                delete existingErrors[errorCodeToRemove];
            }
        });

        if (Object.keys(existingErrors).length) {
            control.setErrors(existingErrors);
        } else {
            control.setErrors(null);
        }
    }

    public static getConditionalValidator(isEnabled: () => boolean, validators: ValidatorFn[]): ValidatorFn {
        return (control: AbstractControl) => {
            if (isEnabled()) {
                for (const validate of validators) {
                    const res = validate(control);

                    if (res !== null) {
                        return res;
                    }
                }
            }

            return null;
        };
    }

    public static copyFormControl(control: AbstractControl): UntypedFormGroup | UntypedFormArray | UntypedFormControl {
        if (control instanceof UntypedFormControl) {
            return new UntypedFormControl(control.value);
        } else if (control instanceof UntypedFormGroup) {
            const copy = new UntypedFormGroup({});
            Object.keys(control.controls).forEach(key => {
                copy.addControl(key, this.copyFormControl(control.controls[key]));
            });
            return copy;
        } else if (control instanceof UntypedFormArray) {
            const copy = new UntypedFormArray([]);
            control.controls.forEach(_control => {
                copy.push(this.copyFormControl(_control));
            });
            return copy;
        }
    }
}
