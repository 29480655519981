import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { AssetCheck } from '@bmng/services/entity-selection/interfaces/http-responses/asset-check.interface';
import { CPCSearchResult } from '@bmng/services/entity-selection/interfaces/http-responses/cpc-search-result.interface';
import {
    PortalSearchResult,
} from '@bmng/services/entity-selection/interfaces/http-responses/portal-search-result.interface';
import {
    SearchParametersInterface,
} from '@bmng/services/entity-selection/interfaces/search-parameters/search-parameters.interface';
import { forkJoin, Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

import { EndpointService } from './../../../core/services/endpoint.service';
import { EntitySelectionServiceInterface } from './interfaces/entity-selection.service.interface';
import { CircleSearchResult } from './interfaces/http-responses/circle-search-result.interface';
import { EntityCounts } from './interfaces/http-responses/entity-counts.interface';
import { HotelInfo } from './interfaces/http-responses/hotel-info.interface';
import { HotelSearchResult } from './interfaces/http-responses/hotel-search-result.interface';
import { ReferenceTypeResponse } from './interfaces/http-responses/reference-type-response.interface';
import { CircleSearchParametersInterface } from './interfaces/search-parameters/circle-search-parameters.interface';
import { CPCSearchParametersInterface } from './interfaces/search-parameters/cpc-search-parameters.interface';
import { HotelSearchParametersInterface } from './interfaces/search-parameters/hotel-search-parameters.interface';
import { PortalSearchParametersInterface } from './interfaces/search-parameters/portal-search-parameters.interface';

type HotelList = HotelInfo[];

@Injectable()
export class EntitySelectionService extends EndpointService implements EntitySelectionServiceInterface {

    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    assetCheck(): Observable<AssetCheck> {
        const url = `${EndpointService.getBmBackendUrl()}/api/search/header/assetcheck`;

        return this.httpGet<AssetCheck>(url, EndpointService.HTTP_HEADERS);
    }

    buildParams(searchParams: SearchParametersInterface): SearchParametersInterface {
        if (searchParams.sort) {
            searchParams.sort = searchParams.sort.toUpperCase();
        }

        return searchParams;
    }

    cpcSearch(searchParams: CPCSearchParametersInterface): Observable<CPCSearchResult> {
        let url = `${EndpointService.getBmBackendUrl()}/api/cpc/`;
        if (searchParams !== null) {
            searchParams = this.buildParams(searchParams);
            url = UrlHelpers.buildUrl(url, searchParams);
        }
        return this.httpGet<CPCSearchResult>(url, EndpointService.HTTP_HEADERS);
    }

    portalSearch(searchParams: PortalSearchParametersInterface): Observable<PortalSearchResult> {
        let url = `${EndpointService.getBmBackendUrl()}/api/portals/`;
        if (searchParams !== null) {
            searchParams = this.buildParams(searchParams);
            url = UrlHelpers.buildUrl(url, searchParams);
        }
        return this.httpGet<CPCSearchResult>(url, EndpointService.HTTP_HEADERS);
    }

    hotelSearch(searchParams: HotelSearchParametersInterface): Observable<HotelSearchResult> {
        let url = `${EndpointService.getBmBackendUrl()}/api/hotels/`;
        if (searchParams !== null) {
            searchParams = this.buildParams(searchParams);
            url = UrlHelpers.buildUrl(url, searchParams);
        }
        return this.httpGet<HotelSearchResult>(url, EndpointService.HTTP_HEADERS);
    }

    circleSearch(searchParams: CircleSearchParametersInterface): Observable<CircleSearchResult> {
        let url = `${EndpointService.getBmBackendUrl()}/api/directChannels/`;
        if (searchParams !== null) {
            searchParams = this.buildParams(searchParams);
            url = UrlHelpers.buildUrl(url, searchParams);
        }
        return this.httpGet<CircleSearchResult>(url, EndpointService.HTTP_HEADERS);
    }

    getAllHotelSearchEntries(searchParams: HotelSearchParametersInterface): Observable<HotelInfo[]> {
        if (typeof searchParams.size === 'undefined') {
            searchParams.size = 1000;
        }

        return this.hotelSearch(searchParams).pipe(
            flatMap(hotelResult => {
                const total = hotelResult.total;
                const calls = (total > hotelResult.result.length) ? Math.ceil(total / hotelResult.result.length) : 0;
                const $observables = [ of(hotelResult.result) ];
                let offset = hotelResult.result.length;

                for (let i = 0; i < calls; i++) {
                    const paramCopy = { ...searchParams };
                    paramCopy.start = offset;
                    $observables.push(this.hotelSearch(paramCopy).pipe(map(res => res.result)));
                    offset += paramCopy.size;
                }

                return forkJoin($observables);
            }),
            map((hotelResults: HotelList[]) => {
                let entries = [];
                hotelResults.forEach(res => entries = entries.concat(res));
                return entries;
            }),
        );
    }

    getEntityCounts(): Observable<EntityCounts> {
        const url = `${EndpointService.getBmBackendUrl()}/api/search/header/count`;
        return this.httpGet<EntityCounts>(url, EndpointService.HTTP_HEADERS);
    }

    getReferenceTypes(): Observable<ReferenceTypeResponse[]> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotels/referenceTypes`;
        return this.httpGet<ReferenceTypeResponse[]>(url, EndpointService.HTTP_HEADERS);
    }
}
