<nav class="navbar navbar-static-top">
    <div class="navbar-pull-left">
        <div class="menu-toggle-actions">
            <bm-button type="icon" icon="nav-expand" (onClick)="toggleMenu()" *ngIf="!pageService.isMenuShown()"></bm-button>
            <bm-button type="icon" icon="nav-collapse" (onClick)="toggleMenu()" *ngIf="pageService.isMenuShown()"
                class="hide-xsmall hide-small hide-medium"></bm-button>
        </div>
    </div>
    <!-- right side menu -->
    <div class="navbar-pull-right">
        <user-info [activeContext]="activeContext" [superAdmin]="isSuperAdmin" [currentRole]="currentRole" [email]="email" (roleDowngrade)="downgradeRole($event)"></user-info>

        <!-- hotel related data -->
        <div class="hotel-info">
            <div *ngIf="userName">{{ 'welcome.title' | lang }}, <strong>{{userName}}</strong> <span *ngIf="currentRole"> ({{currentRole}})</span></div>

            <div class="icons" *ngIf="iconVisibility">
                <a *ngIf="iconVisibility.isSupportPartnerVisible"
                    href="{{baseUrl}}/erpRedirect.do?partnerId={{activeContext.supportPartner || activeContext.billTo}}"
                    [title]="'bmng.header.icons.supportPartner' | lang:(activeContext.supportPartner || activeContext.billTo)"
                    target="_blank"
                    class="icon"
                >
                    <span class="bm-icon-phone"></span>
                </a>
                <a *ngIf="iconVisibility.isBillingPartnerVisible"
                    href="{{baseUrl}}/erpRedirect.do?partnerId={{activeContext.billTo}}"
                    [title]="'bmng.header.icons.billingPartner' | lang:activeContext.billTo"
                    target="_blank"
                    class="icon"
                >
                    <span class="bm-icon-users"></span>
                </a>
                <a *ngIf="iconVisibility.isReportVisible"
                    href="{{baseUrl}}/admin/statistik.jsp"
                    [title]="'bmng.header.icons.propertyPerformance' | lang"
                    target="_blank"
                    class="icon"
                >
                    <span class="bm-icon-performance-report"></span>
                </a>
                <a *ngIf="iconVisibility.isHistoryVisible"
                    [href]="historyUrl"
                    [title]="'bmng.header.icons.history' | lang"
                    target="_blank"
                    class="icon"
                >
                    <span class="bm-icon-application-log"></span>
                </a>
                <div *ngIf="activeContext.name" class="hotel-name">
                    <strong class="hide-xsmall">{{channelName}}</strong>
                    <strong>{{activeContext.name}}</strong>
                    <span *ngIf="activeContext.id"> ({{activeContext.id}})</span>
                </div>
            </div>
        </div>

        <div class= "hotel-info phone-view">
            <div *ngIf="activeContext.name" class="context-name">
                <strong>{{activeContext.name}}</strong>
            </div>
            <div *ngIf="activeContext.id">
                ({{activeContext.id}})
            </div>
        </div>
    </div>
</nav>