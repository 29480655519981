import { Observable } from 'rxjs';
import { LangService } from '@kognitiv/angular-i18n';
import { HttpClient } from '@angular/common/http';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { Injectable } from '@angular/core';
import { EndpointService } from '../endpoint.service';
import { TransactionInitializationMode } from '../reservation-payment/interfaces/reservation-payment.interface';
import { ReservationPaymentLinkServiceInterface } from './interfaces/reservation-payment-link.service.interface';
import { ReservationPaymentLink } from './interfaces/reservation-payment-link.interface';

@Injectable()
export class ReservationPaymentLinkService extends EndpointService implements ReservationPaymentLinkServiceInterface {
    constructor(
        http: HttpClient,
        private readonly lang: LangService,
    ) {
        super(http);
    }

    createPaymentLink(
        hotelId: string,
        channelId: string,
        reservationId: string,
        linkValidityHours: number,
        mode: TransactionInitializationMode,
        amount: number,
        currencyCode: string,
        comment: string,
    ): Observable<ReservationPaymentLink> {
        const url = `${EndpointService.getBmBackendUrl()}/api/payments/links`;

        const body = {
            paymentReference: {
                hotelId,
                channelId,
                reservationId,
            },
            language: this.lang.getCurrentLanguage(),
            linkValidityHours,
            mode,
            amount,
            currencyCode,
            comment,
        };

        return this.httpPost(url, body, EndpointService.HTTP_HEADERS);
    }

    getPaymentLink(id: string): Observable<ReservationPaymentLink> {
        const url = UrlHelpers.buildUrl(`${EndpointService.getBmBackendUrl()}/api/payments/links/${id}`, {
            language: this.lang.getCurrentLanguage(),
        });

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }

    getPaymentLinks(hotelId: string, channelId: string, reservationId: string): Observable<ReservationPaymentLink[]> {
        const url = UrlHelpers.buildUrl(`${EndpointService.getBmBackendUrl()}/api/payments/links`, {
            hotelId,
            reservationId,
            language: this.lang.getCurrentLanguage(),
            channelId,
        });

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }

    sendBySMS(id: string, phoneNumber: string): Observable<boolean> {
        const url = `${EndpointService.getBmBackendUrl()}/api/payments/links/${id}/notification`;
        const body = {
            language: this.lang.getCurrentLanguage(),
            phoneNumber,
        };

        return this.httpPost(url, body, EndpointService.HTTP_HEADERS);
    }

    sendByEmail(id: string, email: string): Observable<boolean> {
        const url = `${EndpointService.getBmBackendUrl()}/api/payments/links/${id}/notification`;
        const body = {
            language: this.lang.getCurrentLanguage(),
            email,
        };

        return this.httpPost(url, body, EndpointService.HTTP_HEADERS);
    }
}
