import { Component, Injectable, OnInit } from '@angular/core';
import { UserManagement, UserStatusType } from '@bmng/services/user-management/interfaces/user-management.interface';
import { LangService } from '@kognitiv/angular-i18n';
import { RenderComponent } from '@kognitiv/bm-components';

interface StatusDetail {
    label: string;
    tooltip?: string;
}

@Injectable()
export class UserStatusHelpers {
    private statusDetails: { [K in UserStatusType]: StatusDetail } = {
        inactive: {
            label: this.lang.get('bmng.users.status.inactive.label'),
            tooltip: this.lang.get('bmng.users.status.inactive.tooltip'),
        },
        notregistered: {
            label: this.lang.get('bmng.users.status.notregistered.label'),
        },
        active: {
            label: this.lang.get('bm.contracts.status.ACTIVE.title'),
        },
    };

    constructor(private lang: LangService) { }

    getStatusDetail<K extends keyof StatusDetail>(detailType: K, status: UserStatusType): StatusDetail[K] {
        const statusDetails = this.getStatusMap(status);

        return statusDetails[detailType];
    }

    getStatusMap(status: UserStatusType): StatusDetail {
        return this.statusDetails[status.toLowerCase()];
    }
}

@Component({
    template: `
        <span *ngIf="!!label">
            <span *ngIf="!tooltip">{{ label }}</span>
            <span *ngIf="!!tooltip">
                {{ label }}
                <span *ngIf="!!tooltip" bmTooltip
                    [title]="tooltip"
                    position="top"><em class="bm-icon-circle-question"></em></span>
            </span>
        </span>
    `,
})
export class UserStatusRendererComponent extends RenderComponent<UserManagement> implements OnInit {
    data: UserManagement;
    label: string;
    tooltip: string;

    constructor(
        public statusHelpers: UserStatusHelpers,
    ) {
        super();
    }

    ngOnInit(): void {
        const status: UserStatusType = this.data.status;

        if (status) {
            this.label = this.statusHelpers.getStatusDetail('label', status);
            this.tooltip = status.toLowerCase() === 'inactive'
                ? this.statusHelpers.getStatusDetail('tooltip', status)
                : null;
        }
    }
}
