import { NgModule } from '@angular/core';
import { SharedModule } from '@bmng/shared/shared.module';

import { ErrorComponent } from './error.component';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        ErrorComponent,
    ],
    exports: [
        ErrorComponent,
    ],
})
export class ErrorModule {}
