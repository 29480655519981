import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category } from '@bmng/services/category/category';
import { ContextType } from '@bmng/services/context/interfaces/context-type.type';
import { LangService } from '@kognitiv/angular-i18n';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EndpointService } from './../endpoint.service';
import { Metadata, MetadataRates } from './interfaces/metadata.interface';
import { MetadataServiceInterface } from './interfaces/metadata.service.interface';

@Injectable()
export class MetadataService extends EndpointService implements MetadataServiceInterface {
    static readonly DEFAULT_MAX_RATES = 20;
    static readonly DEFAULT_MAX_ROOMS = 20;
    static readonly DEFAULT_MAX_CATEGORIES = 50;

    METADATA_ENDPOINT: string = EndpointService.getBmBackendUrl()
        + '/api/{entity}/{id}/metadata?languageCode=' + this.lang.getCurrentLanguage();

    public static hasMultiCurrencySupport(data: Metadata, entityType: ContextType = 'hotel'): boolean {
        if (!data
            || (entityType === 'hotel' && !data.availableCurrencies)
            || (entityType !== 'hotel' && !data.availableCurrenciesInAllProperties)) {
            return false;
        }

        if (entityType === 'hotel' && !!data.availableCurrencies) {
            return data.availableCurrencies.length > 0;
        }
        if (entityType !== 'hotel' && !!data.availableCurrenciesInAllProperties) {
            return data.availableCurrenciesInAllProperties.length > 0;
        }
        return false;
    }

    public static getRatesOfRoom(roomCode: string, metadata: Metadata): MetadataRates[] {
        if (!roomCode || !metadata) {
            return [];
        }

        return metadata.rates
            .filter(rate => rate.roomCode === roomCode);
    }

    constructor(
        http: HttpClient,
        private lang: LangService,
    ) {
        super(http);
    }

    getMetadata(hotelId: string, includePackages: boolean = false, entityType: ContextType = 'hotel'): Observable<Metadata> {
        const url = this.getEndpointUrl(hotelId, includePackages, entityType);
        return this.httpGet<Metadata>(url, EndpointService.HTTP_HEADERS_ACCEPT).pipe(
            map(data => {
                data.maxRates = data.maxRates ?? MetadataService.DEFAULT_MAX_RATES;
                data.maxRooms = data.maxRooms ?? MetadataService.DEFAULT_MAX_ROOMS;
                data.maxCategories = data.maxCategories ?? MetadataService.DEFAULT_MAX_CATEGORIES;

                return data;
            }),
        );
    }

    private getEndpointUrl(hotelId: string, includePackages: boolean = false, entityType: ContextType = 'hotel'): string {
        let url: string =  this.METADATA_ENDPOINT.replace('{entity}', entityType).replace('{id}', hotelId);
        url += `&includePackages=${includePackages ? 'true' : 'false'}`;

        return url;
    }

    getChannelMetadata(channelId: string): Observable<Metadata> {
        const url: string =  this.METADATA_ENDPOINT.replace('{entity}', 'portal').replace('{id}', channelId);

        return this.httpGet<Metadata>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    getDirectMetadata(channelId: string): Observable<Metadata> {
        const url: string =  this.METADATA_ENDPOINT.replace('{entity}', 'direct').replace('{id}', channelId);

        return this.httpGet<Metadata>(url, EndpointService.HTTP_HEADERS);
    }
}

export function formatCategoryLabel(_cat: Category): string {
    return _cat.title ? `${_cat.title} (${_cat.code})` : _cat.code;
}
