import { ReportsReport } from '../interfaces/reports-report.interface';

const mockData: ReportsReport[] = [
    {
        id: 1,
        subscriptionId: 1,
        sentOn: '2018-01-01T12:12:12+00:00',
        link: 'https://kognitiv.com',
        recipients: [ 'jonathan.casey@kognitiv.com' ],
    },
    {
        id: 2,
        subscriptionId: 2,
        sentOn: '2018-01-01T14:14:14+00:00',
        link: 'https://kognitiv.com',
        recipients: [ 'jonathan.casey@kognitiv.com', 'david.hainzl@kognitiv.com' ],
    },
    {
        id: 3,
        subscriptionId: 25789,
        sentOn: '2018-01-05T12:12:12+00:00',
        link: 'https://kognitiv.com',
        recipients: [ 'holger.lausen@kognitiv.com' ],
    },
];

export default {
    data: mockData,
};
