import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ContextType } from '../context/interfaces/context-type.type';
import { EndpointService } from '../endpoint.service';

import { ImagesResult, PossibleImageAlternativeSizeCategory } from './interfaces/images.interface';
import { ImagesServiceInterface } from './interfaces/images.service.interface';

@Injectable()
export class ImagesService extends EndpointService implements ImagesServiceInterface {
    public static getSuggestedImageSizeFromCategory(category: string): PossibleImageAlternativeSizeCategory {
        const mapping: { [key: string]: PossibleImageAlternativeSizeCategory } = {
            22: 'Banner',
        };

        return mapping[category] || 'Other';
    }

    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    getImages(assetId: string, assetType: ContextType, includeArchived: boolean = false): Observable<ImagesResult> {
        let type: string;

        switch (assetType) {
            case 'hotel':
            case 'master': type = 'properties'; break;
            case 'portal': type = 'portals'; break;
            default: throw new Error('hotelImageService only available for hotels, portals and master');
        }

        const url = `${EndpointService.getBmBackendUrl()}/api/images/${type}/${assetId}/metadata?includeArchived=${includeArchived}`;

        return this.httpGet<ImagesResult>(url, EndpointService.HTTP_HEADERS);
    }
}
