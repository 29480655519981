import { Pipe, PipeTransform } from '@angular/core';
import { LangService } from '@kognitiv/angular-i18n';

@Pipe({
    name: 'channelName',
})
export class ChannelNamePipe implements PipeTransform {
    constructor(
        private langService: LangService,
    ) { }

    transform(channelId: string, addId: boolean = true): string {
        // ts[channels.name.[*]]
        const channelName = this.langService.getWithFallback(`channels.name.${channelId}`, channelId);

        if (addId && channelName !== channelId) {
            return `${channelName} (${channelId})`;
        }
        return channelName;
    }
}
