import { Pipe, PipeTransform } from '@angular/core';
import { RouteHelpers } from '@bmng/helpers/route-helpers';
import { ContextService } from '@bmng/services/context/context.service';
import { MenuItemChild, MenuItemRoot } from '@bmng/services/menu/interfaces/menu.interface';
import { assertNever } from '@kognitiv/bm-components';
import { environment } from 'src/environments/environment';

@Pipe({
    name: 'oldBmUrl',
})
export class OldBmUrlPipe implements PipeTransform {
    constructor(
        private contextService: ContextService,
    ) { }

    transform(menuItem: MenuItemChild | MenuItemRoot): string {
        const context = this.contextService.context.value.context;
        const activeContext = RouteHelpers.getActiveContext(context);

        if (activeContext.type === 'pms') {
            console.error(`Old CM URL in non-supported context ${activeContext.type} ...`);
            return;
        }

        if (activeContext.type === 'hotel') {
            // Subcontext /portal/xx/hotel/xx is not supported in old cm, we just fall back to portal ctx
            if (context.channel.id && context.channel.type === 'portal') {
                return `${environment.baseUrls.bm}/search/${context.channel.id}${menuItem.url}`;
            }
         }

        switch (activeContext.type) {
            case 'global': return `${environment.baseUrls.bm}${menuItem.url}`;
            case 'portal': return `${environment.baseUrls.bm}/search/${activeContext.id}${menuItem.url}`;
            case 'unit': return `${environment.baseUrls.bm}/cpcchain/${activeContext.id}${menuItem.url}`;
            case 'cpc': return `${environment.baseUrls.bm}/cpcchain/${activeContext.id}${menuItem.url}`;
            case 'master':
            case 'direct':
            case 'hotel': return `${environment.baseUrls.bm}/cm/${activeContext.id}${menuItem.url}`;
            default: assertNever(activeContext.type);
        }
    }
}
