import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PolicyCleanup } from '@bmng/services/policies/interfaces/policies.interface';

import { UiPolicyCleanup } from '../../../../pages/master/policies/interface/policies-ui.interface';

@Injectable()
export class PolicyFormCleanupSubmitService {
    getCleanupPolicyConfig(form: UntypedFormGroup): PolicyCleanup {
        if (!form) {
            return;
        }

        const policyConfig = form.value as UiPolicyCleanup;
        const cleanupPolicy: PolicyCleanup = {
            code: policyConfig.code,
            amount: policyConfig.amount,
            billingType: policyConfig.billingType,
            currencyCode: policyConfig.currencyCode,
            descriptions: policyConfig.descriptions,
        };

        return cleanupPolicy;
    }
}
