import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { LangService } from '@kognitiv/angular-i18n';
import { delay, map, Observable, of, tap } from 'rxjs';

import { EndpointService } from '../endpoint.service';
import { EndpointPaymentConfig, PaymentConfigTransaction, PaymentMethodChargeData, PaymentMethodContainer, PaymentTransaction, TransactionCancel, TransactionInitializationMode, TransactionProgress, TransactionRefund } from './interfaces/reservation-payment.interface';
import { ReservationPaymentServiceInterface } from './interfaces/reservation-payment.service.interface';

@Injectable()
export class ReservationPaymentService extends EndpointService implements ReservationPaymentServiceInterface {
    constructor(
        http: HttpClient,
        private readonly lang: LangService,
    ) {
        super(http);
    }

    getPaymentMethods(
        hotelId: string,
        channelId: string,
        reservationId: string,
    ): Observable<PaymentMethodContainer[]> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/payments/methods`;

        const url = UrlHelpers.buildUrl(baseUrl, {
            hotelId,
            reservationId,
            channelId: channelId ?? undefined,
            language: this.lang.getCurrentLanguage().toLowerCase(),
        });

        return this.httpGet<PaymentMethodContainer[]>(url, EndpointService.HTTP_HEADERS);
    }

    chargePaymentMethod(
        payload: PaymentMethodChargeData,
    ): Observable<boolean> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/payments`;

        const url = UrlHelpers.buildUrl(baseUrl, {
            language: this.lang.getCurrentLanguage().toLowerCase(),
        });

        return this.httpPost<boolean>(url, payload, EndpointService.HTTP_HEADERS);
    }

    createTransaction(
        hotelId: string,
        channelId: string,
        reservationId: string,
        mode: TransactionInitializationMode,
        amount?: number,
        currencyCode?: string,
        terminalId?: string,
    ): Observable<PaymentTransaction> {
        const url = `${EndpointService.getBmBackendUrl()}/api/payments/initialize`;
        const params = {
            amount,
            currencyCode,
            mode,
            paymentReference: {
                hotelId,
                channelId,
                reservationId,
            },
            language: this.lang.getCurrentLanguage(),
            returnUrl: window.location.href,        // TODO Not sure if this is actually used?
            terminalId,
        };

        return this.httpPost<PaymentTransaction<EndpointPaymentConfig>>(url, params, EndpointService.HTTP_HEADERS).pipe(
            map(data => ({
                ...data,
                paymentConfig: {
                    ...data.paymentConfig,

                    needsPostCheckoutPayment: data.paymentConfig.needs_post_checkout_payment,
                    isTestMode: data.paymentConfig.is_test_mode,
                    merchantId: data.paymentConfig.merchant_id,
                    paymentPartnerInfo: data.paymentConfig.payment_partner_info,
                    allowedCcTypeCodes: data.paymentConfig.allowed_cc_type_codes,
                    allowedMethods: data.paymentConfig.allowed_methods,
                },
            })),
        );
    }

    submitTransactionResult(
        hotelId: string,
        channelId: string,
        reservationId: string,
        paymentId: number,
        transactionResult: PaymentConfigTransaction,
    ): Observable<boolean> {
        const url = `${EndpointService.getBmBackendUrl()}/api/payments/${paymentId}`;
        const params = {
            paymentReference: {
                hotelId,
                channelId,
                reservationId,
            },
            transaction: transactionResult,
        };

        return this.httpPut(url, params, EndpointService.HTTP_HEADERS);
    }

    checkTransactionProgress(
        taskId: string,
        hotelId: string,
        channelId: string,
        reservationId: string,
    ): Observable<TransactionProgress> {
        const url = UrlHelpers.buildUrl(`${EndpointService.getBmBackendUrl()}/api/payments/tasks/${taskId}`, {
            languageCode: this.lang.getCurrentLanguage(),
            hotelId,
            channelId: channelId ?? undefined,
            reservationId,
        });

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }

    cancelTerminalTransaction(
        taskId: string,
        hotelId: string,
        channelId: string,
        reservationId: string,
    ): Observable<boolean> {
        // TODO david.hainzl connect endpoint when it exists
        return of(true).pipe(delay(1000), tap(() => console.log('trx canceled')));
    }

    cancel(cancel: TransactionCancel): Observable<boolean> {
        const url = `${EndpointService.getBmBackendUrl()}/api/payments/cancel`;

        return this.httpPost(url, cancel, EndpointService.HTTP_HEADERS);
    }

    refund(refund: TransactionRefund): Observable<boolean> {
        const url = `${EndpointService.getBmBackendUrl()}/api/payments/refund`;

        return this.httpPost(url, refund, EndpointService.HTTP_HEADERS);
    }

    markPaymentMethodInvalid(
        hotelId: string,
        reservationId: string,
        paymentMethod: PaymentMethodContainer,
    ): Observable<boolean> {
        const url = UrlHelpers.buildUrl(`${EndpointService.getBmBackendUrl()}/api/reservation/invalidCreditCard`, {
            languageCode: this.lang.getCurrentLanguage(),
        });

        const data = {
            hotelId,
            reservationId,
            paymentMethod: paymentMethod.method,
        };

        return this.httpPost(url, data, EndpointService.HTTP_HEADERS);
    }
}
