import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PolicyDescription, PolicyTax, TaxPolicyPayment } from '@bmng/services/policies/interfaces/policies.interface';

import { UiPolicyTax, UiTaxPaymentPolicy } from '../../../../pages/master/policies/interface/policies-ui.interface';

@Injectable()
export class PolicyFormTaxSubmitService {
    getTaxPolicyConfig(form: UntypedFormGroup): PolicyTax {
        if (!form) {
            return;
        }

        const uiPolicyConfig = form.value as UiPolicyTax;

        const taxPolicy: PolicyTax = {
            code: uiPolicyConfig.code,
            paymentPolicies: (uiPolicyConfig.uiPaymentPolicies || []).map(uiPolicy => ({
                taxType: uiPolicy.taxType,
                priceCalculation: uiPolicy.priceCalculation,
                vatCalculation: uiPolicy.taxBase === 'percent' ? uiPolicy.vatCalculation : null,
                taxBase: uiPolicy.taxBase === 'percent' ? null : uiPolicy.taxBase,
                payments: this.getTaxPolicyPayments(uiPolicy),
                descriptions: (uiPolicy.descriptions || []).map(_descr => {
                    const descr: PolicyDescription = {
                        description: uiPolicy.showDescriptions ? _descr.description : '',
                        languageCode: _descr.languageCode,
                    };
                    if (uiPolicy.taxType === 'Other') {
                        descr.title = _descr.title;
                    }
                    return descr;
                }),
            })),
        };

        if (taxPolicy.paymentPolicies.some(
            policy => policy.payments.some(
                payment => payment.amounts.some(
                    amounts => 'amount' in amounts || 'maxAmount' in amounts,
                ),
            ),
        )
        ) {
            taxPolicy.currencyCode = uiPolicyConfig.currencyCode;
        }

        return taxPolicy;
    }

    getTaxPolicyPayments(uiPolicy: UiTaxPaymentPolicy): TaxPolicyPayment[] {
        const payments: TaxPolicyPayment[] = [];
        if (uiPolicy.showAdvancedOptions) {
            if (!uiPolicy.showDateRanges) {
                uiPolicy.uiPayments = [ uiPolicy.uiPayments[0] ];
            }

            uiPolicy.uiPayments.forEach(uiPayment => {
                const amounts: TaxPolicyPayment['amounts'] = [];
                if (!(uiPolicy.taxBase === 'PerPerson' || uiPolicy.taxBase === 'PerPersonPerNight') || !uiPolicy.showAgeRanges) {
                    uiPayment.amounts = [ uiPayment.amounts[0] ];
                }
                uiPayment.amounts.forEach((uiAmounts, index) => {
                    const amountsGroup: TaxPolicyPayment['amounts'][0] = {};

                    if (uiPolicy.taxBase === 'percent') {
                        amountsGroup.percent = uiAmounts.percent;
                    } else {
                        amountsGroup.amount = uiAmounts.amount;
                    }
                    if (uiPolicy.showAgeRanges) {
                        amountsGroup.ageFrom = index === 0 ? 0 : uiAmounts.ageFrom;
                        amountsGroup.ageTo = uiAmounts.ageTo;
                    }
                    if (uiPolicy.showMaximum) {
                        amountsGroup.maxAmount = uiAmounts.maxAmount;
                    }
                    amounts.push(amountsGroup);
                });

                const payment: TaxPolicyPayment = {
                    amounts,
                };

                if (uiPolicy.showDateRanges) {
                    payment.dateFrom = uiPayment.dateFrom;
                    payment.dateTo = uiPayment.dateTo;
                }

                payments.push(payment);
            });

        } else {
            const firstPayment = uiPolicy.uiPayments[0];
            const firstUiAmounts = firstPayment.amounts[0];
            const amountsGroup: TaxPolicyPayment['amounts'][0] = {};
            if (uiPolicy.taxBase === 'percent') {
                amountsGroup.percent = firstUiAmounts.percent;
            } else {
                amountsGroup.amount = firstUiAmounts.amount;
            }

            payments.push({
                amounts: [
                    amountsGroup,
                ],
            });
        }

        return payments;
    }
}
