
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AssetService {
    constructor(
        private http: HttpClient,
    ) { }

    getAsset(url: string): Observable<string> {
        return this.http.get(`/assets/${url}`, { responseType: 'text' });
    }
}
