import { Injectable } from '@angular/core';
import { PolicyCleanup } from '@bmng/services/policies/interfaces/policies.interface';

import { UiPolicyCleanup } from '../../../../pages/master/policies/interface/policies-ui.interface';

@Injectable()
export class PolicyFormCleanupUiService {
    getUiCleanupPolicyConfig(policy: PolicyCleanup): UiPolicyCleanup {
        return {
            type: 'cleanup',
            ...policy,
        };
    }

    getDefaultCleanupPolicyConfig(currencyCode: string): PolicyCleanup {
        return {
            code: `FINAL_CLEANUP`,
            amount: null,
            currencyCode,
            billingType: 'IncludedInTotalPrice',
            descriptions: [],
        };
    }
}
