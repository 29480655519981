import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { EndpointService } from '@bmng/services/endpoint.service';
import { PageService } from '@bmng/services/page.service';
import { UsersService } from '@bmng/services/users/users.service';
import { LangService } from '@kognitiv/angular-i18n';
import { DATE_STANDARD_FORMAT, MomentHelpers } from '@kognitiv/bm-components';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ContextType } from '../context/interfaces/context-type.type';

import {
    PreviewTableConfigResponse,
    PreviewTableKpisResponse,
    SubscriptionReportPayload,
} from './../../../pages/generic/reports/common/types/report-type.type';
import { CustomGroupingFields, CustomReportInfoResponse } from './interfaces/reports-custom.interface';
import { ReportsHistory } from './interfaces/reports-history.interface';
import { ReportsReport } from './interfaces/reports-report.interface';
import {
    PossibleReportSettings,
    ReportsSubscription,
    ReportsSubscriptionOverviewResult,
    ReportsSubscriptionParams,
    ReportType,
    SubscriptionReportDetailsResult,
} from './interfaces/reports-subscription.interface';
import { ReportsServiceInterface } from './interfaces/reports.service.interface';
import reportsMock from './mock-data/reports.mock';

type ReportsContextType = 'hotel' | 'channel' | 'global';

@Injectable()
export class ReportsService extends EndpointService implements ReportsServiceInterface {
    userCurrencyCode: string = this.usersService.currentUserSettings.getValue().currency;

    constructor(
        http: HttpClient,
        private langService: LangService,
        private usersService: UsersService,
        private pageService: PageService,
    ) {
        super(http);
    }

    getAllSubscriptions(
        entityType: ContextType,
        entityId: string,
        params: ReportsSubscriptionParams,
    ): Observable<ReportsSubscriptionOverviewResult> {
        const url: string = `${this.getBaseUrl(entityType, entityId)}/subscriptions`;
        const fullUrl = UrlHelpers.buildUrl(url, params);

        return this.httpGet(fullUrl, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    getSubscription(
        entityType: ContextType,
        entityId: string,
        subscriptionId: number,
    ): Observable<ReportsSubscription> {
        const url: string = `${this.getBaseUrl(entityType, entityId)}/subscription/${subscriptionId}`;

        return this.httpGet<ReportsSubscription>(url, EndpointService.HTTP_HEADERS_ACCEPT).pipe(
            map(subscription => {
                if (!!subscription.custom) {
                    subscription.custom.grouping = subscription.custom.grouping || {
                        primary: '',
                        secondary: '',
                        tertiary: '',
                    };
                }

                return subscription;
            }),
        );
    }

    updateSubscription(
        entityType: ContextType,
        entityId: string,
        subscription: ReportsSubscription,
    ): Observable<never> {
        const baseUrl = `${this.getBaseUrl(entityType, entityId)}/subscription`;
        const copiedSubscription = { ...subscription };
        this.nullGroupingAttributeIfEmpty(copiedSubscription);

        if (subscription.id) {
            return this.httpPut(`${baseUrl}/${subscription.id}`, copiedSubscription, EndpointService.HTTP_HEADERS);
        } else {
            return this.httpPost(baseUrl, copiedSubscription, EndpointService.HTTP_HEADERS);
        }
    }

    private nullGroupingAttributeIfEmpty(sub: ReportsSubscription): void {
        if (!sub.custom || !sub.custom.grouping) {
            return;
        }

        const hasValues: boolean =  [ 'primary', 'secondary', 'tertiary' ].some(attr => !!sub.custom.grouping[attr]);

        if (!hasValues) {
            sub.custom.grouping = null;
        }
    }

    deleteSubscription(
        entityType: ContextType,
        entityId: string,
        subscriptionId: number,
    ): Observable<never> {
        const url: string = `${this.getBaseUrl(entityType, entityId)}/subscription/${subscriptionId}`;

        return this.httpDelete(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    getPossibleSettings(
        entityType: ContextType,
        entityId: string,
    ): Observable<PossibleReportSettings> {
        const baseUrl = `${this.getBaseUrl(entityType, entityId)}/settings`;
        const languageCode = this.langService.getCurrentLanguage();

        const url = UrlHelpers.buildUrl(baseUrl, { languageCode });

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }

    getHistory(
        entityType: ContextType,
        entityId: string,
        reportId: number,
    ): Observable<ReportsHistory[]> {
        const url = `${this.getBaseUrl(entityType, entityId)}/subscription/${reportId}/history`;
        return this.httpGet<ReportsHistory[]>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    getReportDetailsById(entityId: string): Observable<ReportsReport[]> {
        return of(reportsMock.data);
    }

    getOnDemandReportConfigs(
        subscription: ReportsSubscription,
        context: 'hotel' | 'channel',
        propertyId: string,
        languageCode: string,
    ): Observable<PreviewTableConfigResponse> {
        const baseUrl: string = `${EndpointService.getBmBackendUrl()}/api/reports/${context}/${propertyId}/metadata`;
        const url = UrlHelpers.buildUrl(baseUrl, { languageCode });
        const copiedSubscription = { ...subscription };
        this.nullGroupingAttributeIfEmpty(copiedSubscription);

        return this.httpPost(url, copiedSubscription, EndpointService.HTTP_HEADERS);
    }

    getOnDemandReportKpis(
        entityId: string,
        entityType: ContextType,
        config: SubscriptionReportPayload): Observable<PreviewTableKpisResponse[]> {
        const url: string = `${this.getBaseUrl(entityType, entityId)}/subscription/kpi.json`;

        const copiedPayload = { ...config };
        this.nullGroupingAttributeIfEmpty(copiedPayload.subscription);

        if (!copiedPayload.subscription.languageCode) {
            copiedPayload.subscription.languageCode = this.langService.getCurrentLanguage();
        }

        return this.httpPost(url, copiedPayload, EndpointService.HTTP_HEADERS);
    }

    getOnDemandReport(
        entityId: string,
        entityType: ContextType,
        config: SubscriptionReportPayload): Observable<SubscriptionReportDetailsResult> {
        if (!config.subscription.timezone) {
            const userSettings = this.usersService.currentUserSettings.getValue();
            config.subscription.timezone = (!!userSettings) ? userSettings.timeZone : null;
        }

        const url: string = `${this.getBaseUrl(entityType, entityId)}/subscription/report.json`;

        return this.httpPost(url, config, EndpointService.HTTP_HEADERS);
    }

    downloadExcelReportDefault(
        entityType: ContextType,
        entityId: string,
        reportId: number,
    ): void {
        const url = `${this.getBaseUrl(entityType, entityId)}/subscription/${reportId}/report.xlsx`;
        window.open(url);
    }
    downloadExcelReportWithConfig(
        entityType: ContextType,
        entityId: string,
        config: SubscriptionReportPayload): void {

        // Build the filename
        const reportName: string = config.subscription.name;
        const today: string = MomentHelpers.today().format(DATE_STANDARD_FORMAT);
        // ts[bm.reports.subscriptions.overview.reservation.[*]]
        // eslint-disable-next-line max-len
        const recurrence: string = this.langService.get(`bm.reports.subscriptions.overview.reservation.${config.subscription.recurrence.type.toLowerCase()}`);
        const fileName: string = `${reportName} - ${recurrence} - ${entityId} - ${today}.xlsx`;
        const url: string = `${this.getBaseUrl(entityType, entityId)}/subscription/report.xlsx`;

        const payload = {
            json: JSON.stringify(config.subscription),
            searchRequest: JSON.stringify({
                filters: config.filters,
                q: config.q || '',
            }),
        };
        this.httpDownloadPost(url, payload, EndpointService.HTTP_HEADERS_SPREADSHEET).subscribe(
            data => this.createBlob(data, fileName),
            errors => {
                this.pageService.pushNotification({
                    type: 'error',
                    content: this.langService.get('bf.500Message'),
                });
            },
        );
    }

    createBlob(data: Blob, fileName: string): void {

        const blob: Blob = new Blob([ data ], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);

        const a: HTMLAnchorElement = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    downloadHistoryReport(
        entityType: ContextType,
        entityId: string,
        reportId: number,
    ): void {
        const url = `${this.getBaseUrl(entityType, entityId)}/subscription/history/${reportId}`;
        window.open(url);
    }

    getCustomReportInfo(
        entityId: string,
        entityType: ContextType,
        reportType: ReportType,
        groupings: CustomGroupingFields,
        languageCode: string,
        currencyCode?: string,
    ): Observable<CustomReportInfoResponse> {
        const baseUrl = `${this.getBaseUrl(entityType, entityId)}/filters`;
        const params = this.getGroupingParams(groupings, { reportType, languageCode });

        if (!!currencyCode) {
            params.currencyCode = currencyCode;
        }

        const url = UrlHelpers.buildUrl(baseUrl, params);

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }

    private getGroupingParams(groupings: CustomGroupingFields, params: Record<string, string>): Record<string, string> {
        if (!groupings.primary) {
            return params;
        }
        params.primary = groupings.primary;

        if (!groupings.secondary) {
            return params;
        }
        params.secondary = groupings.secondary;

        if (!groupings.tertiary) {
            return params;
        }
        params.tertiary = groupings.tertiary;
        return params;
    }

    private getReportsContextType(contextType: ContextType): ReportsContextType {
        if (contextType === 'hotel') {
            return 'hotel';
        }
        if (contextType === 'global') {
            return 'global';
        }
        return 'channel';
    }

    getBaseUrl(contextType: ContextType, entityId: string): string {
        const entityType = this.getReportsContextType(contextType);

        const entityPart = entityType === 'global' ? 'global' : `${entityType}/${entityId}`;
        return `${EndpointService.getBmBackendUrl()}/api/reports/${entityPart}`;
    }

    // --------
    // HELPERS
    // --------
    applySorting(url: string, config: SubscriptionReportPayload): string {
        url += !!config.sort ? `&sort=${config.sort}` : '';
        url += !!config.sortType ? `&sortType=${config.sortType}` : '';
        return url;
    }
    applyPaging(url: string, config: SubscriptionReportPayload): string {
        const start: number = (!!config.start && config.start > 0) ? config.start : 0;
        const size: number = !!config.size ? config.size : 10;
        url += '&start=' + start * size;
        url += '&size=' + size;
        return url;
    }
    applySubscriptionConfigDetails(url: string, config: SubscriptionReportPayload): string {
        if (!!config.subscription) {
            url += !!config.subscription.dataWindow ? `&dataWindow=${config.subscription.dataWindow}` : '';
            url += !!config.subscription.dataWindowStart ? `&dataWindowStart=${config.subscription.dataWindowStart}` : '';
            url += !!config.subscription.dataWindowEnd ? `&dataWindowEnd=${config.subscription.dataWindowEnd}` : '';
            url += !!config.subscription.type ? `&type=${config.subscription.type}` : '';
            url += !!config.subscription.variant ? `&variant=${config.subscription.variant}` : '';
            url += !!config.subscription.currency ? `&currency=${config.subscription.currency}` : '';
            url += !!config.subscription.languageCode ? `&languageCode=${config.subscription.languageCode}` : '';
        }
        return url;
    }
    applyFilters(url: string, config: SubscriptionReportPayload): string {

        for (const key in config.filters) {
            if (!!config.filters[key] && config.filters[key].length > 0) {
                if (key === 'status') {
                    url += `&status=${config.filters[key].join(',')}`;
                } else if (key === 'hotelId') {
                    url += `&hotelId=${config.filters[key]}`;
                } else if (key === 'hotelName') {
                    url += `&hotelName=${config.filters[key]}`;
                } else if (key === 'bookingDate') {
                    url += `&modifiedDateFrom=${config.filters[key][0]}&modifiedDateTo=${config.filters[key][1]}`;
                } else if (key === 'arrivalDate') {
                    url += `&arrivalDateFrom=${config.filters[key][0]}&arrivalDateTo=${config.filters[key][1]}`;
                } else if (key === 'departureDate') {
                    url += `&departureDateFrom=${config.filters[key][0]}&departureDateTo=${config.filters[key][1]}`;
                }
            }
        }

        // TODO: test this!
        if (!!this.userCurrencyCode) {
            url += `&currencyCode=${this.userCurrencyCode}`;
        }
        return url;
    }

}
