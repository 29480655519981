<div routerLinkActive="active">
    <a [routerLink]="[ '/' + baseContextUrl + '/' + subSubMenuItem.url ]" *ngIf="subSubMenuItem.service === 'bmng'" (click)="closeMenu()">
        <span class="menu-header">
            <bmng-menu-item-leaf [menuItem]="subSubMenuItem"></bmng-menu-item-leaf>
        </span>
    </a>
    <a [href]="subSubMenuItem | oldBmUrl" *ngIf="subSubMenuItem.service !== 'bmng'">
        <span class="menu-header">
            <bmng-menu-item-leaf [menuItem]="subSubMenuItem"></bmng-menu-item-leaf>
        </span>
    </a>
</div>