import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { StaticInfo } from '@bmng/services/static-info/interfaces/static-info.interface';
import { StaticInfoService } from '@bmng/services/static-info/static-info.service';

export const staticInfoResolver: ResolveFn<StaticInfo> =
    () => {
        const staticInfoService = inject(StaticInfoService);

        return staticInfoService.loadStaticInfo();
    };
