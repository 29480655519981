<router-outlet></router-outlet>

<div class="stacked-sub-pages-modal" [ngClass]="{ 'hidden': activeRouterLevel === 0 }">
    <div [ngClass]="{ 'hidden': activeRouterLevel !== 1 }">
        <router-outlet name="level1"></router-outlet>
    </div>
    
    <div [ngClass]="{ 'hidden': activeRouterLevel !== 2 }">
        <router-outlet name="level2"></router-outlet>
    </div>
</div>
