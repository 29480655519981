<ng-container *ngIf="!$any(menuItem).children">

    <div class="main-menu">
        <a *ngIf="menuItem.service === 'bmng'" [routerLink]="[ '/' + baseContextUrl + '/' + menuItem.url ]" routerLinkActive>
            <bmng-menu-item-icon-container
                [menuItem]="menuItem"></bmng-menu-item-icon-container>
            <span class="menu-icon bm-menu-{{ menuItem.icon }}"></span>
            <span class="menu-header">
                <bmng-menu-item-leaf [menuItem]="menuItem" [showIcon]="false"></bmng-menu-item-leaf>
            </span>
        </a>
        <a *ngIf="menuItem.service === 'cm'" [href]="menuItem | oldBmUrl" >
            <span class="menu-icon bm-menu-{{ menuItem.icon }}"></span>
            <span class="menu-header">
                <bmng-menu-item-leaf [menuItem]="menuItem" [showIcon]="false"></bmng-menu-item-leaf>
            </span>
        </a>
        <a *ngIf="menuItem.service === 'external'" [href]="[ menuItem.url ]" target="_blank">
            <span class="menu-icon bm-menu-{{ menuItem.icon }}"></span>
            <span class="menu-header">
                <bmng-menu-item-leaf [menuItem]="menuItem" [showIcon]="false"></bmng-menu-item-leaf>
            </span>
        </a>

    </div>

</ng-container>

<ng-container *ngIf="$any(menuItem).children">
    <a (click)="toggleSubMenu()">
        <span class="menu-icon bm-menu-{{ menuItem.icon }}"></span>
        <span class="menu-header">
            <bmng-menu-item-leaf [menuItem]="menuItem"></bmng-menu-item-leaf>
        </span>
    </a>

    <div class="submenu-container" [class.open]="submenuOpen" [@slideOpen]="(submenuOpen) ? 'open' : 'close'">
        <bmng-sub-menu-item
            *ngFor="let subMenuItem of $any(menuItem).children"
            [subMenuItem]="subMenuItem"
            [baseContextUrl]="baseContextUrl"
            (activeChanged)="subMenuActiveChanged($event)"
        ></bmng-sub-menu-item>
    </div>
</ng-container>
