import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteHelpers } from '@bmng/helpers/route-helpers';
import { UiPolicy, UiPolicyTax } from '@bmng/pages/master/policies/interface/policies-ui.interface';
import { Channel } from '@bmng/services/channels/interfaces/channel';
import { PoliciesResponse, Policy, PolicyType, PolicyUsage, PolicyUsageType } from '@bmng/services/policies/interfaces/policies.interface';
import { NumeralService } from '@kognitiv/bm-components';

import { UsedBy, UsedByType } from '../components/used-by-summary/used-by-summary.interface';

interface PolicyTypeMap {
    policyType: PolicyUsageType;
    usedByType: UsedByType;
}

@Injectable()
export class PoliciesHelpers {
    constructor(
        public router: Router,
    ) {}

    navigateToOverview(target: string, route: ActivatedRoute): void {
        this.router.navigate([ target ], { relativeTo: route });
    }

    navigateToPolicy(policyType: string, serviceCode: string, route: ActivatedRoute): void {
        this.router.navigate([ policyType, 'detail', serviceCode ], { relativeTo: route });
    }

    navigateToNewPolicy(policyType: string, route: ActivatedRoute): void {
        this.router.navigate([ policyType, 'new' ], { relativeTo: route });
    }

    transformPolicyUsageToUsedBy(policyUsage: PolicyUsage): UsedBy[] {
        if (!policyUsage) {
            return [];
        }

        const typeMappings: PolicyTypeMap[] = [
            { policyType: 'offers', usedByType: 'PACKAGE' },
            { policyType: 'packages', usedByType: 'PACKAGE' },
            { policyType: 'promotions', usedByType: 'PROMOTION' },
            { policyType: 'rates', usedByType: 'RATEPLAN' },
            { policyType: 'derivedRates', usedByType: 'RATEPLAN' },
            { policyType: 'shoulders', usedByType: 'RATEPLAN' },
            { policyType: 'services', usedByType: 'SERVICE' },
        ];
        const result: UsedBy[] = [];

        typeMappings.forEach(typeMapping => {
            const policyUsageType = typeMapping.policyType;
            const items = policyUsage[policyUsageType] || [];

            items.map(item => ({
                type: typeMapping.usedByType,
                title: item.title,
                code: item.code,
            })).forEach((item: UsedBy) => result.push(item));
        });

        return result;
    }

    policyHasUsage(policy: Policy): boolean {
        if (!policy || !policy.usage) {
            return false;
        }

        const usage = Object.keys(policy.usage).map(key => Array.isArray(policy.usage[key]) && policy.usage[key].length > 0);

        return usage.some(_usage => _usage === true);
    }

    getCurrenciesFromUsage(policy: Policy): string[] {
        if (!this.policyHasUsage(policy)) {
            return [];
        }

        const usage: PolicyUsage = policy.usage;
        const usedCurrencies: { [code: string]: boolean } = {};

        if (!!usage) {
            const keys: PolicyUsageType[] = [ 'rates', 'offers', 'promotions', 'services' ];
            keys.forEach(key => {
                const usageEntries = usage[key] || [];

                usageEntries
                    .map(entry => entry.currencyCode)
                    .filter(currencyCode => !!currencyCode)
                    .forEach(currencyCode => usedCurrencies[currencyCode] = true);
            });
        }

        return Object.keys(usedCurrencies);
    }

    setDefaultCurrencyCodeIfNeeded(policy: UiPolicy, defaultCurrencyCode: string): UiPolicy {
        if (policy.currencyCode) {
            return policy;
        }

        const hasUsage = this.policyHasUsage(policy);
        const currenciesFromUsage = hasUsage ? this.getCurrenciesFromUsage(policy) : [];
        if (currenciesFromUsage && currenciesFromUsage.length === 1) {
            policy.currencyCode = currenciesFromUsage[0];
        } else {
            policy.currencyCode = defaultCurrencyCode;
        }

        return policy;
    }

    getUniquePolicyCode(prefix: string, usedCodes: string[], count: number): string {
        count = count || usedCodes.length;
        let policyNumber: number = count + 1;
        const policyCode: string = `${prefix}_${policyNumber}`;

        if (!usedCodes.includes(policyCode)) {
            return policyCode;
        }

        return this.getUniquePolicyCode(prefix, usedCodes, policyNumber++);
    }

    getPoliciesCodesByType(allPolicies: PoliciesResponse, uiPolicyType: PolicyType): string[] {
        const policiesByType = this.getPoliciesByType(allPolicies, uiPolicyType);

        return policiesByType.map(policy => policy.code);
    }

    getPoliciesByType(allPolicies: PoliciesResponse, uiPolicyType: PolicyType): Policy[] {
        if (!allPolicies) {
            return [];
        }

        const policiesByType = allPolicies[uiPolicyType];

        if (!Array.isArray(policiesByType)) {
            return [];
        }

        return policiesByType;
    }

    formatNumeral(val: number): string {
        const numeral = NumeralService.get();

        return numeral(val).format('0,0.00');
    }

    checkDefaultPolicy(
        policy: UiPolicy,
        availableCurrencies: string[],
        policies: UiPolicy[],
        hasMultiCurrency: boolean,
    ): boolean | string[] {
        if (!policies) {
            return false;
        }

        const policyIndex = policies.findIndex(_policy => _policy.code === policy.code);
        const currencyCode = policy.currencyCode;

        if (hasMultiCurrency && !availableCurrencies.length) {
            return false;
        }

        if (!hasMultiCurrency) {
            return policyIndex === 0;
        }
        const indexOfFirstWithoutCurrency = policies.findIndex(_policy => !_policy.currencyCode);

        if (currencyCode) {
            const indexOfFirstWithCurrency = policies.findIndex(_policy => _policy.currencyCode === currencyCode);
            if (indexOfFirstWithCurrency === policyIndex &&
                (indexOfFirstWithoutCurrency < 0 || policyIndex < indexOfFirstWithoutCurrency)) {
                return [ currencyCode ];
            }
        } else {
            if (policyIndex > indexOfFirstWithoutCurrency) {
                return [];
            }
            const defaultForCurrencies = availableCurrencies.filter(currency => {
                const indexOfFirstWithCurrency = policies.findIndex(_policy => _policy.currencyCode === currency);
                return indexOfFirstWithCurrency < 0 || policyIndex < indexOfFirstWithCurrency;
            });

            return defaultForCurrencies;
        }

        return [];
    }

    isCurrencyIndependent(policy: UiPolicy): boolean {
        if (!policy.currencyCode) {
            return true;
        }
        if (policy.type === 'tax') {
            return this.isTaxPolicyCurrencyIndependent(policy);
        } else if (policy.type === 'booking') {
            // todo

        } else if (policy.type === 'cancellation') {
            // todo
        }

        return false;
    }

    private isTaxPolicyCurrencyIndependent(policy: UiPolicyTax): boolean {
        const paymentPolicies = policy.paymentPolicies || [];

        if (paymentPolicies.length === 0) {
            return true;
        }

        const hasCurrencyDependantPolicy = paymentPolicies.some(paymentPolicy => {
            const payments = paymentPolicy.payments || [];

            if (payments.length === 0) {
                return false;
            }

            return payments.some(payment => {
                const amounts = payment.amounts || [];

                if (amounts.length === 0) {
                    return false;
                }

                return amounts.some(amountObj => {
                    const hasAmount = typeof amountObj.amount === 'number';
                    const hasMaxAmount = typeof amountObj.maxAmount === 'number';

                    return hasAmount || hasMaxAmount;
                });
            });
        });

        return !hasCurrencyDependantPolicy;
    }

    getEntityId(route: ActivatedRoute): string {
        const paramsFromRoute = RouteHelpers.getFullParamsFromRoute(route.snapshot);

        return paramsFromRoute.contextId;
    }

    getPEGSEnabled(channels: Channel[]): boolean {
        const PEGSchannelId = 'PEGS';
        return this.getChannelEnabled(PEGSchannelId, channels);
    }

    getChannelEnabled(channelId: string, channels: Channel[]): boolean {
        if (Array.isArray(channels)) {
            const PEGSchannel = channels.find(channel => channel.channelId === channelId);
            if (PEGSchannel) {
                return PEGSchannel.enabled;
            }
        }
        return false;
    }
}
