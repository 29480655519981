
import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { Context } from '@bmng/services/context/interfaces/context.interface';

import { ContextItem } from '../../core/services/context/interfaces/context-item.interface';

export class RouteHelpers {
    static getFullDataFromRoute(route: ActivatedRouteSnapshot): Params {
        return RouteHelpers.getKeyFromRoute(route, 'data');
    }

    static getDataFromRoute<T>(route: ActivatedRouteSnapshot, key: string, fallback?: T): T {
        const data = RouteHelpers.getFullDataFromRoute(route);
        if (data[key]) {
            return data[key];
        }
        return fallback;
    }

    static getFullParamsFromRoute(route: ActivatedRouteSnapshot): Params {
        return RouteHelpers.getKeyFromRoute(route, 'params');
    }

    static getParamFromRoute<T>(route: ActivatedRouteSnapshot, key: string, fallback?: T): T {
        const data = RouteHelpers.getFullParamsFromRoute(route);
        if (data[key]) {
            return data[key];
        }
        return fallback;
    }

    static getQueryParamFromActivatedRoute<T>(route: ActivatedRouteSnapshot, key: string, fallback?: T): T {
        const params = !!route.queryParams ? route.queryParams : {};
        const value = params[key];
        return typeof value === 'undefined' ? fallback : value;
    }

    static getActiveContextFromRoute(route: ActivatedRouteSnapshot): ContextItem {
        return this.getActiveContext(RouteHelpers.getDataFromRoute(route, 'context'));

    }

    static getActiveContext(context: Context): ContextItem {
        if (!context) {
            throw new Error(`Context is not defined!
                The likely cause of this is that you\'ve injected the ActivedRoute into a service directly
                - it needs to be passed in from the calling component.`);
        }

        if (context.channel?.id && context.channel?.type === 'direct' && context.hotel?.id) {
            return context.channel;
        } else
        if (context.hotel.id) {
            return context.hotel;
        } else if (context.unit.id) {
            return context.unit;
        } else if (context.channel.id) {
            return context.channel;
        } else {
            return {
                id: null,
                name: null,
                role: null,
                billTo: null,
                supportPartner: null,
                type: 'global',
            };
        }
    }

    private static getKeyFromRoute(route: ActivatedRouteSnapshot, key: 'params' | 'data'): Params {
        if (!route) {
            return {};
        }

        let currentNode = route;
        const data = {};

        do {
            Object.assign(data, currentNode[key]);
            currentNode = currentNode.parent;
        } while (!!currentNode);

        return data;
    }
}
