import { LangService } from '@kognitiv/angular-i18n';
export type ItemInfoType = 'Only' | 'Except';

export class ItemInfo {
    type: ItemInfoType = 'Only';
    name?: string;
    valid = true;
    title = '';
    items: string[];
    hiddenCount = 0;
    summary: string;
    displayName: string;

    constructor(private lang: LangService) { }

    updateSummary(): void {
        if (!this.items || this.items.length === 0) {
            this.summary = '';
            return;
        }

        const items = this.items.join(', ');
        // ts[bmng.item.info.representation.except]
        // ts[bmng.item.info.representation.only]
        const wtiKey = this.type === 'Only' ? 'bmng.item.info.representation.only' : 'bmng.item.info.representation.except';
        this.summary = this.lang.get(wtiKey, this.name, items);

        if (this.name !== 'Channels') {
            const nameExcept = this.lang.get('bmng.item.info.allItemsExcept', this.name);

            this.displayName = this.type === 'Only' ? this.name : nameExcept;
        } else {
            this.displayName = this.name;
        }
    }
}
