
    <a *ngIf="!context || !context.branding?.url" routerLink='/home' class="logo" [style.background-image]="context?.branding?.logoUrl | brandingLogo"></a>
    <a *ngIf="context?.branding?.url" [href]='context.branding?.url' target="_top" class="logo" [style.background-image]="context?.branding?.logoUrl | brandingLogo"></a>

    <div class="show-xsmall show-small show-medium m-2 cf">
        <bm-button type="icon" icon="nav-collapse" (onClick)="closeMenu()" class="float-right"></bm-button>
    </div>

    <div class="menu-items-wrap">
        <div class="menu-items">
            <bmng-main-menu-item
                *ngFor="let menuItem of menu"
                [menuItem]="menuItem"
                [baseContextUrl]="baseContextUrl"
                (submenuOpenChanged)="onOpenMenu($event, menuItem)"
            ></bmng-main-menu-item>
        </div>
        <div class="footer" bm-footer></div>
    </div>