import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EndpointService } from './../endpoint.service';
import { ChannelIdVerificationResult } from './interfaces/channel-id-verification-result.interface';
import { ChannelIdVerificationServiceInterface } from './interfaces/channel-id-verification.service.interface';

@Injectable()
export class ChannelIdVerificationService extends EndpointService implements ChannelIdVerificationServiceInterface {
    constructor(http: HttpClient) {
        super(http);
    }

    verifyChannel(channelId: string, foreignId: string): Observable<ChannelIdVerificationResult> {
        // TODO
        const url = '';
        return this.httpGet<ChannelIdVerificationResult>(url);
    }
}
