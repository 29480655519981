import { UploadResult, UploadValueHolder } from '@kognitiv/bm-components';

export class ImageValueHolder implements UploadValueHolder<string> {
    private fileName: string;

    updateValue(value: string): void {
        this.fileName = value;
    }

    updateValueAt(value: string, _: number): void {
        this.fileName = value;
    }

    getValue(): string {
        return this.fileName;
    }

    deleteValue(value: string): void {
        this.fileName = null;
    }

    translate(uploadedVal: UploadResult<string>): string {
        if (!!uploadedVal && uploadedVal.success) {
            return uploadedVal.url;
        }

        return null;
    }

    getSummary(): UploadResult<string>[] {
        if (!this.fileName) {
            return [];
        }

        return [
            {
                success: true,
                id: null,
                url: this.fileName,
                name: this.fileName,
            },
        ];
    }

}
