import { Component, Injectable, OnInit } from '@angular/core';
import { RequestStatus } from '@bmng/services/waiting-list/interfaces/waiting-list.interface';
import { LangService } from '@kognitiv/angular-i18n';
import { RenderComponent } from '@kognitiv/bm-components';

import { StatusDetail } from './status-detail.interface';

@Injectable()
export class WaitingListStatusHelpers {
    private statusDetails: { [ K in RequestStatus ]: StatusDetail } = {
        NEW:                 { label: this.lang.get('bm.waitinglist.status.NEW'),                color: 'chart-2' },
        REPRIORITISED:       { label: this.lang.get('bm.waitinglist.status.REPRIORITISED'),      color: 'chart-4' },
        EXPIRED:             { label: this.lang.get('bm.waitinglist.status.EXPIRED'),            color: 'secondary' },
        ON_HOLD:             { label: this.lang.get('bm.waitinglist.status.ON_HOLD_BOOKING'),    color: 'chart-4' },
        REQUESTOR_INFORMED:  { label: this.lang.get('bm.waitinglist.status.REQUESTER_INFORMED'), color: 'chart-4' },
        BOOKED:              { label: this.lang.get('bm.waitinglist.status.BOOKED'),             color: 'chart-3' },
        REJECTED:            { label: this.lang.get('bm.waitinglist.status.REJECTED'),           color: 'chart-5' },
    };

    constructor(
        private lang: LangService,
    ) {}

    getStatusDetail<K extends keyof StatusDetail>(detailType: K, status: RequestStatus): StatusDetail[K] {
        const statusDetails = this.getStatusMap(status);

        return statusDetails[detailType];
    }

    getStatusColor(status: RequestStatus): string {
        return this.statusDetails[status].color;
    }

    getStatusLabel(status: RequestStatus): string {
        return this.statusDetails[status].label;
    }

    getAllRequestStatuses(): RequestStatus[] {
        return <RequestStatus[]> Object.keys(this.statusDetails);
    }

    getStatusMap(status: RequestStatus): StatusDetail {
        return this.statusDetails[status];
    }
}

export interface ObjectWithPossibleStatus {
    status?: RequestStatus;
}

@Component({
    selector: 'waiting-list-status-badge-renderer',
    template: `
        <span bmBadge [color]="color">{{ label }}</span>
    `,
})
export class WaitingListStatusBadgeRendererComponent<T extends ObjectWithPossibleStatus> extends RenderComponent<T> implements OnInit {
    data: T;
    label: string;
    color: string;

    constructor(
        public statusHelpers: WaitingListStatusHelpers,
    ) {
        super();
    }

    ngOnInit(): void {
        const status: RequestStatus = this.data.status;
        if (status) {
            this.label = this.statusHelpers.getStatusDetail('label', status);
            this.color = this.statusHelpers.getStatusDetail('color', status);
        }
    }
}
