import { IFileUploadService, NumeralService, UploadState } from '@kognitiv/bm-components';
import { Observable, Subscription, map } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { RouteHelpers } from '@bmng/helpers/route-helpers';
import { LangService } from '@kognitiv/angular-i18n';
import { EndpointService } from '../endpoint.service';
import { ContextService } from '../context/context.service';
import { ContextItem } from '../context/interfaces/context-item.interface';
import { ImageUpload, UploadError } from './image-upload.class';

const numeral = NumeralService.get();

@Injectable()
export class ImageUploadService extends EndpointService implements OnDestroy, IFileUploadService<unknown> {
    private channelId = null;
    private propertyId = null;
    private subscription: Subscription;
    private activeContext: ContextItem;
    private categoryCode: string = null;
    private maxUploadSizeInBytes: number;

    public hasUploadProgress = true;

    // ts[bmng.banners.image.already.existed]
    // ts[bmng.image.too.large]
    private readonly knownUploadErrors = new Map<string, string>([
        [ '002000005', 'bmng.banners.image.already.existed' ],
        [ '002000009', 'bmng.image.too.large' ],
        [ 'E50X', 'bmng.image.too.large' ],
    ]);

    constructor(
        private readonly lang: LangService,
        private contextService: ContextService,
        http: HttpClient,
    ) {
        super(http);
        this.subscription = this.contextService.context.subscribe(context => {
            if (context && context.context) {
                this.activeContext = RouteHelpers.getActiveContext(context.context);

                if (this.activeContext.type === 'hotel') {
                    this.propertyId = this.activeContext.id;
                } else {
                    this.channelId = this.activeContext.id;
                }
            } else {
                this.activeContext = {
                    id: null,
                    type: 'hotel',
                    name: null,
                    role: null,
                    billTo: null,
                    supportPartner: null,
                };
            }
        });
    }

    getKnownErrorMessages(errors: UploadError[], uploadMaxSizeInBytes: number): string[] {
        const maxUploadSizeLabel = numeral(uploadMaxSizeInBytes).format('0.00b');

        return (errors || [])
            .filter(err => this.knownUploadErrors.has(err.code))
            .map(err => this.knownUploadErrors.get(err.code))
            .map(i18nToken => this.lang.get(i18nToken, maxUploadSizeLabel));
    }

    setPropertyId(propertyId: string): void {
        this.propertyId = propertyId;
    }

    setChannelId(channelId: string): void {
        this.channelId = channelId;
    }

    upload(files: File[]): UploadState<unknown> {
        const curUploadState: UploadState<unknown> = {};

        files.forEach(file => {
            const url = this.getEndpointUrl();
            curUploadState[file.name] = new ImageUpload(
                {
                    url,
                    file,
                    channelId: this.channelId,
                    category: this.categoryCode,
                },
                this.http,
                this.getErrorMessage.bind(this),
            );
        });

        return curUploadState;
    }

    private getErrorMessage(errors: UploadError[]): string | null {
        const knownErrors = this.getKnownErrorMessages(errors, this.maxUploadSizeInBytes);
        return knownErrors.length > 0 ? knownErrors.join('\n') : null;
    }

    delete(id: string): Observable<boolean> {
        const url = this.getEndpointUrl();
        const formData = new FormData();

        formData.append('file_name', id);

        const req = new HttpRequest('DELETE', url, formData, {
            reportProgress: false,
            withCredentials: true,
            responseType: 'json',
        });

        return this.http.request(req).pipe(
            map(event => {
                if (event instanceof HttpResponse) {
                    return event.status === 200;
                }
            }),
        );
    }

    getEndpointUrl(): string {
        const bmBackendUrl = EndpointService.getBmBackendUrl();
        const isChannel = !!this.channelId;

        return isChannel
            ? `${bmBackendUrl}/api/cm/portal/${this.channelId}/images/upload`
            : `${bmBackendUrl}/api/cm/hotel/${this.propertyId}/images/upload`;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.subscription = null;
    }

    setMaxUploadInBytes(maxUploadSizeInBytes: number): void {
        this.maxUploadSizeInBytes = maxUploadSizeInBytes;
    }

    setCategoryCode(categoryCode: string): void {
        this.categoryCode = categoryCode;
    }
}
