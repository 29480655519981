import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { LangService } from '@kognitiv/angular-i18n';
import { Moment, MomentService } from '@kognitiv/bm-components';

import { PolicyFormValidators } from '../policy-form-validators.service';

const moment = MomentService.get();

@Injectable()
export class PolicyFormTaxValidators {
    constructor(
        private lang: LangService,
        private validators: PolicyFormValidators,
    ) {}

    taxPaymentPolicyValidation(): ValidatorFn {
        return (paymentPolicy: UntypedFormGroup): ValidationErrors => {
            if (!paymentPolicy) {
                return null;
            }
            const showAdvancedOptions = paymentPolicy.get('showAdvancedOptions').value;
            const showDateRanges = paymentPolicy.get('showDateRanges').value;
            const showMaximum = paymentPolicy.get('showMaximum').value;
            const taxBase = paymentPolicy.get('taxBase').value;
            const isPerPerson = taxBase === 'PerPersonPerNight' || taxBase === 'PerPerson';
            const isPercent = taxBase === 'percent';
            const showAgeRanges = isPerPerson ? paymentPolicy.get('showAgeRanges').value : false;

            const uiPayments = paymentPolicy.get('uiPayments') as UntypedFormArray;
            if (uiPayments && uiPayments.controls) {
                uiPayments.controls.forEach((calcGroup: UntypedFormGroup, dateRangeIndex: number) => {
                    const count = uiPayments.controls.length;
                    const isFirstRow = dateRangeIndex === 0;
                    const isLastRow = dateRangeIndex ===  count - 1;

                    const prevDateToControl: UntypedFormControl = !isFirstRow ?
                        uiPayments.at(dateRangeIndex - 1).get('dateTo') as UntypedFormControl : null;
                    this.conditionalDateRange(
                        calcGroup,
                        showAdvancedOptions && showDateRanges, prevDateToControl,
                    );

                    const dateFrom = calcGroup.get('dateFrom') as UntypedFormControl;
                    this.validators.setConditionalRequiredValidator(
                        dateFrom,
                        (!isFirstRow && count > 1) && showAdvancedOptions && showDateRanges,
                    );

                    const dateTo = calcGroup.get('dateTo') as UntypedFormControl;
                    this.validators.setConditionalRequiredValidator(dateTo,
                        (!isLastRow && count > 1) && showAdvancedOptions && showDateRanges,
                    );

                    const amountsGroupArray = calcGroup.get('amounts') as UntypedFormArray;
                    if (amountsGroupArray.controls) {
                        for (let index = 0; index <= amountsGroupArray.controls.length - 1; index++) {
                            const isFirstAmountsRow = index === 0;
                            const isLastAmountsRow = index === amountsGroupArray.controls.length - 1;
                            const isMandatoryAmountsRow = isFirstRow && isFirstAmountsRow;
                            const inputNeeded = isMandatoryAmountsRow ||
                                (showAdvancedOptions && !showDateRanges && showAgeRanges && isFirstRow) ||
                                (showAdvancedOptions && showDateRanges && !showAgeRanges && isFirstAmountsRow) ||
                                (showAdvancedOptions && showDateRanges && showAgeRanges);

                            const amountsGroup = amountsGroupArray.at(index) as UntypedFormGroup;

                            const amountControl = amountsGroup.get('amount') as UntypedFormControl;
                            this.validators.setConditionalRequiredAndMaxValidators(amountControl, !isPercent && inputNeeded);

                            const percentControl = amountsGroup.get('percent') as UntypedFormControl;
                            this.validators.setConditionalRequiredValidator(percentControl, isPercent && inputNeeded);

                            const maxAmountControl = amountsGroup.get('maxAmount') as UntypedFormControl;
                            this.validators.setConditionalMaxValidator(maxAmountControl, showAdvancedOptions && showMaximum && (
                                (!showDateRanges && !showAgeRanges && isMandatoryAmountsRow) ||
                                (showDateRanges && !showAgeRanges && isFirstAmountsRow) ||
                                (showDateRanges && showAgeRanges)
                            ));

                            const ageFromControl = amountsGroup.get('ageFrom') as UntypedFormControl;
                            this.validators.setConditionalRequiredValidator(ageFromControl,
                                showAdvancedOptions &&
                                showAgeRanges &&
                                !isFirstAmountsRow &&
                                ((isFirstRow && !showDateRanges) || showDateRanges),
                            );

                            const ageToControl = amountsGroup.get('ageTo') as UntypedFormControl;
                            this.validators.setConditionalRequiredValidator(ageToControl,
                                showAdvancedOptions &&
                                showAgeRanges &&
                                !(isLastAmountsRow && index > 0) &&
                                ((isFirstRow && !showDateRanges) || showDateRanges),
                            );

                            const prevAgeToControl = !isFirstAmountsRow ?
                                amountsGroupArray.at(index - 1).get('ageTo') as UntypedFormControl : null;
                            this.conditionalAgeRange(amountsGroup, showAdvancedOptions && showAgeRanges, prevAgeToControl);
                        }
                    }
                });
            }

            const isOtherTaxType = paymentPolicy.get('taxType').value === 'Other';
            const descriptions = paymentPolicy.get('descriptions') as UntypedFormArray;
            if (descriptions && descriptions.length) {
                const titleControl = descriptions.at(0).get('title') as UntypedFormControl;
                this.validators.setConditionalRequiredValidator(titleControl, isOtherTaxType);
            }

        };
    }

    conditionalDateRange(
        calcGroup: UntypedFormGroup,
        condition: boolean,
        prevDateToControl: UntypedFormControl,
    ): void {
        if (!calcGroup) {
            return;
        }
        if (condition) {
            const val = calcGroup.value as {[key: string]: string};
            const dateFrom = val.dateFrom;
            const dateTo = val.dateTo;

            const mDateFrom = dateFrom ? moment(dateFrom, 'YYYY-MM-DD') : null;
            const mDateTo = dateTo ? moment(dateTo, 'YYYY-MM-DD') : null;

            const prevDateTo: string = prevDateToControl ? prevDateToControl.value : '';
            const mPrevDateTo: Moment = prevDateTo ? moment(prevDateTo, 'YYYY-MM-DD') : null;

            if (mDateFrom && mDateTo && mDateFrom.isSameOrAfter(mDateTo)) {
                calcGroup.setErrors({
                    invalid: this.lang.get('component.lib.invalid.range'),
                });
            } else if (mPrevDateTo && mDateFrom && (mDateFrom.diff(mPrevDateTo, 'days') > 1 || mDateFrom.isSameOrBefore(mPrevDateTo))) {
                calcGroup.setErrors({
                    invalid: this.lang.get('bmng.dateranges.gaps.error'),
                });
            } else {
                calcGroup.setErrors(null);
            }
        } else {
            calcGroup.setErrors(null);
        }
    }

    conditionalAgeRange(
        amountGroup: UntypedFormGroup,
        condition: boolean,
        prevAgeToControl: UntypedFormControl,
    ): void {
        if (!amountGroup) {
            return;
        }
        if (condition) {
            const val = amountGroup.value as {[key: string]: string};
            const ageFrom = parseInt(val.ageFrom, 10);
            const ageTo = parseInt(val.ageTo, 10);

            let prevAgeTo: number;
            if (prevAgeToControl) {
                prevAgeTo = parseInt(prevAgeToControl.value, 10);
            }

            if (!isNaN(ageTo) && !isNaN(ageFrom) && (ageTo < ageFrom)) {
                amountGroup.setErrors({
                    invalid: this.lang.get('bmng.agerange.invalid'),
                });
            } else if (!isNaN(prevAgeTo) && !isNaN(ageFrom) && prevAgeTo !== ageFrom - 1) {
                amountGroup.setErrors({
                    invalid: this.lang.get('bmng.ageranges.gaps.error'),
                });
            } else {
                amountGroup.setErrors(null);
            }
        } else {
            amountGroup.setErrors(null);
        }
    }
}
