import { Pipe, PipeTransform } from '@angular/core';
import { DateRangeSingleDatesTranslator } from '@bmng/helpers/date-range-single-dates-translator';
import {
    UiCancellationPaymentPolicy,
    UiCancellationPenalty,
} from '@bmng/pages/master/policies/interface/policies-ui.interface';
import { LangService } from '@kognitiv/angular-i18n';

import { ExpiredDateRangeHelper } from '../services/expired-date-range.helper';

@Pipe({
    name: 'policyCancellationPaymentSummary',
})
export class PolicyCancellationPaymentSummaryPipe implements PipeTransform {
    constructor(
        private lang: LangService,
        private expiredDaterangeHelper: ExpiredDateRangeHelper,
    ) { }

    transform(
        paymentPolicy: UiCancellationPaymentPolicy,
        textOnly: boolean,
        customRefundable: boolean,
        showErrors: boolean,
    ): string {
        const summary: string[] = this.getPolicySummaryItems(paymentPolicy, textOnly, customRefundable, showErrors);
        const summaryString = summary.join('<br>');
        return summaryString;
    }

    public getPolicySummaryItems(
        paymentPolicy: UiCancellationPaymentPolicy,
        textOnly: boolean,
        customRefundable: boolean,
        showErrors: boolean,
    ): string[] {
        let dateRangeSummary: string[] = [];
        let penaltiesSummary: string[] = [];

        if (paymentPolicy.paymentPolicyType === 'override') {
            const dateRanges = DateRangeSingleDatesTranslator.translateToRangesStartEnd(paymentPolicy.uiDateRanges);
            dateRangeSummary = this.expiredDaterangeHelper.getExpiredDateRangeStrings(dateRanges);
        }
        if (textOnly) {
            const description = paymentPolicy.textOnlyDescriptions && paymentPolicy.textOnlyDescriptions.length ?
                paymentPolicy.textOnlyDescriptions[0].description || '' : '';

            if (description) {
                penaltiesSummary.push(`<div>${description}</div>`);
            } else if (showErrors) {
                const errorMessage = this.lang.get('policies.textonly.description.required');
                penaltiesSummary.push(`<div class="policy-no-description">${errorMessage}</div>`);
            }
        } else if (paymentPolicy.uiPenalties && paymentPolicy.uiPenalties.length) {
            const uiPenalties = paymentPolicy.uiPenalties;
            if (paymentPolicy.paymentPolicyType === 'main' && !customRefundable) {
                uiPenalties.length = 1;
            }
            penaltiesSummary = uiPenalties.map(penalty => {
                penalty.summary = penalty.summary || '';
                let penaltySummary = `<div>${penalty.summary}</div>`;
                const description = this.getPenatlyDescription(penalty);

                if (description && penalty.showDescriptions) {
                    penaltySummary = `${penaltySummary}<div>${description}</div>`;
                }

                return penaltySummary;
            });
        }

        return [ ...dateRangeSummary, ...penaltiesSummary ];
    }

    private getPenatlyDescription(penalty: UiCancellationPenalty): string {
        if (penalty.descriptions && penalty.descriptions.length) {
            return penalty.descriptions[0].description || '';
        }

        return '';
    }
}
