import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { LangService } from '@kognitiv/angular-i18n';
import { ObjectHelpers } from '@kognitiv/bm-components';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ContextType } from '../context/interfaces/context-type.type';
import { EndpointService } from '../endpoint.service';
import { MenuServiceInterface } from './interfaces/menu-service.interface';
import { MenuItemRoot } from './interfaces/menu.interface';

@Injectable()
export class MenuService extends EndpointService implements MenuServiceInterface {
    private lastContext: Exclude<ContextType, 'pms'>;
    private lastEntityId: string;

    private menu$: Subject<MenuItemRoot[]> = new BehaviorSubject([]);

    constructor(
        protected http: HttpClient,
        private lang: LangService,
    ) {
        super(http);
    }

    loadMenu(
        context: Exclude<ContextType, 'pms'> = this.lastContext,
        entityId: string = this.lastEntityId,
    ): Observable<MenuItemRoot[]> {
        this.lastContext = context;
        this.lastEntityId = entityId;

        const baseUrl = this.getBaseUrl(this.lastContext, this.lastEntityId);
        const url = UrlHelpers.buildUrl(baseUrl, {
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpGet<MenuItemRoot[]>(url).pipe(
            tap(menu => this.menu$.next(menu)),
        );
    }

    getMenuObservable(): Observable<MenuItemRoot[]> {
        return this.menu$.asObservable();
    }

    private getBaseUrl(context: Exclude<ContextType, 'pms'>, entityId?: string): string {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/settings/menu`;

        switch (context) {
            case 'global': return `${baseUrl}/global`;
            case 'unit': return `${baseUrl}/cpcchain/${entityId}`;
            case 'cpc': return `${baseUrl}/cpcchain/${entityId}`;
            case 'direct': return `${baseUrl}/direct/${entityId}`;
            case 'master':
            case 'portal':
            case 'hotel': return `${baseUrl}/${context}/${entityId}`;
            default: ObjectHelpers.assertNever(context);
        }
    }
}
