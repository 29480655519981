import { Component, Input } from '@angular/core';
import { MenuItemChild } from '@bmng/services/menu/interfaces/menu.interface';

@Component({
    selector: 'bmng-menu-item-icon-container',
    templateUrl: './menu-item-icon-container.component.html',
    styleUrls: [ './menu-item-icon-container.component.scss' ],
})
export class MenuItemIconContainerComponent {
    @Input()
    menuItem: MenuItemChild;

    @Input()
    showChildrenChevron: boolean = false;

    @Input()
    submenuOpen: boolean = false;
}
