import { Injectable } from '@angular/core';
import { PolicyTax, TaxPolicyPayment } from '@bmng/services/policies/interfaces/policies.interface';

import { UiPolicyTax, UiTaxPaymentPolicy } from '../../../../pages/master/policies/interface/policies-ui.interface';

@Injectable()
export class PolicyFormTaxUiService {
    getUiTaxPolicyConfig(policy: PolicyTax): UiPolicyTax {
        if (!Array(policy.paymentPolicies)) {
            policy.paymentPolicies = [];
        } else {
            policy.paymentPolicies.forEach(_policy => {
                _policy.payments = _policy.payments || [];
                _policy.descriptions = _policy.descriptions || [];
            });
        }

        const placeholder: TaxPolicyPayment = {
            dateFrom: null,
            dateTo: null,
            amounts: [
                {
                    amount: null,
                    percent: null,
                    maxAmount: null,
                    ageFrom: null,
                    ageTo: null,
                },
            ],
        };

        return {
            type: 'tax',
            ...policy,
            uiPaymentPolicies: policy.paymentPolicies.map(_policy => {
                if (!_policy.payments.length) {
                    _policy.payments.push(placeholder, placeholder);
                } else if (_policy.payments.length === 1) {
                    _policy.payments.push(placeholder);
                }

                const hasDateRanges = _policy.payments.some(_payment =>
                    _payment.dateFrom || _payment.dateTo,
                );
                const hasMaximum = _policy.payments.some(_payment =>
                    Array.isArray(_payment.amounts) && _payment.amounts.some(_amounts => _amounts.maxAmount),
                );

                const hasAgeRanges = _policy.payments.some(_payment =>
                    Array.isArray(_payment.amounts) && _payment.amounts.some(_amounts => _amounts.ageFrom || _amounts.ageTo),
                );

                const hasDescriptions = _policy.descriptions.some(descriptions => descriptions.description);

                const isPercent = !_policy.taxBase;
                const isPerPerson = _policy.taxBase === 'PerPerson' || _policy.taxBase === 'PerPersonPerNight';

                const uiPolicy: UiTaxPaymentPolicy = {
                    ..._policy,
                    taxType: _policy.taxType,
                    priceCalculation: _policy.priceCalculation,
                    vatCalculation: _policy.vatCalculation,
                    taxBase: isPercent ? 'percent' : _policy.taxBase,
                    uiPayments: _policy.payments.map(payment => {
                        const amountsPlaceholder = placeholder.amounts[0];
                        if (!payment.amounts.length) {
                            payment.amounts.push(amountsPlaceholder, amountsPlaceholder);
                        } else if (payment.amounts.length === 1) {
                            payment.amounts.push(amountsPlaceholder);
                        }
                        return {
                            dateFrom: payment.dateFrom || null,
                            dateTo: payment.dateTo || null,
                            amounts: payment.amounts,
                        };
                    }),
                    showDateRanges: hasDateRanges,
                    showMaximum: hasMaximum,
                    showAgeRanges: hasAgeRanges && isPerPerson,
                    showAdvancedOptions: hasDateRanges || hasMaximum || hasAgeRanges,
                    showDescriptions: hasDescriptions,
                };

                return uiPolicy;
            }),
        };
    }

    getDefaultTaxPolicyConfig(currencyCode: string): PolicyTax {
        return {
            code: `TAXGROUP`,
            currencyCode,
            paymentPolicies: [
                {
                    taxType: 'VisitorsTax',
                    priceCalculation: 'Exclusive',
                    vatCalculation: 'Exclusive',
                    taxBase: 'percent',
                    payments: [
                        {
                            amounts: [
                                {
                                    percent: null,
                                    amount: null,
                                },
                                {
                                    percent: null,
                                    amount: null,
                                },
                            ],
                        },
                        {
                            amounts: [
                                {
                                    percent: null,
                                    amount: null,
                                },
                                {
                                    percent: null,
                                    amount: null,
                                },
                            ],
                        },
                    ],
                },
            ],
        };
    }
}
