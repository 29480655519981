import { EventEmitter } from '@angular/core';

export class DistinctEventEmitter<T> extends EventEmitter<T> {
    private previousValue: T;

    emit(value?: T): void {
        if (value !== this.previousValue) {
            super.emit(value);
            this.previousValue = value;
        }
    }
}
