import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PolicyBookingGuaranteeType } from '@bmng/services/policies/interfaces/policies.interface';
import { LangService } from '@kognitiv/angular-i18n';

import { StayType } from '../../../../pages/master/policies/interface/policies-ui.interface';

@Injectable()
export class PolicyFormBookingValidators {
    constructor(
        private lang: LangService,
    ) {}

    bookingOnHoldValidation(): ValidatorFn {
        return (form: UntypedFormGroup): ValidationErrors => {

            if (!form) {
                return null;
            }

            const durationHours = form.get('durationHours').value;

            const reminderLeadTimeCondition = form.get('hasLeadTimeEnabled').value;
            const reminderLeadTimeCtrl = form.get('reminderLeadTimeHours');
            this.conditionalUniqueRequired(reminderLeadTimeCtrl, reminderLeadTimeCondition);

            const reminderLeadTimeHours = reminderLeadTimeCtrl.value;
            if (reminderLeadTimeHours) {
                if (reminderLeadTimeHours >= durationHours || reminderLeadTimeHours < 8) {
                    reminderLeadTimeCtrl.setErrors({
                        limit: this.lang.get('cm.hold.hold.time.greaterequal.requirement.message'),
                    });
                }
            }

            const minimumLeadTimeDaysCtrl = form.get('minimumLeadTimeDays');
            const minimumLeadTimeDays = minimumLeadTimeDaysCtrl.value;
            if (minimumLeadTimeDays && minimumLeadTimeDays * 24 < durationHours) {
                minimumLeadTimeDaysCtrl.setErrors({
                    limit: this.lang.get('bmng.booking.min.lead.time.limit'),
                });
            }
            this.conditionalUniqueRequired(minimumLeadTimeDaysCtrl, true);

            const availabilityThresholdCtrl = form.get('availabilityThreshold');
            const availabilityThreshold = availabilityThresholdCtrl.value as number;
            if (availabilityThreshold && availabilityThreshold < 1) {
                availabilityThresholdCtrl.setErrors({
                    limit: this.lang.get('bmng.booking.min.availability.threshold'),
                });
            }
            this.conditionalUniqueRequired(availabilityThresholdCtrl, true);

            const isPromotionEnabled = form.get('hasPromotionEnabled').value;
            const promotionType = form.get('promotionType').value as 'amount' | 'percent';
            const promoAmountCondition = isPromotionEnabled && promotionType === 'percent';
            const promoPercentCtrl = form.get('promotion').get('percent');
            this.checkMinOrMax(promoPercentCtrl, true, 0.01, 99.99);
            this.conditionalUniqueRequired(promoPercentCtrl, promoAmountCondition);

            const promoPercentCondition = isPromotionEnabled && promotionType === 'amount';
            const promoAmountCtrl = form.get('promotion').get('amount');
            this.checkMinOrMax(promoAmountCtrl, false, 0.01, 999999.99);
            this.conditionalUniqueRequired(promoAmountCtrl, promoPercentCondition);

            return null;
        };
    }

    removeOnHoldInvalidState(onHoldFormGroup: AbstractControl): void {
        if (!onHoldFormGroup) {
            return;
        }

        const ctrlGroup: AbstractControl[] = [
            onHoldFormGroup.get('reminderLeadTimeHours'),
            onHoldFormGroup.get('minimumLeadTimeDays'),
            onHoldFormGroup.get('availabilityThreshold'),
            onHoldFormGroup.get('promotion').get('percent'),
            onHoldFormGroup.get('promotion').get('amount'),
        ];

        ctrlGroup.forEach(ctrl => {
            this.removeCtrlInvalidState(ctrl);
        });

        onHoldFormGroup.updateValueAndValidity();
    }

    removeDepositInvalidState(
        paymentForm: AbstractControl,
    ): void {
        if (!paymentForm) {
            return;
        }

        const ctrlGroup: AbstractControl[] = [
            paymentForm.get('numberOfNights'),
            paymentForm.get('percent'),
            paymentForm.get('amount'),
        ];

        ctrlGroup.forEach(ctrl => {
            this.removeCtrlInvalidState(ctrl);
        });

        paymentForm.updateValueAndValidity();
    }

    validateDescription(descriptionsFormArray: AbstractControl, textOnly: boolean): void {
        const descriptionToCheck = (<UntypedFormArray>descriptionsFormArray).at(0).get('description');
        this.conditionalUniqueRequired(descriptionToCheck, textOnly);
        descriptionToCheck.updateValueAndValidity();
    }

    bookingPaymentValidation(isPortal?: boolean, textOnly?: boolean): ValidatorFn {
        return (form: UntypedFormGroup): ValidationErrors => {
            if (!form) {
                return null;
            }

            const isOverride = form.get('paymentPolicyType').value === 'override';
            this.conditionalUniqueRequired(form.get('uiDateRanges'), isOverride);

            const guaranteeType = form.get('guaranteeType').value as PolicyBookingGuaranteeType;

            const amountCtrl = form.get('amount');
            const hasAmount = form.get('hasAmount').value;
            this.checkMinOrMax(amountCtrl, false, 0.01, 999999.99);
            this.conditionalUniqueRequired(amountCtrl, hasAmount);

            const percentCtrl = form.get('percent');
            const hasPercent = form.get('hasPercent').value;
            const textonly = textOnly !== undefined ? textOnly : form.parent?.parent?.get('textOnly').value;
            const portalCondition = guaranteeType !== 'None' && guaranteeType !== 'CcStore' && !textonly;

            this.conditionalUniqueRequired(percentCtrl, isPortal ? portalCondition : hasPercent);

            const nightsCtrl = form.get('numberOfNights');
            const stayType = form.get('stayType').value as StayType;
            this.conditionalUniqueRequired(nightsCtrl, hasPercent && stayType === 'Nights');

            if (guaranteeType === 'CcOnline' || ((guaranteeType === 'CcOffline' || guaranteeType === 'BankTransfer')
                && !textonly)) {
                if (!hasAmount && !hasPercent) {
                    return {
                        depositError: 'A deposit in percentage and/or amount is required',
                    };
                }
            }

            return null;
        };
    }

    bookingGuaranteeTypeValidation(hasPaymentProviders: boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if (!control) {
                return null;
            }

            if (control.value === 'CcOnline' && !hasPaymentProviders) {
                return {
                    CcOnlineError: this.lang.get('bmng.policies.booking.cconline.error'),
                };
            }
            return null;
        };
    }

    private checkMinOrMax(ctrl: AbstractControl, isPercent: boolean, min?: number, max?: number): ValidatorFn {
        if (!ctrl) {
            return null;
        }

        const value = !ctrl || ctrl.value === null ? null : ctrl.value as number;

        if (min && value && value < min) {
            ctrl.setErrors({
                min: this.lang.get('search.error.adult'),
            });
        }

        if (max && value && value > max) {
            ctrl.setErrors({
                max: isPercent ? this.lang.get('common.error.percentage.range')
                    : this.lang.get('bm.styleng.validator.maximum', max),
            });
        }
    }

    conditionalUniqueRequired(control: AbstractControl, condition: boolean): void {
        if (!control) {
            return;
        }

        if (condition) {
            control.setValidators([ Validators.required ]);
        } else {
            this.removeCtrlInvalidState(control);
        }
    }

    private removeCtrlInvalidState(control: AbstractControl): void {
        control.setErrors(null);
        control.clearValidators();
    }
}
