import { ResolveFn } from '@angular/router';
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';

export const oldCmLocalhostGuardCanActivate: ResolveFn<boolean> = () => {
    // This is only called if a `~/` route is picked up by Angular which should only happen on localhost
    // Hence we redirect automatically to dev
    const cmUrl = new URL(window.location.href);

    cmUrl.host = 'hotels-dev.seekda.com';
    cmUrl.port = '';

    window.location.href = cmUrl.toString();

    // Give angular 10 secs to pick it up ...
    return timer(10_000).pipe(map(() => true));
};
