import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointService } from '@bmng/services/endpoint.service';
import {
    RateRestrictionSetting } from '@bmng/services/rate-restrictions/interfaces/rate-restriction.interface';
import { RateRestrictionsServiceInterface } from '@bmng/services/rate-restrictions/interfaces/rate-restrictions.service.interface';
import { Observable } from 'rxjs';

@Injectable()
export class RateRestrictionsService extends EndpointService implements RateRestrictionsServiceInterface {

    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    getAllRateRestrictionSettings(hotelId: string): Observable<RateRestrictionSetting[]> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/${hotelId}/rates/settings`;

        return this.httpGet<RateRestrictionSetting[]>(url, EndpointService.HTTP_HEADERS);
    }

    getRateRestrictionSettingById(hotelId: string, restrictionId: number): Observable<RateRestrictionSetting> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/${hotelId}/rates/settings/${restrictionId}`;

        return this.httpGet<RateRestrictionSetting>(url, EndpointService.HTTP_HEADERS);
    }

    saveNewRestriction(hotelId: string, data: RateRestrictionSetting): Observable<RateRestrictionSetting> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/${hotelId}/rates/settings`;

        return this.httpPost<RateRestrictionSetting>(url, data, EndpointService.HTTP_HEADERS);
    }

    updateExistingRestriction(hotelId: string, data: RateRestrictionSetting): Observable<RateRestrictionSetting> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/${hotelId}/rates/settings/${data.id}`;

        return this.httpPut<RateRestrictionSetting>(url, data, EndpointService.HTTP_HEADERS);
    }

    deleteRestriction(restrictionId: number, hotelId: string): Observable<RateRestrictionSetting[]> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/${hotelId}/rates/settings/${restrictionId}`;

        return this.httpDelete<RateRestrictionSetting[]>(url, EndpointService.HTTP_HEADERS);
    }

}
