import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AwardType } from './interfaces/awards.interface';
import { AwardsServiceInterface } from './interfaces/awards.service.interface';

@Injectable()
export class AwardsServiceMock implements AwardsServiceInterface {
    getPortalAwards(portalId: string): Observable<AwardType[]> {
        return of([ this.getHotelsterneAtMock(), this.getHotelstarsEuMock() ]);
    }

    private getHotelsterneAtMock(): AwardType {
        return {
            code: '1',
            name: 'hotelsterne.at',
            numberOfUsages: 5,
            awards: [
                { name: 'Five Stars Superior', code: '5+', numberOfUsages: 1 },
                { name: 'Five Stars', code: '5', numberOfUsages: 1 },
                { name: 'Four Stars Superior', code: '4+', numberOfUsages: 0 },
                { name: 'Four Stars', code: '4', numberOfUsages: 0 },
                { name: 'Three Stars Superior', code: '3+', numberOfUsages: 0 },
                { name: 'Three Stars', code: '3', numberOfUsages: 1 },
                { name: 'Two Stars Superior', code: '2+', numberOfUsages: 0 },
                { name: 'Two Stars', code: '2', numberOfUsages: 0 },
                { name: 'One Star Superior', code: '1+', numberOfUsages: 1 },
                { name: 'One Star', code: '1', numberOfUsages: 1 },
            ],
        };
    }

    private getHotelstarsEuMock(): AwardType {
        return {
            code: '11',
            name: 'hotelstars.eu',
            numberOfUsages: 1,
            awards: [
                { name: 'Five Stars Superior', code: '5*', numberOfUsages: 0 },
                { name: 'Five Stars', code: '5', numberOfUsages: 0 },
                { name: 'Four Stars Superior', code: '4*', numberOfUsages: 0 },
                { name: 'Four Stars', code: '4', numberOfUsages: 1 },
                { name: 'Three Stars Superior', code: '3*', numberOfUsages: 0 },
                { name: 'Three Stars', code: '3', numberOfUsages: 0 },
                { name: 'Two Stars Superior', code: '2*', numberOfUsages: 0 },
                { name: 'Two Stars', code: '2', numberOfUsages: 0 },
                { name: 'One Star Superior', code: '1*', numberOfUsages: 0 },
                { name: 'One Star', code: '1', numberOfUsages: 0 },
            ],
        };
    }
}
