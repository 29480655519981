import { Injectable } from '@angular/core';
import { RouteHelpers } from '@bmng/helpers/route-helpers';
import { ContextService } from '@bmng/services/context/context.service';
import { ContextItem } from '@bmng/services/context/interfaces/context-item.interface';

export interface HeaderIconsVisibility {
    isSupportPartnerVisible: boolean;
    isBillingPartnerVisible: boolean;
    isReportVisible: boolean;
    isHistoryVisible: boolean;
}

@Injectable()
export class HeaderIconsService {
    constructor(
        private contextService: ContextService,
    ) { }

    public getIconsVisibility(): HeaderIconsVisibility {
        const activeContext = RouteHelpers.getActiveContext(this.contextService.context.value.context);

        return {
            isSupportPartnerVisible: this.getSupportPartnerVisibility(activeContext),
            isBillingPartnerVisible: this.getBillingPartnerVisibility(activeContext),
            isReportVisible: this.getReportVisibility(activeContext),
            isHistoryVisible: this.getHistoryVisibility(activeContext),
        };
    }

    private getSupportPartnerVisibility(activeContext: ContextItem): boolean {
        if (activeContext.type === 'global') {
            return false;
        }

        if (!activeContext.supportPartner && !activeContext.billTo) {
            return false;
        }

        if (this.contextService.isSuperAdminOrViewer() || this.contextService.isPartnerAdmin()) {
            return true;
        }
        if (activeContext.role === 'uabadmin') {
            return true;
        }

        return false;
    }

    private getBillingPartnerVisibility(activeContext: ContextItem): boolean {
        if (activeContext.type === 'global') {
            return false;
        }
        if (!activeContext.billTo) {
            return false;
        }

        if (this.contextService.isSuperAdminOrViewer() || this.contextService.isPartnerAdmin()) {
            return true;
        }
        if (activeContext.role === 'uabadmin') {
            return true;
        }

        return false;
    }

    private getHistoryVisibility(activeContext: ContextItem): boolean {
        if (activeContext.type !== 'hotel' && activeContext.type !== 'master' && activeContext.type !== 'portal') {
            return false;
        }

        if (this.contextService.isSuperAdminOrViewer()) {
            return true;
        }

        if (activeContext.role === 'uabadmin') {
            return true;
        }

        return false;
    }

    private getReportVisibility(activeContext: ContextItem): boolean {
        if (this.contextService.isSuperAdmin()) {
            return true;
        }

        if (activeContext.role === 'uabadmin') {
            return true;
        }

        return false;
    }
}
