import { Component, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { ContextService } from '@bmng/services/context/context.service';
import { ContextObjectResponse } from '@bmng/services/context/interfaces/context-object-response.interface';
import { MenuItemRoot } from '@bmng/services/menu/interfaces/menu.interface';
import { MenuService } from '@bmng/services/menu/menu.service';
import { PageService } from '@bmng/services/page.service';
import { Subscription } from 'rxjs';

import { MainMenuItemComponent } from './main-menu-item/main-menu-item.component';

@Component({
    selector: '[bm-menu]',
    templateUrl: './menu.component.html',
    styleUrls: [ './menu.component.scss' ],
})
export class MenuComponent implements OnInit, OnDestroy {

    @ViewChildren(MainMenuItemComponent)
    menuItems: MainMenuItemComponent[];

    public menu: MenuItemRoot[];
    public baseContextUrl: string;

    public context: ContextObjectResponse;

    private subscriptions: Subscription = new Subscription();

    constructor(
        private menuService: MenuService,
        private contextService: ContextService,
        public pageService: PageService,
    ) { }

    ngOnInit(): void {
        this.subscriptions.add(this.contextService.baseContextUrl.subscribe(url => {
            this.baseContextUrl = url;
        }));

        this.subscriptions.add(this.contextService.context.subscribe(context => {
            this.context = context;
        }));

        this.subscriptions.add(this.menuService.getMenuObservable().subscribe(menu => {
            this.menu = menu;
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onOpenMenu(isOpen: boolean, activeMenuItem: MenuItemRoot): void {
        if (!isOpen) {
            return;
        }

        this.menuItems.forEach(menuItem => {
            if (menuItem.menuItem !== activeMenuItem) {
                menuItem.toggleSubMenu(false);
            }
        });
    }

    closeMenu(): void {
        this.pageService.toggleMenuShown(false);
    }
}
