import { Injectable } from '@angular/core';
import { CancellationRefundType, LeadTimeType } from '@bmng/pages/master/policies/interface/policies-ui.interface';
import { OnlineCancellationByGuestType, PolicyBookingGuaranteeType, PolicyBookingType, PolicyPetsType, PolicyType, TaxBase, TaxCalculation, TaxType } from '@bmng/services/policies/interfaces/policies.interface';
import { LangService } from '@kognitiv/angular-i18n';
import { PaymentProvider } from './../../core/services/payment-providers/interfaces/payment-providers';

interface PolicyTitles {
    new: string;
    title: string;
    breadcrumbTitleNew: string;
    breadcrumbTitleEdit: string;
    default?: string;
    defaultMultiCurrency?: string;
    setDefault?: string;
    setDefaultNote?: string;
    setDefaultNote2?: string;
}

@Injectable()
export class PoliciesTextHelpers {

    constructor(
        public lang: LangService,
    ) {}

    getPolicyTitle(policyType: PolicyType, title: keyof PolicyTitles): string {
        const DEFAULT = this.lang.get('default.rate.name');
        const FOR_CURRENCIES = this.lang.get('bm.policies.for.currencies');
        const SET_DEFAULT = this.lang.get('bmng.policies.default.set');

        const policyTitleMap: { [key in PolicyType]: PolicyTitles } = {
            booking: {
                new: this.lang.get('ibe.manager.tab.create.title'),
                title: this.lang.get('cc.hotel.policy.booking.policy.title'),
                breadcrumbTitleNew: this.lang.get('bm.policies.title.booking.new'),
                breadcrumbTitleEdit: this.lang.get('bm.policies.title.booking.edit'),
                default: DEFAULT,
                defaultMultiCurrency: `${DEFAULT} ${FOR_CURRENCIES}`,
                setDefault: SET_DEFAULT,
                setDefaultNote: '',
            },
            cancellation: {
                new: this.lang.get('ibe.manager.tab.create.title'),
                title: this.lang.get('cc.hotel.policy.cancel.policy.title'),
                breadcrumbTitleNew: this.lang.get('bm.policies.title.cancellation.new'),
                breadcrumbTitleEdit: this.lang.get('bm.policies.title.cancellation.edit'),
                default: DEFAULT,
                defaultMultiCurrency: `${DEFAULT} ${FOR_CURRENCIES}`,
                setDefault: SET_DEFAULT,
                setDefaultNote: '',
            },
            tax: {
                new: this.lang.get('ibe.manager.tab.create.title'),
                title: this.lang.get('cc.hotel.policy.taxRuleGroup.title'),
                breadcrumbTitleNew: this.lang.get('bm.policies.title.tax.new'),
                breadcrumbTitleEdit: this.lang.get('bm.policies.title.tax.edit'),
                default: DEFAULT,
                defaultMultiCurrency: `${DEFAULT} ${FOR_CURRENCIES}`,
                setDefault: SET_DEFAULT,
                setDefaultNote: this.lang.get('bmng.policies.default.note.tax'),
                setDefaultNote2: this.lang.get('bmng.policies.default.note'),
            },
            pets: {
                new: this.lang.get('ibe.manager.tab.create.title'),
                title: this.lang.get('cc.hotel.policy.pets.policy.title'),
                breadcrumbTitleNew: this.lang.get('bm.policies.title.pets.new'),
                breadcrumbTitleEdit: this.lang.get('bm.policies.title.pets.edit'),
                default: this.lang.get('bm.contracts.status.ACTIVE.title'),
                defaultMultiCurrency: `${this.lang.get('bm.contracts.status.ACTIVE.title')} ${FOR_CURRENCIES}`,
                setDefault: this.lang.get('bmng.policies.activate'),
                setDefaultNote: this.lang.get('bmng.policies.default.note.pets'),
            },
            cleanup: {
                new: this.lang.get('ibe.manager.tab.create.title'),
                title: this.lang.get('cc.hotel.policy.finalcleanup.policy.title'),
                breadcrumbTitleNew: this.lang.get('bm.policies.title.cleanup.new'),
                breadcrumbTitleEdit: this.lang.get('bm.policies.title.cleanup.edit'),
            },
        };
        const policyByKey = policyTitleMap[policyType];
        if (!policyByKey) {
            return '';
        }

        return policyByKey[title] || '';
    }

    getBookingTypeLabel(bookingType: PolicyBookingType): string {
        const labelMap: { [ key in PolicyBookingType ]: string } = {
            Obligatory: this.lang.get('cc.hotel.policy.booking.normal.label'),
            OnRequest: this.lang.get('cc.hotel.policy.booking.onRequest.label'),
            AvailabilityIndependent: this.lang.get('cc.hotel.policy.booking.overselling.label'),
        };

        return labelMap[bookingType] || '';
    }

    getBookingGuaranteeTypeLabel(guaranteeLabel: PolicyBookingGuaranteeType): string {
        const labelMap: { [ key in PolicyBookingGuaranteeType ]: string } = {
            None: this.lang.get('cc.hotel.policy.booking.nopayment'),
            CcStore: this.lang.get('cc.hotel.policy.booking.creditcardStore'),
            CcOnline: this.lang.get('cc.hotel.policy.booking.creditcardpayment'),
            CcOffline: this.lang.get('cc.hotel.policy.booking.offlineterminal'),
            BankTransfer: this.lang.get('cc.hotel.policy.booking.banktransfer'),
        };

        return labelMap[guaranteeLabel] || '';
    }

    getPaymentInfoForCcOnline(enabledProviders: PaymentProvider[]): string {
        const wrapString = (text: string) => `<div class="bm-alert info">${text}</div>`;

        if (!enabledProviders.length) {
            return wrapString(this.lang.get('cc.hotel.policy.booking.note.onlinepayment'));
        }

        return enabledProviders.map(provider => {
            const infoText = this.lang.get('cc.hotel.policy.booking.creditcardpayment.defaultprovider.nomerchant',
            provider.name || provider.channelId);

            if (!provider.isVisible) {
                return wrapString(`SAO: ${infoText}`);
            }

            return wrapString(infoText);
        }).join(' ');
    }

    getOnlineCancellationByGuestLabel(option: OnlineCancellationByGuestType): string {
        const optionsMap: { [key in OnlineCancellationByGuestType ]: string } = {
            ForbidOnlineCancel: this.lang.get('cc.hotel.policy.cancel.options.ForbidOnlineCancel'),
            AllowOnlineCancelIfNoPaymentDue: this.lang.get('cc.hotel.policy.cancel.options.AllowOnlineCancelIfNoPaymentDue'),
            AllowOnlineCancelAndPayment: this.lang.get('cc.hotel.policy.cancel.options.AllowOnlineCancelAndPayment'),
        };

        return optionsMap[option] || '';
    }

    getcancellationRefundLabel(option: CancellationRefundType): string {
        const optionsMap: { [key in CancellationRefundType ]: string } = {
            AlwaysRefundable: this.lang.get('bmng.policies.refund.AlwaysRefundable'),
            AlwaysNonRefundable: this.lang.get('bmng.policies.refund.AlwaysNonRefundable'),
            CustomRefundable: this.lang.get('bmng.policies.refund.CustomRefundable'),

        };
        return optionsMap[option] || '';
    }

    getLeadTimeLabel(option: LeadTimeType): string {
        const labelMap: { [ key in LeadTimeType ]: string } = {
            Always: this.lang.get('seekda.metacpc2013.charts.rechargealways'),
            Days: this.lang.get('bmng.specific.nr.days'),
        };

        return labelMap[option] || '';

    }

    getPetsTypeLabel(petsType: PolicyPetsType): string {
        const labelMap: { [ key in PolicyPetsType ]: string } = {
            NoPetsAllowed: this.lang.get('policies.pets.animals.0'),
            AllPetsAllowed: this.lang.get('policies.pets.animals.7'),
            AllowedOnRequest: this.lang.get('policies.pets.animals.101'),
            CatsOnly: this.lang.get('policies.pets.animals.1'),
            DogsOnly: this.lang.get('policies.pets.animals.2'),
            DomesticPets: this.lang.get('policies.pets.animals.10'),
            SmallDomesticAnimals: this.lang.get('policies.pets.animals.8'),
            SmallDomesticAnimalsOnly: this.lang.get('policies.pets.animals.5'),
            MidsizeDomesticAnimals: this.lang.get('policies.pets.animals.4'),
            LargeDomesticAnimals: this.lang.get('policies.pets.animals.3'),
            WorkingAnimals: this.lang.get('policies.pets.animals.9'),
            WorkingAnimalsOnly: this.lang.get('policies.pets.animals.6'),
        };

        return labelMap[petsType] || '';
    }

    getTaxTypeLabel(taxType: TaxType): string {
        const labelMap: { [ key in TaxType ]: string } = {
            VisitorsTax: this.lang.get('com.seekda.general.taxes.types.3'),
            VAT : this.lang.get('com.seekda.general.taxes.types.36'),
            StateCharge : this.lang.get('com.seekda.general.taxes.types.15'),
            OccupancyTax : this.lang.get('com.seekda.general.taxes.types.10'),
            ServiceCharge : this.lang.get('com.seekda.general.taxes.types.14'),
            ResortFee : this.lang.get('com.seekda.general.taxes.types.12'),
            Other: this.lang.get('com.seekda.general.taxes.types.other'),
        };

        return labelMap[taxType] || '';
    }

    getTaxBaseLabel(taxBase: TaxBase): string {
        const labelMap: { [ key in TaxBase ]: string } = {
            PerPersonPerNight: this.lang.get('com.seekda.general.taxes.base.PerPersonPerNight'),
            percent : this.lang.get('com.seekda.general.taxes.base.Percent'),
            PerRoomPerNight : this.lang.get('com.seekda.general.taxes.base.PerRoomPerNight'),
            PerPerson : this.lang.get('com.seekda.general.taxes.base.PerPerson'),
            PerRoom : this.lang.get('com.seekda.general.taxes.base.PerRoom'),
        };

        return labelMap[taxBase] || '';
    }

    getTaxCalculationLabel(taxCalculation: TaxCalculation): string {
        const labelMap: { [ key in TaxCalculation ]: string } = {
            Exclusive: this.lang.get('com.seekda.general.taxes.calculation.type.exclusive'),
            InformativeAlreadyIncluded : this.lang.get('com.seekda.general.taxes.calculation.type.informativeAndIncluded'),
            InformativeExclusive : this.lang.get('com.seekda.general.taxes.calculation.type.informativeAndExclusive'),
        };

        return labelMap[taxCalculation] || '';
    }

    getDescriptionNote(maxChars: number, preferredChars: string): string {
        return `${this.lang.get('bmng.pullChannelOffer.form.info.messages.characters')}: 1 - ${maxChars}.
            ${this.lang.get('cc.channelManager.multiligual.compoment.label.preferred')}: ${preferredChars}.`;
    }
}
