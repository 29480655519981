import { Injectable } from '@angular/core';
import { LangService } from '@kognitiv/angular-i18n';

import { PageService } from '../page.service';

import { ErrorHandlerServiceInterface } from './interfaces/error-handler.service.interface';

@Injectable()
export class ErrorHandlerService implements ErrorHandlerServiceInterface {
    constructor(
        private pageService: PageService,
        private lang: LangService,
    ) { }

    public handleError(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors: any,
        customMessage?: string,
    ): void {
        const errorMessage = this.getErrorMessage(errors, customMessage);

        this.pageService.showError(
            this.lang.get('cc.error.page.heading'),
            errorMessage,
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getErrorMessage(errors: any, customMessage?: string): string {
        if (Array.isArray(errors) && errors.length > 0) {
            return this.getMessageFromArray(errors, customMessage);
        } else if (Array.isArray(errors?.error?.errors) && errors.error.errors.length > 0) {
            // Passing in errors from a failed HTTP response
            return this.getMessageFromArray(errors.error.errors, customMessage);
        } else if (typeof errors === 'object' || typeof errors === 'string') {
            return this.formatError(this.getSingleMessage(errors), customMessage);
        }

        return this.formatError('', customMessage);
    }

    private getSingleMessage(errors: string | { code?: string; message?: string }): string {
        if (!errors) {
            return '';
        } else if (typeof errors === 'string') {
            return errors;
        } else if (errors.code && errors.message) {
            return `${errors.message} (${errors.code})`;
        } else if (errors.message) {
            return errors.message;
        } else if (errors.code) {
            return errors.code;
        }

        return '';
    }

    private getMessageFromArray(errors: unknown[], customMessage?: string): string {
        const errorText = errors
            .map(err => this.getSingleMessage(err))
            .filter(err => err)
            .join(', ');

        return this.formatError(errorText, customMessage);
    }

    private formatError(error: string, customMessage?: string): string {
        const err = error || this.lang.get('cc.error.page.message');

        if (customMessage) {
            return `${customMessage}: ${err}`;
        }

        return err;
    }
}
