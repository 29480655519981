import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { EndpointService } from '@bmng/services/endpoint.service';
import {
    SearchAutocompleteParameters,
    SearchAutocompleteResult,
} from '@bmng/services/search-autocomplete/interfaces/search-autocomplete.interface';
import { SearchAutocompleteServiceInterface } from '@bmng/services/search-autocomplete/interfaces/search-autocomplete.service.interface';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class SearchAutocompleteService extends EndpointService implements SearchAutocompleteServiceInterface {
    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    getProposals(options: SearchAutocompleteParameters): Observable<SearchAutocompleteResult> {
        const url: string = `${environment.baseUrls.search}/api/autocompletion.json`;

        return this.httpGet(UrlHelpers.buildUrl(url, options), EndpointService.HTTP_HEADERS);
    }
}
