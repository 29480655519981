import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HotelDashboardComponent } from './dashboard.component';

const dashboardRoutes: Routes = [
    { path: '', component: HotelDashboardComponent },
    { path: 'dashboard', component: HotelDashboardComponent },
];

@NgModule({
    imports: [ RouterModule.forChild(dashboardRoutes) ],
    exports: [ RouterModule ],
})
export class HotelDashboardRoutingModule {}
