import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointService } from '@bmng/services/endpoint.service';
import { Observable } from 'rxjs';

import { HotelCpcPaymentRequest } from './interfaces/hotel-cpc-payment-request.interface';
import { HotelCpcPaymentStatus } from './interfaces/hotel-cpc-payment-status.interface';
import { HotelCpcServiceInterface } from './interfaces/hotel-cpc.service.interface';

@Injectable()
export class HotelCpcService extends EndpointService implements HotelCpcServiceInterface {
    constructor(http: HttpClient) {
        super(http);
    }

    getPaymentStatus(hotelId: string): Observable<HotelCpcPaymentStatus> {
        const url = `${EndpointService.getBmBackendUrl()}/api/cm/api/hotel/${hotelId}/cpc/payment`;

        return this.httpGet<HotelCpcPaymentStatus>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }
    processNextPayment(hotelId: string, paymentRequest: HotelCpcPaymentRequest): Observable<boolean> {
        const url = `${EndpointService.getBmBackendUrl()}/api/cm/api/hotel/${hotelId}/cpc/payment`;

        return this.httpPost<boolean>(url, paymentRequest, EndpointService.HTTP_HEADERS);
    }
}
