import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LabelOverwrites } from '../../../pages/generic/label-overwrites/services/label-overwrites.interface';
import { EndpointService } from '../endpoint.service';
import { LabelOverwritesEntry } from './label-overwrites-request.interface';

@Injectable()
export class LabelOverwritesService extends EndpointService {

    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    getOverwrites(channelId: string, propertyCode?: string, languageCode?: string): Observable<LabelOverwrites> {
        const url = this.getReadEndpointUrl(channelId, propertyCode, languageCode);
        return this.httpGet<LabelOverwrites>(url, EndpointService.HTTP_HEADERS, true);
    }

    private getReadEndpointUrl(channelId: string, propertyCode: string, languageCode?: string): string {
        let url = !!propertyCode
            ? `${EndpointService.getBmBackendUrl()}/api/channels/${channelId}/customLabel/${propertyCode}`
            : `${EndpointService.getBmBackendUrl()}/api/channels/${channelId}/customLabel`;
        if (languageCode && propertyCode) {
            url += `?languageCode=${languageCode}`;
        }
        return url;
    }

    private getWriteEndpointUrl(channelId: string, propertyCode: string): string {
        return !!propertyCode
            ? `${EndpointService.getBmBackendUrl()}/api/channels/${channelId}/hotels/${propertyCode}`
            : `${EndpointService.getBmBackendUrl()}/api/channels/${channelId}`;
    }

    putOverwrites(channelId: string, propertyCode: string, overwrites: LabelOverwrites): Observable<boolean> {
        const url = this.getWriteEndpointUrl(channelId, propertyCode);
        const entry: LabelOverwritesEntry = {
            id: propertyCode || channelId,
            sort: null,
            customLabels: overwrites,
        };
        const writeRequest: LabelOverwritesEntry | LabelOverwritesEntry[] = !!propertyCode
            ? [ entry ]
            : entry;

        return this.httpPatch<boolean>(
            url,
            writeRequest,
            EndpointService.HTTP_HEADERS,
            true,
        );
    }
}
