import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { Observable } from 'rxjs';

import { EndpointService } from '../endpoint.service';
import { ListResult } from '../list-results.interface';

import { DynamicAllocation } from './interfaces/dynamic-allocations.interface';
import { DynamicAllocationListParameters, DynamicAllocationsServiceInterface } from './interfaces/dynamic-allocations.service.interface';

@Injectable()
export class DynamicAllocationsService extends EndpointService implements DynamicAllocationsServiceInterface {

    constructor(http: HttpClient) {
        super(http);
    }

    getDynamicAllocations(hotelId: string, params: DynamicAllocationListParameters): Observable<ListResult<DynamicAllocation>> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/hotels/${hotelId}/dynamicAllocations`;

        return this.httpGet<ListResult<DynamicAllocation>>(UrlHelpers.buildUrl(baseUrl, params), EndpointService.HTTP_HEADERS);
    }

    getDynamicAllocation(hotelId: string, allocationCode: string): Observable<DynamicAllocation> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotels/${hotelId}/dynamicAllocations/${allocationCode}`;

        return this.httpGet<DynamicAllocation>(url, EndpointService.HTTP_HEADERS);
    }

    updateDynamicAllocation(hotelId: string, allocation: DynamicAllocation): Observable<boolean> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/hotels/${hotelId}/dynamicAllocations/${allocation.code}`;

        return this.httpPut(baseUrl, allocation, EndpointService.HTTP_HEADERS);
    }

    createDynamicAllocation(hotelId: string, allocation: DynamicAllocation): Observable<boolean> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/hotels/${hotelId}/dynamicAllocations`;

        return this.httpPost(baseUrl, allocation, EndpointService.HTTP_HEADERS);
    }

    deleteDynamicAllocation(hotelId: string, allocationCode: string): Observable<boolean> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotels/${hotelId}/dynamicAllocations/${allocationCode}`;

        return this.httpDelete(url, EndpointService.HTTP_HEADERS);
    }
}
