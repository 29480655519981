import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BookingEngineService } from '@bmng/services/booking-engine/booking-engine.service';
import { ChannelsService } from '@bmng/services/channels/channels.service';
import { HotelErpService } from '@bmng/services/hotel-erp/hotel-erp.service';
import { UsersService } from '@bmng/services/users/users.service';
import { LangModule, LangService } from '@kognitiv/angular-i18n';
import { BmButtonModule, BmLoadingModule, BmModalModule, LangConfigService } from '@kognitiv/bm-components';

import { KubeUpsellLightboxComponent } from './kube-upsell-lightbox.component';

export type KubeUpsellLightboxModuleParam = 'useProviders' | 'noProviders';

@NgModule({
    imports: [
        CommonModule,
        LangModule,
        BmButtonModule,
        BmModalModule,
        BmLoadingModule,
    ],
    declarations: [ KubeUpsellLightboxComponent ],
    exports: [ KubeUpsellLightboxComponent ],
})
export class KubeUpsellLightboxModule {
    static withConfig(config: KubeUpsellLightboxModuleParam): ModuleWithProviders<KubeUpsellLightboxModule> {
        const providers = config === 'useProviders'
            ? [ LangService, UsersService, LangConfigService, HotelErpService, ChannelsService, BookingEngineService ]
            : [];

        return {
            ngModule: KubeUpsellLightboxModule,
            providers,
        };
    }
}
