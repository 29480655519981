import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {
    setCookie<T>(name: string, value: T, days?: number): void {
        const stringifiedValue = JSON.stringify(value);
        let expires = '';

        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = `expires=${date.toUTCString()}`;
        }

        document.cookie = `${name}=${stringifiedValue};${expires};path=/`;
    }

    getCookie<T>(name: string): T {
        const cookies = (document.cookie || '');
        const stringifiedValue = cookies.split(';')
            .find((cookie) => cookie.includes(`${name}=`));

        if (stringifiedValue) {
            const [ _, value ] = stringifiedValue.split('=');
            return value
                ? JSON.parse(value) as T
                : null;
        }

        return null;
     }
}
