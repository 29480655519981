import { Injectable } from '@angular/core';
import { LangService } from '@kognitiv/angular-i18n';
import { DatePipe, DateRangeStartEnd, MomentHelpers, MomentService } from '@kognitiv/bm-components';

const moment = MomentService.get();

@Injectable()
export class ExpiredDateRangeHelper {
    constructor(
        private lang: LangService,
        private datePipe: DatePipe,
    ) {}

    getExpiredDateRangeString(startDate: string, endDate: string): string {
        if (!startDate && !endDate) {
            return '';
        }

        const today = moment().format('YYYY-MM-DD');
        const dateRange = this.getDateRangeString(startDate, endDate);

        if (MomentHelpers.getMoment(endDate).isBefore(today)) {
            return `<span style="color: rgba(0,0,0,.54)" class="text-disabled">${dateRange} ${this.lang.get('bm.errata.expired')}</span>`;
        }

        return dateRange;
    }

    getExpiredDateRangeStrings(dateRanges: DateRangeStartEnd[]): string[] {
        if (!dateRanges || dateRanges.length < 1) {
            return [];
        }

        return dateRanges.map(dateRange => this.getExpiredDateRangeString(dateRange.start, dateRange.end));
    }

    private getDateRangeString(startDate: string, endDate: string): string {
        const start = startDate ? this.datePipe.transform(startDate)
            : this.lang.get('bmng.before');
        const end = endDate ? this.datePipe.transform(endDate)
            : this.lang.get('hotel.rateManager.ratePlans.label.unlimited');
        return `${start} - ${end}`;
    }

}
