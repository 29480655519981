import { Injectable } from '@angular/core';

import { ComposableRoom, ComposableRoomAdjacencyList, ComposableRoomDependencies, RoomCode } from './interfaces/room-types';

@Injectable()
export class ComposableRoomDependencyResolverService {
    constructor() {}

    expandComposableRoomDependencies(room: RoomCode, dependencies: ComposableRoomDependencies): RoomCode[] {
        const visitedRoomCodes: RoomCode[] = [];
        return this.expandComposableRoomDependenciesIncludingCycleCheck(room, dependencies, visitedRoomCodes);
    }

    private expandComposableRoomDependenciesIncludingCycleCheck(
        room: RoomCode,
        dependencies: ComposableRoomDependencies,
        alreadyVisitedRooms: RoomCode[]): RoomCode[] {
        if (alreadyVisitedRooms.includes(room)) {
            return [];
        }

        const dependentRoomCodes = dependencies[room] || [];
        const roomCodes = [ ...dependentRoomCodes ];

        alreadyVisitedRooms.push(room);

        dependentRoomCodes
            .filter(roomCode => !alreadyVisitedRooms.includes(roomCode))
            .forEach(dependentRoomCode => {
                const subDependentRoomCodes = this.expandComposableRoomDependenciesIncludingCycleCheck(
                    dependentRoomCode,
                    dependencies,
                    alreadyVisitedRooms,
                );

                subDependentRoomCodes.forEach(roomCode => {
                    if (!roomCodes.includes(roomCode)) {
                        roomCodes.push(roomCode);
                    }
                });
            });

        return roomCodes;
    }

    getBaseRoomDepth(room: RoomCode, dependencies: ComposableRoomAdjacencyList, startLevel = 0): number {
        const predecessors = dependencies.predecessors[room] || [];

        if (predecessors.length === 0) {
            return startLevel;
        }

        let current = startLevel;

        predecessors.forEach(predecessor => {
            const predecessorDepth = this.getBaseRoomDepth(predecessor, dependencies, startLevel + 1);

            if (predecessorDepth > current) {
                current = predecessorDepth;
            }
        });

        return current;
    }

    hasCycle(room: RoomCode, dependencies: ComposableRoomAdjacencyList): boolean {
        const successors = this.expandComposableRoomDependencies(room, dependencies.successors);
        const predecessors = this.expandComposableRoomDependencies(room, dependencies.predecessors);
        return successors.some(successor => predecessors.includes(successor));
    }

    createAdjacentList(rooms: ComposableRoom[]): ComposableRoomAdjacencyList {
        const dependencies: ComposableRoomAdjacencyList = {
            successors: {},
            predecessors: {},
        };

        (rooms || []).forEach(room => {
            dependencies.successors[room.code] = dependencies.successors[room.code] || [];
            dependencies.predecessors[room.code] = dependencies.predecessors[room.code] || [];

            if (!room.virtual) {
                return;
            }

            const baseRoomCodes = room.virtual.baseRooms || [];
            baseRoomCodes.forEach(roomCode => {
                if (!dependencies.predecessors[room.code].includes(roomCode)) {
                    dependencies.predecessors[room.code].push(roomCode);
                }
            });
            baseRoomCodes.forEach(successorRoomCode => {
                dependencies.successors[successorRoomCode] = dependencies.successors[successorRoomCode] || [];

                if (!dependencies.successors[successorRoomCode].includes(room.code)) {
                    dependencies.successors[successorRoomCode].push(room.code);
                }
            });
        });

        return dependencies;
    }
}
