import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CodeTitle } from '@bmng/services/metadata/interfaces/metadata.interface';
import { PetsPaymentPolicy } from '@bmng/services/policies/interfaces/policies.interface';

import { UiPolicyPets } from '../../../../pages/master/policies/interface/policies-ui.interface';
import { PolicyFormHelpersService } from '../policy-form-helpers.service';
import { PolicyFormValidators } from '../policy-form-validators.service';

import { PolicyFormPetsValidators } from './policy-form-pets-validators.service';

@Injectable()
export class PolicyFormPetsService {
    constructor(
        private formHelpersService: PolicyFormHelpersService,
        private petsValidators: PolicyFormPetsValidators,
        private validators: PolicyFormValidators,
    ) {}

    getPetsPolicyForm(
        policyConfig: UiPolicyPets,
        isNewPolicy: boolean,
        languages: CodeTitle[],
        usedCodes?: string[],
    ): UntypedFormGroup {
        if (!policyConfig) {
            return;
        }

        return new UntypedFormGroup({
            code: new UntypedFormControl(policyConfig.code, [
                Validators.required,
                this.validators.uniqueCodeValidation(isNewPolicy, usedCodes),
            ]),
            petsAllowed: new UntypedFormControl(policyConfig.petsAllowed, [
                this.petsValidators.petsAllowedValidation(),
            ]),
            descriptionsNoPetsAllowed: new UntypedFormArray(
                this.formHelpersService.getDescriptionControlsByLang(languages, policyConfig.descriptionsNoPetsAllowed),
            ),
            descriptionNoPetsAllowedTabIndex: new UntypedFormControl(0),
            currencyCode: new UntypedFormControl(policyConfig.currencyCode),
            uiPaymentPolicies: new UntypedFormArray((policyConfig.uiPaymentPolicies || [])
                .map(paymentPolicy => this.getPetsPaymentPolicyControls(paymentPolicy, languages))),
        }, [ this.petsValidators.petsPolicyValidation() ] );
    }

    getPetsPaymentPolicyControls(paymentPolicy: PetsPaymentPolicy, languages: CodeTitle[]): UntypedFormGroup {
        return new UntypedFormGroup({
            petsType: new UntypedFormControl(paymentPolicy.petsType),
            amount: new UntypedFormControl(paymentPolicy.amount),
            descriptions: new UntypedFormArray(
                this.formHelpersService.getDescriptionControlsByLang(languages, paymentPolicy.descriptions),
            ),
            descriptionTabIndex: new UntypedFormControl(0),
        });
    }

}
