import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { RouteHelpers } from '@bmng/helpers/route-helpers';
import { ContextType } from '@bmng/services/context/interfaces/context-type.type';
import { RateServiceLimitType, RateServicePriceType } from '@bmng/services/rate-services/interfaces/rate-services.interface';
import { ResContext, ResError, ResGuestInfo, ResPricePerDay, ResPricePerDayDate, ResRoom, ResService } from '@bmng/services/reservations/interfaces/reservations.interface';
import { StaticInfo } from '@bmng/services/static-info/interfaces/static-info.interface';
import { StaticInfoService } from '@bmng/services/static-info/static-info.service';
import { LangService } from '@kognitiv/angular-i18n';
import { SelectOption } from '@kognitiv/bm-components';
import { MomentService } from '@kognitiv/bm-components';

import { EndpointService } from '@bmng/services/endpoint.service';
import { Context } from '@bmng/services/context/interfaces/context.interface';
import { RateServicesHelpers } from '../../rate-services/helpers/rate-services-helpers';

@Injectable()
export class ReservationsHelpers {
    moment = MomentService.get();

    staticData: StaticInfo;

    constructor(
        private lang: LangService,
        public router: Router,
        private servicesHelpers: RateServicesHelpers,
        private staticInfoService: StaticInfoService,
    ) {
        this.staticData = this.staticInfoService.getStaticInfo();
    }

    getNameWithCode(code: string, name?: string): string {
        return name ? name + ' (' + code + ')' : code;
    }

    hasAction(action: string | string[], possible: string[]): boolean {
        if (typeof possible === 'undefined') {
            return false;
        }
        let hasAction: boolean = false;

        if (typeof action === 'string') {
            hasAction = (possible.indexOf(action) > -1);
        } else if (typeof action === 'object' && action.length) {
            let _hasAction: boolean = false;
            action.forEach(_action => {
                if (possible.indexOf(_action) > -1) {
                    _hasAction = true;
                }
            });

            if (_hasAction) {
                hasAction = true;
            }
        }

        return hasAction;
    }

    isStatus(status: string | string[], resStatus: string): boolean {
        let isStatus: boolean = false;

        if (typeof status === 'string') {
            isStatus = (status === resStatus);
        } else if (typeof status === 'object' && status.length) {
            isStatus = (status.indexOf(resStatus) > -1);
        }

        return isStatus;
    }

    generateReservationLink(reservationId: string, hotelId: string, languageCode: string): string {
        return `${EndpointService.getBmBackendUrl()}/reservation/email/hotelier/${languageCode}/${hotelId}/${reservationId}`;
    }

    fillArray(length: number): number[] {
        if (typeof length === 'string') {
            length = parseInt(length, 10);
        }
        const arr = Array(length).fill(0).map((x, i) => i);
        return arr;
    }

    getChildAgeSelect(): SelectOption[] {
        const selectData: SelectOption[] = [];
        const min = 0;
        const max = 17;
        const yearLabel = this.lang.get('portal.search.occupancy.year');
        const yearsLabel = this.lang.get('portal.search.occupancy.years');

        for (let i = min; i <= max; i++) {
            selectData.push({
                id: i,
                text: i.toString() + ' ' + (i === 1 ? yearLabel : yearsLabel),
            });
        }

        return selectData;
    }

    getQuantitySelect(max: number = 99, min: number = 1): SelectOption[] {
        const selectData: SelectOption[] = [];
        if (!max || !(max >= 1)) {
            return selectData;
        }

        for (let i = min; i <= max; i++) {
            selectData.push({
                id: i,
                text: i.toString(),
            });
        }

        return selectData;
    }

    guestHasAdditionalDetails(guestInfo: ResGuestInfo): boolean {
        if (guestInfo.phone || guestInfo.birthDate) {
            return true;
        } else if (guestInfo.address) {
            if (
                guestInfo.address.addressLine ||
                guestInfo.address.addressLine2 ||
                guestInfo.address.city ||
                guestInfo.address.zip ||
                guestInfo.address.countryCode
            ) {
                return true;
            }
        }

        return false;
    }

    hasHTML(str: string): boolean {
        const doc = new DOMParser().parseFromString(str, 'text/html');
        return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isNumber(val: any): val is number {
        return typeof val === 'number';
    }

    cleanUpSubmitObject<T>(obj: T): T {
        if (obj) {
            Object.keys(obj).forEach(_key => {
                if (_key.indexOf('_') === 0) {
                    delete obj[_key];
                }
            });
        }

        return obj;
    }

    renderAddressOnOneLine(address: ResGuestInfo['address']): string {
        const addressArray = [];
        if (address) {
            if (address.addressLine) {
                addressArray.push(address.addressLine);
            }
            if (address.addressLine2) {
                addressArray.push(address.addressLine2);
            }
            if (address.city && address.zip) {
                addressArray.push(`${address.zip} ${address.city}`);
            } else if (address.zip) {
                addressArray.push(address.zip);
            } else if (address.city) {
                addressArray.push(address.city);
            }

            if (address.state) {
                addressArray.push(address.state);
            }

            if (address.countryCode) {
                addressArray.push(this.staticInfoService.getCountryName(address.countryCode));
            }

            return addressArray.join(', ');
        }
    }

    renderGuestDetails(val: ResGuestInfo): string {
        let info: string = '';

        if (val.primary) {
            const primaryContactLabel: string = this.lang.get('com.seekda.cc.general.primary.contact.label');
            info += `<div><span class="bm-badge" color="success">${primaryContactLabel}</span></div>`;
        }
        info += '<div class="guest-info">';
        if (val.email) {
            info += `<div><a href="mailto:${val.email}">${val.email}</a></div>`;
        }
        if (val.phone) {
            const phoneLabel = this.lang.get('com.seekda.cc.general.phone.label');
            info += `<div><small>${phoneLabel}</small>: ${val.phone}</div>`;
        }
        if (val.birthDate) {
            const birthDateLabel = this.lang.get('hotel.setting.personal.info.field.birthdate');
            const birthDate = this.moment(val.birthDate).format('L');
            info += `<div><small>${birthDateLabel}:</small> ${birthDate}</div>`;
        }
        if (val.address) {
            const addressLabel = this.lang.get('title.ibe.gui.address');
            if (val.address.addressLine) {
                info += `<div><small>${addressLabel}</small>: ${val.address.addressLine}</div>`;
            }
            if (val.address.addressLine2) {
                info += `<div><small>${addressLabel}</small>: ${val.address.addressLine2}</div>`;
            }
            if (val.address.city) {
                const cityLabel = this.lang.get('personal.information.city.label');
                info += `<div><small>${cityLabel}</small>: ${val.address.city}</div>`;
            }
            if (val.address.zip) {
                const zipLabel = this.lang.get('personal.information.zip.label');
                info += `<div><small>${zipLabel}</small>: ${val.address.zip}</div>`;
            }
            if (val.address.state) {
                const stateLabel = this.lang.get('com.seekda.cc.general.state.label');
                info += `<div><small>${stateLabel}</small>: ${val.address.state}</div>`;
            }
            if (val.address.countryCode) {
                const countryLabel = this.lang.get('com.seekda.cc.general.country.label');
                const countryName = this.staticInfoService.getCountryName(val.address.countryCode);
                info += `<div><small>${countryLabel}</small>: ${countryName}</div>`;
            }
        }
        info += '</div>';

        return info;
    }

    getTotalServiceAmount(services: ResService[]): number {
        let totalServiceAmount: number = 0;

        if (services && services.length) {
            services.forEach(_s => {
                if (!_s._deleted) {
                    totalServiceAmount += _s.priceTotal;
                }
            });
        }

        return totalServiceAmount;
    }

    getTotalRoomAmount(rooms: ResRoom[]): number {
        let totalRoomAmount: number = 0;

        if (rooms && rooms.length) {
            rooms.forEach(_r => {
                if (!_r._deleted) {
                    totalRoomAmount += _r.price;
                }
            });
        }

        return totalRoomAmount;
    }

    getPersonsCount(rooms: ResRoom[]): number {
        let count: number = 0;

        if (rooms && rooms.length) {
            rooms.forEach(_room => {
                count += _room.guests.adults;
                count += _room.guests.children;
            });
        }

        return count;
    }

    getShoulderRateDates(checkIn: string, checkOut: string, start: string, end: string): string {
        let dates = '';
        const dateFormat = 'YYYY-MM-DD';
        const mCheckIn = this.moment(checkIn, dateFormat);
        const mCheckOut = this.moment(checkOut, dateFormat);
        const mStart = this.moment(start, dateFormat);
        const mEnd = this.moment(end, dateFormat);
        const diffBegin = mStart.diff(mCheckIn, 'days');
        const diffEnd = mCheckOut.diff(mEnd, 'days');
        if (diffBegin === 1) {
            dates += `${mCheckIn.format('L')}`;
        } else if (diffBegin > 1) {
            dates += `${mCheckIn.format('L')} - ${mStart.subtract(1, 'days').format('L')}`;
        }
        if (diffBegin && diffEnd) {
            dates += ', ';
        }
        if (diffEnd === 1) {
            dates += `${mCheckOut.format('L')}`;
        } else if (diffEnd > 1) {
            dates += `${mEnd.add(1, 'days').format('L')} - ${mCheckOut.format('L')}`;
        }

        return dates;
    }

    getDurationFromNumber(nr: number, type: 'minutes' | 'hours'): string {
        if (nr) {
            return this.moment.duration(nr, type).humanize();
        }
    }

    getPerDayPriceDates(checkIn: string, lengthOfStay: number): ResPricePerDayDate[] {
        if (!checkIn) {
            return [];
        }

        const perDayPriceDates: ResPricePerDayDate[] = [];
        const momentCheckIn = this.moment(checkIn, 'YYYY-MM-DD');

        for (let index = 0; index < lengthOfStay; index++) {
            const momentDay = momentCheckIn.clone().add(index, 'd');

            const dayDate: ResPricePerDayDate = {
                day: momentDay.format('dd'),
                date: momentDay.format('L'),
            };

            perDayPriceDates.push(dayDate);
        }

        return perDayPriceDates;
    }

    getErrorMessage(error: ResError[]): string {
        let errorMessage: string = this.lang.get('ibe.generic.error.message');

        if (error && error.length) {
            errorMessage = `${error[0].message} (${error[0].code})`;
        }

        return errorMessage;
    }

    scrollToElement(element: string): void {
        window.scrollTo(0, 5000);
    }

    getActiveContextType(route: ActivatedRoute): ContextType {
        let contextType = RouteHelpers.getActiveContextFromRoute(route.snapshot).type;

        if (contextType === null) {
            contextType = 'global';
        }

        return contextType;
    }

    getGenericContextData(snapshot: ActivatedRouteSnapshot): ResContext {
        const activeContext = RouteHelpers.getActiveContextFromRoute(snapshot);
        const fullContext: Context = RouteHelpers.getDataFromRoute(snapshot, 'context');
        const hotelId = this.getContextHotelId(snapshot);

        if (activeContext.type === 'global') {
            return {
                contextType: 'global',
                contextId: null,
                hotelId,
            };
        } else if (activeContext.type === 'hotel') {
            return {
                contextType: 'hotel',
                contextId: null,
                hotelId,
            };
        } else if (activeContext.type === 'unit') {
            // In the unit's reservation page, we display data from the parent (= master or portal)
            return {
                contextType: fullContext.channel.type,
                contextId: fullContext.channel.id,
                hotelId,
            };
        } else {
            return {
                contextType: activeContext.type,
                contextId: activeContext.id,
                hotelId,
            };
        }
    }

    private getContextHotelId(snapshot: ActivatedRouteSnapshot): string {
        /**
         * `reservationHotelId` is from the route part of the reservation, ie /portal/:portalId/reservations/:reservationHotelId/...
         * `hotelIdFromRoute` is if we're in an actual hotel subcontext, ie /portal/:portalId/hotel/:hotelId/reservations/...
         * `hotelIdFromContext` if we're in a channel context with a hotel, ie from a circle.
         */
        const fullContext: Context = RouteHelpers.getDataFromRoute(snapshot, 'context');

        const reservationHotelId = RouteHelpers.getParamFromRoute(snapshot, 'reservationHotelId', '');
        const hotelIdFromRoute = RouteHelpers.getParamFromRoute(snapshot, 'hotelId', '');
        const hotelIdFromContext = fullContext?.hotel?.id;

        return reservationHotelId || hotelIdFromRoute || hotelIdFromContext;
    }

    applyDiscountToPerDayPrices(prices: ResPricePerDay[], discount: number): ResPricePerDay[] {
        if (!prices || !prices.length || !discount || isNaN(discount)) {
            return [];
        }
        const discountedPrices: ResPricePerDay[] = [];

        prices.forEach(_price => {
            const discountAmount = _price.totalAmount * discount / 100;
            const totalDiscounted = _price.totalAmount - discountAmount;

            discountedPrices.push({
                discountAmount,
                totalAmount: totalDiscounted,
            });
        });

        return discountedPrices;
    }

    getPriceTypeLabel(priceBase: RateServicePriceType): string {
        if (priceBase === null) {
            priceBase = '_Unit';
        }
        const priceTypeLabelMap = this.servicesHelpers.getPriceTypeLabelMap();

        return priceTypeLabelMap[priceBase] || '';
    }

    getLimitTypeLabel(limitType: RateServiceLimitType, priceBase: RateServicePriceType, maxPerBooking: number): string | null {
        if (priceBase === null) {
            priceBase = '_Unit';
        }
        if (limitType === null) {
            if (maxPerBooking === -1) {
                limitType = '_None';
            } else {
                return `${this.lang.get('bm.service.limit.per.booking')}: ${maxPerBooking}`;
            }
        }
        const priceTypeMap = this.servicesHelpers.getPriceTypeMap();
        const priceTypeObject = priceTypeMap[priceBase];
        if (priceTypeObject) {
            const limitObject = priceTypeObject.options.find(_opt => _opt.id === limitType);
            if (limitObject) {
                return limitObject.text;
            }
        }

        return null;
    }

    getServiceQuantityLabel(priceBase: RateServicePriceType, limitType: RateServiceLimitType): string | null {
        switch (priceBase) {
            case 'Person':
                if (limitType === 'Person') {
                    return this.lang.get('search.result.body.persons');
                } else {
                    return null;
                }
            case 'PersonNight':
                if (limitType === 'Person') {
                    return this.lang.get('search.result.body.persons');
                } else if (limitType === 'Night') {
                    return this.lang.get('dynamic.shop.precheckout.nights.label');
                } else {
                    return null;
                }
            case 'Room':
                if (limitType === 'Room') {
                    return this.lang.get('search.result.rooms');
                } else {
                    return null;
                }
            case 'RoomNight':
                if (limitType === 'Room') {
                    return this.lang.get('search.result.rooms');
                } else if (limitType === 'Night') {
                    return this.lang.get('dynamic.shop.precheckout.nights.label');
                } else {
                    return null;
                }
            case null:
                return this.lang.get('cc.reservation.details.service.quantity');
            default:
                return null;
        }
    }

    calculateServiceUnitPriceFromMultiplied(
        price: number,
        priceBase: RateServicePriceType,
        limitType: RateServiceLimitType,
        persons: number,
        nights: number,
        rooms: number,
    ): number {
        let calcUnitPrice: number;
        switch (priceBase) {
            case 'Person':
                if (limitType === 'Person') {
                    calcUnitPrice =  price;
                } else {
                    calcUnitPrice =  price / persons;
                }
                break;

            case 'PersonNight':
                if (limitType === 'Person') {
                    calcUnitPrice =  price / nights;
                } else if (limitType === 'Night') {
                    calcUnitPrice = price / persons;
                } else {
                    calcUnitPrice = price / persons / nights;
                }
                break;

            case 'Room':
                if (limitType === 'Room') {
                    calcUnitPrice = price;
                } else {
                    calcUnitPrice = price / rooms;
                }
                break;

            case 'RoomNight':
                if (limitType === 'Room') {
                    calcUnitPrice =  price / nights;
                } else if (limitType === 'Night') {
                    calcUnitPrice = price / rooms;
                } else {
                    calcUnitPrice = price / rooms / nights;
                }
                break;
            default:
                calcUnitPrice = price;
        }

        return calcUnitPrice;
    }

    calculateServiceMultipliedPrice(
        unitPrice: number,
        priceBase: RateServicePriceType,
        limitType: RateServiceLimitType,
        persons: number,
        nights: number,
        rooms: number,
    ): {
        price: number;
        label: string;
    } {
        let calcUnitPrice: number;
        let calcLabel: string;

        switch (priceBase) {
            case 'Person':
                if (limitType === 'Person') {
                    calcUnitPrice =  unitPrice;
                    calcLabel = this.lang.get('bm.service.priceType.perGuest');
                } else {
                    calcUnitPrice =  unitPrice * persons;
                    calcLabel =  this.lang.get('bm.service.price.for.x.persons', persons);
                }
                break;

            case 'PersonNight':
                if (limitType === 'Person') {
                    calcUnitPrice =  unitPrice * nights;
                    calcLabel =  this.lang.get('bm.service.price.pperson.for.x.nights', nights);
                } else if (limitType === 'Night') {
                    calcUnitPrice = unitPrice * persons;
                    calcLabel = this.lang.get('bm.service.price.for.x.persons.pnight', persons);
                } else {
                    calcUnitPrice = unitPrice * persons * nights;
                    calcLabel = this.lang.get('bm.service.price.for.x.persons.for.y.nights', persons, nights);
                }
                break;

            case 'Room':
                if (limitType === 'Room') {
                    calcUnitPrice = unitPrice;
                    calcLabel = this.lang.get('bm.service.priceType.perRoom');
                } else {
                    calcUnitPrice = unitPrice * rooms;
                    calcLabel = this.lang.get('bm.service.price.for.x.rooms', rooms);
                }
                break;

            case 'RoomNight':
                if (limitType === 'Room') {
                    calcUnitPrice =  unitPrice * nights;
                    calcLabel =  this.lang.get('bm.service.price.proom.for.x.nights', nights);
                } else if (limitType === 'Night') {
                    calcUnitPrice = unitPrice * rooms;
                    calcLabel = this.lang.get('bm.service.price.for.x.rooms.pnight', rooms);
                } else {
                    calcUnitPrice = unitPrice * rooms * nights;
                    calcLabel = this.lang.get('bm.service.price.for.x.rooms.for.y.nights', rooms, nights);
                }
                break;
            default:
                calcUnitPrice =  unitPrice;
                calcLabel = this.lang.get('bm.service.priceType.perUnit');
        }

        return {
            price: calcUnitPrice,
            label: calcLabel,
        };
    }
}
