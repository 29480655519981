import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AvailabilityBulkUpdateDateAndSeasonComponent } from '@bmng/pages/master/availability-bulk-update/availability-bulk-update-page/availability-bulk-update-date-and-season/availability-bulk-update-date-and-season.component';
import { DynamicAllocationsDisabledDatesPipe } from '@bmng/pages/master/dynamic-allocations/detail/dynamic-allocation-travel-window/dynamic-allocation-disabled-dates.pipe';
import { DayClassPipe } from '@bmng/pages/master/power-grid/pipes/day-class.pipe';
import { DayOfMonthPipe } from '@bmng/pages/master/power-grid/pipes/day-of-month.pipe';
import { TextOverflowEllipsisPipe } from '@bmng/pages/master/power-grid/pipes/text-overflow-ellipsis.pipe';
import { ToColumnWidthPipe } from '@bmng/pages/master/power-grid/pipes/to-column-width.pipe';
import { WeekdayPipe } from '@bmng/pages/master/power-grid/pipes/weekday.pipe';
import { PowerGridLegendComponent } from '@bmng/pages/master/power-grid/power-grid/power-grid-legend/power-grid-legend.component';
import { RateBulkUpdateModalRestrictionsComponent } from '@bmng/pages/master/power-grid/power-grid/rate-bulk-update-modal/rate-bulk-update-modal-restrictions/rate-bulk-update-modal-restrictions.component';
import { ReservationsHelpers } from '@bmng/pages/master/reservations/helpers/reservations-helpers';
import { RewardsCurrencyFormatPipe } from '@bmng/pages/master/rewards/pipes/rewards-currency-format.pipe';
import { RewardsCurrencyNamePipe } from '@bmng/pages/master/rewards/pipes/rewards-currency.name.pipe';
import { CalendarDaywiseWidthDirective } from '@bmng/pages/master/yearly-overview/yearly-overview-calendar/yearly-overview-calendar-daywise-width/calendar-daywise-width.directive';
import { CodeGeneratorDirective } from '@bmng/services/code-generator/code-generator.directive';
import { ActionBarDesktopButtonComponent } from '@bmng/shared/components/action-bar/action-bar-desktop-button/action-bar-desktop-button.component';
import { ActionBarDesktopComponent } from '@bmng/shared/components/action-bar/action-bar-desktop/action-bar-desktop.component';
import { DeleteConfirmationModalComponent } from '@bmng/shared/components/delete-confirmation-modal/delete-confirmation-modal.component';
import { PageRootEmbeddedComponent } from '@bmng/shared/components/page-root-embedded/page-root-embedded.component';
import { PageRootComponent } from '@bmng/shared/components/page-root/page-root.component';
import { PreviewModalComponent } from '@bmng/shared/components/preview-modal/preview-modal.component';
import { PdaStatusBadgeRendererComponent } from '@bmng/shared/components/status-badge-renderers/pda-status.renderer.helper';
import { ReservationStatusBadgeRendererComponent, ReservationStatusHelpers } from '@bmng/shared/components/status-badge-renderers/reservation-status.renderer.helper';
import { AutofocusDirective } from '@bmng/shared/directives/autofocus.directive';
import { ImgToBannerPipe } from '@bmng/shared/pipes/img-to-banner.pipe';
import { ImgToFaviconPipe } from '@bmng/shared/pipes/img-to-favicon.pipe';
import { PoliciesSummaryHelper } from '@bmng/shared/services/policies-summary.helper';
import { LangModule } from '@kognitiv/angular-i18n';
import { BMSTYLE_UPLOAD_SERVICE, BMSTYLE_UPLOAD_VALUE_HOLDER, BmComponentsModule } from '@kognitiv/bm-components';

import { ImageUploadService } from '@bmng/services/upload/image-upload.service';
import { ImageValueHolder } from '@bmng/services/upload/image-value-holder.class';
import { AvailabilityBulkUpdateModalComponent } from './../pages/master/power-grid/power-grid/availability-bulk-update-modal/availability-bulk-update-modal.component';
import { AccessRestrictionInfoComponent } from './components/access-restriction/access-restriction-info-component/access-restriction-info/access-restriction-info.component';
import { AccessRestrictionSelectComponent } from './components/access-restriction/access-restriction-select/access-restriction-select.component';
import { AccessRestrictionSummaryComponent } from './components/access-restriction/access-restriction-summary/access-restriction-summary.component';
import { ActionBarDesktopBreadcrumbComponent } from './components/action-bar/action-bar-desktop-breadcrumb/action-bar-desktop-breadcrumb.component';
import { ActionBarDesktopEditPreviewToggleComponent } from './components/action-bar/action-bar-desktop-edit-preview-toggle/action-bar-desktop-edit-preview-toggle.component';
import { BmConfirmationModalComponent } from './components/bm-confirmation-modal/bm-confirmation-modal.component';
import { BmSeasonSelectComponent } from './components/bm-season-select/bm-season-select.component';
import { BookingOffsetRestrictionsDemonstrationComponent } from './components/booking-offset-restrictions-demonstration/booking-offset-restrictions-demonstration.component';
import { BookingOffsetRestrictionsComponent } from './components/booking-offset-restrictions/booking-offset-restrictions.component';
import { ChannelIconComponent } from './components/channel-icon/channel-icon.component';
import { CommissionSelectComponent } from './components/commission-select/commission-select.component';
import { ConfirmPageLeaveModalComponent } from './components/confirm-page-leave-modal/confirm-page-leave-modal.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicFormUIService } from './components/dynamic-form/dynamic-form.ui.service';
import { FirstUseScreenComponent } from './components/first-use-screen/first-use-screen.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderIconsService } from './components/header/header-icons.service';
import { HeaderComponent } from './components/header/header.component';
import { UserInfoComponent } from './components/header/user-info/user-info.component';
import { ImageSelectorControlComponent } from './components/image-selector/image-selector-control/image-selector-control.component';
import { ImageSelectorComponent } from './components/image-selector/image-selector.component';
import { ImageViewComponent } from './components/image-view/image-view.component';
import { InfoBannerComponent } from './components/info-banner/info-banner.component';
import { InputNoteComponent } from './components/input-note/input-note.component';
import { KubeUpsellLightboxComponent } from './components/kube-upsell-lightbox/kube-upsell-lightbox.component';
import { KubeUpsellLightboxModule } from './components/kube-upsell-lightbox/kube-upsell-lightbox.module';
import { BrandingLogoPipe } from './components/menu/branding-logo.pipe';
import { MainMenuItemComponent } from './components/menu/main-menu-item/main-menu-item.component';
import { MenuItemLeafComponent } from './components/menu/menu-item-leaf/menu-item-leaf.component';
import { MenuComponent } from './components/menu/menu.component';
import { OldBmUrlPipe } from './components/menu/old-bm-url.pipe';
import { SubMenuItemComponent } from './components/menu/sub-menu-item/sub-menu-item.component';
import { SubSubMenuItemComponent } from './components/menu/sub-sub-menu-item/sub-sub-menu-item.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PolicyInfoComponent } from './components/policy/policy-info/policy-info.component';
import { PolicySelectComponent } from './components/policy/policy-select/policy-select.component';
import { RateServiceIndicatedDiscountExampleComponent } from './components/rate-service-indicated-discount-example/rate-service-indicated-discount-example.component';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { RestrictionsForChainComponent } from './components/restrictions-for-chain/restrictions-for-chain.component';
import { SelectPropertiesRestrictionComponent } from './components/restrictions-for-chain/select-properties-restriction/select-properties-restriction.component';
import { HasRightDirective } from './components/rights-directives/has-right.directive';
import { SuperAdminOnlyDirective } from './components/rights-directives/super-admin-only.directive';
import { SuperAdminOrViewerDirective } from './components/rights-directives/super-admin-or-viewer.directive';
import { RoomsRatesSelectComponent } from './components/rooms-rates-select/rooms-rates-select.component';
import { SelectRatesRestrictionComponent } from './components/rooms-rates-select/select-rates-restriction/select-rates-restriction.component';
import { SelectRoomsRestrictionComponent } from './components/rooms-rates-select/select-rooms-restriction/select-rooms-restriction.component';
import { PdaStatusHelpers } from './components/status-badge-renderers/pda-status.renderer.helper';
import { ReportStatusBadgeRendererComponent, ReportStatusHelpers } from './components/status-badge-renderers/report-status.renderer.helper';
import { UserStatusHelpers, UserStatusRendererComponent } from './components/status-badge-renderers/user-status.renderer.helper';
import { WaitingListStatusBadgeRendererComponent, WaitingListStatusHelpers } from './components/status-badge-renderers/waitinglist-status.renderer.helper';
import { TableSelectorComponent } from './components/table-selector/table-selector.component';
import { TranslationHintsComponent } from './components/translation-hints/translation-hints.component';
import { UsedBySummaryComponent } from './components/used-by-summary/used-by-summary.component';
import { ChannelNamePipe } from './pipes/channel-name.pipe';
import { ContractAdminUrlPipe } from './pipes/contract-admin-url.pipe';
import { ContractSummaryPipe } from './pipes/contract-summary.pipe';
import { FilterForCustomMessagesPipe } from './pipes/filter-for-custom-messages.pipe';
import { FilterSourcePipe } from './pipes/filter-source.pipe';
import { GuestsPipe } from './pipes/guests.pipe';
import { HasPassedPipe } from './pipes/has-passed.pipe';
import { ImageCategoryPipe } from './pipes/images/img-category.pipe';
import { ImageFallbackPipe } from './pipes/images/img-fallback.pipe';
import { ImageUrlPipe } from './pipes/images/img-url.pipe';
import { IncludesElementPipe } from './pipes/includes-element.pipe';
import { IsChannelEnabledPipe } from './pipes/is-channel-enabled.pipe';
import { IsTruthyPipe } from './pipes/is-truthy.pipe';
import { JoinTokensPipe } from './pipes/join-tokens.pipe';
import { MapSourcePipe } from './pipes/map-source.pipe';
import { MealPlanNamePipe } from './pipes/meal-plan-name.pipe';
import { NaPipe } from './pipes/na.pipe';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { ObjectKeysPipe } from './pipes/object-keys.pipe';
import { PickTranslatedStringPipe } from './pipes/pick-translated-string.pipe';
import { PmsChannelAlertPipe } from './pipes/pms-channels-alert.pipe';
import { PolicyBookingPaymentSummaryPipe } from './pipes/policy-booking-payment-summary.pipe';
import { PolicyCancellationPaymentSummaryPipe } from './pipes/policy-cancellation-payment-summary.pipe';
import { PolicyTaxPaymentSummaryPipe } from './pipes/policy-tax-payment-summary.pipe';
import { PullChannelAlertPipe } from './pipes/pull-channel-alert.pipe';
import { PullChannelTypeFilterPipe } from './pipes/pull-channel-type-filter.pipe';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { UpdateablePmsChannelsPipe } from './pipes/updateable-pms-channels.pipe';
import { UserAvatarPipe } from './pipes/user-avatar.pipe';
import { BookingOffsetRestrictionValidators } from './services/booking-offset-restrictions.validator';
import { ExpiredDateRangeHelper } from './services/expired-date-range.helper';
import { PoliciesHelpers } from './services/policies-helpers';
import { PoliciesTextHelpers } from './services/policies-text-helpers';
import { PolicyFormBookingSubmitService } from './services/policy-form-service/booking/policy-form-booking-submit.service';
import { PolicyFormBookingUiService } from './services/policy-form-service/booking/policy-form-booking-ui.service';
import { PolicyFormBookingValidators } from './services/policy-form-service/booking/policy-form-booking-validators.service';
import { PolicyFormBookingService } from './services/policy-form-service/booking/policy-form-booking.service';
import { PolicyFormCancellationSubmitService } from './services/policy-form-service/cancellation/policy-form-cancellation-submit.service';
import { PolicyFormCancellationUiService } from './services/policy-form-service/cancellation/policy-form-cancellation-ui.service';
import { PolicyFormCancellationValidators } from './services/policy-form-service/cancellation/policy-form-cancellation-validators.service';
import { PolicyFormCancellationService } from './services/policy-form-service/cancellation/policy-form-cancellation.service';
import { PolicyFormCleanupSubmitService } from './services/policy-form-service/cleanup/policy-form-cleanup-submit.service';
import { PolicyFormCleanupUiService } from './services/policy-form-service/cleanup/policy-form-cleanup-ui.service';
import { PolicyFormCleanupService } from './services/policy-form-service/cleanup/policy-form-cleanup.service';
import { PolicyFormPetsSubmitService } from './services/policy-form-service/pets/policy-form-pets-submit.service';
import { PolicyFormPetsUiService } from './services/policy-form-service/pets/policy-form-pets-ui.service';
import { PolicyFormPetsValidators } from './services/policy-form-service/pets/policy-form-pets-validators.service';
import { PolicyFormPetsService } from './services/policy-form-service/pets/policy-form-pets.service';
import { PolicyFormHelpersService } from './services/policy-form-service/policy-form-helpers.service';
import { PolicyFormSubmitService } from './services/policy-form-service/policy-form-submit.service';
import { PolicyFormUiService } from './services/policy-form-service/policy-form-ui.service';
import { PolicyFormValidators } from './services/policy-form-service/policy-form-validators.service';
import { PolicyFormService } from './services/policy-form-service/policy-form.service';
import { PolicyFormTaxSubmitService } from './services/policy-form-service/tax/policy-form-tax-submit.service';
import { PolicyFormTaxUiService } from './services/policy-form-service/tax/policy-form-tax-ui.service';
import { PolicyFormTaxValidators } from './services/policy-form-service/tax/policy-form-tax-validators.service';
import { PolicyFormTaxService } from './services/policy-form-service/tax/policy-form-tax.service';
import { PortalPoliciesTableHelper } from './services/portal-policies-table.helper';
import { ImagePickerComponent } from './components/image-picker/image-picker.component';
import { KubePreviewComponent } from './components/kube-preview/kube-preview.component';
import { KubePreviewCardComponent } from './components/kube-preview/kube-preview-card.component';
import { UserAccountsComponent } from './components/user-accounts/list/user-accounts.component';
import { UserFormService } from './components/user-accounts/user-form.service';
import { UserAccountEditComponent } from './components/user-accounts/edit/user-account-edit.component';
import { UploadTriggerDirective } from './directives/upload-trigger.directive';
import { ImageUploadValidatorsService } from './components/image-selector/image-upload-validators.service';
import { MenuItemIconContainerComponent } from './components/menu/menu-item-icon-container/menu-item-icon-container.component';
import { CookieService } from './services/cookie-service.service';
import { FormFieldTooltipPipe } from './pipes/form-field-tooltip.pipe';
import { ExtractFileNamePipe } from './pipes/extract-file-name.pipe';
import { ReservationTransactionDetailModalComponent } from './components/reservation-transaction-detail-modal/reservation-transaction-detail-modal';
import { ReservationPaymentMethodDetailsComponent } from './components/reservation-payment-method-details/reservation-payment-method-details.component';
import { ReservationPaymentMethodDetailsLineComponent } from './components/reservation-payment-method-details/reservation-payment-method-details-line.component';
import { PaymentProductPipe } from './pipes/payment-product.pipe';
import { ExpiryDatePipe } from './pipes/expiry-date.pipe';
import { DecodeUriComponentPipe } from './pipes/decode-uri-component.pipe';
import { ReservationRefundModalComponent } from './components/reservation-refund-modal/reservation-refund-modal.component';
import { ReservationCancelModalComponent } from './components/reservation-cancel-modal/reservation-cancel-modal.component';
import { ReservationMarkPaymentMethodInvalidModalComponent } from './components/reservation-mark-payment-method-invalid-modal/reservation-mark-payment-method-invalid-modal.component';

@NgModule({
    imports: [
        CommonModule,
        BmComponentsModule,
        RouterModule,
        FormsModule,
        HttpClientModule,
        LangModule.forChild(),
        FormsModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'always' }),
        KubeUpsellLightboxModule.withConfig('noProviders'),
    ],
    declarations: [
        // Components
        PageRootComponent,
        PageRootEmbeddedComponent,

        HeaderComponent,
        UserInfoComponent,
        MenuComponent,
        FooterComponent,
        ActionBarDesktopComponent,
        ActionBarDesktopButtonComponent,
        ActionBarDesktopBreadcrumbComponent,
        ActionBarDesktopEditPreviewToggleComponent,
        ReservationStatusBadgeRendererComponent,
        ReportStatusBadgeRendererComponent,
        PdaStatusBadgeRendererComponent,
        WaitingListStatusBadgeRendererComponent,
        UserStatusRendererComponent,
        BmSeasonSelectComponent,
        FirstUseScreenComponent,
        ImagePickerComponent,

        ReadMoreComponent,
        PreviewModalComponent,

        PaginatorComponent,
        BmConfirmationModalComponent,

        AccessRestrictionSummaryComponent,
        AccessRestrictionInfoComponent,
        AccessRestrictionSelectComponent,

        PowerGridLegendComponent,
        AvailabilityBulkUpdateModalComponent,
        RateBulkUpdateModalRestrictionsComponent,

        AvailabilityBulkUpdateDateAndSeasonComponent,

        PolicySelectComponent,
        PolicyInfoComponent,

        DeleteConfirmationModalComponent,
        ConfirmationModalComponent,
        ConfirmPageLeaveModalComponent,

        DynamicFormComponent,

        MainMenuItemComponent,
        SubMenuItemComponent,
        SubSubMenuItemComponent,
        MenuItemLeafComponent,
        MenuItemIconContainerComponent,

        TableSelectorComponent,
        CommissionSelectComponent,

        BookingOffsetRestrictionsComponent,
        BookingOffsetRestrictionsDemonstrationComponent,

        UserAccountsComponent,
        UserAccountEditComponent,

        // Pipes
        Nl2brPipe,
        NumberFormatPipe,
        ObjectKeysPipe,
        FilterSourcePipe,
        MapSourcePipe,
        NaPipe,
        GuestsPipe,
        ImageUrlPipe,
        ImageCategoryPipe,
        ImgToBannerPipe,
        ImgToFaviconPipe,
        ChannelNamePipe,
        HasPassedPipe,
        ImageFallbackPipe,
        ToColumnWidthPipe,
        DayOfMonthPipe,
        WeekdayPipe,
        DayClassPipe,
        UserAvatarPipe,
        OldBmUrlPipe,
        BrandingLogoPipe,
        TextOverflowEllipsisPipe,
        FilterForCustomMessagesPipe,
        PolicyCancellationPaymentSummaryPipe,
        PolicyBookingPaymentSummaryPipe,
        PolicyTaxPaymentSummaryPipe,
        PmsChannelAlertPipe,
        PullChannelAlertPipe,
        PullChannelTypeFilterPipe,
        UpdateablePmsChannelsPipe,
        IsTruthyPipe,
        IncludesElementPipe,
        RewardsCurrencyNamePipe,
        RewardsCurrencyFormatPipe,
        StripHtmlPipe,
        SentenceCasePipe,
        PickTranslatedStringPipe,
        DynamicAllocationsDisabledDatesPipe,
        MealPlanNamePipe,
        ExtractFileNamePipe,
        DecodeUriComponentPipe,

        PaymentProductPipe,
        ExpiryDatePipe,

        // Directives
        SuperAdminOnlyDirective,
        SuperAdminOrViewerDirective,
        HasRightDirective,
        AutofocusDirective,
        CodeGeneratorDirective,
        CalendarDaywiseWidthDirective,
        UploadTriggerDirective,

        // Components
        ImageSelectorComponent,
        RoomsRatesSelectComponent,
        RestrictionsForChainComponent,
        SelectRoomsRestrictionComponent,
        SelectRatesRestrictionComponent,
        SelectPropertiesRestrictionComponent,
        ImageSelectorControlComponent,
        ImageViewComponent,
        TranslationHintsComponent,
        UsedBySummaryComponent,
        ChannelIconComponent,
        InputNoteComponent,
        RateServiceIndicatedDiscountExampleComponent,
        IsChannelEnabledPipe,
        JoinTokensPipe,
        ContractSummaryPipe,
        ContractAdminUrlPipe,
        InfoBannerComponent,
        KubePreviewComponent,
        KubePreviewCardComponent,
        FormFieldTooltipPipe,

        ReservationTransactionDetailModalComponent,
        ReservationPaymentMethodDetailsComponent,
        ReservationPaymentMethodDetailsLineComponent,
        ReservationRefundModalComponent,
        ReservationCancelModalComponent,
        ReservationMarkPaymentMethodInvalidModalComponent,
    ],
    exports: [
        // Components
        PageRootComponent,
        PageRootEmbeddedComponent,

        HeaderComponent,
        MenuComponent,
        FooterComponent,
        ActionBarDesktopComponent,
        ActionBarDesktopButtonComponent,
        ActionBarDesktopBreadcrumbComponent,
        ActionBarDesktopEditPreviewToggleComponent,
        ReservationStatusBadgeRendererComponent,
        ReportStatusBadgeRendererComponent,
        PdaStatusBadgeRendererComponent,
        WaitingListStatusBadgeRendererComponent,
        UserStatusRendererComponent,
        BmSeasonSelectComponent,
        FirstUseScreenComponent,
        ImagePickerComponent,

        ReadMoreComponent,
        PreviewModalComponent,

        PaginatorComponent,
        BmConfirmationModalComponent,

        AccessRestrictionSummaryComponent,
        AccessRestrictionInfoComponent,
        AccessRestrictionSelectComponent,

        PowerGridLegendComponent,
        AvailabilityBulkUpdateModalComponent,
        RateBulkUpdateModalRestrictionsComponent,

        AvailabilityBulkUpdateDateAndSeasonComponent,

        PolicySelectComponent,
        PolicyInfoComponent,

        ImageSelectorControlComponent,
        RoomsRatesSelectComponent,
        RestrictionsForChainComponent,
        SelectRoomsRestrictionComponent,
        SelectRatesRestrictionComponent,
        SelectPropertiesRestrictionComponent,
        ImageViewComponent,

        DeleteConfirmationModalComponent,
        ConfirmationModalComponent,
        ConfirmPageLeaveModalComponent,

        DynamicFormComponent,

        TableSelectorComponent,

        BookingOffsetRestrictionsComponent,
        BookingOffsetRestrictionsDemonstrationComponent,

        TranslationHintsComponent,
        UsedBySummaryComponent,

        CommissionSelectComponent,
        ChannelIconComponent,
        InputNoteComponent,

        UserAccountsComponent,
        UserAccountEditComponent,
        ImageSelectorComponent,

        ReservationTransactionDetailModalComponent,
        ReservationPaymentMethodDetailsComponent,
        ReservationPaymentMethodDetailsLineComponent,
        ReservationRefundModalComponent,
        ReservationCancelModalComponent,
        ReservationMarkPaymentMethodInvalidModalComponent,

        // Pipes
        Nl2brPipe,
        NumberFormatPipe,
        ObjectKeysPipe,
        FilterSourcePipe,
        MapSourcePipe,
        NaPipe,
        GuestsPipe,
        ImageUrlPipe,
        ImageCategoryPipe,
        ImgToBannerPipe,
        ImgToFaviconPipe,
        ImageFallbackPipe,
        ChannelNamePipe,
        HasPassedPipe,
        FilterForCustomMessagesPipe,
        ToColumnWidthPipe,
        DayOfMonthPipe,
        WeekdayPipe,
        DayClassPipe,
        TextOverflowEllipsisPipe,
        PolicyCancellationPaymentSummaryPipe,
        PolicyBookingPaymentSummaryPipe,
        PolicyTaxPaymentSummaryPipe,
        PmsChannelAlertPipe,
        PullChannelAlertPipe,
        PullChannelTypeFilterPipe,
        UpdateablePmsChannelsPipe,
        IsTruthyPipe,
        IncludesElementPipe,
        IsChannelEnabledPipe,
        JoinTokensPipe,
        ContractSummaryPipe,
        ContractAdminUrlPipe,
        InfoBannerComponent,
        KubePreviewComponent,
        KubePreviewCardComponent,
        RewardsCurrencyNamePipe,
        RewardsCurrencyFormatPipe,
        StripHtmlPipe,
        PickTranslatedStringPipe,
        DynamicAllocationsDisabledDatesPipe,
        MealPlanNamePipe,
        FormFieldTooltipPipe,
        ExtractFileNamePipe,
        PaymentProductPipe,
        ExpiryDatePipe,
        DecodeUriComponentPipe,

        // Directives
        SuperAdminOnlyDirective,
        SuperAdminOrViewerDirective,
        HasRightDirective,
        AutofocusDirective,
        CodeGeneratorDirective,
        CalendarDaywiseWidthDirective,

        // Re-Export Common and Forms Module
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LangModule,
        BmComponentsModule,
        RateServiceIndicatedDiscountExampleComponent,
        KubeUpsellLightboxComponent,
    ],
    providers: [
        Title,
        ImageUrlPipe,
        ImgToBannerPipe,
        ImgToFaviconPipe,
        ChannelNamePipe,
        PolicyCancellationPaymentSummaryPipe,
        PickTranslatedStringPipe,
        ReservationStatusHelpers,
        ReportStatusHelpers,
        PdaStatusHelpers,
        UserStatusHelpers,
        WaitingListStatusHelpers,
        HeaderIconsService,
        ReservationsHelpers,
        PoliciesSummaryHelper,
        PolicyCancellationPaymentSummaryPipe,
        PolicyBookingPaymentSummaryPipe,
        PolicyTaxPaymentSummaryPipe,
        PoliciesTextHelpers,
        PoliciesHelpers,
        PolicyFormBookingUiService,
        PolicyFormService,
        PolicyFormHelpersService,
        PortalPoliciesTableHelper,
        UpdateablePmsChannelsPipe,

        PolicyFormBookingService,
        PolicyFormCancellationService,
        PolicyFormTaxService,
        PolicyFormPetsService,
        PolicyFormCleanupService,

        PolicyFormSubmitService,
        PolicyFormBookingSubmitService,
        PolicyFormCancellationSubmitService,
        PolicyFormTaxSubmitService,
        PolicyFormPetsSubmitService,
        PolicyFormCleanupSubmitService,

        PolicyFormValidators,
        PolicyFormCancellationValidators,
        PolicyFormPetsValidators,
        PolicyFormTaxValidators,
        PolicyFormBookingValidators,

        PolicyFormUiService,
        PolicyFormCancellationUiService,
        PolicyFormTaxUiService,
        PolicyFormPetsUiService,
        PolicyFormCleanupUiService,

        ExpiredDateRangeHelper,

        BookingOffsetRestrictionValidators,

        DynamicFormUIService,

        UserFormService,

        ImageUploadValidatorsService,

        CookieService,

        { provide: BMSTYLE_UPLOAD_VALUE_HOLDER, useClass: ImageValueHolder },
        { provide: BMSTYLE_UPLOAD_SERVICE, useClass: ImageUploadService },

    ],
})
export class SharedModule { }
