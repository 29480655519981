
import { Injectable } from '@angular/core';
import notificationsMock from '@bmng/services/guest-notifications/mock-data/notifications.mock';
import { Observable, of,  throwError } from 'rxjs';

import { GuestNotification, GuestNotificationsOverview, GuestNotificationType } from './interfaces/guest-notifications.interface';
import { GuestNotificationsServiceInterface } from './interfaces/guest-notifications.service.interface';

@Injectable()
export class GuestNotificationsServiceMock implements GuestNotificationsServiceInterface {
    private _notifications = notificationsMock.data;

    errorCode: number;
    get notifications(): GuestNotification[] {
        return this.copy(this._notifications);
    }

    getGuestNotifications(hotelId: string): Observable<GuestNotificationsOverview[]> {
        if (this.errorCode) {
            return this.failObservable();
        }

        const overviews: GuestNotificationsOverview[] = this.notifications
            .map(n => ({
                type: n.type,
                enabled: n.enabled,
            }));
        return of(overviews);
    }
    getGuestNotification(hotelId: string, notificationType: GuestNotificationType): Observable<GuestNotification> {
        if (this.errorCode) {
            return this.failObservable();
        }

        const notification = this.notifications.find(n => n.type === notificationType);
        if (!notification) {
            return throwError([ { code: 404, message: 'Notification not found' } ]);
        }
        return of(notification);
    }
    updateGuestNotification(hotelId: string, notification: GuestNotification): Observable<boolean> {
        if (this.errorCode) {
            return this.failObservable();
        }

        const existing = this.notifications.findIndex(n => n.type === notification.type);
        if (existing === -1) {
            return throwError([ { code: 404, message: 'Notification not found' } ]);
        }
        this.notifications[existing] = notification;
        return of(true);
    }

    private copy<T>(data: T): T {
        return JSON.parse(JSON.stringify(data));
    }
    private failObservable(): Observable<never> {
        return throwError([
            {
                code: this.errorCode,
                message: 'I fail!',
            },
        ]);
    }

    failWith(errorCode: number): void {
        this.errorCode = errorCode;
    }
}
