import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { UserSettings } from '@bmng/services/users/interfaces/user-settings.interface';
import { UsersService } from '@bmng/services/users/users.service';

import { I18nService } from '../services/i18n.service';

export const langResolver: ResolveFn<string> =
    () => {
        const usersService = inject(UsersService);
        const i18nService = inject(I18nService);

        const userSettings: UserSettings = usersService.currentUserSettings.getValue();

        return i18nService.changeLanguage(userSettings);
    };
