import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AnalyticsEvent } from './interfaces/analytics-event.interface';
import { AnalyticsServiceInterface, FormAnalyticsSubscription } from './interfaces/analytics-service.interface';

type EntryValue = number | string | boolean;

type FormEventType = 'save' | 'discard';

interface FormEventPayload {
    type: FormEventType;
    fields: UpdatesStash;
}

interface EntryStash {
    [key: string]: EntryValue | EntryValue[];
}

interface UpdateEntry {
    oldVale?: EntryValue | EntryValue[];
    value: EntryValue | EntryValue[];
}

interface UpdatesStash {
    [key: string]: UpdateEntry;
}

interface FieldUpdate {
    key: string;
    value: EntryValue | EntryValue[];
}

function transformToUpdates(name: string, item: unknown): FieldUpdate[] {
    const results: FieldUpdate[] = [];

    if (item === null || item === undefined) {
        return results;
    } else if (Array.isArray(item)) {
        const items = item as unknown[];

        if (items.length === 0 || items.every(isPrimaryType)) {
            results.push({
                key: name,
                value: !!items ? (items as EntryValue[]) : [],
            });
        } else {
            items.forEach((cur, idx, _) => {
                const nextName = !name ? `${idx}` : `${name}.${idx}`;
                const subResults = transformToUpdates(nextName, cur);
                subResults.forEach(r => results.push(r));
            });
        }
    } else if (typeof item === 'object') {
        const keys = Object.keys(item);
        keys.forEach(key => {
            const val: unknown = item[key];
            const nextName = !name ? `${key}` : `${name}.${key}`;
            const subResults = transformToUpdates(nextName, val);
            subResults.forEach(r => results.push(r));
        });
    } else if (isPrimaryType(item)) {
        results.push({
            key: name,
            value: item as EntryValue,
        });
    }

    return results;
}

function isPrimaryType(item: unknown): boolean {
    return (typeof item === 'number' || typeof item === 'string' || typeof item === 'boolean');
}

function transformUpdatesToStash(updates: FieldUpdate[]): EntryStash {
    const stash: EntryStash = {};
    updates.forEach(update => {
        stash[update.key] = update.value;
    });

    return stash;
}

function calculateDiff(stash: EntryStash, oldValuesStash: EntryStash): UpdatesStash {
    const result = {};

    getKeysOfStashes(stash, oldValuesStash).forEach(key => {
        const value = stash[key];
        const oldValue = oldValuesStash[key];

        const isArray = Array.isArray(value) && Array.isArray(oldValue);
        const isValueDifferent = isArray ? !areArraysEqual(value as unknown[], oldValue as unknown[]) : value !== oldValue;

        if (isValueDifferent) {
            result[key] = { oldValue, value };
        }
    });

    return result;
}

function getKeysOfStashes(...stashes: EntryStash[]): string[] {
    const keys: { [key: string]: boolean } = {};

    (stashes || []).forEach(stash => {
        Object.keys(stash || {}).forEach(k => keys[k] = true);
    });

    return Object.keys(keys);
}

function areArraysEqual(val: unknown[], val2: unknown[]): boolean {
    for (let i = 0; i < val.length; i++) {
        if (val[i] !== val2[i]) {
            return false;
        }
    }

    return true;
}

export class AutoSubscribingFormAnalyticsSubscription<TForm, TTrack> implements FormAnalyticsSubscription {
    private readonly subscription = new Subscription();
    private currentRawFormValue: TTrack;
    private initialValuesStash: EntryStash = {};

    constructor(
        private entityId: string,
        private analyticsSrv: AnalyticsServiceInterface,
        private form: UntypedFormGroup,
        private transform: (input: TForm) => TTrack,
    ) {
        this.updateInitialValues();

        this.subscription.add(form.valueChanges.subscribe(_ => {
            const inputData = this.form.getRawValue();

            if (!!this.transform && typeof this.transform === 'function') {
                this.currentRawFormValue = this.transform(inputData);
            } else {
                this.currentRawFormValue = inputData;
            }
        }));
    }

    private updateInitialValues(): void {
        const formValues = this.form.getRawValue();
        const valuesAsUpdates = transformToUpdates('', formValues);
        this.initialValuesStash = transformUpdatesToStash(valuesAsUpdates);
    }

    resetChanges(): void {
        if (!!this.currentRawFormValue) {
            this.analyticsSrv.track(this.getCurrentAnalyticsEvent('discard'));
        }

        this.currentRawFormValue = null;
    }

    trackChanges(): Promise<boolean> {
        if (!!this.currentRawFormValue) {
            return this.analyticsSrv.track(this.getCurrentAnalyticsEvent('save'));
        }

        return new Promise((resolve, _) => resolve(false));
    }

    private getCurrentAnalyticsEvent(type: FormEventType): AnalyticsEvent<FormEventPayload> {
        return {
            collection: 'form.events',
            entityId: this.entityId,
            data: this.wrapCurrentDiffAsTrackingPayload(type),
        };
    }

    private wrapCurrentDiffAsTrackingPayload(type: FormEventType): FormEventPayload {
        let diffUpdates: UpdatesStash = {};

        if (!!this.currentRawFormValue) {
            const currentValueStash = transformUpdatesToStash(transformToUpdates('', this.currentRawFormValue));
            diffUpdates = calculateDiff(currentValueStash, this.initialValuesStash);
        }

        const payload: FormEventPayload = {
            type,
            fields: diffUpdates,
        };

        return payload;
    }

    unsubscribe(): void {
        this.analyticsSrv = null;
        this.subscription.unsubscribe();
    }
}
