import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { FormHelpers } from '@bmng/helpers/form-helpers';
import { LangService } from '@kognitiv/angular-i18n';

@Injectable()
export class BookingOffsetRestrictionValidators {
    constructor(private readonly langService: LangService) { }

    getBookingOffsetValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            const type = control.get('type').value;
            const setLastMinuteNullErrors = () => {
                [ 'lastMinute.days', 'lastMinute.hour' ].forEach(ctrlName => control.get(ctrlName).setErrors(null));
            };
            const setEarlyBirdNullErrors = () => {
                [ 'earlyBird.days', 'earlyBird.hour' ].forEach(ctrlName => control.get(ctrlName).setErrors(null));
            };

            if (type === 'EARLY_BIRD') {
                setLastMinuteNullErrors();
            }

            if (type === 'LAST_MINUTE') {
                setEarlyBirdNullErrors();
            }

            if (type === 'NONE') {
                setLastMinuteNullErrors();
                setEarlyBirdNullErrors();
            }

            if (type !== 'EARLY_BIRD_AND_LAST_MINUTE') {
                return null;
            }

            const earlyBirdDayVal = control.get('earlyBird.days').value;
            const lastMinDayVal = control.get('lastMinute.days').value;

            if (lastMinDayVal > earlyBirdDayVal) {
                return null;
            }

            return {
                overlappingEarlyBirdLastMinutePeriod: this.langService.get('hotel.rateManager.error.overlappingAdvanceMinMaxBooing'),
            };
        };
    }

    earlyBirdValidators(bookingOffsetTypeCtrl: UntypedFormControl) {
        return FormHelpers.getConditionalValidator(
            () => [ 'EARLY_BIRD', 'EARLY_BIRD_OR_LAST_MINUTE', 'EARLY_BIRD_AND_LAST_MINUTE' ].includes(bookingOffsetTypeCtrl.value),
            [ Validators.required, Validators.min(0), Validators.max(999) ],
        );
    }

    lastMinuteValidators(bookingOffsetTypeCtrl: UntypedFormControl) {
        return FormHelpers.getConditionalValidator(
            () => [ 'LAST_MINUTE', 'EARLY_BIRD_OR_LAST_MINUTE', 'EARLY_BIRD_AND_LAST_MINUTE' ].includes(bookingOffsetTypeCtrl.value),
            [ Validators.required, Validators.min(0), Validators.max(999) ],
        );
    }
}
