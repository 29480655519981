import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { bmLoginGuardServiceCanActivate } from '@bmng/core/guards/bm-login-guard.service';
import { loginGuardServiceCanActivate } from '@bmng/core/guards/login-guard.service';
import { contextResolver } from '@bmng/core/resolvers/context-resolver.service';
import { langResolver } from '@bmng/core/resolvers/lang-resolver.service';
import { unsetLangResolver } from '@bmng/core/resolvers/unset-lang-resolver.service';
import { PageRootEmbeddedComponent } from '@bmng/shared/components/page-root-embedded/page-root-embedded.component';
import { PageRootComponent } from '@bmng/shared/components/page-root/page-root.component';

import { bmLangAgnosticLoginGuardServiceCanActivate } from './core/guards/bm-lang-agnostic-login-guard.service';
import { cpcChainGuardServiceCanActivate } from './core/guards/cpcchain-guard.service';
import { oldCmLocalhostGuardCanActivate } from './core/guards/old-cm-localhost-guard.service';
import { subContextGuardServiceCanActivateChild } from './core/guards/sub-context-guard.service';
import { wildcardRouteGuardCanActivate } from './core/guards/wildcard-route-guard.service';
import { menuResolver } from './core/resolvers/menu-resolver.service';
import { routerDataResolver } from './core/resolvers/router-data-resolver.service';
import { staticInfoResolver } from './core/resolvers/static-info-resolver.service';
import { ErrorComponent } from './pages/global/error/error.component';

const routeChildren: { [T in 'unsecured' | 'global' | 'master' | 'portal' | 'cpcchain' | 'circle']: Route[] } = {
    unsecured: [
        {
            path: '',
            redirectTo: '/home',
            pathMatch: 'full',
        },
        {
            path: 'login',
            loadChildren: () => import('./pages/global/login/login.module').then(m => m.LoginModule),
            resolve: { lang: unsetLangResolver },
        },
        {
            matcher: url => {
                if (url.length > 0 && url[0].path === '~') {
                    return { consumed: url };
                }

                return null;
            },
            canActivate: [ oldCmLocalhostGuardCanActivate ],
            // The activation guard does a redirect, error component is just a fallback ...
            component: ErrorComponent,
            resolve: { lang: unsetLangResolver },
        },
    ],
    global: [
        {
            path: '',
            data: { contextType: 'global' },
            loadChildren: () => import('./pages/global/global.module').then(m => m.GlobalModule),
            resolve: {
                lang: langResolver,
                context: contextResolver,
                staticInfo: staticInfoResolver,
                menu: menuResolver,
            },
        },
    ],
    portal: [
        {
            path: 'portal/:contextId/unit/:unitId/hotel/:hotelId',
            canActivateChild: [ subContextGuardServiceCanActivateChild ],
            data: { contextType: 'portal' },
            loadChildren: () => import('./pages/portal/portal.module').then(m => m.PortalModule),
            canActivate: [ wildcardRouteGuardCanActivate ],
            resolve: {
                lang: langResolver,
                context: contextResolver,
                staticInfo: staticInfoResolver,
                menu: menuResolver,
            },
        },
        {
            path: 'portal/:contextId/unit/:unitId',
            data: { contextType: 'portal' },
            loadChildren: () => import('./pages/portal/portal.module').then(m => m.PortalModule),
            canActivate: [ wildcardRouteGuardCanActivate ],
            resolve: {
                lang: langResolver,
                context: contextResolver,
                staticInfo: staticInfoResolver,
                menu: menuResolver,
            },
        },
        {
            path: 'portal/:contextId/hotel/:hotelId',
            canActivateChild: [ subContextGuardServiceCanActivateChild ],
            data: { contextType: 'portal' },
            loadChildren: () => import('./pages/portal/portal.module').then(m => m.PortalModule),
            canActivate: [ wildcardRouteGuardCanActivate ],
            resolve: {
                lang: langResolver,
                context: contextResolver,
                staticInfo: staticInfoResolver,
                menu: menuResolver,
            },
        },
        {
            path: 'portal/:contextId',
            data: { contextType: 'portal' },
            loadChildren: () => import('./pages/portal/portal.module').then(m => m.PortalModule),
            canActivate: [ wildcardRouteGuardCanActivate ],
            resolve: {
                lang: langResolver,
                context: contextResolver,
                staticInfo: staticInfoResolver,
                menu: menuResolver,
            },
        },
    ],
    master: [
        {
            path: 'master/:contextId/unit/:unitId/hotel/:hotelId',
            canActivateChild: [ subContextGuardServiceCanActivateChild ],
            data: { contextType: 'master' },
            loadChildren: () => import('./pages/master/hotel.module').then(m => m.HotelModule),
            canActivate: [ wildcardRouteGuardCanActivate ],
            resolve: {
                lang: langResolver,
                context: contextResolver,
                staticInfo: staticInfoResolver,
                menu: menuResolver,
            },
        },
        {
            path: 'master/:contextId/unit/:unitId',
            data: { contextType: 'master' },
            loadChildren: () => import('./pages/master/master.module').then(m => m.MasterModule),
            canActivate: [ wildcardRouteGuardCanActivate ],
            resolve: {
                lang: langResolver,
                context: contextResolver,
                staticInfo: staticInfoResolver,
                menu: menuResolver,
            },
        },
        {
            path: 'master/:contextId/hotel/:hotelId',
            canActivateChild: [ subContextGuardServiceCanActivateChild ],
            data: { contextType: 'master' },
            loadChildren: () => import('./pages/master/hotel.module').then(m => m.HotelModule),
            canActivate: [ wildcardRouteGuardCanActivate ],
            resolve: {
                lang: langResolver,
                context: contextResolver,
                staticInfo: staticInfoResolver,
                menu: menuResolver,
            },
        },
        {
            path: 'master/hotel/:contextId',
            data: { contextType: 'hotel' },
            loadChildren: () => import('./pages/master/hotel.module').then(m => m.HotelModule),
            canActivate: [ wildcardRouteGuardCanActivate ],
            resolve: {
                lang: langResolver,
                context: contextResolver,
                staticInfo: staticInfoResolver,
                menu: menuResolver,
            },
        },
        {
            path: 'master/:contextId',
            data: { contextType: 'master' },
            loadChildren: () => import('./pages/master/master.module').then(m => m.MasterModule),
            canActivate: [ wildcardRouteGuardCanActivate ],
            resolve: {
                lang: langResolver,
                context: contextResolver,
                staticInfo: staticInfoResolver,
                menu: menuResolver,
            },
        },
    ],
    circle: [
        {
            path: 'circle/:contextId',
            data: { contextType: 'direct' },
            loadChildren: () => import('./pages/circle/circle.module').then(m => m.CircleModule),
            canActivate: [ wildcardRouteGuardCanActivate ],
            resolve: {
                lang: langResolver,
                context: contextResolver,
                staticInfo: staticInfoResolver,
                menu: menuResolver,
            },
        },
    ],
    cpcchain: [
        {
            path: 'cpcchain/:contextId',
            canActivate: [ cpcChainGuardServiceCanActivate ],
            component: ErrorComponent,           // The cpcChainGuardServiceActivate usually does a redirect to an existing route
            // If the chain does not have a parent we fall back to a "page not found".
            data: {
                error: 'NOT_FOUND',
            },
        },
    ],
};

// ---------------------------------------------------------
// + ROUTES
// ---------------------------------------------------------
const appRoutes: Routes = [
    {
        path: '',
        children: [
            ...routeChildren.unsecured,
        ],
        component: PageRootComponent,
    },
    {
        path: 'error',
        component: PageRootComponent,
        children: [
            {
                path: '',
                component: ErrorComponent,
            },
        ],
        resolve: { lang: unsetLangResolver },
    },
    // Deprecated access for embedding pages with /iframe
    {
        path: 'iframe/:language',
        children: [
            ...routeChildren.portal,
            ...routeChildren.master,
            ...routeChildren.cpcchain,
            ...routeChildren.global,
        ],
        data: { embedded: true, fullscreen: true },
        resolve: { pageRouterConfig: routerDataResolver },
        canActivate: [ bmLoginGuardServiceCanActivate ],
        component: PageRootEmbeddedComponent,
    },
    {
        path: 'embedded/:language',
        children: [
            ...routeChildren.portal,
            ...routeChildren.master,
            ...routeChildren.cpcchain,
            ...routeChildren.global,
        ],
        data: { embedded: true, fullscreen: true },
        resolve: { pageRouterConfig: routerDataResolver },
        canActivate: [ bmLoginGuardServiceCanActivate ],
        component: PageRootEmbeddedComponent,
    },
    {
        path: 'embedded/:language',
        outlet: 'level1',
        children: [
            ...routeChildren.portal,
            ...routeChildren.master,
            ...routeChildren.cpcchain,
            ...routeChildren.global,
        ],
        data: { embedded: true, fullscreen: true },
        resolve: { pageRouterConfig: routerDataResolver },
        canActivate: [ bmLoginGuardServiceCanActivate ],
        component: PageRootEmbeddedComponent,
    },
    {
        path: 'embedded/:language',
        outlet: 'level2',
        children: [
            ...routeChildren.portal,
            ...routeChildren.master,
            ...routeChildren.cpcchain,
            ...routeChildren.global,
        ],
        data: { embedded: true, fullscreen: true },
        resolve: { pageRouterConfig: routerDataResolver },
        canActivate: [ bmLoginGuardServiceCanActivate ],
        component: PageRootEmbeddedComponent,
    },
    {
        path: 'modal',
        children: [
            ...routeChildren.portal,
            ...routeChildren.master,
            ...routeChildren.cpcchain,
            ...routeChildren.global,
        ],
        data: { embedded: true, fullscreen: true },
        resolve: { pageRouterConfig: routerDataResolver },
        canActivate: [ bmLangAgnosticLoginGuardServiceCanActivate ],
        component: PageRootEmbeddedComponent,
    },
    {
        path: 'modal',
        outlet: 'level1',
        children: [
            ...routeChildren.portal,
            ...routeChildren.master,
            ...routeChildren.cpcchain,
            ...routeChildren.global,
        ],
        data: { embedded: true, fullscreen: true },
        resolve: { pageRouterConfig: routerDataResolver },
        canActivate: [ bmLangAgnosticLoginGuardServiceCanActivate ],
        component: PageRootEmbeddedComponent,
    },
    {
        path: 'modal',
        outlet: 'level2',
        children: [
            ...routeChildren.portal,
            ...routeChildren.master,
            ...routeChildren.cpcchain,
            ...routeChildren.global,
        ],
        data: { embedded: true, fullscreen: true },
        resolve: { pageRouterConfig: routerDataResolver },
        canActivate: [ bmLangAgnosticLoginGuardServiceCanActivate ],
        component: PageRootEmbeddedComponent,
    },
    {
        path: '',
        children: [
            ...routeChildren.portal,
            ...routeChildren.master,
            ...routeChildren.circle,
            ...routeChildren.cpcchain,
            ...routeChildren.global,
        ],
        resolve: {

        },
        canActivate: [ loginGuardServiceCanActivate ],
        component: PageRootComponent,
    },
    // 404
    {
        path: '**',
        component: PageRootComponent,
        children: [
            {
                path: '',
                component: ErrorComponent,
            },
        ],
        resolve: { lang: unsetLangResolver },
        data: {
            error: 'NOT_FOUND',
        },
    },
];

@NgModule({
    imports: [ RouterModule.forRoot(appRoutes, {}) ],
    exports: [ RouterModule ],
})
export class AppRoutingModule { }
