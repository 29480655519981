import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { EndpointData } from './endpoint-data.interface';

type HeadersParam = HttpHeaders | {
    [header: string]: string | string[];
};

@Injectable()
export class EndpointService {
    /**
     * @deprecated You should usually use HTTP_HEADERS instead
     */
    protected static readonly HTTP_HEADERS_ACCEPT: HttpHeaders = new HttpHeaders({
        Accept: 'application/json;charset=UTF-8',
    });

    /**
     * @deprecated You should usually use HTTP_HEADERS instead
     */
    protected static readonly HTTP_HEADERS_CONTENTTYPE: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
    });

    protected static readonly HTTP_HEADERS: HttpHeaders = new HttpHeaders({
        Accept: 'application/json;charset=UTF-8',
        'Content-Type': 'application/json;charset=UTF-8',
    });

    protected static readonly HTTP_HEADERS_SPREADSHEET = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
    });

    private static getCurrentBaseUrls(): {
        frontend: string;
        backend: string;
    } {
        const defaultSet = environment.baseUrls.bmBackendUrls[0];

        if (!window) {
            return defaultSet;
        }

        const urlSet = environment.baseUrls.bmBackendUrls.find(u => u.frontend === window.location.origin);
        return urlSet ?? defaultSet;
    }

    static getHotelmanagerUrl(): string {
        return this.getCurrentBaseUrls().frontend;
    }

    static getBmBackendUrl(): string {
        return this.getCurrentBaseUrls().backend;
    }

    constructor(
        protected http: HttpClient,
    ) { }

    protected httpGet<T>(
        url: string,
        headers: HeadersParam = {},
        withCredentials: boolean = true,
    ): Observable<T> {
        const req = this.http.get<T>(url, {
            headers,
            withCredentials,
        });
        return this.executeRequest<T>(req, false);
    }

    protected httpGetWithFullData<T>(
        url: string,
        headers: HeadersParam = {},
        withCredentials: boolean = true,
    ): Observable<EndpointData<T>> {
        const req = this.http.get<T>(url, {
            headers,
            withCredentials,
        });
        return this.executeRequest<T>(req, true);
    }

    protected httpPost<T>(
        url: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any,
        headers: HeadersParam = {},
        withCredentials: boolean = true,
    ): Observable<T> {
        const req = this.http.post(url, JSON.stringify(data), {
            headers,
            withCredentials,
        });
        return this.executeRequest<T>(req, false);
    }

    protected httpPostWithFullData<T>(
        url: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any,
        headers: HeadersParam = {},
        withCredentials: boolean = true,
    ): Observable<EndpointData<T>> {
        const req = this.http.post(url, JSON.stringify(data), {
            headers,
            withCredentials,
        });
        return this.executeRequest<T>(req, true);
    }

    protected httpPostRaw<T>(
        url: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any,
        headers: HeadersParam = {},
        withCredentials: boolean = true,
    ): Observable<T> {
        const req = this.http.post(url, data, {
            headers,
            withCredentials,
        });
        return this.executeRequest<T>(req, false);
    }

    protected httpPostRawWithFullData<T>(
        url: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any,
        headers: HeadersParam = {},
        withCredentials: boolean = true,
    ): Observable<EndpointData<T>> {
        const req = this.http.post(url, data, {
            headers,
            withCredentials,
        });
        return this.executeRequest<T>(req, true);
    }

    protected httpPut<T>(
        url: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any = {},
        headers: HeadersParam = {},
        withCredentials: boolean = true,
    ): Observable<T> {
        const req = this.http.put(url, JSON.stringify(data), {
            headers,
            withCredentials,
        });
        return this.executeRequest<T>(req, false);
    }

    protected httpPutWithFullData<T>(
        url: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any = {},
        headers: HeadersParam = {},
        withCredentials: boolean = true,
    ): Observable<EndpointData<T>> {
        const req = this.http.put(url, JSON.stringify(data), {
            headers,
            withCredentials,
        });
        return this.executeRequest<T>(req, true);
    }

    protected httpDelete<T>(
        url: string,
        headers: HeadersParam = {},
        withCredentials: boolean = true,
    ): Observable<T> {
        const req = this.http.delete(url, {
            headers,
            withCredentials,
        });
        return this.executeRequest<T>(req, false);
    }

    protected httpDeleteWithFullData<T>(
        url: string,
        headers: HeadersParam = {},
        withCredentials: boolean = true,
        params: HttpParams = new HttpParams(),
    ): Observable<EndpointData<T>> {
        const req = this.http.delete(url, {
            headers,
            withCredentials,
            params,
        });
        return this.executeRequest<T>(req, true);
    }

    protected httpDeleteWithData<T>(
        url: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any = {},
        headers: HeadersParam = {},
    ): Observable<T> {
        const req = this.http.request('DELETE', url, {
            body: data,
            headers,
            withCredentials: true,
        });
        return this.executeRequest<T>(req, false);
    }

    protected httpPatch<T>(
        url: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any = {},
        headers: HeadersParam = {},
        withCredentials: boolean = true,
    ): Observable<T> {
        const req = this.http.patch(url, JSON.stringify(data), {
            headers,
            withCredentials,
        });
        return this.executeRequest<T>(req, false);
    }

    protected httpPatchWithFullData<T>(
        url: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any = {},
        headers: HeadersParam = {},
        withCredentials: boolean = true,
    ): Observable<EndpointData<T>> {
        const req = this.http.patch(url, JSON.stringify(data), {
            headers,
            withCredentials,
        });
        return this.executeRequest<T>(req, true);
    }

    protected httpDownloadPost(
        url: string,
        data: { [key: string]: string },
        headers: HeadersParam = {},
        withCredentials: boolean = true,
    ): Observable<Blob> {
        // buildUrl returns ?foo=bar so we have to remove the ?
        const dataAsString = UrlHelpers.buildUrl('', data).slice(1);

        const req = this.http.post(url, dataAsString, {
            headers,
            withCredentials,
            responseType: 'blob',
        });
        return this.executeRequest<Blob>(req, false);
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    private executeRequest<T>(request: Observable<any>, returnEntireResponse?: false): Observable<T>;
    private executeRequest<T>(request: Observable<any>, returnEntireResponse: true): Observable<EndpointData<T>>;
    private executeRequest<T>(request: Observable<any>, returnEntireResponse: boolean): Observable<T> | Observable<EndpointData<T>>;
    private executeRequest<T>(
        request: Observable<any>,
        returnEntireResponse: boolean = false,
    ): Observable<T> | Observable<EndpointData<T>> {
        /* eslint-enable @typescript-eslint/no-explicit-any */
        if (this.isTestEnv()) {
            throw new Error('Trying to do an http call in a testing environment. Please replace your services with a mock service');
        }

        return request.pipe(
            map(resp => {
                if (typeof resp.success === 'undefined' || resp.success === true) {
                    if (returnEntireResponse) {
                        return resp;
                    }
                    if ('result' in resp) {
                        return resp.result;
                    } else if ('data' in resp) {
                        return resp.data;
                    } else {
                        return resp;
                    }
                } else {
                    if (resp.errors) {
                        throw resp.errors;
                    } else {
                        throw resp;
                    }
                }
            }),
            catchError(err => {
                try {
                    const data = err.json();
                    return throwError(data.errors);
                } catch (ex) {
                    // Could not parse response as json so we are just passing it here
                }
                return throwError(err);
            }),
        );
    }

    private isTestEnv(): boolean {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const hasTestingBackend = (<any>this.http).handler.backend.constructor.name === 'HttpClientTestingBackend';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isInKarmaWindow = (<any>window).karma || (<any>window).__karma__;

        return !hasTestingBackend && isInKarmaWindow;
    }
}
