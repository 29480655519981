import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { RouteHelpers } from '@bmng/helpers/route-helpers';
import { ContextService } from '@bmng/services/context/context.service';
import { ContextItem } from '@bmng/services/context/interfaces/context-item.interface';
import { PageService } from '@bmng/services/page.service';
import { LangService } from '@kognitiv/angular-i18n';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ContextRole } from '../../../core/services/context/context.service';

import { HeaderIconsService, HeaderIconsVisibility } from './header-icons.service';

@Component({
    selector: '[bm-header]',
    templateUrl: './header.component.html',
    styleUrls: [ './header.component.scss' ],
})
export class HeaderComponent implements OnInit, OnDestroy {
    public userName = '';
    public email = '';
    isSuperAdmin: boolean = false;

    public baseUrl = environment.baseUrls.bm;
    public historyUrl = '';

    public activeContext: ContextItem;
    public currentRole: string;
    public channelName = '';

    public iconVisibility: HeaderIconsVisibility;

    private subscriptions = new Subscription();

    constructor(
        public pageService: PageService,
        private contextService: ContextService,
        private headerIconsService: HeaderIconsService,
        private lang: LangService,
        private changeDetectorRef: ChangeDetectorRef,
    ) { }

    public ngOnInit(): void {
        this.setData();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    downgradeRole(role: ContextRole): void {
        if (!!role) {
            this.contextService.fakeRole(role);
        } else {
            this.contextService.resetFakeRole();
        }

        window.location.reload();
    }

    toggleMenu(): void {
        this.pageService.toggleMenuShown();
        this.changeDetectorRef.detectChanges();
    }

    private setData(): void {
        const context = this.contextService.context.value;

        if (!context.context) {
            return;
        }

        this.isSuperAdmin = this.contextService.isSuperAdmin();
        this.activeContext = RouteHelpers.getActiveContext(context.context);
        this.currentRole = this.getRole();

        if ((context.context.hotel.id || context.context.unit.id) && context.context.channel.name) {
            this.channelName = `${context.context.channel.name} | `;
        }

        this.userName = context.userDetails.displayName;
        this.email = context.userDetails.email;
        this.historyUrl = this.getHistoryUrl();

        this.iconVisibility = this.headerIconsService.getIconsVisibility();
    }

    private getRole(): string {
        return this.contextService.getCurrentRole();
    }


    private getHistoryUrl(): string {
        const languageCode = this.lang.getCurrentLanguage().toUpperCase();
        const historyContext = this.activeContext.type === 'hotel' || this.activeContext.type === 'master' ? 'hotels' : 'channels';

        return `${environment.baseUrls.historyNew}/${historyContext}/${this.activeContext.id}?languageCode=${languageCode}`;
    }
}
