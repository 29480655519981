import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EndpointService } from './../endpoint.service';
import { Rate } from './interfaces/rate';
import { Rates } from './interfaces/rates';

@Injectable()
export class RatesService extends EndpointService {

    constructor(http: HttpClient) {
        super(http);
    }

    getRates(hotelId: string): Observable<Rates> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/${hotelId}/rates`;

        return this.httpGet<Rates>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    createNetAndCommissionRatesForWizard(hotelId: string,
        netRatesEnabled: boolean,
        netRates: string[],
        netRateDiscount: number,
        commissionEnabled: boolean,
        commission: number,
    ): Observable<Rate[]> {
        let url = `${EndpointService.getBmBackendUrl()}/api/cm/api/hotel/${hotelId}/rates/netAndCommissionWizard`;
        let params = [];

        if (!commissionEnabled && !netRatesEnabled) {
            return;
        }

        if (netRatesEnabled) {
            params = params.concat(netRates.map(ratePath => `netRate=${ratePath}`));
            params.push(`netRateDiscount=${netRateDiscount}`);
        }
        if (commissionEnabled) {
            params.push(`commission=${commission}`);
        }
        url += '?' + params.join('&');

        return this.httpGet<Rate[]>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }
}
