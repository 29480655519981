export class TimeLogger {
    private static readonly TIME_STAMPS: { [key: string]: Date } = {};

    static start(key: string): void {
        TimeLogger.TIME_STAMPS[key] = new Date();
    }

    static end(key: string, message?: string): void {
        const startTime = TimeLogger.TIME_STAMPS[key];
        const endTime = new Date();

        if (startTime) {
            const timeDiff = endTime.getTime() - startTime.getTime();
            const timeDiffInSeconds = timeDiff / 1000;
            const timeMessage = `[TIMELOG] [${key}] took ${timeDiffInSeconds}sec ${message || ''}`;

            console.log(timeMessage);
        } else {
            console.log(`${key} was not started.`);
        }
    }
}
