import { Injectable } from '@angular/core';
import { UiPolicy, UiPolicyBooking, UiPolicyCancellation, UiPolicyCleanup, UiPolicyPets, UiPolicyTax, UiTaxPaymentPolicy } from '@bmng/pages/master/policies/interface/policies-ui.interface';
import { PolicyType } from '@bmng/services/policies/interfaces/policies.interface';
import { PolicyBookingPaymentSummaryPipe } from '@bmng/shared/pipes/policy-booking-payment-summary.pipe';
import { PolicyCancellationPaymentSummaryPipe } from '@bmng/shared/pipes/policy-cancellation-payment-summary.pipe';
import { PolicyTaxPaymentSummaryPipe } from '@bmng/shared/pipes/policy-tax-payment-summary.pipe';
import { PoliciesTextHelpers } from '@bmng/shared/services/policies-text-helpers';
import { LangService } from '@kognitiv/angular-i18n';
import { BmCurrencyPipe } from '@kognitiv/bm-components';

import { PoliciesHelpers } from './policies-helpers';

@Injectable()
export class PoliciesSummaryHelper {
    constructor(
        private readonly lang: LangService,
        private readonly currencyPipe: BmCurrencyPipe,
        public helpers: PoliciesHelpers,
        public textHelpers: PoliciesTextHelpers,
        private readonly bookingPaymentPipe: PolicyBookingPaymentSummaryPipe,
        private readonly cancellationPaymentPipe: PolicyCancellationPaymentSummaryPipe,
        private taxPaymentPipe: PolicyTaxPaymentSummaryPipe,
    ) {}

    public getSummary(policy: UiPolicy, type: PolicyType): string {
        const items = this.getSummaryItems(policy, type);
        return items.join(' ');
    }

    public getSummaryItems(policy: UiPolicy, type: PolicyType): string[] {
        switch (type) {
            case 'booking':
                return this.getBookingPolicySummary(<UiPolicyBooking>policy);
            case 'cancellation':
                return this.getCancellationPolicySummary(<UiPolicyCancellation>policy);
            case 'cleanup':
                return this.getCleanupPolicySummary(<UiPolicyCleanup>policy);
            case 'pets':
                return this.getPetsPolicySummary(<UiPolicyPets>policy);
            case 'tax':
                return this.getTaxPolicySummary(<UiPolicyTax>policy);
            default:
                break;
        }
    }

    private getBookingPolicySummary(policy: UiPolicyBooking): string[] {
        const summary = [];
        const separatorLine = '<div class="policy-summary-divider"></div>';
        let isFirstLine = true;

        policy.uiPaymentPolicies.forEach((paymenyPolicy) => {
            const lines: string[] = this.bookingPaymentPipe.getPolicySummaryItems(
                paymenyPolicy,
                policy.currencyCode,
                policy.textOnly,
                policy.bookingType,
                false,
                policy.onHold?.durationHours,
            );

            lines.forEach((line, idx, _) => {
                if (!isFirstLine && idx === 0) {
                    summary.push(separatorLine + line);
                } else if (idx > 0) {
                    summary.push('<br>' + line);
                } else {
                    summary.push(line);
                }
            });

            isFirstLine = false;
        });

        return summary;
    }

    private getCancellationPolicySummary(policy: UiPolicyCancellation): string[] {
        const summary: string[] = [];
        const separatorLine = '<div class="policy-summary-divider"></div>';
        let isFirstLine = true;

        policy.uiPaymentPolicies.forEach((paymenyPolicy) => {
            const lines = this.cancellationPaymentPipe.getPolicySummaryItems(
                paymenyPolicy,
                policy.textOnly,
                policy.cancellationRefund === 'CustomRefundable',
                false,
            );

            lines.forEach((line, idx, _) => {
                if (!isFirstLine && idx === 0) {
                    summary.push(separatorLine + line);
                } else if (idx > 0) {
                    summary.push('' + line);
                } else {
                    summary.push(line);
                }
            });

            isFirstLine = false;
        });

        return summary;
    }

    private getCleanupPolicySummary(policy: UiPolicyCleanup): string[] {
        const summary: string[] = [];

        if (policy.billingType === 'IncludedInTotalPrice') {
            summary.push(this.lang.get('cc.hotel.policy.finalCleanup.IncludedInTotalPrice'));
        } else if (policy.billingType === 'NotIncludedInTotalPrice') {
            summary.push(this.lang.get('cc.hotel.policy.finalCleanup.NotIncludedInTotalPrice'));
        }

        if (policy.amount && policy.currencyCode) {
            const amountWithCurrency = this.currencyPipe.transform(policy.amount, policy.currencyCode);
            const perRoomLabel = this.lang.get('com.seekda.general.taxes.base.PerRoom');

            summary.push(`${amountWithCurrency} ${perRoomLabel}`);
        }

        return summary;
    }

    private getPetsPolicySummary(policy: UiPolicyPets): string[] {
        const summary: string[] = [];
        if (policy.petsAllowed === 0) {
            summary.push(this.textHelpers.getPetsTypeLabel('NoPetsAllowed'));
        }
        if (Array.isArray(policy.uiPaymentPolicies)) {
            policy.uiPaymentPolicies.forEach((paymentPolicy) => {
                if (paymentPolicy.petsType) {
                    const petsType = this.textHelpers.getPetsTypeLabel(paymentPolicy.petsType);
                    if (petsType) {
                        summary.push(petsType);
                    }
                }
                if (typeof paymentPolicy.amount === 'string') {
                    paymentPolicy.amount = parseFloat(paymentPolicy.amount);
                }
                if (typeof paymentPolicy.amount === 'number') {
                    const amountWithCurrency = this.currencyPipe.transform(paymentPolicy.amount, policy.currencyCode || '');
                    summary.push(amountWithCurrency);
                }
            });
        }

        return summary;
    }

    private getTaxPolicySummary(policy: UiPolicyTax): string[] {
        const summary: string[] = [];

        if (Array.isArray(policy.uiPaymentPolicies)) {
            policy.uiPaymentPolicies.forEach((paymentPolicy) => {
                const taxType = this.getTaxTypeOrTitle(paymentPolicy);
                const taxCalculation = this.textHelpers.getTaxCalculationLabel(paymentPolicy.priceCalculation);
                const amountsSummaryItems = this.taxPaymentPipe.getTaxPaymentSummaryItems(paymentPolicy);

                summary.push(`${taxType} (${taxCalculation})`);
                amountsSummaryItems.forEach(item => summary.push(item));
            });
        }
        return summary;
    }

    private getTaxTypeOrTitle(paymentPolicy: UiTaxPaymentPolicy): string {
        if (paymentPolicy.taxType === 'Other') {
            if (!Array.isArray(paymentPolicy.descriptions) || !paymentPolicy.descriptions.length) {
                return '';
            }
            return paymentPolicy.descriptions[0].title || '';
        } else {
            return `${this.textHelpers.getTaxTypeLabel(paymentPolicy.taxType)}:`;
        }
    }
}
