import { Component, Injectable, OnInit } from '@angular/core';
import { ReportsSubscription } from '@bmng/core/services/reports/interfaces/reports-subscription.interface';
import { EnabledStatus } from '@bmng/core/services/reservations/interfaces/reservations.interface';
import { LangService } from '@kognitiv/angular-i18n';
import { RenderComponent } from '@kognitiv/bm-components';

import { StatusDetail } from './status-detail.interface';

@Injectable()
export class ReportStatusHelpers {
    private statusDetails: { [ K in EnabledStatus ]: StatusDetail } = {
        Enabled:  { label: this.lang.get('bm.reports.subscriptions.overview.enabled'),  color: 'success' },
        Disabled: { label: this.lang.get('bm.reports.subscriptions.overview.disabled'), color: 'secondary' },
    };

    constructor(
        private lang: LangService,
    ) {}

    getStatusDetail<K extends keyof StatusDetail>(detailType: K, status: EnabledStatus): StatusDetail[K] {
        const statusDetails = this.getStatusMap(status);

        return statusDetails[detailType];
    }

    getStatusMap(status: EnabledStatus): StatusDetail {
        return this.statusDetails[status];
    }
}

@Component({
    selector: 'report-status-badge-renderer',
    template: `
        <span bmBadge [color]="color">{{ label }}</span>
    `,
})
export class ReportStatusBadgeRendererComponent extends RenderComponent<ReportsSubscription> implements OnInit {
    data: ReportsSubscription;
    label: string;
    color: string;

    constructor(
        public statusHelpers: ReportStatusHelpers,
    ) {
        super();
    }

    ngOnInit(): void {
        const status: EnabledStatus = this.data.enabled ? 'Enabled' : 'Disabled';
        if (status) {
            this.label     = this.statusHelpers.getStatusDetail('label', status);
            this.color     = this.statusHelpers.getStatusDetail('color', status);
        }
    }
}
