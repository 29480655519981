import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { Observable } from 'rxjs';

import { EndpointService } from '../endpoint.service';

import { ChannelAttributesResult, ChannelAttributesServiceInterface } from './interfaces/channel-attributes.service.interface';

@Injectable()
export class ChannelAttributesService extends EndpointService implements ChannelAttributesServiceInterface {
    private baseUrl: string = `${EndpointService.getBmBackendUrl()}/api/hotel/switch/api/channels/attributes`;

    constructor(http: HttpClient) {
        super(http);
    }

    getAttributesById(hotelId: string, ids: string[]): Observable<ChannelAttributesResult> {
        return this.callEndpoint({
            hotelId,
            channelId: ids,
        });
    }

    getAttributesByType(hotelId: string, types: string[]): Observable<ChannelAttributesResult> {
        return this.callEndpoint({
            hotelId,
            type: types,
        });
    }

    getPMSAttributesById(hotelId: string, ids: string[]): Observable<ChannelAttributesResult> {
        return this.callEndpoint({
            hotelId,
            channelId: ids,
            type: 'PMS',
        });
    }

    getAttributesByPortalIds(portalIds: string[]): Observable<ChannelAttributesResult> {
        return this.callEndpoint({
            channelId: portalIds,
        });
    }

    getAllChannelAttributesByType(types: string[]): Observable<ChannelAttributesResult> {
        return this.callEndpoint({
            type: types,
        });
    }

    getChannelsHavingAttribute(hotelId: string, attribute: string): Observable<ChannelAttributesResult> {
        return this.callEndpoint({
            hotelId,
            hasAttribute: attribute,
        });
    }

    private callEndpoint(params: { [ key: string ]: unknown }): Observable<ChannelAttributesResult> {
        const url = UrlHelpers.buildUrl(this.baseUrl, params);

        return this.httpGet<ChannelAttributesResult>(url);
    }
}
