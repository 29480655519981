import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from './../../../../environments/environment';
import { EndpointService } from './../endpoint.service';
import { HistoryUpdate, Pms, RequestedPmsData } from './interfaces/pms';
import { PmsRights } from './interfaces/pms-rights';

@Injectable()
export class PmsService extends EndpointService {

    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    getPms(hotelId: string): Observable<Pms[]> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/${hotelId}/channels?type=PMS&hidden=false&config=true&details=true`;

        return this.httpGet<Pms[]>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    getPmsFromCM(hotelId: string): Observable<Pms[]> {
        const url = `${EndpointService.getBmBackendUrl()}/api/cm/api/hotel/${hotelId}/channels?enabled=true&hidden=false&type=PMS`;

        return this.httpGet<Pms[]>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    updatePms(hotelId: string, pmsId: string, enabled: boolean): Observable<Pms[]> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/${hotelId}/pms/${pmsId}`;
        const params = {
            enabled,
        };

        return this.httpPost<Pms[]>(url, params, EndpointService.HTTP_HEADERS);
    }

    getPmsRights(hotelId: string): Observable<PmsRights> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/${hotelId}/pms/rights`;

        return this.httpGet<PmsRights>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    requestNewPms(hotelId: string, formData: RequestedPmsData): Observable<never> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/${hotelId}/pms/request`;
        const params = {
            hotelId,
            body: formData,
        };

        return this.httpPost<never>(url, params, EndpointService.HTTP_HEADERS);
    }

    getPmsAvailabilityHistory(hotelId: string, pms: string[]): Observable<HistoryUpdate> {
        const params = 'company_id=' + pms.join('&company_id=');
        const url =
            `${EndpointService.getBmBackendUrl()}/api/hotel/${hotelId}/availability_updates/get_update_dates_by_company_id?${params}`;

        return this.httpGet<HistoryUpdate>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    getPmsRatesHistory(hotelId: string, pms: string[]): Observable<HistoryUpdate> {
        const params = 'company_id=' + pms.join('&company_id=');
        const url =  `${EndpointService.getBmBackendUrl()}/api/hotel/${hotelId}/rateplan_updates/get_update_dates_by_company_id?${params}`;

        return this.httpGet<HistoryUpdate>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    getPmsSetupUrl(hotelId: string, channelId: string): string {
        return `${environment.baseUrls.bm}/channelSetupAction.do?formAction=channelSetUp&hotelId=${hotelId}&channelId=${channelId}&` +
             `autoLock=true&isThickBox=true&returnUrl=${encodeURIComponent(document.location.href)}`;
    }
}
