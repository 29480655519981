import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { LangService } from '@kognitiv/angular-i18n';
import { Observable, of } from 'rxjs';
import { filter, mapTo, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { ErrorHandlerService } from './error-handler/error-handler.service';
import { I18nService } from './i18n.service';
import { LinkService } from './link.service';
import { EndpointService } from './endpoint.service';

@Injectable({ providedIn: 'root' })
export class LoginService {
    private static readonly BM_BACKEND_REDIRECT_URL = `${EndpointService.getBmBackendUrl()}/redirect`;
    alreadyRedirected: boolean = false;

    static getLogoutUrl(): string {
        return `${environment.baseUrls.login}/logout?redirect=${encodeURIComponent(window.location.href)}`;
    }
    static getMfaEnableUrl(): string {
        let serviceUrl = window.location.href;
        serviceUrl = serviceUrl.replace(/redirected=true/, '');

        const profileUrl = UrlHelpers.buildUrl(`${environment.baseUrls.login}/profile`, {
            action: 'enableMfa',
            service: serviceUrl,
        });

        return UrlHelpers.buildUrl(LoginService.BM_BACKEND_REDIRECT_URL, {
            clearSession: true,
            url: profileUrl,
        });
    }

    constructor(
        private router: Router,
        private linkService: LinkService,
        private langService: LangService,
        private errorHandler: ErrorHandlerService,
        private i18nService: I18nService,
    ) { }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catchUnauthenticatedUser(err: any): 'NO_REDIRECT' | 'REDIRECTED' {
        if (err instanceof HttpErrorResponse && Array.isArray(err.error.errors) && err.error.errors.length > 0) {
            if (err.error.errors[0].code === 'E401') {
                this.redirectToLogin();
                return 'REDIRECTED';
            } else if (err.error.errors[0].code === 'EMFA') {
                this.redirectToMfaEnable();
                return 'REDIRECTED';
            }
        }

        return 'NO_REDIRECT';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catchUnauthenticatedUserFromLoginGuard(errors: any): Observable<boolean> {
        const redirectionResult = this.catchUnauthenticatedUser(errors);

        if (redirectionResult === 'NO_REDIRECT') {
            return this.navigateToErrorPage(errors);
        }

        return of(false);
    }

    private redirectToLogin(): void {
        const url = this.linkService.getLocation();
        this.alreadyRedirected = /redirected=true/.test(window.location.toString());
        this.router.navigate([ '/login' ], {
            queryParams: {
                prevUrl: url,
            },
        });
    }

    private redirectToMfaEnable(): void {
        window.location.href = LoginService.getMfaEnableUrl();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private navigateToErrorPage(errors: any): Observable<boolean> {
        return this.i18nService.isLanguageLoaded().pipe(
            filter(loaded => loaded),
            // Without this magic take(1) the router navigation doesn't work ...
            // Seems to be fixed in later versions of Angular so we might be able to remove it after upgrading to 8 or 9
            take(1),
            tap(() => {
                this.router.navigate([ '/error' ], {
                    queryParams: {
                        title: this.langService.get('seekda.errors.generalErrorTitle'),
                        message: this.errorHandler.getErrorMessage(errors),
                    },

                });
            }),
            mapTo(false),
        );
    }
}
