// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ArrayHelpers {
    export class Filter {
        /**
         * Filter all falsy values of an array
         * call like this: `.filter(ArrayHelpers.Filter.onlyTruthy)`
         */
        static onlyTruthy<T>(val: T): boolean {
            return !!val;
        }

        /**
         * Filter all duplicated entries of an array
         * call like this: `.filter(ArrayHelpers.Filter.noDuplicates)`
         */
        static noDuplicates<T>(val: T, idx: number, arr: T[]): boolean {
            return arr.indexOf(val) === idx;
        }
    }

    export class General {
        /**
         * Returns a copy of an array, as a new value
         *
         * @param array
         */
        static copyArray<T>(array: T[]): T[] {
            return array.map(item => item);
        }

        /**
         * Flattens an array
         *
         * @param array
         */
        static flatten<T>(array: T[][]): T[] {
            return array.reduce((prev, curr) => prev.concat(curr), []);
        }
    }
}
