import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from '@bmng/services/category/category';
import { LangService } from '@kognitiv/angular-i18n';
import { SelectOption } from '@kognitiv/bm-components';

@Injectable()
export class RateServicesHelpers {

    priceTypeMap: { [ key: string ]: { options: SelectOption[]; label: string } } = {
        _Unit: {
            label: this.lang.get('bm.service.priceType.perUnit'),
            options: [
                {
                    id: '_None',
                    text: this.lang.get('bm.service.limit.none'),
                },
                {
                    id: '_Unit',
                    text: this.lang.get('bm.service.limit.per.booking'),
                },
            ],
        },
        UnitNight: {
            label: this.lang.get('bm.service.priceType.perUnitPerNight'),
            options: [
                {
                    id: '_None',
                    text: this.lang.get('bm.service.limit.none'),
                },
                {
                    id: '_Unit',
                    text: this.lang.get('bm.service.limit.per.night'),
                },
            ],
        },
        Person: {
            label: this.lang.get('bm.service.priceType.perGuest'),
            options: [
                {
                    id: '_None',
                    text: this.lang.get('bm.service.limit.per.guests.all'),
                },
                {
                    id: 'Person',
                    text: this.lang.get('bm.service.limit.per.guests'),
                },
            ],
        },
        PersonNight: {
            label: this.lang.get('bm.service.priceType.perGuestPerNight'),
            options: [
                {
                    id: '_None',
                    text: this.lang.get('bm.service.limit.per.guest.night.all'),
                },
                {
                    id: 'Night',
                    text: this.lang.get('bm.service.limit.per.guest.night.nights'),
                },
                {
                    id: 'Person',
                    text: this.lang.get('bm.service.limit.per.guest.night.guests'),
                },
            ],
        },
        Room: {
            label: this.lang.get('bm.service.priceType.perRoom'),
            options: [
                {
                    id: '_None',
                    text: this.lang.get('bm.service.limit.per.room.all'),
                },
                {
                    id: 'Room',
                    text: this.lang.get('bm.service.limit.per.room'),
                },
            ],
        },
        RoomNight: {
            label: this.lang.get('bm.service.priceType.perRoomPerNight'),
            options: [
                {
                    id: '_None',
                    text: this.lang.get('bm.service.limit.per.room.night.all'),
                },
                {
                    id: 'Night',
                    text: this.lang.get('bm.service.limit.per.room.night.nights'),
                },
                {
                    id: 'Room',
                    text: this.lang.get('bm.service.limit.per.room.night.rooms'),
                },
            ],
        },
    };

    constructor(
        public router: Router,
        public lang: LangService,
    ) {}

    navigateToService(target: string, serviceCode: string, route: ActivatedRoute): void {
        this.router.navigate([ target, serviceCode ], { relativeTo: route });
    }

    navigateToOverview(target: string, route: ActivatedRoute): void {
        this.router.navigate([ target ], { relativeTo: route });
    }

    navigateToNewService(route: ActivatedRoute): void {
        this.router.navigate([ 'new' ], { relativeTo: route });
    }

    navigateToEnableLanguages(): void {
        console.log('navigateToEnableLanguages');
    }

    filterUnknownCategories(categories: string[], options: Category[]): string[] {
        if (!categories || !categories.length || !options || !options.length) {
            return [];
        }
        return categories.filter(_cat => options.findIndex(_option => _option.code === _cat) > -1);
    }

    getPriceTypeMap(): { [ key: string ]: { options: SelectOption[]; label: string } } {
        return this.priceTypeMap;
    }

    getPriceTypeLabelMap(): { [key: string]: string } {
        const labelMap: { [key: string]: string } = {};
        Object.keys(this.priceTypeMap).forEach(_type => {
            labelMap[_type] = this.priceTypeMap[_type].label;
        });
        return labelMap;
    }

    getPriceTypeOptionsMap(): { [key: string]: SelectOption[] } {
        const optionsMap: { [key: string]: SelectOption[] } = {};
        Object.keys(this.priceTypeMap).forEach(_type => {
            optionsMap[_type] = this.priceTypeMap[_type].options;
        });
        return optionsMap;
    }
}
