import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    RateService,
    RateServicesResult,
    RateServiceWithOrder,
    ServiceMode,
} from '@bmng/services/rate-services/interfaces/rate-services.interface';
import { RateServicesServiceInterface } from '@bmng/services/rate-services/interfaces/rate-services.service.interface';
import { Observable, of } from 'rxjs';

import { EndpointService } from './../endpoint.service';
import NEW_SERVICE_DATA from './new-rate-service-data/new-rate-service-data';

@Injectable()
export class RateServicesService extends EndpointService implements RateServicesServiceInterface {
    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    getServicesList(
        propertyId: string,
        languageCode: string,
    ): Observable<RateServicesResult> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/${propertyId}/services` +
                    `?languageCode=${languageCode}`;

        return this.httpGet<RateServicesResult>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    getServiceDetail(
        propertyId: string,
        serviceCode: string,
        languageCode: string,
    ): Observable<RateService> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/${propertyId}/services/${serviceCode}` +
                    `?languageCode=${languageCode}`;

        return this.httpGet<RateService>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    saveService(
        propertyId: string,
        service: RateService,
        serviceMode: ServiceMode,
    ): Observable<RateService> {
        let url = `${EndpointService.getBmBackendUrl()}/api/hotel/${propertyId}/services`;

        if (serviceMode === 'new') {
            return this.httpPost(url, service, EndpointService.HTTP_HEADERS);
        } else if (serviceMode === 'edit') {
            url += `/${service.serviceCode}`;
            return this.httpPut(url, service, EndpointService.HTTP_HEADERS);
        }
    }

    deleteService(
        propertyId: string,
        serviceCode: string,
    ): Observable<{ success: boolean }> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/${propertyId}/services/${serviceCode}`;

        return this.httpDelete<{ success: boolean }>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    saveServicesOrder(
        propertyId: string,
        services: RateServiceWithOrder[],
    ): Observable<{ success: boolean }> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/${propertyId}/services/order`;

        return this.httpPost(url, services, EndpointService.HTTP_HEADERS);
    }

    getNewServiceData(): Observable<RateService> {
        return of(NEW_SERVICE_DATA);
    }
}
