import { LangService } from '@kognitiv/angular-i18n';
import { Injectable } from '@angular/core';
import { UiPolicy } from '@bmng/pages/master/policies/interface/policies-ui.interface';
import { Column } from '@kognitiv/bm-components';
import { ObjectHelpers } from '@bmng/helpers/object-helpers';
import { PortalPolicyApplicabilityRendererComponent } from '@bmng/pages/master/policies/partials/policy-applicability-renderer/policy-applicability-renderer.component';
import { PolicySortDirection } from '@bmng/services/policies/interfaces/policies.interface';

@Injectable()
export class PortalPoliciesTableHelper {
    private static readonly CURRENCY_CODE = 'currencyCode';

    constructor(
        private lang: LangService,
    ){}

    removeCurrencyColumn(columns: Column<UiPolicy>[]): Column<UiPolicy>[] {
        return columns.filter(col => col.key !== PortalPoliciesTableHelper.CURRENCY_CODE);
    }

    addApplicabilityColumn(columns: Column<UiPolicy>[]): Column<UiPolicy>[] {
        columns.push({
            title: this.lang.get('bm.accessRestrictions.overview.table.header.applicability'),
            key: 'applicability',
            widthRatio: 3,
            renderComponent: PortalPolicyApplicabilityRendererComponent,
        });
        return columns;
    }

    reorderPolicies(data: UiPolicy[], target: PolicySortDirection): UiPolicy[] {
        const policies = ObjectHelpers.copy(data);
        const oldOrder = target.policy.sort;
        if (target.direction === 'up') {
            if (oldOrder === 0) {
                return policies;
            }

            policies[oldOrder].sort -= 1;
            policies[oldOrder - 1].sort += 1; // previous
        } else {
            if (oldOrder === data.length) {
                return policies;
            }
            policies[oldOrder].sort += 1;
            policies[oldOrder + 1].sort -= 1; // next
        }

        return policies.sort((p1, p2) => p1.sort - p2.sort);
    }

    addPolicyStatusColumn(columns: Column<UiPolicy>[]): Column<UiPolicy>[] {
        columns.splice(1, 0, {
            title: this.lang.get('bm.pda.table.status'),
            key: 'status',
            widthRatio: 2,
            renderer: (_value, row) => {
                let cls = 'success';
                let label = this.lang.get('hotel.enabled');

                if (!row.enabled) {
                    cls = 'error';
                    label = this.lang.get('hotel.disabled');
                }

                return `<span class="bm-badge ${cls}">${label}</span>`;
            },
        });
        return columns;
    }
}
