import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ContextType } from '@bmng/services/context/interfaces/context-type.type';
import { UserManagement } from '@bmng/services/user-management/interfaces/user-management.interface';
import { LangService } from '@kognitiv/angular-i18n';

export type PageViewType = 'view' | 'edit' | 'new' | 'resend';

@Injectable()
export class UserFormService {
    masterPropertyId = '';
    maxPossibleUsers: number = 150;

    private userRoleTexts = {
        ADMIN: {
            title: this.lang.get('bmng.usermngt.userroles.admin.title'),
            description: this.lang.get('bmng.usermngt.userroles.admin.description'),
        },
        API_ADMIN: {
            title: this.lang.get('bmng.usermngt.userroles.api_admin.title'),
            description: this.lang.get('bmng.usermngt.userroles.api_admin.description'),
        },
        RECEPTIONIST: {
            title: this.lang.get('bmng.usermngt.userroles.receptionist.title'),
            description: this.lang.get('bmng.usermngt.userroles.receptionist.description'),
        },
        UABADMIN: {
            title: this.lang.get('bmng.usermngt.userroles.uabadmin.title'),
            description: this.lang.get('bmng.usermngt.userroles.uabadmin.description'),
        },
        UABUSER: {
            title: this.lang.get('bmng.usermngt.userroles.uabuser.title'),
            description: this.lang.get('bmng.usermngt.userroles.uabuser.description'),
        },
        UPDATER: {
            title: this.lang.get('bmng.usermngt.userroles.updater.title'),
            description: this.lang.get('bmng.usermngt.userroles.updater.description'),
        },
        VIEWER: {
            title: this.lang.get('bmng.usermngt.userroles.viewer.title'),
            description: this.lang.get('bmng.usermngt.userroles.viewer.description'),
        },
        WEBAGENCY_DSO: {
            title: this.lang.get('bmng.usermngt.userroles.webagency-dso.title'),
            description: this.lang.get('bmng.usermngt.userroles.webagency-dso.description'),
        },
        WEBAGENCY: {
            title: this.lang.get('bmng.usermngt.userroles.webagency.title'),
            description: this.lang.get('bmng.usermngt.userroles.webagency.description'),
        },
    };

    private circleRoleTexts = {
        ADMIN: {
            title: this.lang.get('bmng.usermngt.userroles.admin.title'),
            description: this.lang.get('bm.circle.permission.admin.descr'),
        },
        VIEWER: {
            title: this.lang.get('bmng.usermngt.userroles.viewer.title'),
            description: this.lang.get('bm.circle.permission.viewer.descr'),
        },
        WEBAGENCY: {
            title: this.lang.get('bmng.usermngt.userroles.webagency.title'),
            description: this.lang.get('bm.circle.permission.webagency.descr'),
        },
    };

    constructor(private readonly lang: LangService) { }

    getUserRoleTexts(type: ContextType, userRole: string) {
        return type === 'direct'
            ? this.circleRoleTexts[userRole]
            : this.userRoleTexts[userRole];
    }

    generateFormControls(user: UserManagement): UntypedFormGroup {
        return new UntypedFormGroup({
            uid: new UntypedFormControl(user.uid),
            email: new UntypedFormControl({ value: user.email, disabled: !!user.email }, [ Validators.required, Validators.email ]),
            userRole: new UntypedFormControl(user.userRole),
            status: new UntypedFormControl(user.status),
            isMasterUser: new UntypedFormControl(user.isMasterUser),
            inviteEmail: new UntypedFormGroup({
                enabled: new UntypedFormControl(user.inviteEmail ? user.inviteEmail.enabled : true),
                username: new UntypedFormControl(user.username),
                emailContent: new UntypedFormControl(user.inviteEmail ? user.inviteEmail.emailContent : ''),
                language: new UntypedFormControl(user.inviteEmail ? user.inviteEmail.language : undefined),
            }),
        }, []);
    }

    getData(form: UntypedFormGroup): UserManagement {
        const user = form.getRawValue();

        user.username = user.inviteEmail.username;
        delete user.inviteEmail.username;

        if (user.userRole === 'API_ADMIN') {
            user.inviteEmail.enabled = false;
            user.inviteEmail.emailContent = '';
        }

        return user;
    }
}
