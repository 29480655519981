import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Upload, UploadResult } from '@kognitiv/bm-components';
import { Observable, Subscription } from 'rxjs';

export interface ImageUploadConfig {
    channelId?: string;
    category?: string;
    url: string;
    file: File;
}

export interface UploadResponse {
    success: boolean;
    errors?: UploadError[];
    result: {
        file_name: string;
        category: number | null;
        width: number;
        height: number;
        size: number;
        archived: boolean;
        type: string;
        url_address: string;
    };
}

export interface UploadError {
    code: string;
    message: string;
}

export interface UploadResultData<T> {
    errors?: UploadError[];
    result: T;
}

export type ErrorMessageRetreive = (errors: UploadError[]) => string | null;

export class ImageUpload implements Upload<unknown> {
    private uploadSubscription: Subscription;

    constructor(
        private readonly uploadConfig: ImageUploadConfig,
        private readonly http: HttpClient,
        private readonly getErrorMessage: ErrorMessageRetreive,
    ) {}

    getResult(): Observable<number | UploadResult<unknown>> {
        return new Observable(observer => {
            const formData: FormData = new FormData();
            formData.append(
                'file',
                this.uploadConfig.file,
            );
            formData.append('file_name', this.sanitizeFileName(this.uploadConfig.file?.name));
            formData.append('flush_pictures', 'true');

            if (this.uploadConfig.channelId) {
                formData.append('channelId', this.uploadConfig.channelId);
            }

            if (this.uploadConfig.category) {
                formData.append('category', this.uploadConfig.category);
            }

            const req = new HttpRequest('POST', this.uploadConfig.url, formData, {
                reportProgress: true,
                withCredentials: true,
                responseType: 'json',
            });

            this.uploadSubscription = this.http.request(req).subscribe(
                event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        const percentDone = Math.round((100 * event.loaded) / event.total);
                        observer.next(percentDone);
                    } else if (event instanceof HttpResponse) {
                        const responseData = <UploadResponse>event.body;
                        const error = responseData?.errors?.length
                            ? { message: this.mapErrorsToMessage(responseData.errors), cause: responseData.errors }
                            : null;

                        observer.next({
                            url: responseData?.result?.url_address,
                            name: responseData?.result?.file_name,
                            id: responseData?.result?.file_name,
                            success: responseData.success,
                            data: this.extractResultWrap(event),
                            error,
                        });
                        observer.complete();
                    }
                },
                error => {
                    console.error('Error uploading image', error);
                    const errors = (error?.error?.errors as UploadError[]) || [];

                    observer.next({
                        success: false,
                        name: null,
                        url: null,
                        id: null,
                        data: error,
                        error: {
                            message: this.mapErrorsToMessage(errors),
                            cause: error,
                        },
                    });
                    observer.complete();
                },
            );

            return () => this.abort();
        });
    }

    private mapErrorsToMessage(errors: UploadError[]): string | null {
        let errorMessage = null;

        if (errors?.length && typeof this.getErrorMessage === 'function') {
            errorMessage = this.getErrorMessage(errors);
        }

        return errorMessage;
    }

    // should mirror logic of old legacy CM
    // https://github.com/seekda/channel-manager/blob/master/src/main/java/com/seekda/imageaccess/cloudinary/CloudinaryImageAccess.java#L509
    sanitizeFileName(fileName: string): string {
        if (!fileName) {
            return null;
        }

        const fileParts = fileName.split('.');
        const fileExtension = fileParts.length
            ? '.' + fileParts.pop()
            : '';

        const replacedItems = fileParts.join('-')
            .replaceAll(/\+/g, ' ')
            .replaceAll(/ /g, '_')
            .replaceAll(/&/g, '_')
            .replaceAll(/#/g, '_')
            .replaceAll(/%/g, '_')
            .replaceAll(/ /g, '_')
            .replaceAll(/_*$/g, '')
            .replaceAll(/(\d+)\-/g, '$1-') + fileExtension;

        return replacedItems;
    }

    private extractResultWrap(event: HttpResponse<unknown>): UploadResultData<unknown> {
        if (!event) {
            return null;
        }

        const data: UploadResultData<unknown> = {
            result: event,
        };

        if (event.body) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const objectToBeChecked = event.body as any;

            if (objectToBeChecked.errors) {
                data.errors = objectToBeChecked.errors;
            }
        }

        return data;
    }

    private abort(): void {
        if (!this.uploadSubscription) {
            return;
        }

        this.uploadSubscription.unsubscribe();
        this.uploadSubscription = null;
    }
}
