import { Injectable } from '@angular/core';
import { ModalViewType } from '@kognitiv/bm-components/lib/components/bm-modal/types/modal-view.type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WindowListenerService } from '../window-listener.service';

@Injectable()
export class ModalViewTypeService {
    constructor(private windowSrv: WindowListenerService) {}

    getSuggestedModalViewMode(): ModalViewType {
        return this.windowSrv.width() < 768 ? 'regular' : 'drawer';
    }

    getSuggestedModalViewTypeUpdates(): Observable<ModalViewType> {
        return this.windowSrv.resize().pipe(
            map(_ => this.getSuggestedModalViewMode()),
        );
    }
}
