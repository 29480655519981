import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObjectHelpers } from '@kognitiv/bm-components';
import { Observable } from 'rxjs';

import { EndpointService } from '../endpoint.service';
import { ContactSupportData } from './interfaces/contact-support-data.interface';
import { ContactSupportContextType, ContactSupportServiceInterface } from './interfaces/contact-support-service.interface';

@Injectable()
export class ContactSupportService extends EndpointService implements ContactSupportServiceInterface {
    constructor(http: HttpClient) {
        super(http);
    }

    sendContactSupportRequest(context: ContactSupportContextType, assetId: string, data: ContactSupportData): Observable<boolean> {
        let contextUrlParameter = '';

        switch (context) {
            case 'master':
            case 'portal':
                contextUrlParameter = 'channels';
                break;

            case 'hotel':
                contextUrlParameter = 'hotels';
                break;

            default:
                ObjectHelpers.assertNever(context);
        }

        const url = `${EndpointService.getBmBackendUrl()}/api/${contextUrlParameter}/${assetId}/contactsupport`;

        return this.httpPost(url, data, EndpointService.HTTP_HEADERS);
    }
}
