import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EndpointService } from '../endpoint.service';

import { DerivedRateInformation } from './interfaces/derived-rates.interface';
import { DerivedRatesServiceInterface } from './interfaces/derived-rates.service.interface';

@Injectable({ providedIn: 'root' })
export class DerivedRatesService extends EndpointService implements DerivedRatesServiceInterface {
    constructor(http: HttpClient) {
        super(http);
    }

    getDerivedRateInformation(hotelId: string, rateCode: string): Observable<DerivedRateInformation> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotels/${hotelId}/derivedRateplans/${rateCode}`;
        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }
}
