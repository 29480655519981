import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Policy, PolicyType } from '@bmng/services/policies/interfaces/policies.interface';
import { ObjectHelpers } from '@kognitiv/bm-components';

import { PolicyFormBookingSubmitService } from './booking/policy-form-booking-submit.service';
import { PolicyFormCancellationSubmitService } from './cancellation/policy-form-cancellation-submit.service';
import { PolicyFormCleanupSubmitService } from './cleanup/policy-form-cleanup-submit.service';
import { PolicyFormPetsSubmitService } from './pets/policy-form-pets-submit.service';
import { PolicyFormTaxSubmitService } from './tax/policy-form-tax-submit.service';

@Injectable()
export class PolicyFormSubmitService {
    constructor(
        private bookingSubmitService: PolicyFormBookingSubmitService,
        private cancellationSubmitService: PolicyFormCancellationSubmitService,
        private petsSubmitService: PolicyFormPetsSubmitService,
        private cleanupSubmitService: PolicyFormCleanupSubmitService,
        private taxSubmitService: PolicyFormTaxSubmitService,
    ) {}

    getPolicyConfigByType(
        policyType: PolicyType,
        form: UntypedFormGroup,
    ): Policy {
        switch (policyType) {
            case 'cleanup':
                return this.cleanupSubmitService.getCleanupPolicyConfig(form);
            case 'pets':
                return this.petsSubmitService.getPetsPolicyConfig(form);
            case 'booking':
                return this.bookingSubmitService.getBookingPolicyConfig(form);
            case 'cancellation':
                return this.cancellationSubmitService.getCancellationPolicyConfig(form);
            case 'tax':
                return this.taxSubmitService.getTaxPolicyConfig(form);
            default:
                ObjectHelpers.assertNever(policyType);
        }
    }
}
