import { DateRangeFromTo, DateRangeStartEnd, MomentService } from '@kognitiv/bm-components';

import { ArrayHelpers } from './array-helpers';

const moment = MomentService.get();

interface MomentsFromTo {
    from: moment.Moment;
    to: moment.Moment;
}

export class DateRangeSingleDatesTranslator {
    public static translateToRangesStartEnd(dates: string[]): DateRangeStartEnd[] {
        return DateRangeSingleDatesTranslator.translateToRanges(dates)
            .map(dateRange => ({
                start: dateRange.from,
                end: dateRange.to,
            }));
    }

    public static translateToRanges(dates: string[]): DateRangeFromTo[] {
        if (!dates || dates.length === 0) {
            return [];
        }

        const ranges: MomentsFromTo[] = [];
        let from: moment.Moment = null;
        let to: moment.Moment = null;
        const filteredDates = this.getUniqueDates(dates).map(d => moment.utc(d, 'YYYY-MM-DD'));

        filteredDates.forEach(date => {
            if (from === null) {
                from = date;
            } else {
                const comparisonDate = (to === null) ? from : to;
                const differenceInDays = date.diff(comparisonDate, 'days');

                if (differenceInDays === 1) {
                    to = date;
                } else {
                    ranges.push({
                        from,
                        to: to || from,
                    });

                    to = null;
                    from = date;
                }
            }
        });

        if (from !== null) {
            ranges.push({
                from,
                to: to || from,
            });
        }

        return ranges.map(r => ({
            from: r.from.format('YYYY-MM-DD'),
            to: r.to.format('YYYY-MM-DD'),
        }));
    }

    private static getUniqueDates(dates: string[]): string[] {
        if (!dates || dates.length === 0) {
            return [];
        }

        return dates.filter((date, index) => dates.indexOf(date) === index);
    }

    public static translateToDateTokensStartEnd(ranges: DateRangeStartEnd[]): string[] {
        return DateRangeSingleDatesTranslator.translateToDateTokens(ranges.map(range => ({
            from: range.start,
            to: range.end,
        })));
    }

    public static translateToDateTokens(ranges: DateRangeFromTo[]): string[] {
        if (!ranges || ranges.length === 0) {
            return [];
        }

        const rangeDateArrays: string[][] = ranges.map(range => {
            let cur = moment(range.from, 'YYYY-MM-DD');

            if (!cur.isValid()) {
                return [];
            }

            const isSingleDayEntry = range.to === range.from;

            if (isSingleDayEntry) {
                return [ cur.format('YYYY-MM-DD') ];
            } else {
                const dateEntries: string[] = [];
                const lastDate = moment(range.to, 'YYYY-MM-DD');

                if (!lastDate.isValid()) {
                    return [];
                }

                while (cur.isBefore(lastDate)) {
                    dateEntries.push(cur.format('YYYY-MM-DD'));
                    cur = cur.add(1, 'days');
                }

                dateEntries.push(lastDate.format('YYYY-MM-DD'));

                return this.getUniqueDates(dateEntries);
            }
        });

        return ArrayHelpers.General.flatten(rangeDateArrays);
    }
}
