import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EndpointService } from '../endpoint.service';

import { BmLoginResult } from './interfaces/bm-login-result.interface';
import { BmLoginServiceInterface } from './interfaces/bm-login.service.interface';

@Injectable()
export class BmLoginService extends EndpointService implements BmLoginServiceInterface {
    constructor(http: HttpClient) {
        super(http);
    }

    login(username: string, password: string, remindMe: boolean): Observable<BmLoginResult> {
        // TODO Ignores the remind me flag ...
        return null;
    }
}
