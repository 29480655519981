import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { PageRouterConfig } from '../services/page-router-config.interface';

export const routerDataResolver: ResolveFn<PageRouterConfig> =
    (route: ActivatedRouteSnapshot) => {
        const pageRouterConfig: PageRouterConfig = {
            fullscreen: route.data?.fullscreen ? route.data.fullscreen : false,
        };

        // TODO: set the fullscreen setting into the pageService config?
        return pageRouterConfig;
    };
