import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { EndpointService } from '@bmng/services/endpoint.service';
import { SearchParametersInterface } from '@bmng/services/entity-selection/interfaces/search-parameters/search-parameters.interface';
import { ErrataSetting, ErrataSettingResponse } from '@bmng/services/errata/interface/errata.interface';
import { Observable } from 'rxjs';

@Injectable()
export class ErrataService extends EndpointService {
    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    getErrataSettings(hotelId: string, searchParams?: SearchParametersInterface): Observable<ErrataSettingResponse> {
        let url = `${EndpointService.getBmBackendUrl()}/api/errata/${hotelId}/list`;

        if (!!searchParams) {
            url = UrlHelpers.buildUrl(url, searchParams);
        }
        return this.httpGet<ErrataSettingResponse>(url, EndpointService.HTTP_HEADERS_CONTENTTYPE);
    }

    getErrataById(hotelId: string, errataId: number): Observable<ErrataSetting> {
        const url = `${EndpointService.getBmBackendUrl()}/api/errata/${hotelId}/${errataId}`;
        return this.httpGet<ErrataSetting>(url, EndpointService.HTTP_HEADERS_CONTENTTYPE);
    }

    saveErrata(hotelId: string, payload: ErrataSetting): Observable<never> {
        const url = `${EndpointService.getBmBackendUrl()}/api/errata/${hotelId}`;
        return this.httpPost(url, payload, EndpointService.HTTP_HEADERS_CONTENTTYPE);
    }

    updateErrata(hotelId: string, payload: ErrataSetting[]): Observable<never> {
        const url = `${EndpointService.getBmBackendUrl()}/api/errata/${hotelId}`;
        return this.httpPut(url, payload, EndpointService.HTTP_HEADERS_CONTENTTYPE);
    }

    deleteErrata(errataId: number[], hotelId: string): Observable<ErrataSettingResponse> {
        const url = `${EndpointService.getBmBackendUrl()}/api/errata/${hotelId}/${errataId}`;
        return this.httpDelete<ErrataSettingResponse>(url, EndpointService.HTTP_HEADERS_CONTENTTYPE);
    }
}
