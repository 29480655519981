import { Injectable } from '@angular/core';
import { LangService } from '@kognitiv/angular-i18n';

import { StaticInfoService } from './static-info/static-info.service';

@Injectable()
export class TableRendererService {

    private countries = {};
    private countriesNamingFct: (country: string) => string = country => this.countries[country] || country;
    // ts[channels.name.[*]]
    private channelsNamingFct: (channel: string) => string = channel => this.lang.getWithFallback(`channels.name.${channel}`, channel);

    constructor(
        private staticInfoService: StaticInfoService,
        private lang: LangService,
    ) {
        this.staticInfoService.getStaticInfo().countries.forEach(country => {
            this.countries[country.code] = country.name;
        });
    }

    public countriesRenderer(
        type: 'EXCEPT' | 'ONLY' | '' = '',
        items: string[] = [],
    ): string {
        if (!items || items.length === 0) {
            return this.lang.get('cc.hoet.amenity.label.all');
        }

        const numMapping = {
            1: 'one',
            2: 'two',
            3: 'three',
        };
        const numStr = numMapping[items.length] || 'moreThanThree';

        const maxFirstTwoItems = items.slice(0, 2)
            .map(this.countriesNamingFct);

        // ts[bm.promotions.countries.[only|except].[one|two|three|moreThanThree].label]
        return this.lang.get(
            `bm.promotions.countries.${type.toLowerCase()}.${numStr}.label`,
            ...maxFirstTwoItems,
            items.length - 2,
        );
    }

    public channelsRenderer(
        type: 'EXCEPT' | 'ONLY' | '' = '',
        items: string[] = [],
    ): string {
        if (!items || items.length === 0) {
            return this.lang.get('cc.hoet.amenity.label.all');
        }

        const numMapping = {
            1: 'one',
            2: 'two',
            3: 'three',
        };
        const numStr = numMapping[items.length] || 'moreThanThree';

        const maxFirstTwoItems = items.slice(0, 2)
            .map(this.channelsNamingFct);

        // ts[bm.promotions.channels.[only|except].[one|two|three|moreThanThree].label]
        return this.lang.get(
            `bm.promotions.channels.${type.toLowerCase()}.${numStr}.label`,
            ...maxFirstTwoItems,
            items.length - 2,
        );
    }
}
