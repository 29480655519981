import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GuestNotification, GuestNotificationsOverview, GuestNotificationType } from './interfaces/guest-notifications.interface';
import { GuestNotificationsServiceInterface } from './interfaces/guest-notifications.service.interface';

@Injectable()
export class GuestNotificationsService implements GuestNotificationsServiceInterface {
    getGuestNotifications(hotelId: string): Observable<GuestNotificationsOverview[]> {
        throw new Error('Method not implemented');
    }
    getGuestNotification(hotelId: string, notificationType: GuestNotificationType): Observable<GuestNotification> {
        throw new Error('Method not implemented');
    }
    updateGuestNotification(hotelId: string, notification: GuestNotification): Observable<boolean> {
        throw new Error('Method not implemented');
    }
}
