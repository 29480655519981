import { Pipe, PipeTransform } from '@angular/core';
import { ContextService } from '@bmng/services/context/context.service';
import { LangService } from '@kognitiv/angular-i18n';

export interface TranslationItem { languageCode: string; text: string }

@Pipe({
    name: 'pickTranslatedStringPipe',
})
export class PickTranslatedStringPipe implements PipeTransform {
    constructor(private readonly lang: LangService, private readonly contextService: ContextService) { }

    transform(source: TranslationItem[], showMissingTranslationText = true): string {
        if (source?.length > 0) {
            const preferredLanguages: string[] = [];

            preferredLanguages.push(this.lang.getCurrentLanguage()?.toUpperCase());
            preferredLanguages.push(this.contextService.context.value.contextInformation.defaultLanguage?.toUpperCase());

            // try to get translation for user language
            let translated = source.find(s => s.languageCode.toUpperCase() === preferredLanguages[0])?.text;

            if (translated && preferredLanguages[0]) {
                return translated;
            }

            // try to get translation to property language
            translated = source.find(s => s.languageCode.toUpperCase() === preferredLanguages[1])?.text;

            if (translated && preferredLanguages[1]) {
                return translated;
            }

            // translation not found, either return empty string or warning
            return showMissingTranslationText
                ? this.lang.get('no.translation.for.label', `'${Array.from(new Set(preferredLanguages).values()).join(', ')}'`)
                : '';
        }

        return '';
    }
}
