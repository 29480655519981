import { KubeStyleVariables, KubeStyling } from './kube-style.interface';

export class KubeStyleConstants {
    static readonly NOT_FOUND_ERROR_CODE = 'BES.NFE.604';
    static readonly MAX_CSS_LENGTH = 20_000;

    // this value indicates that default value should be used in BmNg and when used in KUBE / BfWidget
    // applications. It is string value, as there were some difficulties in these applications, when
    // plain null value was used.
    static readonly INHERITED_VALUE = 'null';

    static readonly BOOKING_STYLE_DEFAULTS: KubeStyleVariables = {
        mainColor: '#ffffff',
        secondaryColor: '#555555',
        containerBackground: '#f5f5f5',
        buttonsBackground: '#459ba9',
        baseFontFamily: 'Lato',
        headingsFontFamily: 'Lato',

        searchBarButtonFontCustomColor: KubeStyleConstants.INHERITED_VALUE,
        searchBarButtonBackgroundCustomColor: KubeStyleConstants.INHERITED_VALUE,
        searchBarInputsFontCustomColor: KubeStyleConstants.INHERITED_VALUE,
        cardBackgroundCustomColor: KubeStyleConstants.INHERITED_VALUE,
        borderCustomRadius: '15',
        boxCustomShadow: 'true',
        trustYouPrimaryCustomColor: KubeStyleConstants.INHERITED_VALUE,
        trustYouFontCustomColor: '#333',
        trustYouLogoFontCustomColor: KubeStyleConstants.INHERITED_VALUE,
    };

    /**
     * Using the 100 most popular fonts from Google Fonts as of 2021-09-07
     * + fonts already used by customers
     * https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=[redacted]
     * Docs: https://developers.google.com/fonts/docs/developer_api
     * https://valsofthospitality.atlassian.net/browse/VPDC-35675
     */
    static readonly AVAILABLE_FONTS: string[] = [
        'ABeeZee',
        'Abel',
        'Abril Fatface',
        'Acme',
        'Alegreya Sans',
        'Alike Angular',
        'Almendra',
        'Amatic SC',
        'Amiko',
        'Angkor',
        'Anton',
        'Architects Daughter',
        'Archivo Narrow',
        'Arimo',
        'Arvo',
        'Asap',
        'Assistant',
        'Balsamiq Sans',
        'Barlow Condensed',
        'Barlow',
        'Bebas Neue',
        'Bitter',
        'Cabin',
        'Cairo',
        'Catamaran',
        'Caveat',
        'Comfortaa',
        'Cormorant Garamond',
        'Crimson Text',
        'Dancing Script',
        'Didact Gothik',
        'DM Sans',
        'Dosis',
        'EB Garamond',
        'Encode Sans Condensed',
        'Encode Sans',
        'Exo 2',
        'Fira Sans Condensed',
        'Fira Sans',
        'Fjalla One',
        'Heebo',
        'Hind Madurai',
        'Hind Siliguri',
        'Hind',
        'IBM Plex Sans',
        'IBM Plex Serif',
        'Inconsolata',
        'Indie Flower',
        'Inter',
        'Josefin Sans',
        'Kanit',
        'Karla',
        'Lato',
        'Libre Baskerville',
        'Libre Franklin',
        'Lobster',
        'Lora',
        'Maven Pro',
        'Merriweather Sans',
        'Merriweather',
        'Montserrat',
        'Mukta',
        'Muli',
        'Mulish',
        'Nanum Gothic',
        'Noto Sans HK',
        'Noto Sans JP',
        'Noto Sans KR',
        'Noto Sans SC',
        'Noto Sans TC',
        'Noto Sans',
        'Noto Serif JP',
        'Noto Serif',
        'Numans',
        'Nunito Sans',
        'Nunito',
        'Open Sans Condensed',
        'Open Sans',
        'Oswald',
        'Overpass',
        'Oxygen',
        'Pacifico',
        'Padauk',
        'Patrick Hand',
        'Permanent Marker',
        'Playfair Display',
        'Poppins',
        'Prompt',
        'PT Sans Narrow',
        'PT Sans',
        'PT Serif',
        'Questrial',
        'Quicksand',
        'Rajdhani',
        'Raleway',
        'Roboto Condensed',
        'Roboto Mono',
        'Roboto Slab',
        'Roboto',
        'Rubik',
        'Sarala',
        'Shadows Into Light',
        'Signika Negative',
        'Slabo 27px',
        'Source Code Pro',
        'Source Sans Pro',
        'Source Serif Pro',
        'Taviraj',
        'Teko',
        'Telex',
        'Titillium Web',
        'Ubuntu',
        'Varela Round',
        'Work Sans',
        'Yanone Kaffeesatz',
        'Yantramanav',
        'Zilla Slab',
    ];

    static readonly KUBE_STYLINGS_DEFAULT: KubeStyling = {
        id: -1,
        name: '',
        default: false,
        customStyle: {
            variables: KubeStyleConstants.BOOKING_STYLE_DEFAULTS,
            css: '',
        },
        processedCss: '',
    };
}
