import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouteHelpers } from '@bmng/helpers/route-helpers';

@Injectable()
export class TableIdGeneratorService {
    getTableId(prefix: string, activatedRoute: ActivatedRoute): string {
        const activeContext = RouteHelpers.getActiveContextFromRoute(activatedRoute.snapshot);
        return `${activeContext.type}-${prefix}`;
    }
}
