import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CodeTitle } from '@bmng/services/metadata/interfaces/metadata.interface';
import { PolicyType } from '@bmng/services/policies/interfaces/policies.interface';
import { ObjectHelpers } from '@kognitiv/bm-components';

import { UiPolicy, UiPolicyBooking, UiPolicyCancellation, UiPolicyCleanup, UiPolicyPets, UiPolicyTax } from '../../../pages/master/policies/interface/policies-ui.interface';

import { PolicyFormBookingService } from './booking/policy-form-booking.service';
import { PolicyFormCancellationService } from './cancellation/policy-form-cancellation.service';
import { PolicyFormCleanupService } from './cleanup/policy-form-cleanup.service';
import { PolicyFormPetsService } from './pets/policy-form-pets.service';
import { PolicyFormTaxService } from './tax/policy-form-tax.service';

@Injectable()
export class PolicyFormService {
    constructor(
        private bookingService: PolicyFormBookingService,
        private cancellationService: PolicyFormCancellationService,
        private cleanupFormService: PolicyFormCleanupService,
        private petsFormService: PolicyFormPetsService,
        private taxFormService: PolicyFormTaxService,
    ) {}

    getPolicyFormByType(
        policyType: PolicyType,
        policyConfig: UiPolicy,
        isNewPolicy: boolean,
        languages: CodeTitle[],
        usedCodes?: string[],
        hasPaymentProviders?: boolean,
    ): UntypedFormGroup {
        switch (policyType) {
            case 'cleanup':
                return this.cleanupFormService.getCleanupPolicyForm(
                    policyConfig as UiPolicyCleanup,
                    isNewPolicy,
                    languages,
                    usedCodes,
                );
            case 'pets':
                return this.petsFormService.getPetsPolicyForm(
                    policyConfig as UiPolicyPets,
                    isNewPolicy,
                    languages,
                    usedCodes,
                );
            case 'booking':
                return this.bookingService.getBookingPolicyForm(
                    policyConfig as UiPolicyBooking,
                    isNewPolicy,
                    languages,
                    usedCodes,
                    hasPaymentProviders,
                );
            case 'cancellation':
                return this.cancellationService.getCancellationPolicyForm(
                    policyConfig as UiPolicyCancellation,
                    isNewPolicy,
                    languages,
                    usedCodes,
                );
            case 'tax':
                return this.taxFormService.getTaxPolicyForm(
                    policyConfig as UiPolicyTax,
                    isNewPolicy,
                    languages,
                    usedCodes,
                );
            default:
                ObjectHelpers.assertNever(policyType);
        }
    }

    updatePolicySummaryByType(
        policyType: PolicyType,
        policyConfig: UiPolicy,
        form: UntypedFormGroup,
    ): void {
        if (!policyType) {
            return;
        }
        switch (policyType) {
            case 'booking':
                return;

            case 'cancellation':
                policyConfig = policyConfig as UiPolicyCancellation;
                return this.cancellationService.updateCancellationPolicySummary(
                    policyConfig,
                    form,
                );

            case 'tax':
                return;

            case 'pets':
                return;

            case 'cleanup':
                return;

            default:
                ObjectHelpers.assertNever(policyType);
        }
    }
}
