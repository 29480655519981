import { Component, Input } from '@angular/core';
import { MenuItemChild } from '@bmng/services/menu/interfaces/menu.interface';

@Component({
    selector: 'bmng-menu-item-leaf',
    templateUrl: './menu-item-leaf.component.html',
    styleUrls: [ './menu-item-leaf.component.scss' ],
})
export class MenuItemLeafComponent {
    @Input()
    menuItem: MenuItemChild;

    @Input()
    showChildrenChevron: boolean = false;

    @Input()
    submenuOpen: boolean = false;

    @Input()
    showIcon: boolean = true;
}
