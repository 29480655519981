import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../shared/shared.module';

import { HotelDashboardRoutingModule } from './dashboard-routing.module';
import { HotelDashboardComponent } from './dashboard.component';

@NgModule({
    imports: [
        SharedModule,
        RouterModule,
        HotelDashboardRoutingModule,
    ],
    declarations: [
        HotelDashboardComponent,
    ],
})
export class HotelDashboardModule {}
