import { Component, EventEmitter, HostBinding, Input, Output, ViewChild, ViewChildren } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { MenuItemRoot } from '@bmng/services/menu/interfaces/menu.interface';

import { MENU_ANIMATION } from '../menu-animations';
import { SubMenuItemComponent } from '../sub-menu-item/sub-menu-item.component';

@Component({
    selector: 'bmng-main-menu-item',
    templateUrl: './main-menu-item.component.html',
    styleUrls: [ './main-menu-item.component.scss' ],
    animations: [
        MENU_ANIMATION,
    ],
})
export class MainMenuItemComponent {
    @Input()
    menuItem: MenuItemRoot;

    @Input()
    baseContextUrl: string;

    @Output()
    submenuOpenChanged: EventEmitter<boolean> = new EventEmitter();

    @ViewChild(RouterLinkActive)
    routerLinkActive: RouterLinkActive;

    @ViewChildren(SubMenuItemComponent)
    subMenus: SubMenuItemComponent[];

    submenuOpen: boolean = false;

    @HostBinding('class.active')
    get active(): boolean {
        return this.isActive();
    }

    constructor(
    ) { }

    private isActive(): boolean {
        if (this.subMenus && this.subMenus.length) {
            return this.subMenus.some(s => s.isActive());
        } else if (this.routerLinkActive) {
            return this.routerLinkActive.isActive;
        }

        return false;
    }

    toggleSubMenu(newStatus: boolean = !this.submenuOpen): void {
        this.submenuOpen = newStatus;
        this.submenuOpenChanged.emit(this.submenuOpen);
    }

    subMenuActiveChanged(subMenuActive: boolean): void {
        if (subMenuActive) {
            this.toggleSubMenu(true);
        }
    }

}
