import { Injectable } from '@angular/core';
import { Policy, PolicyBooking, PolicyCancellation, PolicyCleanup, PolicyPets, PolicyTax, PolicyType } from '@bmng/services/policies/interfaces/policies.interface';
import { ObjectHelpers } from '@kognitiv/bm-components';
import { Observable, of } from 'rxjs';

import { BookingSettings } from '../../../core/services/metadata/interfaces/metadata.interface';
import { PolicyContext, UiPolicy } from '../../../pages/master/policies/interface/policies-ui.interface';

import { PolicyFormBookingUiService } from './booking/policy-form-booking-ui.service';
import { PolicyFormCancellationUiService } from './cancellation/policy-form-cancellation-ui.service';
import { PolicyFormCleanupUiService } from './cleanup/policy-form-cleanup-ui.service';
import { PolicyFormPetsUiService } from './pets/policy-form-pets-ui.service';
import { PolicyFormTaxUiService } from './tax/policy-form-tax-ui.service';

@Injectable()
export class PolicyFormUiService {
    constructor(
        private bookingUiService: PolicyFormBookingUiService,
        private cancellationUiService: PolicyFormCancellationUiService,
        private taxUiService: PolicyFormTaxUiService,
        private petsUiService: PolicyFormPetsUiService,
        private cleanupUiService: PolicyFormCleanupUiService,
    ) {}

    getUiPolicyConfigByType(
        policy: Policy,
        policyType: PolicyType,
        bookingSettings?: BookingSettings,
        context?: PolicyContext,
        portalId?: string,
    ): UiPolicy {
        if (!policy || !policyType) {
            return;
        }

        const policyContext: PolicyContext = context || 'hotel';

        switch (policyType) {
            case 'booking':
                return this.bookingUiService.getUiBookingPolicyConfig(policy as PolicyBooking, policyContext, bookingSettings, portalId);

            case 'cancellation':
                return this.cancellationUiService.getUiCancellationPolicyConfig(policy as PolicyCancellation, policyContext, portalId);

            case 'tax':
                return this.taxUiService.getUiTaxPolicyConfig(policy as PolicyTax);

            case 'pets':
                return this.petsUiService.getUiPetsPolicyConfig(policy as PolicyPets);

            case 'cleanup':
                return this.cleanupUiService.getUiCleanupPolicyConfig(policy as PolicyCleanup);

            default:
                ObjectHelpers.assertNever(policyType);
        }
    }

    getDefaultPolicyConfigByType(
        policyType: PolicyType,
        currencyCode: string,
        portalId?: string,
    ): Policy {
        if (!policyType) {
            return;
        }
        switch (policyType) {
            case 'booking':
                return this.bookingUiService.getDefaultBookingPolicyConfig(currencyCode, portalId);

            case 'cancellation':
                return this.cancellationUiService.getDefaultCancellationPolicyConfig(currencyCode, null, portalId);

            case 'tax':
                return this.taxUiService.getDefaultTaxPolicyConfig(currencyCode);

            case 'pets':
                return this.petsUiService.getDefaultPetsPolicyConfig(currencyCode);

            case 'cleanup':
                return this.cleanupUiService.getDefaultCleanupPolicyConfig(currencyCode);

            default:
                ObjectHelpers.assertNever(policyType);
        }
    }

    getDefaultPolicyConfigByTypeAsObservable(
        policyType: PolicyType,
        currencyCode: string,
        portalId?: string,
    ): Observable<Policy> {
        if (!policyType) {
            return;
        }

        const policyConfig = this.getDefaultPolicyConfigByType(policyType, currencyCode, portalId);

        return of(policyConfig);
    }
}
