
import { Injectable } from '@angular/core';
import { LangService } from '@kognitiv/angular-i18n';
import { LanguageConfig } from '@kognitiv/bm-components';
import { LangConfigService } from '@kognitiv/bm-components';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class I18nService {
    private alreadyResolved$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private lang: LangService,
        private langConfigService: LangConfigService,
    ) { }

    changeLanguage(langConfig: LanguageConfig): Observable<string> {
        if (this.alreadyResolved$.value && langConfig.language === this.lang.getCurrentLanguage().toLowerCase()) {
            return of(langConfig.language);
        } else {
            // TODO This should be the only place the language changes ?!
            return forkJoin([
                this.lang.changeLanguage('bm-frontend', '/assets/langs', langConfig.language.toLowerCase()),
                this.langConfigService.changeLanguage(langConfig),
            ]).pipe(
                tap(() => this.alreadyResolved$.next(true)),
                map(() => langConfig.language));
        }
    }

    isLanguageLoaded(): Observable<boolean> {
        return this.alreadyResolved$.asObservable();
    }
}
