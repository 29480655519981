import { Injectable } from '@angular/core';
import { PolicyDescription, PolicyPets } from '@bmng/services/policies/interfaces/policies.interface';

import { UiPetsPaymentPolicy, UiPolicyPets } from '../../../../pages/master/policies/interface/policies-ui.interface';

@Injectable()
export class PolicyFormPetsUiService {
    getUiPetsPolicyConfig(policy: PolicyPets): UiPolicyPets {
        const petsAllowed = this.getPetsAllowed(policy);
        const uiPolicy: UiPolicyPets = {
            ...policy,
            type: 'pets',
            petsAllowed,
            descriptionsNoPetsAllowed: this.getNoPetsAllowedDescriptions(petsAllowed, policy),
            descriptionNoPetsAllowedTabIndex: 0,
            uiPaymentPolicies: this.getUiPetsPaymentPolicies(petsAllowed, policy),
        };

        return uiPolicy;
    }

    private getUiPetsPaymentPolicies(petsAllowed: number, policy: PolicyPets): UiPetsPaymentPolicy[] {
        if (petsAllowed) {
            return (policy.paymentPolicies || []).map(paymentPolicy => {
                const uiPaymentPolicy: UiPetsPaymentPolicy = {
                    petsType: paymentPolicy.petsType,
                    amount: paymentPolicy.amount,
                    descriptions: paymentPolicy.descriptions,
                    descriptionTabIndex: 0,
                };
                return uiPaymentPolicy;
            });
        } else if (petsAllowed === 0) {
            const uiPaymentPolicy: UiPetsPaymentPolicy = {
                petsType: null,
                descriptions: [],
                descriptionTabIndex: 0,
            };

            return [ uiPaymentPolicy ];
        } else {
            /* only for invalid legacy pets policies */
            return (policy.paymentPolicies || [])
                .filter(paymentPolicy => paymentPolicy.petsType !== 'NoPetsAllowed')
                .map(paymentPolicy => {
                    const uiPaymentPolicy: UiPetsPaymentPolicy = {
                        petsType: paymentPolicy.petsType,
                        descriptions: paymentPolicy.descriptions,
                        descriptionTabIndex: 0,
                    };
                    return uiPaymentPolicy;
                },
                );
        }
    }

    private getPetsAllowed(policy: PolicyPets): number | null {
        const paymentPoliciesCount = policy.paymentPolicies.length;
        const noPetsAllowedPolicy = policy.paymentPolicies.find(paymentPolicy => paymentPolicy.petsType === 'NoPetsAllowed');
        if (noPetsAllowedPolicy && paymentPoliciesCount > 1) {
            /* flag for invalid legacy pets policies */
            return null;
        }

        if (noPetsAllowedPolicy && paymentPoliciesCount === 1) {
            return 0;
        }

        return 1;
    }

    private getNoPetsAllowedDescriptions(petsAllowed: number, policy: PolicyPets): PolicyDescription[] {
        if (!petsAllowed) {
            const noPetsAllowedPolicy = policy.paymentPolicies.find(paymentPolicy => paymentPolicy.petsType === 'NoPetsAllowed');
            return noPetsAllowedPolicy.descriptions || [];
        } else {
            return [];
        }
    }

    getDefaultPetsPolicyConfig(currencyCode: string): PolicyPets {
        return {
            code: `PET`,
            currencyCode,
            paymentPolicies: [ {
                amount: null,
                petsType: 'NoPetsAllowed',
                descriptions: [],
            } ],
        };
    }
}
