import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { Observable } from 'rxjs';

import { KubeStyling } from '../booking-styles/kube-style.interface';
import { KubeWidgetsContextType } from '../context/interfaces/context-type.type';
import { EndpointService } from '../endpoint.service';
import { ListResult } from '../list-results.interface';
import {
    BookingEngine,
    BookingEngineCreate,
    BookingEngineRequestParams,
    BookingEngineStylingPreview,
    BookingEngineSummary,
    BookingEngineUpdate,
} from './interfaces/booking-engine.interface';
import { BookingEngineServiceInterface } from './interfaces/booking-engine.service.interface';


export interface DSRConversionStep {
    event_collection: string;
    optional?: boolean;
    actor_property?: string;
    timeframe?: string;
    filters?: unknown[];
}

export interface DSRFilter {
    property_name: string;
    operator: string;
    property_value: string;
    channel_name?: string;
    channel_value?: string;
}

@Injectable({
    providedIn: 'root',
})
export class BookingEngineService extends EndpointService implements BookingEngineServiceInterface {
    loadBookingEngine(hotelId: string): Observable<BookingEngine> {
        return this.httpGet<BookingEngine>(this.getBookingEngineUrl(hotelId), EndpointService.HTTP_HEADERS);
    }

    getStylesPreview(propertyId: string,
        propertyType: KubeWidgetsContextType,
        data: KubeStyling): Observable<BookingEngineStylingPreview> {
        const url: string = `${EndpointService.getBmBackendUrl()}/api/${propertyType}/${propertyId}/bookingEngine/preview/style`;
        return this.httpPost(url, data, EndpointService.HTTP_HEADERS);
    }

    saveBookingEngine(hotelId: string, bookingEngine: BookingEngine): Observable<BookingEngine> {
        return this.httpPatch<BookingEngine>(this.getBookingEngineUrl(hotelId), bookingEngine, EndpointService.HTTP_HEADERS, true);
    }

    getBookingEnginesList(hotelId: string, requestParams: BookingEngineRequestParams): Observable<ListResult<BookingEngineSummary>> {
        const url = UrlHelpers.buildUrl(this.getBookingEngineUrl(hotelId), requestParams);
        return this.httpGet<ListResult<BookingEngineSummary>>(url, EndpointService.HTTP_HEADERS);
    }

    updateBookingEngine(hotelId: string, code: string, data: BookingEngineUpdate): Observable<boolean> {
        return this.httpPatch(this.getBookingEngineUrl(hotelId, code), data, EndpointService.HTTP_HEADERS);
    }
    createBookingEngine(hotelId: string, data: BookingEngineCreate): Observable<BookingEngineSummary> {
        return this.httpPost(this.getBookingEngineUrl(hotelId), data, EndpointService.HTTP_HEADERS);
    }

    getBookingEngine(hotelId: string, code: string): Observable<BookingEngineSummary> {
        return this.httpGet(this.getBookingEngineUrl(hotelId, code), EndpointService.HTTP_HEADERS);
    }

    deleteBookingEngine(hotelId: string, code: string): Observable<void> {
        return this.httpDelete(this.getBookingEngineUrl(hotelId, code), EndpointService.HTTP_HEADERS);
    }

    private getBookingEngineUrl(hotelId: string, code?: string): string {
        return `${EndpointService.getBmBackendUrl()}/api/hotels/${hotelId}/bookingengines${code ? `/${code}` : ''}`;
    }

    // Warning: this is temporarily used
    // will remove it once visitors are available
    getCircleVisitor(hotelId: string, circleId: string): Observable<number> {
        const url: string = `${EndpointService.getBmBackendUrl()}/api/cm/hotel/${hotelId}/events/ibe/count_unique`;

        const body = {
            timeframe: 'previous_30_days',
            target_property: 'uid',
            filters: [
                { operator: 'eq', property_name: 'channelId', property_value: circleId === 'ibe' ? 'ibe' : `${hotelId}-${circleId}` },
            ],
        };

        return this.httpPost(url, body, EndpointService.HTTP_HEADERS);
    }
}
