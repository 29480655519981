import { Component, EventEmitter, HostBinding, Input, Output, ViewChild, ViewChildren } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { DistinctEventEmitter } from '@bmng/helpers/distinct-event-emitter';
import { MenuItem } from '@bmng/services/menu/interfaces/menu.interface';
import { PageService } from '@bmng/services/page.service';

import { MENU_ANIMATION } from '../menu-animations';
import { SubSubMenuItemComponent } from '../sub-sub-menu-item/sub-sub-menu-item.component';

@Component({
    selector: 'bmng-sub-menu-item',
    templateUrl: './sub-menu-item.component.html',
    styleUrls: [ './sub-menu-item.component.scss' ],
    animations: [
        MENU_ANIMATION,
    ],
})
export class SubMenuItemComponent {
    @Input()
    subMenuItem: MenuItem;

    @Input()
    baseContextUrl: string;

    @Output()
    activeChanged: EventEmitter<boolean> = new DistinctEventEmitter();

    @Output()
    submenuOpenChanged: EventEmitter<boolean> = new DistinctEventEmitter();

    @ViewChild(RouterLinkActive)
    routerLinkActive: RouterLinkActive;

    @ViewChildren(SubSubMenuItemComponent)
    subMenus: SubSubMenuItemComponent[];

    submenuOpen: boolean = false;

    @HostBinding('class.active')
    get active(): boolean {
        return this.isActive();
    }

    constructor(
        private pageService: PageService,
    ) { }

    isActive(): boolean {
        let active = false;

        if (this.subMenus && this.subMenus.length) {
            active = this.subMenus.some(s => s.isActive());
        } else if (this.routerLinkActive) {
            active = this.routerLinkActive.isActive;
        }

        this.activeChanged.emit(active);

        return active;
    }

    toggleSubMenu(newStatus: boolean = !this.submenuOpen): void {
        this.submenuOpen = newStatus;
        this.submenuOpenChanged.emit(this.submenuOpen);
    }

    subMenuActiveChanged(subMenuActive: boolean): void {
        if (subMenuActive) {
            this.toggleSubMenu(true);
        }
    }

    closeMenu(): void {
        if (this.pageService.isMobileView()) {
            this.pageService.toggleMenuShown(false);
        }
    }
}
