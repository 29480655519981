import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export const MENU_ANIMATION: AnimationTriggerMetadata = trigger('slideOpen', [
    state('close', style({
        height: 0,
    })),
    state('open', style({
        height: '*',
    })),
    transition('open <=> close', animate('0.4s ease-in-out')),
]);
