import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { EndpointService } from '@bmng/services/endpoint.service';
import { Policy, PolicySortDirection, SetDefaultPolicyData } from '@bmng/services/policies/interfaces/policies.interface';
import { LangService } from '@kognitiv/angular-i18n';
import { Observable } from 'rxjs';

import { PoliciesResponse, PolicyType, PortalPolicySort } from './interfaces/policies.interface';
import { PoliciesServiceInterface } from './interfaces/policies.interface.service';

@Injectable()
export class PoliciesService extends EndpointService implements PoliciesServiceInterface {
    public policyOrderChange: EventEmitter<PolicySortDirection> = new EventEmitter();

    constructor(
        http: HttpClient,
        private langService: LangService,
    ) {
        super(http);
    }

    getPolicies(
        entityId: string,
        isPortal?: boolean,
    ): Observable<PoliciesResponse> {
        const languageCode = this.langService.getCurrentLanguage();
        let url = `${this.getBaseUrl(isPortal)}/${entityId}/policies`;
        url = UrlHelpers.buildUrl(url, { languageCode } );

        return this.httpGet<PoliciesResponse>(url, EndpointService.HTTP_HEADERS);
    }

    getPolicy(
        entityId: string,
        policyType: PolicyType,
        policyCode: string,
        isPortal?: boolean,
    ): Observable<Policy> {
        const languageCode = this.langService.getCurrentLanguage();
        let url: string = `${this.getBaseUrl(isPortal)}/${entityId}/policies/${policyType}/${policyCode}`;
        url = UrlHelpers.buildUrl(url, { languageCode } );

        return this.httpGet<Policy>(url, EndpointService.HTTP_HEADERS);
    }

    savePolicy(
        entityId: string,
        policyType: PolicyType,
        isNewPolicy: boolean,
        policy: Policy,
        isPortal?: boolean,
    ): Observable<Policy> {
        const languageCode = this.langService.getCurrentLanguage();
        let url = `${this.getBaseUrl(isPortal)}/${entityId}/policies/${policyType}`;

        if (isNewPolicy) {
            url = UrlHelpers.buildUrl(url, { languageCode } );
            return this.httpPost(url, policy, EndpointService.HTTP_HEADERS);
        } else {
            url += `/${policy.code}`;
            url = UrlHelpers.buildUrl(url, { languageCode } );
            return this.httpPut(url, policy, EndpointService.HTTP_HEADERS);
        }
    }

    removePolicy(
        entityId: string,
        code: string,
        policyType: PolicyType,
        isPortal?: boolean,
    ): Observable<boolean> {
        const url: string = `${this.getBaseUrl(isPortal)}/${entityId}/policies/${policyType}/${code}`;

        return this.httpDelete(url, EndpointService.HTTP_HEADERS);
    }

    setDefaultPolicy(
        hotelId: string,
        policyType: PolicyType,
        policyCode: string,
        currencyCode?: string,
    ): Observable<boolean> {
        const url = `${this.getBaseUrl()}/${hotelId}/policies/${policyType}/default`;
        const payload: SetDefaultPolicyData = {
            code: policyCode,
        };
        if (currencyCode) {
            payload.currencyCode = currencyCode;
        }
        return this.httpPut(url, payload, EndpointService.HTTP_HEADERS);
    }

    getPolicyPreview(
        entityId: string,
        policyType: PolicyType,
        policy: Policy,
        isPortal?: boolean,
    ): Observable<Policy> {
        const languageCode = this.langService.getCurrentLanguage();
        let url = `${this.getBaseUrl(isPortal)}/${entityId}/policies/${policyType}/preview`;
        url = UrlHelpers.buildUrl(url, { languageCode } );

        return this.httpPost(url, policy, EndpointService.HTTP_HEADERS);
    }

    reorderPolicies(
        entityId: string,
        policyType: PolicyType,
        sortList: PortalPolicySort[],
    ) {
        const url = `${this.getBaseUrl(true)}/${entityId}/policies/${policyType}/sort`;
        return this.httpPost(url, sortList, EndpointService.HTTP_HEADERS);
    }

    private getBaseUrl(isPortal?: boolean) {
        return `${EndpointService.getBmBackendUrl()}/api/` + (isPortal ? 'channels' : 'hotels');
    }
}
