import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PetsPaymentPolicy, PolicyPets } from '@bmng/services/policies/interfaces/policies.interface';

import { UiPolicyPets } from '../../../../pages/master/policies/interface/policies-ui.interface';

@Injectable()
export class PolicyFormPetsSubmitService {
    getPetsPolicyConfig(form: UntypedFormGroup): PolicyPets {
        if (!form) {
            return;
        }
        const uiPolicyConfig = form.value as UiPolicyPets;
        const petsPolicy: PolicyPets = {
            code: uiPolicyConfig.code,
            paymentPolicies: [],
        };

        if (!uiPolicyConfig.petsAllowed) {
            const paymentPolicy: PetsPaymentPolicy = {
                petsType: 'NoPetsAllowed',
                descriptions: uiPolicyConfig.descriptionsNoPetsAllowed,
            };

            petsPolicy.paymentPolicies = [ paymentPolicy ];
        } else {
            if (Array.isArray(uiPolicyConfig.uiPaymentPolicies)) {
                let hasAmount: boolean = false;
                petsPolicy.paymentPolicies = uiPolicyConfig.uiPaymentPolicies.map(uiPaymentPolicy => {
                    const paymentPolicy: PetsPaymentPolicy = {
                        petsType: uiPaymentPolicy.petsType,
                        descriptions: uiPaymentPolicy.descriptions,
                    };

                    if (typeof uiPaymentPolicy.amount === 'number' || typeof uiPaymentPolicy.amount === 'string') {
                        paymentPolicy.amount = uiPaymentPolicy.amount;
                        hasAmount = true;
                    }

                    return paymentPolicy;
                });

                if (hasAmount) {
                    petsPolicy.currencyCode = uiPolicyConfig.currencyCode;
                }
            }
        }

        return petsPolicy;
    }
}
