import { Injectable } from '@angular/core';
import { NavigationExtras, NavigationStart, PRIMARY_OUTLET, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { NavigationResult, PageResultRef, RoutingNavigator } from './routing-navigator.interface';

@Injectable({
    providedIn: 'root',
})
export class StackableRoutingNavigatorService implements RoutingNavigator {
    static readonly OUTLET_NAMES = [ 'level1', 'level2' ];

    private curLevel = 0;

    private readonly levelChange = new BehaviorSubject<NavigationResult>([ 0, null, 'Nop', null ]);
    private sourceTag: number;

    constructor(
        private router: Router,
    ) {
        this.registerBackAndForthButtonHandler();
    }

    private registerBackAndForthButtonHandler(): void {
        if (!!this.router?.events) {
            this.router.events.pipe(
                filter((event: NavigationStart) => event?.navigationTrigger === 'popstate'),
            ).subscribe(_ => this.closeAllModals());
        }
    }

    private closeAllModals(): void {
        while (this.curLevel > 0) {
            this.close();
        }
    }

    navigate(route: string | string[], extras?: NavigationExtras): void {
        if (this.curLevel === 0) {
            const params: string[] = Array.isArray(route) ? route : [ route ];
            this.router.navigate(params, extras);
        } else {
            this.navigateInCurrentOutlet(route, extras);
        }
    }

    private navigateInCurrentOutlet(route: string | string[], extras?: NavigationExtras): void {
        const params = { outlets: {} };
        const extraParams = extras || {};
        const outletName = this.getCurrentOutletName();

        extraParams.skipLocationChange = true;
        params.outlets[outletName] = route;

        this.router.navigate([ params ], extraParams);
    }

    close(result?: PageResultRef): void {
        this.navigateInCurrentOutlet(null);
        this.curLevel--;
        this.levelChange.next([ this.curLevel, result, 'Close', this.sourceTag ]);
    }

    navigateInChild(route: string | string[], extras?: NavigationExtras, tag?: number): void {
        this.curLevel++;
        this.navigateInCurrentOutlet(route, extras);
        this.sourceTag = tag;
        this.levelChange.next([ this.curLevel, null, 'Open', this.sourceTag ]);
    }

    getStackHeight(): number {
        return this.curLevel;
    }

    getCurrentOutletName(): string {
        if (this.curLevel > 0) {
            const idx = this.curLevel - 1;
            return StackableRoutingNavigatorService.OUTLET_NAMES[idx];
        }

        return PRIMARY_OUTLET;
    }

    isInStack(outletName: string): boolean {
        if (outletName === PRIMARY_OUTLET) {
            return false;
        }

        return StackableRoutingNavigatorService.OUTLET_NAMES.indexOf(outletName) >= 0;
    }

    getActiveStackLevel(): Observable<NavigationResult> {
        return this.levelChange;
    }
}
