import { Component, Injectable, OnInit } from '@angular/core';
import { ReservationStatus } from '@bmng/core/services/reservations/interfaces/reservations.interface';
import { LangService } from '@kognitiv/angular-i18n';
import { RenderComponent } from '@kognitiv/bm-components';

import { StatusDetail } from './status-detail.interface';

export interface ObjectWithPossibleStatus {
    status?: ReservationStatus;
}

@Injectable()
export class ReservationStatusHelpers {
    private statusDetails: { [ K in ReservationStatus ]: StatusDetail } = {
        Cancelled:     { label: this.lang.get('cc.reservation.status.cancelled'),         color: 'chart-5' },
        Expired:       { label: this.lang.get('cc.reservation.status.expired'),           color: 'secondary' },
        Imported:      { label: this.lang.get('cc.reservation.status.imported'),          color: 'chart-3' },
        Initiate:      { label: this.lang.get('cc.reservation.status.initiate'),          color: 'chart-4' },
        Modify:        { label: this.lang.get('cc.reservation.status.modify'),            color: 'chart-3' },
        NoShow:        { label: this.lang.get('common.reservation.status.no-show.label'), color: 'chart-2' },
        OnHold:        { label: this.lang.get('common.reservation.status.noun.onhold'),   color: 'chart-4' },
        Pending:       { label: this.lang.get('common.reservation.status.noun.pending'),  color: 'chart-4' },
        Requested:     { label: this.lang.get('cc.reservation.status.requested'),         color: 'chart-6' },
        RequestDenied: { label: this.lang.get('cc.reservation.status.request.denied'),    color: 'chart-5' },
        Reserved:      { label: this.lang.get('cc.reservation.status.reserved'),          color: 'chart-3' },
    };

    private getUnknownStatus = (unKnownCode: string): StatusDetail => ({ label: unKnownCode, color: 'warning' });

    constructor(
        private lang: LangService,
    ) {}

    getStatusDetail<K extends keyof StatusDetail>(detailType: K, status: ReservationStatus): StatusDetail[K] {
        const statusDetails = this.getStatusMap(status) || this.getUnknownStatus(status);
        return statusDetails[detailType];
    }

    getAllReservationStatuses(): ReservationStatus[] {
        return <ReservationStatus[]> Object.keys(this.statusDetails);
    }

    getStatusMap(status: ReservationStatus): StatusDetail {
        return this.statusDetails[status];
    }
}

@Component({
    selector: 'reservation-status-badge-renderer',
    template: `
        <span bmBadge [color]="color">{{ label }}</span>
    `,
})
export class ReservationStatusBadgeRendererComponent<T extends ObjectWithPossibleStatus> extends RenderComponent<T> implements OnInit {
    data: T;
    label: string;
    color: string;

    constructor(
        public statusHelpers: ReservationStatusHelpers,
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.data.status) {
            const status: ReservationStatus = this.data.status;
            if (status) {
                this.label = this.statusHelpers.getStatusDetail('label', status);
                this.color = this.statusHelpers.getStatusDetail('color', status);
            }
        }
    }
}
