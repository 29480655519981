import { Pipe, PipeTransform } from '@angular/core';
import { EndpointService } from '@bmng/services/endpoint.service';

@Pipe({
    name: 'brandingLogo',
})
export class BrandingLogoPipe implements PipeTransform {
    transform(logo: string): string {
        if (!logo) {
            return '';
        }

        const imgUrl = `${EndpointService.getBmBackendUrl()}${logo}`;
        return `url('${imgUrl}')`;
    }
}
