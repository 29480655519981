import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DateRangeSingleDatesTranslator } from '@bmng/helpers/date-range-single-dates-translator';
import { BookingOnHold, BookingPaymentPolicy, PolicyBooking } from '@bmng/services/policies/interfaces/policies.interface';
import { DateRangeStartEnd } from '@kognitiv/bm-components';

import { UiBookingOnHold, UiBookingPaymentPolicy, UiPolicyBooking } from '../../../../pages/master/policies/interface/policies-ui.interface';

@Injectable()
export class PolicyFormBookingSubmitService {
    getBookingPolicyConfig(form: UntypedFormGroup): PolicyBooking {
        if (!form) {
            return;
        }

        const policyValue = form.getRawValue() as UiPolicyBooking;

        const policyConfig: PolicyBooking = {
            code: policyValue.code,
            textOnly: policyValue.textOnly,
            bookingType: policyValue.bookingType,
            paymentPolicies: this.getPaymentPolicies(policyValue.uiPaymentPolicies, policyValue.textOnly),
        };

        if(policyValue.portalId) {
            policyConfig.portalId = policyValue.portalId;
            policyConfig.applicability = {
                hotels: policyValue.uiApplicability.hotels.map(code => ({ code })),
                suppliers: policyValue.uiApplicability.suppliers.map(code => ({ code })),
            };
        }

        if (policyValue.hasOnHoldEnabled && policyValue.uiOnHold) {
            policyConfig.onHold = this.getOnHold(policyValue.uiOnHold);
        }

        if (this.hasCurrencyCode(policyConfig)) {
            policyConfig.currencyCode = policyValue.currencyCode;
        }

        if (policyValue.portalId) {
            policyConfig.enabled = policyValue.enabled;
            policyConfig.sort = policyValue.sort;
        }

        return policyConfig;
    }

    hasCurrencyCode(policyConfig: PolicyBooking): boolean {
        const hasOnHoldPromotion = policyConfig.onHold && policyConfig.onHold.promotion;
        const hasCurrencyInOnHold = hasOnHoldPromotion && policyConfig.onHold.promotion.hasOwnProperty('amount');
        const hasCurrencyInPayments = policyConfig.paymentPolicies.some(policy => policy.hasOwnProperty('amount'));
        return hasCurrencyInOnHold || hasCurrencyInPayments;
    }

    private getOnHold(uiOnHold: UiBookingOnHold): BookingOnHold {

        const bookingOnHold: BookingOnHold = {
            durationHours: uiOnHold.durationHours,
            minimumLeadTimeDays: uiOnHold.minimumLeadTimeDays,
            availabilityThreshold: uiOnHold.availabilityThreshold,
            stayDatesExcluded: uiOnHold.stayDatesType === 'blackOut' ? this.getStartAndEndArray(uiOnHold.uiStayDatesExcluded) : [],
            stayDatesIncluded: uiOnHold.stayDatesType === 'custom' ? this.getStartAndEndArray(uiOnHold.uiStayDatesIncluded)  : [],
        };

        if (uiOnHold.hasLeadTimeEnabled) {
            bookingOnHold.reminderLeadTimeHours = uiOnHold.reminderLeadTimeHours;
        }

        if (uiOnHold.hasPromotionEnabled) {
            if (uiOnHold.promotionType === 'amount') {
                bookingOnHold.promotion = { amount: uiOnHold.promotion.amount };
            } else {
                bookingOnHold.promotion = { percent: uiOnHold.promotion.percent };
            }
        }

        return bookingOnHold;
    }

    private getPaymentPolicies(uiPaymentPolicies: UiBookingPaymentPolicy[], textOnly: boolean): BookingPaymentPolicy[] {
        return uiPaymentPolicies.map((payment, index) => {

            const policy: BookingPaymentPolicy = {
                priority: index + 1,
                guaranteeType: payment.guaranteeType,
                descriptions: payment.descriptions || [],
            };

            const hasAmountOrPercentByType = payment.guaranteeType !== 'None' && payment.guaranteeType !== 'CcStore';

            if (hasAmountOrPercentByType && payment.hasAmount && !textOnly) {
                policy.amount = payment.amount;
            }

            if (hasAmountOrPercentByType && payment.hasPercent && !textOnly) {
                policy.percent = payment.percent;
            }

            if (!textOnly && hasAmountOrPercentByType && payment.hasPercent && payment.stayType === 'Nights') {
                policy.numberOfNights = payment.numberOfNights;
            }

            if (payment.paymentPolicyType === 'override') {
                policy.dateRanges = this.getStartAndEndArray(payment.uiDateRanges);
            }

            return policy;
        });
    }

    private getStartAndEndArray(dateRanges: string[]): DateRangeStartEnd[] {
        return DateRangeSingleDatesTranslator.translateToRangesStartEnd(dateRanges);
    }
}
