import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LangService } from '@kognitiv/angular-i18n';
import { SelectOption } from '@kognitiv/bm-components';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EndpointService } from './../endpoint.service';
import { StaticInfoServiceInterface } from './interfaces/static-info-service.interface';
import { StaticInfo } from './interfaces/static-info.interface';

@Injectable()
export class StaticInfoService extends EndpointService implements StaticInfoServiceInterface {
    private staticInfo: StaticInfo;

    constructor(
        http: HttpClient,
        private lang: LangService,
    ) {
        super(http);
    }

    loadStaticInfo(): Observable<StaticInfo> {
        const languageCode = this.lang.getCurrentLanguage();
        const url = `${EndpointService.getBmBackendUrl()}/api/cm/api/staticinfos?lang=${languageCode}`;

        return this.httpGet<StaticInfo>(url, EndpointService.HTTP_HEADERS_ACCEPT).pipe(
            map(staticInfo => this.staticInfo = staticInfo),
        );
    }

    getStaticInfo(): StaticInfo {
        return this.staticInfo;
    }

    getCurrencyListAsSelectOptions(defaultCurrency?: string): SelectOption<string, string>[] {
        const currencyCodes = Object.keys(this.getStaticInfo().currencySymbols);

        // filter out prioritized but non existing currencies
        let prioritizedCurrencyCodes = [ defaultCurrency, 'EUR', 'USD', 'MXN' ]
            .filter(currency => currencyCodes.includes(currency));

        prioritizedCurrencyCodes = Array.from(new Set(prioritizedCurrencyCodes));

        return prioritizedCurrencyCodes.concat(
            currencyCodes
                .filter(code => !prioritizedCurrencyCodes.includes(code))
                .sort())
            .map(code => ({ id: code, text: code }));
    }

    getCountryName(countryCode: string): string {
        if (!countryCode) {
            return;
        }

        const country = this.staticInfo.countries.find(_c => _c.code === countryCode);
        const countryName = country ? country.name : countryCode;

        return countryName;
    }
}
