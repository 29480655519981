import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DateRangeSingleDatesTranslator } from '@bmng/helpers/date-range-single-dates-translator';
import { UiCancellationPaymentPolicy, UiCancellationPenalty, UiPolicyCancellation } from '@bmng/pages/master/policies/interface/policies-ui.interface';
import { CancellationPaymentPolicy, CancellationPenalty, PolicyCancellation, PolicyDescription } from '@bmng/services/policies/interfaces/policies.interface';

@Injectable()
export class PolicyFormCancellationSubmitService {
    getCancellationPolicyConfig(form: UntypedFormGroup): PolicyCancellation {
        if (!form) {
            return;
        }

        const uiPolicyValue = form.value as UiPolicyCancellation;
        uiPolicyValue.uiPaymentPolicies = uiPolicyValue.uiPaymentPolicies || [];

        const policyConfig: PolicyCancellation = {
            code: uiPolicyValue.code,
            onlineCancellationByGuest: uiPolicyValue.onlineCancellationByGuest,
            textOnly: uiPolicyValue.textOnly,
            paymentPolicies: uiPolicyValue.uiPaymentPolicies
                .map((uiPaymentPolicy, index) => this.getCancellationPaymentPolicy(uiPaymentPolicy, index, uiPolicyValue))
                .filter(paymentPolicy => !!paymentPolicy),
        };

        if (policyConfig.paymentPolicies.some(paymentPolicy => (paymentPolicy.penalties || []).some(
            penalty => penalty.hasOwnProperty('amount'),
        ))) {
            policyConfig.currencyCode = uiPolicyValue.currencyCode;
        }

        if(uiPolicyValue.portalId) {
            policyConfig.portalId = uiPolicyValue.portalId;
            policyConfig.applicability = {
                hotels: uiPolicyValue.uiApplicability.hotels.map(code => ({ code })),
                suppliers: uiPolicyValue.uiApplicability.suppliers.map(code => ({ code })),
            };
            policyConfig.sort = uiPolicyValue.sort;
            policyConfig.enabled = uiPolicyValue.enabled;
        }

        return policyConfig;
    }

    getCancellationPaymentPolicy(
        uiPaymentPolicy: UiCancellationPaymentPolicy,
        index: number,
        uiPolicyValue: UiPolicyCancellation,
    ): CancellationPaymentPolicy {
        if (!uiPaymentPolicy) {
            return;
        }
        const isMainPolicy = uiPaymentPolicy.paymentPolicyType === 'main';
        const isTextOnly = uiPolicyValue.textOnly;

        uiPaymentPolicy.uiPenalties = uiPaymentPolicy.uiPenalties || [];

        if (isMainPolicy && uiPaymentPolicy.uiPenalties.length &&
            (isTextOnly || uiPolicyValue.cancellationRefund !== 'CustomRefundable')) {
            uiPaymentPolicy.uiPenalties.length = 1;
        }

        const paymentPolicy: CancellationPaymentPolicy = {
            priority: index + 1,
            penalties: uiPaymentPolicy.uiPenalties.map(uiPenalty => {
                const isAlways = uiPenalty.leadTimeType === 'Always' || typeof uiPenalty.leadTimeType === 'undefined';
                const penalty: CancellationPenalty = {
                    leadTimeAlways: isAlways,
                    descriptions: this.getPenaltyDescriptions(
                        uiPenalty,
                        isTextOnly,
                        uiPaymentPolicy.textOnlyDescriptions,
                    ),
                };

                if (isTextOnly && isMainPolicy) {
                    return penalty;
                }

                penalty.leadTimeDays = uiPenalty.leadTimeType === 'Days' ? uiPenalty.leadTimeDays : null;
                penalty.leadTimeOfDay = uiPenalty.leadTimeType === 'Days' ? `${uiPenalty.leadTimeOfDay}:00` : null;
                penalty.numberOfNights = uiPenalty.hasPercent && uiPenalty.stayType === 'Nights' ? uiPenalty.numberOfNights : null;

                if (isMainPolicy) {
                    if (uiPolicyValue.cancellationRefund === 'AlwaysNonRefundable') {
                        penalty.percent = 100;
                    } else if (uiPolicyValue.cancellationRefund === 'AlwaysRefundable') {
                        penalty.percent = 0;
                    } else {
                        if (uiPenalty.hasAmount) {
                            penalty.amount = uiPenalty.amount;
                        }

                        if (uiPenalty.hasPercent) {
                            penalty.percent = uiPenalty.percent;
                        }
                    }
                } else {
                    if (uiPenalty.hasAmount) {
                        penalty.amount = uiPenalty.amount;
                    }

                    if (uiPenalty.hasPercent) {
                        penalty.percent = uiPenalty.percent;
                    }
                }

                return penalty;
            }),
        };

        if (!isMainPolicy) {
            paymentPolicy.dateRanges = DateRangeSingleDatesTranslator.translateToRangesStartEnd(uiPaymentPolicy.uiDateRanges);
        }

        return paymentPolicy;
    }

    private getPenaltyDescriptions(
        uiPenalty: UiCancellationPenalty,
        textOnly: boolean,
        textOnlyDescriptions: PolicyDescription[],
    ): PolicyDescription[] {
        if (textOnly) {
            return textOnlyDescriptions;
        }

        if (!uiPenalty.showDescriptions) {
            return [];
        }

        return uiPenalty.descriptions;
    }
}
