import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { DistinctEventEmitter } from '@bmng/helpers/distinct-event-emitter';
import { MenuItemChild } from '@bmng/services/menu/interfaces/menu.interface';
import { PageService } from '@bmng/services/page.service';

@Component({
    selector: 'bmng-sub-sub-menu-item',
    templateUrl: './sub-sub-menu-item.component.html',
    styleUrls: [ './sub-sub-menu-item.component.scss' ],
})
export class SubSubMenuItemComponent {
    @Input()
    subSubMenuItem: MenuItemChild;

    @Input()
    baseContextUrl: string;

    @Output()
    activeChanged: EventEmitter<boolean> = new DistinctEventEmitter();

    @ViewChild(RouterLinkActive, { static: true })
    routerLinkActive: RouterLinkActive;

    constructor(
        private pageService: PageService,
    ) { }

    isActive(): boolean {
        const active = this.routerLinkActive.isActive;
        this.activeChanged.emit(active);
        return active;
    }

    closeMenu(): void {
        if (this.pageService.isMobileView()) {
            this.pageService.toggleMenuShown(false);
        }
    }
}
