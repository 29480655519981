<div *ngIf="breadcrumbConfig && breadcrumbConfig.length > 0" class="bc-panel my-4">
    <div *ngFor="let item of breadcrumbConfig; let i = index">
        <!-- link, if on click was supplied -->
        <a *ngIf="item.onClick" (click)="item.onClick()">{{ item.title | sentenceCasePipe }}</a>
        <!-- title, if no onClick was supplied -->
        <span *ngIf="!item.onClick">{{ item.title | sentenceCasePipe }}</span>
        <!-- links / text divider -->
        <span class="divider" *ngIf="i < breadcrumbConfig.length - 1">/</span>
    </div>
</div>
