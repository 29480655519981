import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CodeTitle } from '@bmng/services/metadata/interfaces/metadata.interface';

import { UiPolicyCleanup } from '../../../../pages/master/policies/interface/policies-ui.interface';
import { PolicyFormHelpersService } from '../policy-form-helpers.service';
import { PolicyFormValidators } from '../policy-form-validators.service';

@Injectable()
export class PolicyFormCleanupService {
    constructor(
        private formHelpersService: PolicyFormHelpersService,
        private validators: PolicyFormValidators,
    ) {}

    getCleanupPolicyForm(
        policyConfig: UiPolicyCleanup,
        isNewPolicy: boolean,
        languages: CodeTitle[],
        usedCodes?: string[],
    ): UntypedFormGroup {
        if (!policyConfig) {
            return;
        }

        return new UntypedFormGroup({
            code: new UntypedFormControl(policyConfig.code, [
                Validators.required,
                this.validators.uniqueCodeValidation(isNewPolicy, usedCodes),
            ]),
            amount: new UntypedFormControl(policyConfig.amount, [
                Validators.required,
                Validators.max(999999.99),
            ]),
            currencyCode: new UntypedFormControl(policyConfig.currencyCode),
            billingType: new UntypedFormControl(policyConfig.billingType),
            descriptions: new UntypedFormArray(this.formHelpersService.getDescriptionControlsByLang(languages, policyConfig.descriptions)),
            descriptionTabIndex: new UntypedFormControl(0),
        });
    }
}
