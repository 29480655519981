import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { LoginService } from '@bmng/services/login.service';
import { UsersService } from '@bmng/services/users/users.service';
import { LangService } from '@kognitiv/angular-i18n';
import { catchError, map } from 'rxjs/operators';


export const bmLangAgnosticLoginGuardServiceCanActivate: ResolveFn<boolean> =
    () => {
        const usersService = inject(UsersService);
        const loginService = inject(LoginService);
        const langService = inject(LangService);
        const language: string = (langService.getCurrentLanguage() || 'en').toLowerCase();

        return usersService.getUserSettingsFromOldBm(language)
            .pipe(
                map((data) => !!data),
                catchError(err => loginService.catchUnauthenticatedUserFromLoginGuard(err)),
            );
    };
