import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CodeTitle } from '@bmng/services/metadata/interfaces/metadata.interface';
import { PolicyDescription } from '@bmng/services/policies/interfaces/policies.interface';

@Injectable()
export class PolicyFormHelpersService {

    getDescriptionControlsByLang(
        languages: CodeTitle[],
        descriptions: PolicyDescription[],
        addTitle: boolean = false,
    ): UntypedFormGroup[] {
        const controls: UntypedFormGroup[] = [];
        descriptions = descriptions || [];

        if (Array.isArray(languages)) {
            languages.forEach(lang => {
                const descriptionByLang = descriptions.find(descr => {
                    const languageCode1 = descr.languageCode;
                    const languageCode2 = lang.code;
                    if (languageCode1 && languageCode2) {
                        return languageCode1.toLowerCase() === languageCode2.toLowerCase();
                    }
                });

                const formGroupConfig: {[key: string]: UntypedFormControl } = {
                    languageCode: new UntypedFormControl(lang.code),
                    description: new UntypedFormControl(descriptionByLang ? descriptionByLang.description : ''),
                };
                if (addTitle) {
                    formGroupConfig.title = new UntypedFormControl(descriptionByLang ? descriptionByLang.title : '');
                }
                controls.push(
                    new UntypedFormGroup(formGroupConfig),
                );
            });
        }

        return controls;
    }
}
