import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import {
    DiscountResult,
    NewOfferPayload,
    PriceDropAlert,
    PriceDropAlertDetail,
    PriceDropAlertDetailResult,
    PriceDropAlertResult,
    RevokeOfferPayload,
} from '@bmng/services//price-drop-alerts/interfaces/price-drop-alert.interface';
import { PriceDropAlertConfig } from '@bmng/services/price-drop-alerts/interfaces/price-drop-alert-config.interface';
import { PriceDropAlertsServiceInterface } from '@bmng/services/price-drop-alerts/interfaces/price-drop-alerts.service.interface';
import { DataProviderConfig } from '@kognitiv/bm-components';
import { Observable } from 'rxjs';

import { EndpointService } from './../endpoint.service';

@Injectable()
export class PriceDropAlertService extends EndpointService implements PriceDropAlertsServiceInterface {

    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    getPriceDropAlertsConfig(hotelId: string): Observable<PriceDropAlertConfig> {
        const url = `${EndpointService.getBmBackendUrl()}/api/pricedrop/config/${hotelId}`;

        return this.httpGet<PriceDropAlertConfig>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    updatePriceDropAlertsConfig(config: PriceDropAlertConfig): Observable<PriceDropAlertConfig> {
        const url = `${EndpointService.getBmBackendUrl()}/api/pricedrop/config`;

        return this.httpPut<PriceDropAlertConfig>(url, config, EndpointService.HTTP_HEADERS);
    }

    getPriceDropAlerts(hotelId: string, languageCode: string, query: DataProviderConfig): Observable<PriceDropAlertResult> {
        const url = this.getListUrl('json', hotelId, languageCode, query);

        return this.httpGet<PriceDropAlertResult>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    getPriceDropAlertDetail(hotelId: string, languageCode: string, pdaId: string): Observable<PriceDropAlertDetailResult> {
        const url = `${EndpointService.getBmBackendUrl()}/api/pricedrop/detail/${hotelId}?id=${pdaId}&languageCode=${languageCode}`;

        return this.httpGetWithFullData<PriceDropAlertDetail[]>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    deletePriceDropAlert(hotelId: string, pdaId: string | number, hash: string): Observable<PriceDropAlert[]> {
        const url = UrlHelpers.buildUrl(`${EndpointService.getBmBackendUrl()}/api/pricedrop/alert`, {
            hotelId,
            id: pdaId,
            hash,
            revokeTheOffer: true,
        });

        return this.httpDelete<PriceDropAlert[]>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    bulkDeletePriceDropAlert(hotelId: string, pdas: PriceDropAlert[]): Observable<PriceDropAlert[]> {
        const url = `${EndpointService.getBmBackendUrl()}/api/pricedrop/alert/purge`;

        const ids: RevokeOfferPayload[] = [];
        pdas.forEach(pda => {
            ids.push({
                id: pda.id,
                hash: pda.hash,
            });
        });

        const body = {
            hotelId,
            ids,
            revokeTheOffer: true,
        };

        return this.httpDeleteWithData<PriceDropAlert[]>(url, body, EndpointService.HTTP_HEADERS);
    }

    getOfferDiscountDetails(payload: NewOfferPayload): Observable<DiscountResult> {
        const url: string = `${EndpointService.getBmBackendUrl()}/api/hotel/switch/api/discount`;

        return this.httpPost<DiscountResult>(url, payload, EndpointService.HTTP_HEADERS_CONTENTTYPE);
    }

    sendOffer(payload: NewOfferPayload): Observable<DiscountResult> {
        const url = `${EndpointService.getBmBackendUrl()}/api/pricedrop/alert/email`;

        return this.httpPost<DiscountResult>(url, payload, EndpointService.HTTP_HEADERS);
    }

    revokeOffer(payload: RevokeOfferPayload): Observable<PriceDropAlertDetailResult> {
        const url = `${EndpointService.getBmBackendUrl()}/api/pricedrop/alert/revoke`;

        return this.httpPost<PriceDropAlertDetailResult>(url, payload, EndpointService.HTTP_HEADERS);
    }

    downloadPriceDropAlerts(hotelId: string, languageCode: string, query: DataProviderConfig): void {
        const url = this.getListUrl('xlsx', hotelId, languageCode, query);
        window.open(url);
    }

    private getListUrl(type: 'json' | 'xlsx', hotelId: string, languageCode: string, query: DataProviderConfig): string {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/pricedrop/alert/search.${type}`;
        const params = {
            hotelId,
            languageCode,
            ...this.getSorting(query),
            ...query.filters,
        };

        return UrlHelpers.buildUrl(baseUrl, params);
    }

    private getSorting(query: DataProviderConfig): { [key: string]: string } {
        const mapping: { [ K in keyof PriceDropAlert ]?: string } = {
            created: 'dateCreated',
            numberOfNights: 'nights',
            userRequestedPrice: 'price',
        };

        return {
            sort: mapping[query.sortCol] || query.sortCol,
            sortType: query.sortDir,
        };
    }
}
