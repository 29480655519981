import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { Observable } from 'rxjs';

import { EndpointService } from '../endpoint.service';
import { ListResult } from '../list-results.interface';

import { Contract, ContractProperty, ListContract } from './interfaces/contract.interface';
import { ContractsOverviewRequest, ContractsServiceInterface } from './interfaces/contracts.service.interface';

@Injectable()
export class ContractsService extends EndpointService implements ContractsServiceInterface {
    private static readonly BASE_URL = `${EndpointService.getBmBackendUrl()}/api/contracts`;

    constructor(http: HttpClient) {
        super(http);
    }

    getOverview(config: ContractsOverviewRequest): Observable<ListResult<ListContract>> {
        const url = UrlHelpers.buildUrl(`${ContractsService.BASE_URL}`, config);

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }
    syncContracts(): Observable<number> {
        const url = `${ContractsService.BASE_URL}/refresh`;

        return this.httpPost(url, {}, EndpointService.HTTP_HEADERS);
    }

    getContract(contractId: number): Observable<Contract> {
        const url = `${ContractsService.BASE_URL}/${contractId}`;

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }
    updateContract(contract: Contract): Observable<Contract> {
        return this.httpPost(ContractsService.BASE_URL, contract, EndpointService.HTTP_HEADERS);
    }
    refreshContractFromErp(contractId: number): Observable<Contract> {
        const url = `${ContractsService.BASE_URL}/refresh/${contractId}`;

        return this.httpPost(url, {}, EndpointService.HTTP_HEADERS);
    }

    getContractProperty(contractId: number, propertyId: string): Observable<ContractProperty> {
        const url = `${ContractsService.BASE_URL}/${contractId}/properties/${propertyId}`;

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }

    updateContractProperty(property: ContractProperty): Observable<ContractProperty> {
        const url = `${ContractsService.BASE_URL}/properties`;

        return this.httpPost(url, property, EndpointService.HTTP_HEADERS);
    }
}
