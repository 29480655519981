<bm-button *ngIf="buttonConfig.type === 'button'"
    [type]="showIcon ? 'smallicon' : 'regular'"
    [color]="buttonConfig.color"
    [isDisabled]="buttonConfig.enabled === false"
    [icon]="showIcon ? buttonConfig.icon : null"
    (onClick)="buttonConfig.onClick()">{{ buttonConfig.title }}</bm-button>
<bm-button-dropdown *ngIf="buttonConfig.type === 'dropdown'"
    [type]="buttonConfig.dropdownType"
    [color]="buttonConfig.color"
    [label]="buttonConfig.title"
    [disabled]="buttonConfig.enabled === false"
    (click)="buttonConfig.dropdownType === 'split' ? buttonConfig.onClick() : null">
    <bm-button-dropdown-item *ngFor="let child of buttonConfig.children"
        [label]="child.title"
        (click)="child.onClick()"></bm-button-dropdown-item>
</bm-button-dropdown>
