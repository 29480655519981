import { Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { RouteHelpers } from '@bmng/helpers/route-helpers';

import { AnalyticsService } from '../analytics/analytics.service';
import { RouteTransformerService } from '../routes/route-transformer.service';

import { TrackingNavigationEvent } from './interfaces/tracking.interfaces';

@Injectable()
export class TrackingService {
    constructor(
        private analyticsService: AnalyticsService,
        private route: ActivatedRoute,
        private routeTransformer: RouteTransformerService,
    ) {}

    trackNavigationEvent(event: Event): void {
        if (event instanceof NavigationEnd) {
            const paramsFromRoute = RouteHelpers.getFullParamsFromRoute(this.route.snapshot.root);
            const paths = this.routeTransformer.getPathPattern();
            const navigationEvent: TrackingNavigationEvent = Object.assign(paths, {
                activeNavigation: this.routeTransformer.getActiveNavigation(paths.pathPattern),
            });

            this.analyticsService.track<TrackingNavigationEvent>({
                collection: 'navigation',
                entityId: paramsFromRoute.contextId,
                data: navigationEvent,
            });
        }
    }
}
