import { Injectable } from '@angular/core';
import { CategoryType } from '@bmng/services/images/interfaces/images.interface';
import { PageService } from '@bmng/services/page.service';
import { LangService } from '@kognitiv/angular-i18n';
import { ImageFileValidators, UploadFileAsyncValidator } from '@kognitiv/bm-components';

interface ValidatorConfig {
    minWidth: number;
    minHeight?: number;

    warning?: {
        minWidth: number;
        minHeight?: number;
    };
}

@Injectable()
export class ImageUploadValidatorsService {
    constructor(
        private readonly lang: LangService,
        private readonly pageSrv: PageService,
    ) { }

    public getImageValidatorForCategory(category: CategoryType): UploadFileAsyncValidator {
        switch (category) {
            case CategoryType.BANNER:
                return this.getBannerValidator();
            case CategoryType.LOGO:
                return this.getLogoValidator();
            case CategoryType.MISCELLANEOUS:
            case CategoryType.SMALL_LOGO:
                return null;
            default:
                return this.getGenericValidator();
        }
    }

    private getLogoValidator(): UploadFileAsyncValidator {
        return this.getValidator({
            minWidth: 100,
            minHeight: 100,
        });
    }

    private getBannerValidator(): UploadFileAsyncValidator {
        return this.getValidator({
            minWidth: 800,
            warning: {
                minWidth: 1400,
            },
        });
    }

    private getGenericValidator(): UploadFileAsyncValidator {
        return this.getValidator({
            minWidth: 600,
            minHeight: 800,
            warning: {
                minWidth: 1200,
                minHeight: 1600,
            },
        });
    }

    private getValidator(config: ValidatorConfig): UploadFileAsyncValidator {
        // ts[cc.hotel.pictures.size.error.category]
        // ts[cc.hotel.pictures.size.error.category.width]
        // ts[cc.hotel.pictures.size.warning.category.width]
        // ts[cc.hotel.pictures.size.warning.category]
        return ImageFileValidators.getImageValidator((image: HTMLImageElement) => {
            if (image.width < config.minWidth || (typeof config.minHeight === 'number' && image.height < config.minHeight)) {
                const errorMessage = typeof config.minHeight === 'number'
                    ? this.lang.get('cc.hotel.pictures.size.error.category', config.minWidth, config.minHeight, image.width, image.height)
                    : this.lang.get('cc.hotel.pictures.size.error.category.width', config.minWidth, image.width, image.height);

                return {
                    sizeError: errorMessage,
                };
            }

            if (config.warning && (image.width < config.warning.minWidth ||
                (typeof config.warning.minHeight === 'number' && image.height < config.warning.minHeight))) {
                const warningMessage = typeof config.warning?.minHeight === 'number'
                    ? this.lang.get(
                        'cc.hotel.pictures.size.warning.category',
                        config.warning.minWidth,
                        config.warning.minHeight,
                        image.width,
                        image.height,
                    )
                    : this.lang.get('cc.hotel.pictures.size.warning.category.width', config.warning.minWidth, image.width, image.height);

                this.pageSrv.pushNotification({
                    content: warningMessage,
                    type: 'warning',
                });
            }

            return null;
        });
    }
}
