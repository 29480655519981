import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageService } from '@bmng/services/page.service';

import { MasterPage } from '../master-page.component';

@Component({
    selector: 'bm-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: [ './dashboard.component.scss' ],
    animations: [
        trigger('card', [
            state('open', style({
                height: '*',
            })),
            transition('void => *', [
                style({ height: 0, padding: '0 30px' }),
                animate('.4s ease-in-out'),
            ]),
            transition('* => void', [
                animate('.4s ease-in-out', style({ height: 0, padding: '0 30px' })),
            ]),
        ]),
    ],
})
export class HotelDashboardComponent extends MasterPage implements OnInit {
    settingEnabled: boolean = false;
    public open: string;
    constructor(
        public pageService: PageService,
        public route: ActivatedRoute,
    ) {
        super(pageService, route);
    }

    ngOnInit(): void {

        this.initPage({
            title: 'Dashboard',
            subtitle: 'Information at a glance',
            onlyPageContents: this.pageService.showOnlyPageContents,
            isEditable: true,
            breadcrumbConfigs: [],
        });
    }
}
