import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { RouteHelpers } from '@bmng/helpers/route-helpers';
import { CpcChainUnitInfoService } from '@bmng/services/cpcchain-unitinfo/cpcchain-unitinfo.service';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';

export const cpcChainGuardServiceCanActivate: ResolveFn<boolean | Observable<boolean> | Promise<boolean>> =
    (route: ActivatedRouteSnapshot) => {
        const router = inject(Router);
        const cpcChainUnitInfoService = inject(CpcChainUnitInfoService);
        const cpcChainId: string = RouteHelpers.getParamFromRoute(route, 'contextId');

        return cpcChainUnitInfoService.getUnitInfo(cpcChainId)
            .pipe(
                tap(unitInfo => {
                    if (unitInfo.info.portalId) {
                        router.navigateByUrl(`/portal/${unitInfo.info.portalId}/unit/${cpcChainId}`);
                    } else if (unitInfo.info.masterpropertyId) {
                        router.navigateByUrl(`/master/${unitInfo.info.masterpropertyId}/unit/${cpcChainId}`);
                    }
                }),
                catchError(() => of(true)),
                mapTo(true),
            );
    };
