import { SelectOption } from '@kognitiv/bm-components';
import * as fastDeepEqual from 'fast-deep-equal';
import { ObjectHelpers as ComponentsObjectHelpers } from '@kognitiv/bm-components';

export interface ObjectDiff<T> {
    added: T[];
    removed: T[];
}

export interface ToSelectOptionsParameters {
    includeCodeInText?: boolean;
}

export class ObjectHelpers extends ComponentsObjectHelpers {
    static copy<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }

    static removeNullValues<T>(obj: T): T {
        if (!obj) {
            return obj;
        }

        if (typeof obj !== 'object') {
            return obj;
        }

        Object.keys(obj).forEach(key => {
            if (obj[key] === null) {
                delete obj[key];
            } else if (typeof obj[key] === 'object') {
                obj[key] = this.removeNullValues(obj[key]);
            }
        });

        return obj;
    }

    static equal<T>(obj1: T, obj2: T): boolean {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    static deepEqual<T>(obj1: T, obj2: T): boolean {
        return fastDeepEqual(obj1, obj2);
    }

    static toSelectOptions<T extends string = string>(
        obj: { [ key in T ]: string},
        params: ToSelectOptionsParameters = {},
    ): SelectOption<T>[] {
        return Object.keys(obj).map(key => {
            let text = obj[key];
            if (params.includeCodeInText) {
                text += ` (${key})`;
            }

            return {
                id: <T> key,
                text,
            };
        });
    }

    static isEmpty(obj: Record<string, unknown>): boolean {
        if (typeof obj !== 'object') {
            return false;
        }

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }

        }
        return true;
    }

    static values<T>(obj: { [key: string]: T } | { [key: number]: T } ): T[] {
        return Object.keys(obj).map(key => obj[key]);
    }

    static getDiff<T>(before: T[], after: T[]): ObjectDiff<T> {
        return {
            added: after.filter(afterItem => before.indexOf(afterItem) === -1),
            removed: before.filter(beforeItem => after.indexOf(beforeItem) === -1),
        };
    }
}
