import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { EndpointService } from '@bmng/services/endpoint.service';
import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { HotelSearchServiceInterface } from './interfaces/hotel-search.service.interface';
import { HotelSearchRequest, SearchHotel, SearchHotelResult } from './interfaces/search-hotel.interface';

type SearchHotelList = SearchHotel[];

@Injectable()
export class HotelSearchService extends EndpointService implements HotelSearchServiceInterface {

    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    getHotels(request: HotelSearchRequest): Observable<SearchHotel[]> {
        const url = this.getUrl(request);

        return this.httpGet<SearchHotelResult>(url, EndpointService.HTTP_HEADERS_ACCEPT, false).pipe(
            map((result) => result.properties),
        );
    }

    private getUrl(request: HotelSearchRequest): string {
        const baseUrl = `${environment.baseUrls.search}/api/search.json`;
        return UrlHelpers.buildUrl(baseUrl, request);
    }

    getAllHotels(request: HotelSearchRequest): Observable<SearchHotel[]> {
        request.limit = 100;

        return this.httpGet<SearchHotelResult>(this.getUrl(request), EndpointService.HTTP_HEADERS_ACCEPT, false).pipe(
            map(result => {
                if (!result.properties) {
                    return [];
                }

                const observables: Observable<SearchHotelList>[] = [ of(result.properties) ];
                const total = result.totalMatchedProperties;
                const calls = (total > result.properties.length) ? Math.ceil(result.totalMatchedProperties / result.properties.length) : 0;
                let offset = result.properties.length;

                for (let i = 0; i < calls; i++) {
                    const copiedRequest = { ...request };
                    copiedRequest.offset = offset;
                    observables.push(this.getHotels(copiedRequest));
                    offset += 100;
                }

                return zip(observables);
            }),
            map((hotelResults: SearchHotelList[]) => {
                let flatHotelList: SearchHotel[] = [];
                hotelResults.forEach(list => flatHotelList = flatHotelList.concat(list));
                return flatHotelList;
            }),
        );
    }
}
