import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { I18nService } from '../services/i18n.service';
import { UsersService } from '../services/users/users.service';

export const unsetLangResolver: ResolveFn<string> =
    () => {
        const usersService = inject(UsersService);
        const i18nService = inject(I18nService);

        const userSettings = usersService.currentUserSettings.getValue();

        if (userSettings) {
            return i18nService.changeLanguage(userSettings);
        }

        // TODO Magically find which language the user has set in the browser, currently returning default EN
        return i18nService.changeLanguage({
            language: 'en',
            currency: 'USD',
            dateFormat: 'MM/DD/YYYY',
            timeFormat: '12h',
            timeZone: 'America/New_York',
            decimalFormat: '.',
            thousandsFormat: ',',
            firstDoW: 1,
        });
    };
