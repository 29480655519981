import { Injectable } from '@angular/core';
import { LangService } from '@kognitiv/angular-i18n';
import { MomentHelpers } from '@kognitiv/bm-components';

import { Channel } from '../channels/interfaces/channel';
import { StaticInfo } from '../static-info/interfaces/static-info.interface';

import { ItemInfo, ItemInfoType } from './interfaces/access-restriction-item-info';
import {
    AccessRestriction,
    AccessRestrictionRule,
    AccessRestrictionRuleType,
    RateFence,
    StayDate,
} from './interfaces/access-restriction.interface';
import { RuleSummary } from './rule-summary.interface';

@Injectable()
export class AccessRestrictionSummaryService {
    constructor(
        private langService: LangService,
    ) {}

    public getRestrictionSummary(restriction: AccessRestriction, info: StaticInfo, channels: Channel[]): RuleSummary[] {
        // ts[common.openfor]
        // ts[common.closedfor]
        if (!restriction || !restriction.rules) {
            return [];
        }

        return this.createRestrictionSummary(restriction, info, channels);
    }

    private createRestrictionSummary(restriction: AccessRestriction, info: StaticInfo, channels: Channel[]): RuleSummary[] {
        return restriction.rules
            .map(rule => {
                const isInvertible = this.isInvertible(rule);
                const summary = this.getRuleSummary(rule, info, channels)
                    .filter(item => !!item);
                let type = rule.type;

                if (isInvertible) {
                    type = (type === 'Allow') ? 'Block' : 'Allow';
                }

                summary.forEach(item => {
                    if (isInvertible) {
                        item.type = item.type === 'Only' ? 'Except' : 'Only';
                    }

                    item.updateSummary();
                });

                return {
                    type,
                    summary,
                };
            })
            .filter(summaryItem => summaryItem.summary.length > 0);
    }

    public getRuleSummary(rule: AccessRestrictionRule, info: StaticInfo, channels: Channel[]): ItemInfo[] {
        const items: ItemInfo[] = [];

        items.push(this.getChannelSummary(rule.includedChannels, 'Only', channels, rule.type));
        items.push(this.getTokensSummary(this.langService.get('cc.ibe.gui.label.promotional.code.plural'), rule.promotionCodes, 'Only'));
        items.push(this.getDatesSummary(this.langService.get('bf.stayDates'), rule.includedStayDates, 'Only'));
        items.push(this.getDatesSummary(this.langService.get('bf.stayDates'), rule.excludedStayDates, 'Except'));

        if (!!rule.rateFence) {
            items.push(this.getCountryNameSummary(this.langService.get('seekda.chainmetacpc.channel.WEGO.countries'),
                rule.rateFence.includedCountryCodes, 'Only', info));
            items.push(this.getCountryNameSummary(this.langService.get('seekda.chainmetacpc.channel.WEGO.countries'),
                rule.rateFence.excludedCountryCodes, 'Except', info));
            items.push(this.getDatesSummary(this.langService.get('bm.campaigns.form.promotions.table.bookingDates'),
                rule.rateFence.includedFenceDates, 'Only'));
            items.push(this.getDatesSummary(this.langService.get('bm.campaigns.form.promotions.table.bookingDates'),
                rule.rateFence.excludedFenceDates, 'Except'));
            items.push(this.getTimesSummary(rule.rateFence.timeFences, 'Only'));
            items.push(this.getWeekdaySummary(rule.rateFence.applyToWeekdays));
            items.push(this.getDeviceTypeSummary(rule.rateFence.deviceTypes));
        }

        return items.filter(cur => !!cur);
    }

    private getChannelSummary(channelCodes: string[],
        type: ItemInfoType,
        channels: Channel[],
        ruleType: AccessRestrictionRuleType): ItemInfo {

        const isBlockingAllChannels = ruleType === 'Block' && channelCodes.length === 0;

        if (isBlockingAllChannels) {
            return this.getTokensSummary(
                this.langService.get('bf.frontend.restrictor.label.channel'),
                [ this.langService.get('cc.channels.allchannels') ],
                'Except',
            );
        }

        const tokens: string[] = channelCodes
            .map(code => {
                const channelObj = channels.find(channel => channel.channelId === code);
                return !!channelObj ? channelObj.channelName : code;
            });

        return this.getTokensSummary(
            this.langService.get('bf.frontend.restrictor.label.channel'),
            tokens,
            type,
        );
    }

    private getCountryNameSummary(name: string, countryCodes: string[], type: ItemInfoType, info: StaticInfo): ItemInfo {
        const tokens = info.countries
            .filter(country => countryCodes.indexOf(country.code) >= 0)
            .map(country => country.name);

        return this.getTokensSummary(name, tokens, type);
    }

    private getTokensSummary(name: string, values: string[], type: ItemInfoType): ItemInfo {
        if (!values || values.length === 0) {
            return null;
        }

        const item = new ItemInfo(this.langService);
        item.type = type;
        item.name = name;
        item.items = values;
        return item;
    }

    private getDatesSummary(name: string, values: StayDate[], type: ItemInfoType): ItemInfo {
        const tokens = values.map(val => {
            const start = MomentHelpers.formatUserDate(val.start);
            const end = MomentHelpers.formatUserDate(val.end);
            return `${start} - ${end}`;
        });

        return this.getTokensSummary(name, tokens, type);
    }

    private getTimesSummary(values: StayDate[], type: ItemInfoType): ItemInfo {
        const tokens = values.map(val => {
            const start = MomentHelpers.formatUserTime(val.start);
            const end = MomentHelpers.formatUserTime(val.end);
            return `${start} - ${end}`;
        });

        return this.getTokensSummary(this.langService.get('bm.promotions.bookingTimeOfDay'), tokens, type);
    }

    private getWeekdaySummary(weekdays: string[]): ItemInfo {
        if (!weekdays || weekdays.length === 0 || weekdays.length === 7) {
            return null;
        }

        const item = new ItemInfo(this.langService);
        const weekdayLabelMap: { [key: string]: string } = {
            SUN :  this.langService.get('bm.promotions.weekdays.sunday'),
            MON : this.langService.get('bm.promotions.weekdays.monday'),
            TUE : this.langService.get('bm.promotions.weekdays.tuesday'),
            WED : this.langService.get('bm.promotions.weekdays.wednesday'),
            THU : this.langService.get('bm.promotions.weekdays.thursday'),
            FRI : this.langService.get('bm.promotions.weekdays.friday'),
            SAT : this.langService.get('bm.promotions.weekdays.saturday'),
        };
        item.name = this.langService.get('seekda.ba.weekdays');
        item.items = weekdays.map(weekday => weekdayLabelMap[weekday]);
        item.type = 'Only';

        return item;
    }

    private getDeviceTypeSummary(deviceTypes: string[]): ItemInfo {
        if (deviceTypes.length === 0 || deviceTypes.length === 2) {
            return null;
        }

        const item = new ItemInfo(this.langService);
        const deviceLabelMap: { [key: string]: string } = {
            DESKTOP : this.langService.get('bm.promotion.devicetype.desktop'),
            MOBILE : this.langService.get('bm.promotion.devicetype.mobile'),
        };
        item.name = this.langService.get('hotel.rateManager.accessObject.deviceType');
        item.items = deviceTypes.map(type => deviceLabelMap[type]);
        item.type = 'Only';

        return item;
    }

    public isInvertible(rule: AccessRestrictionRule): boolean {
        const isBlockingAllChannels = rule.includedChannels.length === 0 && rule.type === 'Block';

        if (isBlockingAllChannels) {
            return false;
        }

        const expectedEmptyAttributes: (keyof AccessRestrictionRule)[] = [
            'includedChannels',
            'promotionCodes',
        ];

        const hasSimpleAttributesSet = expectedEmptyAttributes
            .map(attr => rule[attr])
            .map(values => {
                if (Array.isArray(values)) {
                    return !!values && values.length > 0;
                }

                return false;
            })
            .some(val => val);

        if (hasSimpleAttributesSet) {
            return false;
        }

        if (!rule.rateFence) {
            return true;
        }

        if (rule.rateFence.applyToWeekdays.length > 0 && rule.rateFence.applyToWeekdays.length < 7) {
            return false;
        }

        const expectedEmptyFenceAttributes: (keyof RateFence)[] = [
            'includedCountryCodes',
            'includedFenceDates',
            'timeFences',
        ];

        const hasSimpleAttributeSetInRateFence =  !!rule.rateFence && expectedEmptyFenceAttributes
            .map(attr => rule.rateFence[attr])
            .map(values => {
                if (Array.isArray(values)) {
                    return !!values && values.length > 0;
                }

                return false;
            })
            .some(val => val);

        return !hasSimpleAttributeSetInRateFence;
    }
}
