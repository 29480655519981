import { Component } from '@angular/core';

@Component({
    selector: '[bm-footer]',
    templateUrl: './footer.component.html',
    styleUrls: [ './footer.component.scss' ],
})
export class FooterComponent {
    public currentYear = new Date().getFullYear();
}
