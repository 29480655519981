<div [ngClass]="{ 'menu-shown': pageService.isMenuShown() }">
    <div class="floating-placeholder"></div>
    <div class="action-bar">
        <div class="action-bar-inner">
            <div class="buttons-left">
                <div class="page-title" *ngIf="pageService.isRemovingPaddingAndMargin()">
                    <h4><img src="/assets/img/logo-seekda-dark-small.png"> {{ pageService.title }}</h4>
                </div>

                <action-bar-desktop-breadcrumb class="hide-xsmall hide-small hide-medium" [breadcrumbConfig]="pageService.breadcrumbConfigs">
                </action-bar-desktop-breadcrumb>
            </div>
            <div class="buttons-right">
                <action-bar-desktop-edit-preview-toggle
                    *ngIf="pageService.topToggleButtonConfig" [config]="pageService.topToggleButtonConfig">
                </action-bar-desktop-edit-preview-toggle>

                <action-bar-desktop-button class="hide-xsmall hide-small hide-medium" *ngFor="let buttonConfig of pageService.buttonConfigs"
                    [buttonConfig]="buttonConfig" [showIcon]="false"></action-bar-desktop-button>
            </div>
        </div>
    </div>
</div>
