<ng-container *ngIf="!$any(subMenuItem).children">
    <a [routerLink]="[ '/' + baseContextUrl + '/' + subMenuItem.url ]" routerLinkActive (click)="closeMenu()" *ngIf="subMenuItem.service === 'bmng'">
        <span class="menu-header">
            <bmng-menu-item-leaf [menuItem]="subMenuItem"></bmng-menu-item-leaf>
        </span>
    </a>
    <a [href]="subMenuItem | oldBmUrl" *ngIf="subMenuItem.service !== 'bmng'">
        <span class="menu-header">
            <bmng-menu-item-leaf [menuItem]="subMenuItem"></bmng-menu-item-leaf>
        </span>
    </a>
</ng-container>
<ng-container *ngIf="$any(subMenuItem).children">
    <a (click)="toggleSubMenu()">
        <span class="menu-header">
            <bmng-menu-item-leaf [menuItem]="subMenuItem"  [showChildrenChevron]="true" [submenuOpen]="submenuOpen"></bmng-menu-item-leaf>
        </span>
    </a>
    <div class="submenu-container" [class.open]="submenuOpen" [@slideOpen]="(submenuOpen) ? 'open' : 'close'">
        <bmng-sub-sub-menu-item
            *ngFor="let subSubMenuItem of $any(subMenuItem).children"
            [subSubMenuItem]="subSubMenuItem"
            [baseContextUrl]="baseContextUrl"
            (activeChanged)="subMenuActiveChanged($event)"
        ></bmng-sub-sub-menu-item>
    </div>
</ng-container>
