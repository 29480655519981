import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { LangService } from '@kognitiv/angular-i18n';
import { ObjectHelpers } from '@kognitiv/bm-components';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { BookingEngineContextType } from '../context/interfaces/context-type.type';
import { EndpointService } from '../endpoint.service';
import { KubeBootstrapData } from './interfaces/kube-bootstrap.interface';
import { KubeBootstrapServiceInterface } from './interfaces/kube-bootstrap.service.interface';

@Injectable()
export class KubeBootstrapService extends EndpointService implements KubeBootstrapServiceInterface {
    private static readonly BOOTSTRAP_URL = `${environment.baseUrls.switch}/api/bootstrap.json`;

    constructor(
        private readonly langService: LangService,
        protected readonly httpClient: HttpClient,
    ) {
        super(httpClient);
    }

    getBootstrap(assetId: string, assetType: BookingEngineContextType): Observable<KubeBootstrapData> {
        const languageCode = this.langService.getCurrentLanguage();
        const domain = this.getKubeDomain(assetId, assetType);
        const url = UrlHelpers.buildUrl(KubeBootstrapService.BOOTSTRAP_URL, {
            domain,
            languageCode,
        });

        return this.httpGet(url);
    }

    getKubeDomain(assetId: string, assetType: BookingEngineContextType): string {
        const isDev = !environment.production;

        const url = isDev
            ? `${assetId}.dev.officialbookings.com`
            : `${assetId}.officialbookings.com`;

        if (assetType === 'hotel' || assetType === 'portal' || assetType === 'direct') {
            return url;
        }

        ObjectHelpers.assertNever(assetType);
    }
}
