
import { Component, Input } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FabButtonConfig } from '@kognitiv/bm-components';

@Component({
    selector: 'action-bar-desktop-button',
    templateUrl: './action-bar-desktop-button.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ActionBarDesktopButtonComponent {
    @Input() buttonConfig: FabButtonConfig;
    @Input() showIcon: boolean;
}
