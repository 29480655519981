import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { RouteHelpers } from '@bmng/helpers/route-helpers';
import { ContextService } from '@bmng/services/context/context.service';
import { LoginService } from '@bmng/services/login.service';
import { MenuItemRoot } from '@bmng/services/menu/interfaces/menu.interface';
import { MenuService } from '@bmng/services/menu/menu.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

export const menuResolver: ResolveFn<boolean | MenuItemRoot[]> =
    (route: ActivatedRouteSnapshot) => {
        const menuService = inject(MenuService);
        const loginService = inject(LoginService);
        const contextService = inject(ContextService);

        const data = RouteHelpers.getFullDataFromRoute(route);
        const menuContext = contextService.getContextForMenu(route);

        if (data.embedded) {
            // On Embedded mode we don't display the menu so simply don't fetch anything
            return of([]);
        }

        return menuService.loadMenu(menuContext.context, menuContext.id)
            .pipe(
                catchError(err => loginService.catchUnauthenticatedUserFromLoginGuard(err)),
            );

    };
