import { Pipe, PipeTransform } from '@angular/core';
import { UiTaxPaymentPolicy } from '@bmng/pages/master/policies/interface/policies-ui.interface';

import { ExpiredDateRangeHelper } from '../services/expired-date-range.helper';

@Pipe({
    name: 'policyTaxPaymentSummary',
})
export class PolicyTaxPaymentSummaryPipe implements PipeTransform {

    constructor(
        private expiredDaterangeHelper: ExpiredDateRangeHelper,
    ) { }

    transform(paymentPolicy: UiTaxPaymentPolicy): string {
        return this.getTaxPaymentSummaryItems(paymentPolicy).join('');
    }

    public getTaxPaymentSummaryItems(paymentPolicy: UiTaxPaymentPolicy): string[] {
        const summaryItems: string[] = [];

        if (Array.isArray(paymentPolicy.uiPayments)) {
            paymentPolicy.uiPayments.forEach(uiPayment => {
                const dateRangeSummary = (uiPayment.dateFrom || uiPayment.dateTo) ?
                    this.expiredDaterangeHelper.getExpiredDateRangeString(uiPayment.dateFrom, uiPayment.dateTo) : '';

                if (!!dateRangeSummary) {
                    summaryItems.push(`<div>${dateRangeSummary}</div>`);
                }

                if (Array.isArray(uiPayment.amounts)) {
                    uiPayment.amounts.forEach(amounts => {
                        if (amounts.summary) {
                            summaryItems.push(`<div class="ml-3">${amounts.summary}</div>`);
                        }
                    });
                }
            });
        }

        return summaryItems;
    }
}
