import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { PageService } from '@bmng/services/page.service';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: './page-root-embedded.component.html',
    styleUrls: [ './page-root-embedded.component.scss' ],
})
export class PageRootEmbeddedComponent implements OnInit, OnDestroy {
    subscription = new Subscription();

    constructor(
        public pageService: PageService,

        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        this.subscription = this.pageService.pageDataChanged.subscribe(() => {
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnInit(): void {
        this.renderer.addClass(this.document.body, 'embedded-body');
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(this.document.body, 'embedded-body');
        this.subscription.unsubscribe();
    }
}
