import { Injectable } from '@angular/core';

import { PolicyBase, PolicyType, Policy, PoliciesResponse } from './interfaces/policies.interface';

@Injectable()
export class PoliciesUtilService {
    constructor() {}

    getPolicy<T extends PolicyBase>(code: string, currencyCode: string, policies: T[]): T {
        if (!policies || policies.length === 0) {
            return null;
        }

        return policies
            .filter(policy => !currencyCode || !policy.currencyCode || policy.currencyCode === currencyCode)
            .find(policy => !code || policy.code === code);
    }

    getPolicyByTypeAndCode(type: PolicyType, code: string, allPolicies: PoliciesResponse): Policy {
        if (!allPolicies) {
            return null;
        }

        const policies = (allPolicies[type] as Policy[]) || [];
        return policies.find(p => p.code === code) || null;
    }
}
