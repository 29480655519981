import { Injectable } from '@angular/core';

@Injectable()
export class LinkService {
    getLocation(): string {
        if (window && (window.self !== window.top)) {
            return '_PARENT_LOCATION_';
        }

        const location = window.location.toString();
        return this.cutRedirectParam(location);
    }

    hasParameter(name: string): boolean {
        return this.getLocation().indexOf(name) >= 0;
    }

    /**
     * Removes redirected=true from the url, if it exists
     *
     * @param url
     */
    cutRedirectParam(url: string): string {
        url = url.replace(/redirected=true(\&)?/, '');
        url = url.replace(/&$/, '');
        return url;
    }
}
