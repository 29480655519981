import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LangService } from '@kognitiv/angular-i18n';

import { UiPetsPaymentPolicy } from '../../../../pages/master/policies/interface/policies-ui.interface';
import { PolicyFormValidators } from '../policy-form-validators.service';

@Injectable()
export class PolicyFormPetsValidators {
    constructor(
        private validators: PolicyFormValidators,
        private lang: LangService,
    ) {}

    petsPolicyValidation(): ValidatorFn {
        return (petsPolicy: UntypedFormGroup): ValidationErrors => {
            if (!petsPolicy) {
                return null;
            }

            const petsAllowed = petsPolicy.get('petsAllowed') as UntypedFormControl;
            const uiPaymentPolicies = petsPolicy.get('uiPaymentPolicies') as UntypedFormArray;

            if (uiPaymentPolicies && Array.isArray(uiPaymentPolicies.controls) && uiPaymentPolicies.controls.length > 0) {
                uiPaymentPolicies.controls.forEach((paymentGroup: UntypedFormGroup) => {
                    const petsTypeControl = paymentGroup.get('petsType') as UntypedFormControl;
                    const amountControl = paymentGroup.get('amount') as UntypedFormControl;
                    const paymentPoliciesValue = uiPaymentPolicies.value as UiPetsPaymentPolicy[];
                    let usedValues: string[] = [];

                    if (Array.isArray(paymentPoliciesValue)) {
                        usedValues = paymentPoliciesValue.map(_p => _p.petsType);
                    }

                    this.conditionalUniqueRequired(petsTypeControl, Boolean(petsAllowed.value), usedValues);
                    this.validators.setConditionalMaxValidator(amountControl, Boolean(petsAllowed.value));

                    return null;
                });
            } else {
                return {
                    error: this.lang.get('bmng.policies.payment.none.error'),
                };
            }

            return null;
        };
    }

    petsAllowedValidation(): ValidatorFn {
        return (petsAllowedControl: UntypedFormControl): ValidationErrors => {
            if (!petsAllowedControl) {
                return null;
            }
            const petsAllowed = petsAllowedControl.value;
            if (petsAllowed === null) {
                petsAllowedControl.markAsTouched();
                petsAllowedControl.markAsDirty();
                const err: ValidationErrors = {
                    error: this.lang.get('bmng.policies.pets.conflict.error'),
                };
                return err;

            }

            return null;
        };
    }

    conditionalUniqueRequired(control: UntypedFormControl, condition: boolean, usedValues: string[]): void {
        if (!control) {
            return;
        }
        if (condition) {
            control.setValidators([ Validators.required ]);
            const value = control.value as string;
            if (value) {
                if (Array.isArray(usedValues) && usedValues.filter(usedValue => usedValue === value).length > 1 ) {
                    control.setErrors({
                        invalid: this.lang.get('bmng.policies.petsType.multiple.error'),
                    });
                } else {
                    control.setErrors(null);
                }
            }
        } else {
            control.setErrors(null);
            control.clearValidators();
        }
    }
}
