import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EndpointService } from './../endpoint.service';
import { CpcStatus } from './interfaces/cpc-status.interface';
import { CpcStatusServiceInterface } from './interfaces/cpc-status.service.interface';

@Injectable()
export class CpcStatusService extends EndpointService implements CpcStatusServiceInterface {

    constructor(http: HttpClient) {
        super(http);
    }

    getCpcStatus(hotelId: string, channelIds: string[] = []): Observable<CpcStatus> {
        const url = `${EndpointService.getBmBackendUrl()}/api/cpc/meta/properties/${hotelId}/status.json?channels=${channelIds.join(',')}`;

        return this.httpGet<CpcStatus>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }
}
