import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Md5 } from 'ts-md5';

@Pipe({
    name: 'userAvatar',
})
export class UserAvatarPipe implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer,
    ) { }

    transform(email: string, size: number = 64): SafeUrl {
        const hash = Md5.hashStr(email);
        const url = `https://secure.gravatar.com/avatar/${hash}.png?s=${size}&d=mm`;

        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
}
