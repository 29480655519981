import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { Observable } from 'rxjs';

import { EndpointService } from '../endpoint.service';

import { AutocompleteItem } from './interfaces/autocomplete.interface';
import { AutocompleteServiceInterface } from './interfaces/autocomplete.service.interface';

@Injectable()
export class AutocompleteService extends EndpointService implements AutocompleteServiceInterface {
    private static readonly BASE_URL = `${EndpointService.getBmBackendUrl()}/api/hotel/switch/api/autocomplete`;

    constructor(http: HttpClient) {
        super(http);
    }

    findContractsByName(searchTerm: string): Observable<AutocompleteItem[]> {
        const baseUrl = `${AutocompleteService.BASE_URL}/contracts/name`;
        const url = UrlHelpers.buildUrl(baseUrl, { value: searchTerm } );

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }
    findPartnersByName(searchTerm: string): Observable<AutocompleteItem[]> {
        const baseUrl = `${AutocompleteService.BASE_URL}/partner/name`;
        const url = UrlHelpers.buildUrl(baseUrl, { value: searchTerm } );

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }
}
