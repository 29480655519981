import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { ContextItem } from '@bmng/services/context/interfaces/context-item.interface';
import { LoginService } from '@bmng/services/login.service';
import { ContextRole } from '@bmng/services/context/context.service';
import { StaticInfoService } from '@bmng/services/static-info/static-info.service';
import { UsersService } from '@bmng/services/users/users.service';
import { LangService } from '@kognitiv/angular-i18n';
import { environment } from 'src/environments/environment';


interface RoleOption {
    label: string;
    role: ContextRole;
}

@Component({
    selector: 'user-info',
    templateUrl: './user-info.component.html',
    styleUrls: [ './user-info.component.scss' ],
})
export class UserInfoComponent implements OnInit, OnDestroy {
    rolesForDowngrading: RoleOption[] = [
        { label: this.lang.get('bmng.usermngt.userroles.admin.title'), role: 'admin' },
        { label: this.lang.get('bmng.usermngt.userroles.updater.title'), role: 'updater' },
        { label: this.lang.get('bmng.usermngt.userroles.receptionist.title'), role: 'receptionist' },
        { label: this.lang.get('bmng.usermngt.userroles.uabadmin.title'), role: 'uabadmin' },
        { label: this.lang.get('bmng.usermngt.userroles.uabuser.title'), role: 'uabuser' },
        { label: this.lang.get('bmng.usermngt.userroles.viewer.title'), role: 'viewer' },
        { label: this.lang.get('bmng.usermngt.userroles.webagency.title'), role: 'webdeveloper' },
        { label: this.lang.get('bmng.usermngt.userroles.webagency_dso.title'), role: 'webdeveloperDS' },
    ];

    @Input()
    activeContext: ContextItem;

    @Input()
    superAdmin: boolean;

    @Output()
    roleDowngrade: EventEmitter<ContextRole> = new EventEmitter();

    @Input()
    email: string;

    @Input()
    currentRole: string;

    isDev: boolean = !environment.production;

    baseUrl: string = environment.baseUrls.bm;
    logoutUrl: string = LoginService.getLogoutUrl();

    dropdownOpened: boolean;
    activeLanguage: string;
    languages: { [ code: string ]: string } = {};

    private dropdownCloseUnsubscribe: () => void = () => {};

    constructor(
        private userService: UsersService,
        private staticInfoService: StaticInfoService,
        private lang: LangService,
        private renderer: Renderer2,
    ) { }

    ngOnInit(): void {
        this.getData();
        // this.openDropdown();
    }

    ngOnDestroy(): void {
        this.dropdownCloseUnsubscribe();
    }

    openDropdown(clickEvt: Event): void {
        clickEvt.preventDefault();
        clickEvt.stopPropagation();

        this.dropdownOpened = !this.dropdownOpened;

        if (this.dropdownOpened) {
            this.dropdownCloseUnsubscribe = this.renderer.listen('body', 'click', () => {
                this.dropdownOpened = false;
                this.dropdownCloseUnsubscribe();
            });
        } else {
            this.dropdownCloseUnsubscribe();
        }
    }

    changeLanguage(language: string): void {
        this.userService.updateUserLanguage(language)
            .subscribe(() => window.location.reload());
    }

    fakeRole(role: ContextRole): void {
        if (this.superAdmin) {
            this.roleDowngrade.emit(role);
        }
    }

    private getData(): void {
        if (!this.activeContext) {
            return;
        }

        this.activeLanguage = this.userService.currentUserSettings.value && this.userService.currentUserSettings.value.language || 'EN';
        this.languages = this.staticInfoService.getStaticInfo().adminUILanguages;
    }
}
