import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbConfig, FabButtonConfig, Notification, LocalStorageService } from '@kognitiv/bm-components';
import { Subscription } from 'rxjs';

import { NavigationResult } from './navigation/routing-navigator.interface';
import { StackableRoutingNavigatorService } from './navigation/stackable-routing-navigator.service';
import { PageConfig, TopToggleButtonConfig } from './page-config.interface';
import { WindowListenerService } from './window-listener.service';

export type PageAttribute = keyof PageService | 'All';

@Injectable()
export class PageService implements OnDestroy {
    public showOnlyPageContents: boolean = false;
    public removePaddingAndMargin: boolean = false;
    public isEditable: boolean = true;
    public buttonConfigs: FabButtonConfig[] = [];
    public breadcrumbConfigs: BreadcrumbConfig[] = [];
    public replicateButtons: boolean = false;
    public isMaxWidth: boolean = true;
    public errorShown: boolean = false;
    public errorTitle: string = '';
    public errorMessage: string = '';
    public topToggleButtonConfig: TopToggleButtonConfig = null;
    public pageDataChanged: EventEmitter<PageAttribute> = new EventEmitter();

    title: string = '';
    subtitle: string = '';

    private _notifications: Notification[] = [];

    private menuShown: boolean = true;
    private compactMenu: boolean = false;

    private pageConfigs: { [key: number]: PageConfig } = {};
    private subscription: Subscription = new Subscription();
    public get notifications(): Notification[] {
        return this._notifications;
    }

    constructor(
        private titleService: Title,
        private stackableRouterNavSrv: StackableRoutingNavigatorService,
        private windowListenerSrv: WindowListenerService,
        private localStorageSrv: LocalStorageService,
    ) {
        this.setMenuState();

        this.subscription.add(this.stackableRouterNavSrv.getActiveStackLevel().subscribe(
            ([ currentIdx, _, stackLevelChangeType ]: NavigationResult) => {
                if (stackLevelChangeType !== 'Close') {
                    return;
                }

                const config = this.pageConfigs[currentIdx];
                const hasConfigStored = !!config;

                if (hasConfigStored) {
                    this.triggerNewPage(config);
                }
            }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    isMobileView(): boolean {
        return this.windowListenerSrv.width() <= 991;
    }

    isFullscreenFromRoute(route: ActivatedRoute): boolean {
        return this.getRouterConfigFromRoute(route, 'fullscreen') || false;
    }
    isEditableFromRoute(route: ActivatedRoute, defaultValue: boolean): boolean {
        return this.getRouterConfigFromRoute(route, 'fullscreen', defaultValue);
    }

    // Menu helpers
    toggleMenuShown(menuShown?: boolean): void {
        if (menuShown !== undefined) {
            this.menuShown = menuShown;
        } else {
            this.menuShown = !this.menuShown;
        }
        this.localStorageSrv.setItem('is-menu-collapsed', !this.menuShown);
    }
    isMenuShown(): boolean {
        return this.menuShown;
    }

    toggleCompactMenu(compactMenu?: boolean): void {
        if (compactMenu !== undefined) {
            this.compactMenu = compactMenu;
        } else {
            this.compactMenu = !this.compactMenu;
        }
    }
    isMenuCompact(): boolean {
        return this.compactMenu;
    }

    isButtonsReplicated(): boolean {
        return this.replicateButtons;
    }

    isOnlyPageContentsShown(): boolean {
        return this.showOnlyPageContents;
    }

    isRemovingPaddingAndMargin(): boolean {
        return this.removePaddingAndMargin;
    }

    isPageEditable(): boolean {
        return this.isEditable;
    }

    // Global Page helpers
    triggerNewPage(newConfig: PageConfig): void {
        const idx = this.stackableRouterNavSrv.getStackHeight();
        this.pageConfigs[idx] = newConfig;

        this.showOnlyPageContents = newConfig.onlyPageContents;
        this.removePaddingAndMargin = newConfig.removePaddingAndMargin;
        this.titleService.setTitle(newConfig.pageTitle + ' | Hotel Manager');
        this.title = newConfig.title;
        this.subtitle = newConfig.subtitle;
        this.isEditable = newConfig.isEditable;
        this.isMaxWidth = 'isMaxWidth' in newConfig ? newConfig.isMaxWidth : true;
        this.buttonConfigs = newConfig.buttonConfigs || [];
        this.breadcrumbConfigs = newConfig.breadcrumbConfigs || [];
        this.replicateButtons = newConfig.replicateButtons;
        this.topToggleButtonConfig = newConfig.topToggleButtonConfig;

        this.pageDataChanged.emit('All');
    }

    updatePage(config: PageConfig): void {
        if ('onlyPageContents' in config) {
            this.showOnlyPageContents = config.onlyPageContents;
        }

        if ('pageTitle' in config) {
            this.titleService.setTitle(config.pageTitle + ' | Hotel Manager');
        }

        if ('title' in config) {
            this.title = config.title;
        }

        if ('subtitle' in config) {
            this.subtitle = config.subtitle;
        }

        if ('isEditable' in config) {
            this.isEditable = config.isEditable;
        }

        if ('isMaxWidth' in config) {
            this.isMaxWidth = config.isMaxWidth;
        }

        if ('buttonConfigs' in config) {
            this.buttonConfigs = config.buttonConfigs || [];
        }

        if ('breadcrumbConfigs' in config) {
            this.breadcrumbConfigs = config.breadcrumbConfigs || [];
        }

        if ('replicateButtons' in config) {
            this.replicateButtons = config.replicateButtons;
        }

        if ('removePaddingAndMargin' in config) {
            this.removePaddingAndMargin = config.removePaddingAndMargin;
        }

        if ('topToggleButtonConfig' in config) {
            this.topToggleButtonConfig = config.topToggleButtonConfig;
        }

        this.pageDataChanged.emit('All');
    }

    showError(title: string, message: string): void {
        this.errorMessage = message;
        this.errorTitle = title;
        this.errorShown = true;

        this.pageDataChanged.emit('errorShown');
    }

    pushNotification(notification: Notification): void {
        this._notifications = this._notifications.concat([ notification ]);
        this.pageDataChanged.emit('notifications');
    }

    clearNotifications(): void {
        this._notifications = [];
        this.pageDataChanged.emit('notifications');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getRouterConfigFromRoute(route: ActivatedRoute, key: string, _default: any = undefined): any {
        if (route && route.pathFromRoot) {
            const setKeysInRoute = route.pathFromRoot
                .map(path => path.snapshot.data)
                .map(params => params.pageRouterConfig)
                .filter(p => !!p)                           // Filter undefined values
                .map(config => config[key])                 // Get the wanted configs
                .filter(p => !!p);                          // Filter undefined values

            if (setKeysInRoute.length > 0) {
                return setKeysInRoute[setKeysInRoute.length - 1];
            }
            return _default;
        }
    }
    private setMenuState() {
        const isMenuCollapsedSession: boolean = this.localStorageSrv.getItem('is-menu-collapsed');
        if (!isMenuCollapsedSession) {
            this.menuShown = !this.isMobileView();
            this.localStorageSrv.setItem('is-menu-collapsed', !this.menuShown);
        } else {
            this.menuShown = !isMenuCollapsedSession;
        }
    }
}
