<div class="error-page">
    <h2>{{ errorTitle }}</h2>

    <div class="error-content">
        <h3>{{ 'onboarding.error.general.title' | lang }}</h3>

        <p>
            {{ errorMessage }}
        </p>
    </div>
</div>