import { Injectable } from '@angular/core';
import { DateRangeSingleDatesTranslator } from '@bmng/helpers/date-range-single-dates-translator';
import { BookingSettings } from '@bmng/services/metadata/interfaces/metadata.interface';
import { BookingOnHold, BookingPaymentPolicy, PolicyBooking, PolicyDateRange } from '@bmng/services/policies/interfaces/policies.interface';

import { PolicyContext, UiBookingOnHold, UiBookingPaymentPolicy, UiPolicyBooking } from '../../../../pages/master/policies/interface/policies-ui.interface';

@Injectable()
export class PolicyFormBookingUiService {

    getUiBookingPolicyConfig(
        policy: PolicyBooking,
        context: PolicyContext,
        bookingSettings?: BookingSettings,
        portalId?: string,
    ): UiPolicyBooking {
        const hasOnRequest = bookingSettings ? bookingSettings.allowOnRequest : false;
        const hasAvailabilityIndependent = bookingSettings ? bookingSettings.allowAvailabilityIndependent : false;

        return {
            type: 'booking',
            inherited: policy.portalId !== portalId,
            portalId,
            enabled: policy.enabled,
            sort: policy.sort,
            hasOnRequest,
            hasAvailabilityIndependent,
            ...policy,
            hasOnHoldEnabled: !!policy.onHold,
            uiOnHold: this.getUIOnHold(policy.onHold || this.getDefaultOnHold()),
            uiPaymentPolicies: this.getUIPayments(policy.paymentPolicies, context === 'portal'),
            context,
        };
    }

    public getUIOnHold(onHold: BookingOnHold): UiBookingOnHold {
        return {
            ...onHold,
            stayDatesType: this.getStayDatesType(onHold),
            uiStayDatesIncluded: this.getDatesFromDateRanges(onHold.stayDatesIncluded) || [],
            uiStayDatesExcluded: this.getDatesFromDateRanges(onHold.stayDatesExcluded) || [],
            hasLeadTimeEnabled: !!onHold.reminderLeadTimeHours,
            hasPromotionEnabled: !!onHold.promotion,
            promotionType: !onHold.promotion || !!onHold.promotion?.percent ? 'percent' : 'amount',
        };
    }

    private getStayDatesType(onHold: BookingOnHold): string {
        if (onHold.stayDatesIncluded && onHold.stayDatesIncluded.length) {
            return 'custom';
        }

        if (onHold.stayDatesExcluded && onHold.stayDatesExcluded.length) {
            return 'blackOut';
        }

        return 'noRestriction';
    }

    private getDefaultOnHold(): BookingOnHold {
        return {
            durationHours: 24,
            reminderLeadTimeHours: 12,
            minimumLeadTimeDays: null,
            availabilityThreshold: 2,
            promotion: {
                percent: 10,
            },
            stayDatesIncluded: [],
            stayDatesExcluded: [],
        };
    }

    public getUIPayments(payments: BookingPaymentPolicy[], isPortal?: boolean): UiBookingPaymentPolicy[] {

        payments = (payments || []).sort((a, b) => this.sortByPriority(a, b));

        return (payments || []).map((payment, index) => {
            const hasDates = payment.dateRanges && payment.dateRanges.length;
            const requiresPercentInPortal = isPortal && payment.guaranteeType !== 'None' && payment.guaranteeType !== 'CcStore';
            return {
                ...payment,
                paymentPolicyType: !hasDates ? 'main' : 'override',
                uiDateRanges: hasDates ? this.getDatesFromDateRanges(payment.dateRanges) : [],
                stayType: typeof payment.numberOfNights === 'number' ? 'Nights' : 'FullStay',
                hasAmount: typeof payment.amount === 'number',
                hasPercent: typeof payment.percent === 'number' || requiresPercentInPortal,
                descriptionTabIndex: 0,
                showDescriptions: (payment.descriptions || []).some(descriptions => descriptions.description),
                index,
            };
        });
    }

    getDefaultBookingPolicyConfig(currencyCode: string, portalId?: string): PolicyBooking {
        return {
            code: 'BOOKING',
            currencyCode,
            portalId,
            bookingType: 'Obligatory',
            textOnly: false,
            enabled: true,
            paymentPolicies: [
                {
                    priority: 1,
                    dateRanges: [],
                    guaranteeType: 'None',
                    amount: null,
                    percent: null,
                    numberOfNights: null,
                    descriptions: [],
                },
            ],
            applicability: {
                hotels: [],
                suppliers: [],
            },
        };
    }

    private getDatesFromDateRanges(dateRanges: PolicyDateRange[]): string[] {
        return DateRangeSingleDatesTranslator.translateToDateTokens(
            (dateRanges || []).map(range => ({
                from: range.start,
                to: range.end,
            })),
        );
    }

    private sortByPriority(a: BookingPaymentPolicy, b: BookingPaymentPolicy): number {
        const keyA = a.priority;
        const keyB = b.priority;

        if (keyA < keyB) {
            return -1;
        }
        if (keyA > keyB) {
            return 1;
        }

        return 0;
    }
}
