import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from './../../../../environments/environment';
import { EndpointService } from './../endpoint.service';
import { Bookability } from './interfaces/bookability.interface';
import { BookabilityServiceInterface } from './interfaces/bookability.service.interface';

@Injectable()
export class BookabilityService extends EndpointService implements BookabilityServiceInterface {

    constructor(http: HttpClient) {
        super(http);
    }

    getBookability(hotelId: string): Observable<Bookability>;
    getBookability(hotelId: string, startDate: string, numberOfDays: number): Observable<Bookability>;
    getBookability(hotelId: string, startDate?: string, numberOfDays?: number): Observable<Bookability> {
        let url: string = `${environment.baseUrls.switch}/switch/api/properties/${hotelId}/bookability.json`;
        if (startDate && numberOfDays) {
            url += `?startDate=${startDate}`;
            url += `&numberOfDays=${numberOfDays}`;
        }

        return this.httpGet<Bookability>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }
}
