import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CpcPaymentInfo } from '@bmng/services/cpc-payments/interfaces/cpc-payment-info.interface';
import { Observable } from 'rxjs';

import { EndpointService } from './../endpoint.service';
import { CpcPaymentsServiceInterface } from './interfaces/cpc-payments.service.interface';

@Injectable()
export class CpcPaymentService extends EndpointService implements CpcPaymentsServiceInterface {

    constructor(http: HttpClient) {
        super(http);
    }

    getCpcPayments(hotelId: string): Observable<CpcPaymentInfo> {
        const url = `${EndpointService.getBmBackendUrl()}/api/cpc/meta/properties/${hotelId}/payments.json`;

        return this.httpGet<CpcPaymentInfo>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }
}
