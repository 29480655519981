import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { LoginService } from '@bmng/services/login.service';
import { UsersService } from '@bmng/services/users/users.service';
import { catchError, map } from 'rxjs/operators';


export const bmLoginGuardServiceCanActivate: ResolveFn<boolean> =
    (route: ActivatedRouteSnapshot) => {
        const usersService = inject(UsersService);
        const loginService = inject(LoginService);
        const language: string = route.params.language.toLowerCase();

        return usersService.getUserSettingsFromOldBm(language)
            .pipe(
                map((data) => !!data),
                catchError(err => loginService.catchUnauthenticatedUserFromLoginGuard(err)),
            );
    };
