import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { StackableRoutingNavigatorService } from '@bmng/services/navigation/stackable-routing-navigator.service';
import { PageService } from '@bmng/services/page.service';
import { EventService } from '@kognitiv/bm-components';
import { Subscription } from 'rxjs';

@Component({
    selector: 'action-bar-desktop',
    templateUrl: './action-bar-desktop.component.html',
    styleUrls: [ './action-bar-desktop.component.scss' ],
})
export class ActionBarDesktopComponent implements OnInit, OnDestroy {

    @HostBinding('class.floating')
    isFloating: boolean = false;

    @HostBinding('class.modal-view')
    isModalOpened: boolean = false;

    private subscriptions: Subscription = new Subscription();

    constructor(
        public pageService: PageService,
        private eventService: EventService,
        private stackNavSrv: StackableRoutingNavigatorService,
    ) {
        this.subscriptions.add(this.stackNavSrv.getActiveStackLevel().subscribe(_ => {
            this.isModalOpened = this.stackNavSrv.getStackHeight() >= 1;
        }));
    }

    ngOnInit(): void {
        this.setUpScrollListener();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private setUpScrollListener(): void {
        this.eventService.onElementScroll('.content-container', 15).subscribe(evt => {
            const element: HTMLElement = evt.srcElement as HTMLElement;
            this.isFloating = element.scrollTop >= 80;
        });
    }
}
