import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'imgToFavicon',
})
export class ImgToFaviconPipe implements PipeTransform {
    // Extract the sources matched with the ids passed by param
    transform(img: string): string {
        if (!img) {
            return '';
        }
        return img.replace('upload', 'upload/w_200,c_fill,h_200');
    }
}
